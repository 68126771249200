import React from "react";

export default function MinusIcon(props: Icon) {
  const { height, width, style } = props;
  return (
    <svg
      style={style}
      width={width ? width : height ? height : 16}
      height={height ? height : width ? width : 16}
      viewBox="0 0 16 16"
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z" />
    </svg>
  );
}
