import React, { useState, useRef, MutableRefObject, ReactNode } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import ChevronDown from "../icons/ChevronDown";
import useMediaQuery from "hooks/useMediaQuery";

const variants = {
  open: { height: "auto" },
  closed: { height: 0 },
};

export default function StatusDialog(props: IStatusDialog) {
  const {
    dropdownTriggerStyle,
    activeButtonStyle,
    menuClassNames,
    element,
    menu,
    width,
    // state,
    scroll,
    noArrow,
    arrowClass,
    title,
  } = props;

  const ref = useRef<HTMLDivElement>();
  const elmRef = useRef<any>();
  const matchesMobile = useMediaQuery("(max-width: 500px)");
  const [open, setOpen] = useState(false);

  useOnClickOutside(ref, (e) => {
    e.stopPropagation();
    setOpen(false);
  });

  return (
    <div
      ref={ref as MutableRefObject<HTMLDivElement>}
      className="position-relative h-100"
      style={{ width: width }}
      key="dropdown-container"
    >
      <button
        ref={elmRef as any}
        className={`d-flex align-items-center justify-content-between gap-2 h-100 rounded-pill ${dropdownTriggerStyle} ${
          activeButtonStyle && open ? "bg-secondary-subtle text-primary" : ""
        }`}
        style={{
          padding: "0 1rem",
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(!open);
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            setOpen(open);
          } else if (e.keyCode === 27) {
            setOpen(false);
          }
        }}
        key="dialog-trigger"
        aria-label={title}
      >
        {element}
        {!noArrow && (
          <span
            style={{ transition: "transform 2s ease-in-out" }}
            className={`d-flex align-items-center ${
              arrowClass ? arrowClass : ""
            }`}
          >
            <ChevronDown
              style={{
                transform: open ? "scaleY(-1)" : "scaleY(1)",
                transition: "transform 0.4s ease-in-out",
              }}
            />
          </span>
        )}
      </button>
      <AnimatePresence>
        {open && (
          <motion.div
            className={`rounded-3 shadow-lg bg-white d-flex flex-column list-group align-items-center ${menuClassNames}`}
            style={
              matchesMobile
                ? {
                    position: "absolute",
                    top: `calc(100% + 1em)`,
                    zIndex: 600,
                    minWidth: "200px",
                    maxWidth: "100vw",
                    width: "calc( 100vw - 6em)",
                    left: 0,
                    right: "auto",
                    overflowY: scroll ? "scroll" : "hidden",
                    overflowX: "hidden",
                    maxHeight: scroll ? "300px" : "",
                  }
                : {
                    position: "absolute",
                    top: "100%",
                    zIndex: 600,
                    overflowY: scroll ? "scroll" : "hidden",
                    overflowX: "hidden",
                    maxHeight: scroll ? "300px" : "",
                    minWidth: "200px",
                    maxWidth: "450px",
                  }
            }
            variants={variants}
            initial="closed"
            animate="open"
            exit={variants.closed}
            transition={{ damping: 300 }}
            key={"dropdown-menu" + Math.floor(Math.random())}
            onKeyDown={(e) => {
              if (e.keyCode === 27) {
                setOpen(false);
              }
            }}
          >
            {menu}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

interface IStatusDialog {
  element: JSX.Element;
  elementClassNames?: string;
  menu: ReactNode;
  menuClassNames?: string;
  state?: { menuOpen: boolean; setMenuOpen: Function };
  direction?: "up" | "down";
  scroll?: boolean;
  preventCloseOnSelect?: boolean;
  dropdownTriggerStyle?: string;
  noArrow?: boolean;
  arrowClass?: string;
  width?: string;
  right?: string;
  activeButtonStyle?: boolean;
  dropdownButtonStyle?: string;
  title?: string;
}
