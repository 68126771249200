import React from "react";
import PersonCircle from "./icons/PersonCircle";
import ToggleOff from "./icons/ToggleOff";
import ToggleOn from "./icons/ToggleOn";

export function FilterToggleButton(props: {
  isActive: boolean;
  setIsActive: (active: boolean) => void;
}) {
  const { isActive, setIsActive } = props;
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsActive(!isActive);
      }}
    >
      {isActive && (
        <div
          role="button"
          className="d-flex justify-content-between align-items-center border-bottom px-3 py-3"
        >
          <div className="d-flex align-items-center gap-2">
            <span
              className="d-flex align-items-center text-primary"
              style={{ opacity: 0.9 }}
            >
              <PersonCircle width={21} />
            </span>
            <p className="mb-0 fs-4 text-primary">Vis kun mine opgaver: </p>
          </div>
          <div className="d-flex align-items-center gap-2 text-primary">
            <ToggleOn width={24} />
            <p className="mb-0 text-muted fs-4">
              <span className="fw-semibold text-muted opacity-50">Nej</span> /{" "}
              <span className="fw-semibold text-primary">Ja</span>
            </p>
          </div>
        </div>
      )}
      {!isActive && (
        <div
          className="d-flex justify-content-between align-items-center border-bottom px-3 py-3"
          role="button"
        >
          <div className="d-flex align-items-center gap-2">
            <span
              className="d-flex align-items-center text-primary"
              style={{ opacity: 0.9 }}
            >
              <PersonCircle width={21} />
            </span>
            <p className="mb-0  text-primary">Vis kun mine opgaver: </p>
          </div>
          <div className="d-flex align-items-center gap-2">
            <span className="d-flex align-items-center text-primary">
              <ToggleOff width={24} />
            </span>
            <p className="mb-0 text-muted fs-4">
              <span className="fw-semibold text-primary">Nej</span> /{" "}
              <span className="fw-semibold text-muted opacity-50">Ja</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
