import { ResultAppender } from '@/models/ResultAppender';
import { ResultType, SearchResult } from '@/models/SearchResult';
import { SourceBase } from '@/models/SourceBase';
import { Grund, GrundFetcher } from '../Data/GrundFetcher';

export class EsrToGrundAppender extends ResultAppender {
    public grundFetcher: GrundFetcher;
    public source: SourceBase;

    constructor(source: SourceBase) {
        super();
        this.source = source;
        this.grundFetcher = new GrundFetcher(
            source.options.dafUser,
            source.options.dafPassword,
            source.options.dafEjendomsrelationDate);
    }

    public CanAppendResult(resultDataType: string): boolean {
        return resultDataType === 'ejendomsrelation';
    }

    public async getResults(searchresult: SearchResult, source: SourceBase): Promise<SearchResult[]> {
        if (!searchresult.data || searchresult.data.length === 0) {
            return [];
        }

        const result = await this.grundFetcher.GetFromEsr(
            searchresult.data[0].kommune.KomKode,
            searchresult.data[0].ejendomsnummer,
        );

        return result.map((r: Grund) =>
            GrundFetcher.MapToSearchResult(
                `${searchresult.id}_${r.id_lokalId}`,
                source.name,
                r,
                source.getResultType('grund'),
            ),
        );

    }
}
