import Vue from 'vue';
import Vuex from 'vuex';
import { Initialize, Localization, Layout, Behavior } from '@/models/Initialize';
import { InitializeHelper } from '@/helpers/InitializeHelper';
import { SourceWorker } from '@/models/SourceWorker';
import { IHistorySourceOptions } from '@/components/Sources/Common/HistorySource';
import { SourceResult } from '@/models/SourceResult';
import { SearchInput } from '@/models/SearchInput';
import { ResultType, SearchResult } from '@/models/SearchResult';
import { ResultBehaviour } from '@/models/ResultBehaviour';
import { SourcenameAndId } from '@/models/SourcenameAndId';
import { Widgets } from '@/models/Widgets/Widgets';
import { eventbus, eventNames } from '@/helpers/EventBus';
import { NameValue } from '@/models/NameValue';
import { SelectedItemPayload } from '@/models/SelectedItemPayload';

Vue.use(Vuex);

const storeFactory = (initialize: Initialize) => {
    let historyList: SearchResult[] = [];
    if (localStorage) {
        const history = localStorage.getItem(initialize.instanceId + '_autocomplete-history');
        if (history) {
            try {
                historyList = JSON.parse(history);
                if (historyList && historyList.length > 0) {
                    let clearHistory = false;

                    if (historyList.findIndex((h) => !h.id || !h.data || !h.data.id) > -1) {
                        clearHistory = true;
                    }

                    // tslint:disable-next-line:max-line-length
                    if (!clearHistory && Array.from(new Set(historyList.map((h) => h.id))).length !== historyList.length) {
                        clearHistory = true;
                    }

                    if (clearHistory) {
                        throw new Error('History is not valid');
                    }
                }
            } catch (error) {
                historyList = [];
            }
        }
    }

    let sourceWorkers = [];
    sourceWorkers = InitializeHelper.ParseSources(initialize).map(
        (s) => new SourceWorker(s),
    );

    // tslint:disable-next-line:max-line-length
    const iconName = initialize.layout.iconProvider.name ? initialize.layout.iconProvider.name.replace(' ', '').toLowerCase() : 'custom';
    // tslint:disable-next-line:max-line-length
    const iconType = initialize.layout.iconProvider.type ? initialize.layout.iconProvider.type.replace(' ', '').toLowerCase() : 'custom';


    const events: { [name: string]: Array<(payload: any) => void>; } = {};
    const callEvent = (name: string, payload: any) => {
        if (events[name]) {
            for (const eventFunction of events[name]) {
                eventFunction(payload);
            }
        }
    };

    const store = new Vuex.Store({
        state: {
            localization: Object.assign({}, initialize.localization),
            widgets: Object.assign({}, initialize.widgets),
            layout: Object.assign({}, initialize.layout),
            behavior: Object.assign({}, initialize.behavior),
            instanceId: initialize.instanceId,
            history: historyList,
            sourceWorkers,
            activeSource: null,
            isParentLoading: false,
            isLocked: false,
            selectedItem: null,
            searchInput: {
                id: null,
                text: '',
                suggestion: null,
            },
            inputText: '',
            searchText: '',
            isActive: false,
            menuActive: false,
            version: process.env.VUE_APP_VERSION,
            isNewSearch: true,
        } as IState,
        getters: {
            getIcon(state: IState) {
                return (nameOrElement: string) => state.layout.iconProvider.getIcon(nameOrElement);
            },
            getIconType(state: IState) {
                return iconType;
            },
            isLocked(state: IState) {
                return state.isParentLoading || state.isLocked;
            },
            getIconName(state: IState) {
                return iconName;
            },
            history(state: IState) {
                return state.history;
            },
            sourceWorkers(state: IState) {
                return state.sourceWorkers;
            },
            localization(state: IState) {
                return state.localization;
            },
            activeSource(state: IState) {
                return state.activeSource;
            },
            searchButtonPlacement(state: IState) {
                return state.layout.searchButtonPlacement;
            },
            menuButtonPlacement(state: IState) {
                return state.layout.menuButtonPlacement;
            },
            showHistoryWidget(state: IState) {
                return !!state.widgets.historyWidget && !state.activeSource && state.history.length > 0;
            },
            items(state: IState) {
                if (state.activeSource) {
                    return state.activeSource.groupResults;
                } else if (state.behavior.showResultsIfSingleGroup) {
                    const workers = state.sourceWorkers.filter((w) => w.hasResult(state.searchInput));
                    // tslint:disable-next-line:max-line-length
                    return [].concat.apply([], workers.map((w) => w.getSearchResults(state.searchInput, workers.length === 1 && workers[0].isAutoComplete)));
                } else {
                    // tslint:disable-next-line:max-line-length
                    return [].concat.apply([], state.sourceWorkers.map((w) => w.getSearchResults(state.searchInput)));
                }
            },
            totalItems(state: IState, getters: any) {
                return getters.items.length;
            },
            isLoading(state: IState) {
                return !!state.sourceWorkers.find((s) => s.isLoading);
            },
            canClear(state: IState) {
                return (state.inputText || state.activeSource) && !state.isParentLoading && !state.isLocked;
            },
            canSearch(state: IState, getters: any) {
                if (getters.isLocked) {
                    return false;
                }

                if (!!state.selectedItem) {
                    return true;
                }

                if (!!initialize.events.search && state.searchText && !state.activeSource) {
                    return true;
                }

                if (state.inputText !== state.searchText) {
                    return true;
                }

                if (!state.selectedItem) {
                    if (getters.totalItems === 1) {
                        return true;
                    }

                    if (getters.totalItems > 0 && state.behavior.enterSelectsFirst) {
                        return true;
                    }
                }

                if (getters.canPerformNonAutocompleteSearch) {
                    return true;
                }

                return false;
            },
            canPerformNonAutocompleteSearch(state: IState) {
                return state.activeSource &&
                    !state.activeSource.isAutoComplete &&
                    state.activeSource.isNotLatestInput(state.searchInput);
            },
        },
        actions: {
            on(context: any, payload: { name: string, eventFunction: (payload: any) => void }) {
                if (events[payload.name]) {
                    events[payload.name].push(payload.eventFunction);
                } else {
                    events[payload.name] = [payload.eventFunction];
                }
            },
            setActiveSource(context: any, source: SourceWorker) {
                context.commit('activeSource', source);
                context.dispatch('setFocus');
            },
            clearHistory(context: any) {
                context.commit('clearHistory');
            },
            selectNext(context: any) {
                if (context.getters.totalItems === 0) {
                    return;
                }

                const currentIndex = context.getters.items
                    .findIndex((i: SourceResult) => i === context.state.selectedItem);
                for (let index = currentIndex + 1; index < context.getters.totalItems; index++) {
                    if (!context.getters.items[index].sourceWorker.isDisabled) {
                        context.dispatch('setSelectedItem', { selectedItem: context.getters.items[index] } as SelectedItemPayload);
                        return;
                    }
                }

                context.dispatch('setSelectedItem', null);
            },
            selectPrevious(context: any) {
                if (context.getters.totalItems === 0) {
                    return;
                }

                const currentIndex = context.getters.items
                    .findIndex((i: SourceResult) => i === context.state.selectedItem);
                for (let index = currentIndex - 1; index >= -1; index--) {
                    if (index === -1 || !context.getters.items[index].sourceWorker.isDisabled) {
                        context.dispatch('setSelectedItem', { selectedItem: context.getters.items[index] } as SelectedItemPayload);
                        return;
                    }
                }

                for (let index = context.getters.totalItems - 1; index > -1; index--) {
                    if (!context.getters.items[index].sourceWorker.isDisabled) {
                        context.dispatch('setSelectedItem', { selectedItem: context.getters.items[index] } as SelectedItemPayload);
                        return;
                    }
                }

                context.dispatch('setSelectedItem', null);
            },
            setSelectedItem(context: any, payload: SelectedItemPayload) {
                const selectedItem = payload ? payload.selectedItem : null;
                context.commit('selectedItem', selectedItem);
                let skipSetInput = payload && payload.skipSetInput;
                if (!skipSetInput && context.state.activeSource &&
                    context.state.activeSource.isReadOnly) {
                    skipSetInput = true;
                }

                if (!skipSetInput && selectedItem && selectedItem.searchResult &&
                    selectedItem.searchResult.resultType !== ResultType.suggestion) {
                    skipSetInput = true;
                }

                if (!skipSetInput) {
                    context.commit('inputText', selectedItem && selectedItem.searchResult ? selectedItem.searchResult.title : context.state.searchText);
                }

                context.dispatch('itemHovered', selectedItem);
            },
            performSourceSearch(context: any, setInput: boolean) {
                context.state.searchInput.id = null;
                
                const searchInput = context.state.searchInput;
                const deletedChars = setInput && searchInput && context.state.inputText && searchInput.text.length > context.state.inputText.length;

                if (setInput) {
                    context.commit('searchText', context.state.inputText);
                }

                if (context.state.searchText.startsWith(':')) {
                    for (const sourceResult of context.state.sourceWorkers) {
                        sourceResult.clearResult();
                    }
                    return;
                }

                store.commit('isNewSearch', true);
                const worker = context.state.activeSource as SourceWorker;
                if (worker) {
                        if(worker.isAutoComplete || !setInput){
                            worker.search(context.state.searchInput)
                        
                        .then((valid: boolean)=>{
                            if(valid && !deletedChars && worker.hasResult && worker.resultCount === 1){
                                const result = worker.groupResults[0];
                                if(result.searchResult.resultType === ResultType.result || result.searchResult.resultType === ResultType.extendedResult){
                                    context.dispatch('selectResult', result);
                                }
                            }
                        });
                    }else if (worker.resultCount > 0 &&
                        worker.isNotLatestInput(context.state.searchInput)){
                        worker.clearResult();
                    }
                    return;
                }

                for (const sourceWorker of context.state.sourceWorkers as SourceWorker[]) {
                    if (sourceWorker.isAutoComplete) {
                        sourceWorker.search(context.state.searchInput);
                    }
                }
            },
            selectResult(context: any, sourceResult: SourceResult) {
                if (context.getters.isLocked) {
                    return;
                }
                /** Det er en gruppe der er valgt */
                if (!sourceResult.searchResult) {
                    context.commit('activeSource', sourceResult.sourceWorker);
                    if (!sourceResult.sourceWorker.isAutoComplete) {
                        
                        context.dispatch('performSourceSearch', false);
                    }
                    return;
                }

                if (
                    sourceResult.searchResult.resultType === ResultType.text &&
                    initialize.events.search
                ) {
                    initialize.events.search(sourceResult.searchResult.title);
                    context.commit('addToHistory', sourceResult.searchResult);
                    context.commit('hideAutocomplete');
                    return;
                }

                // TODO: Denne skal lige testes!!!
                context.commit('inputText', sourceResult.searchResult.inputText || sourceResult.searchResult.title);
                context.commit('searchText', sourceResult.searchResult.title);

                context.dispatch('setFocus');

                if (sourceResult.searchResult.resultType === ResultType.history) {
                    if (sourceResult.searchResult.data.resultType === ResultType.result ||
                        sourceResult.searchResult.data.resultType === ResultType.extendedResult) {

                        context.dispatch('setResultById',
                            {
                                id: sourceResult.searchResult.data.id,
                                source: sourceResult.searchResult.data.source,
                            } as SourcenameAndId,
                        );
                        return;
                    } else {
                        context.dispatch('selectResult',
                            {
                                searchResult: sourceResult.searchResult.data,
                            } as SourceResult,
                        );
                        return;
                    }
                }

                if (sourceResult.searchResult.resultType === ResultType.searchById) {
                    // tslint:disable-next-line:max-line-length
                    context.dispatch('searchById', { id: sourceResult.searchResult.data.id, source: sourceResult.searchResult.data.source } as SourcenameAndId);
                    return;
                }

                if (context.state.behavior.showResultsIfSingleGroup && !context.state.activeSource) {
                    context.commit('activeSource', sourceResult.sourceWorker);
                }

                if (sourceResult.searchResult.resultType === ResultType.suggestion) {
                    context.commit('searchInputSuggestion', sourceResult.searchResult);
                    context.dispatch('performSourceSearch', false);
                    context.commit('searchInputSuggestion', null);
                    return;
                }

                if (initialize.events.itemClicked) {
                    if(context.state.selectedItem != null && context.state.selectedItem.searchResult != null){
                }
                    if (sourceResult.searchResult.resultType === ResultType.extendedResult) {
                        if (sourceResult.sourceWorker.source.getExtendedResultWithAppenders) {
                            sourceResult.sourceWorker.hasError = false;
                            sourceResult.sourceWorker.isBusy = true;
                            context.commit('isLocked', true);
                            sourceResult.sourceWorker.source.getExtendedResultWithAppenders(sourceResult.searchResult)
                                .then((result: SearchResult) => {
                                    sourceResult.sourceWorker.isBusy = false;
                                    context.commit('isLocked', false);
                                    initialize.events.itemClicked(result);
                                    context.commit('addToHistory', sourceResult.searchResult);
                                    context.commit('hideAutocomplete');
                                }).catch((error) => {
                                    sourceResult.sourceWorker.hasError = true;
                                    sourceResult.sourceWorker.isBusy = false;
                                    context.commit('isLocked', false);
                                });
                            return;
                        }
                    }

                    initialize.events.itemClicked(sourceResult.searchResult);
                    context.commit('addToHistory', sourceResult.searchResult);
                }

                context.commit('hideAutocomplete');
            },
            search(context: any) {
                if (context.state.searchText.startsWith(':')) {
                    if (context.state.searchText === ':version') {
                        alert('Version ' + context.state.version);
                    }
                    if (context.state.searchText === ':clearhistory') {
                        context.dispatch('clearHistory');
                        alert('Done');
                    }

                    return;
                }

                if (!context.getters.canSearch) {
                    return;
                }



                if (!!context.state.selectedItem) {
                    context.dispatch('selectResult', context.state.selectedItem);
                    return;
                }

                if (context.state.inputText !== context.state.searchText) {
                    context.dispatch('performSourceSearch', true);
                    return;
                }

                if (context.getters.canPerformNonAutocompleteSearch) {
                    context.dispatch('performSourceSearch', false);
                    return;
                }

                if (!context.state.selectedItem && context.getters.totalItems === 1) {
                    context.dispatch('selectResult', context.getters.items[0]);
                    return;
                }

                if (!context.state.selectedItem && context.getters.totalItems > 0 &&
                    this.state.behavior.enterSelectsFirst) {
                    context.dispatch('selectResult', context.getters.items[0]);
                    return;
                }

                if (
                    context.state.searchText &&
                    initialize.events.search &&
                    !context.state.activeSource
                ) {
                    initialize.events.search(context.state.searchText);
                    return;
                }
            },
            clear(context: any, all: boolean) {
                if (context.getters.isLocked) {
                    return;
                }

                if (!all && context.state.inputText === '' && context.state.activeSource) {
                    context.dispatch('activateGroup', null);
                    return;
                }

                context.dispatch('activateGroup', null);

                context.commit('inputText', '');
                context.commit('searchText', '');
                context.commit('searchInput', { id: null, suggestion: null, text: '' } as SearchInput);

                for (const sourceWorker of context.state.sourceWorkers as SourceWorker[]) {
                    sourceWorker.clearResult();
                }

                if (all && initialize.events.cleared) {
                    initialize.events.cleared();
                }
            },
            activateGroup(context: any, sourceWorker: SourceWorker) {
                if (sourceWorker && sourceWorker.isDisabled) {
                    return;
                }

                context.commit('activeSource', sourceWorker);

                if (!sourceWorker) {
                    context.dispatch('performSourceSearch', false);
                }

                context.dispatch('setFocus');
            },
            setFocus(context: any) {
                eventbus.$emit(eventNames.setFocus);
            },
            getResultById(context: any, payload: SourcenameAndId): Promise<SearchResult> {
                if (context.getters.isLocked) {
                    return;
                }

                const worker = context.state.sourceWorkers.find(
                    (s: SourceWorker) => s.source.name.toLowerCase() === payload.source.toLowerCase(),
                );
                return worker.source.get(payload.id);
            },
            setResultById(context: any, payload: SourcenameAndId) {
                if (context.getters.isLocked) {
                    return;
                }

                for (const source of context.state.sourceWorkers) {
                    if (source.source.name.toLowerCase() === payload.source.toLowerCase()) {
                        source.source.get(payload.id).then((result: SearchResult) => {
                            if (result) {
                                if (source.source.sourceType === ResultBehaviour.default) {
                                    context.commit('activeSource', source);
                                }
                                context.dispatch('selectResult', {
                                    component: null,
                                    searchResult: result,
                                    sourceWorker: source,
                                });
                            }
                        });
                    } else {
                        source.clearResult();
                    }
                }
            },
            searchById(context: any, payload: SourcenameAndId) {
                if (context.getters.isLocked) {
                    return;
                }

                for (const source of context.state.sourceWorkers as SourceWorker[]) {
                    if (source.source.name.toLowerCase() === payload.source.toLowerCase()) {
                        context.commit('activeSource', source);
                        source.search({ id: payload.id, text: 'ssssss' });
                    } else {
                        source.clearResult();
                    }
                }
            },
            itemHovered(context: any, sourceResult: SourceResult) {
                if (sourceResult && sourceResult.searchResult && initialize.events.itemHover) {
                    initialize.events.itemHover(sourceResult.searchResult);
                }
            },
            setMenuActive(context: any, menuActive: boolean) {
                context.commit('menuActive', menuActive);
                if (initialize.events.menuActiveChanged) {
                    initialize.events.menuActiveChanged(menuActive);
                }
            },
        },
        mutations: {
            menuActive(state: IState, value: boolean) {
                state.menuActive = value;
            },
            isLocked(state: IState, value: boolean) {
                state.isLocked = value;
            },
            isParentLoading(state: IState, value: boolean) {
                state.isParentLoading = value;
            },
            isNewSearch(state: IState, value: boolean) {
                state.isNewSearch = value;
            },
            activeSource(state: IState, source: SourceWorker) {
                state.activeSource = source;
            },
            selectedItem(state: IState, item: SourceResult) {
                state.selectedItem = item;
            },
            clearHistory(state: IState) {
                state.history = [];
            },
            hideAutocomplete(state: IState) {
                state.isActive = false;
            },
            showAutocomplete(state: IState) {
                state.isActive = true;
            },
            searchText(state: IState, value: string) {
                state.searchText = value;
                state.searchInput.text = value;
            },
            inputText(state: IState, value: string) {
                state.inputText = value;
            },
            searchInput(state: IState, value: SearchInput) {
                state.searchInput = value;
            },
            searchInputSuggestion(state: IState, suggestion: SearchResult) {
                state.searchInput.suggestion = suggestion;
            },
            addToHistory(state: IState, result: SearchResult) {
                if (result) {
                    if (result.resultType === ResultType.history) {
                        return;
                    }

                    const newId = result.source + '_' + result.id;
                    const index = state.history.findIndex((h) => h.id === newId);
                    if (index > -1) {
                        state.history.splice(index, 1);
                    }

                    // tslint:disable-next-line:max-line-length
                    state.history.unshift({
                        id: newId,
                        title: result.title,
                        description: result.description,
                        // tslint:disable-next-line:max-line-length
                        resultType: ResultType.history,
                        source: result.source,
                        datatype: null,
                        data: {
                            id: result.id, title: result.title,
                            description: result.description, resultType: result.resultType, source: result.source,
                        },
                    });

                    if (state.history.length > 30) {
                        state.history.splice(state.history.length - 1, 1);
                    }
                    // tslint:disable-next-line:max-line-length
                    localStorage.setItem(initialize.instanceId + '_autocomplete-history', JSON.stringify(state.history));
                }
                for (const source of state.sourceWorkers) {
                    if (source.source.name === 'history') {
                        if (!(source.source.options as IHistorySourceOptions).sourceAndName) {
                            // tslint:disable-next-line:max-line-length
                            (source.source.options as IHistorySourceOptions).sourceAndName = state.sourceWorkers.map((w) => ({ sourceId: w.source.name, sourceName: w.source.badgeTitle } as NameValue));
                        }
                        (source.source.options as IHistorySourceOptions).history = state.history;
                        break;
                    }
                }
            },
        },
    });

    store.watch(
        (state, getters) => getters.items,
        (newValue: SourceResult[], oldValue: SourceResult[]) => {
            if (newValue !== oldValue) {
                const isNewSearch = store.state.isNewSearch;


                if (isNewSearch) {
                    if (newValue.length > 0) {
                        store.commit('isNewSearch', false);

                        if(store.state.behavior.enterSelectsFirst){
                            store.dispatch('setSelectedItem', { selectedItem: newValue[0], skipSetInput: true } as SelectedItemPayload);
                            return;
                        }
                    }
                    store.dispatch('setSelectedItem', null);
                    return;
                }

                store.commit('searchInputSuggestion', null);
                let selectedItem: SourceResult = null;

                if (store.state.activeSource && !store.state.activeSource.isAutoComplete && newValue.length === 1) {
                    store.dispatch('selectResult', newValue[0]);
                    return;
                }
                
                if(!selectedItem && store.state.behavior.enterSelectsFirst && newValue && newValue.length > 0){
                    store.dispatch('setSelectedItem', { selectedItem: newValue[0], skipSetInput: true } as SelectedItemPayload);
                    return;
                }
            }
        },
    );

    if (initialize.events.hasActiveSource) {
        store.watch(
            (state) => state.activeSource,
            (activeSource) => {
                initialize.events.hasActiveSource(!!activeSource);
            },
        );
    }

    store.watch(
        (state) => state.isActive,
        (isActive) => {
            callEvent('isActive', isActive);
        },
    );

    if (initialize.events.loadingChanged) {
        store.watch(
            (state, getters) => getters.isLoading,
            (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    initialize.events.loadingChanged(newValue);
                }
            },
        );
    }

    store.commit('addToHistory', null);

    return store;
};


export default storeFactory;

export interface IState {
    instanceId: string;
    isActive: boolean;
    localization: Localization;
    widgets: Widgets;
    layout: Layout;
    behavior: Behavior;
    history: SearchResult[];
    sourceWorkers: SourceWorker[];
    activeSource: SourceWorker;
    isParentLoading: boolean;
    isLocked: boolean;
    selectedItem: SourceResult;
    searchInput: SearchInput;
    inputText: string;
    searchText: string;
    menuActive: boolean;
    version: string;
    isNewSearch: boolean;
}
