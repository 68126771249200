import ChrOplysninger from "./ChrOplysninger";
import CvrVirksomheder from "./CvrVirksomheder";
import CvrBranchekoder from "./CvrBranchekoder";
import ElOplysninger from "./ElOplysninger";
import SmileyBranchekoder from "./SmileyBranchekoder";
import SmileyVirksomheder from "./SmileyVirksomheder";
import styles from "./SupplerendeOplysninger.module.scss";

export default function SupplerendeOplysninger(props: SupplerendeOplysninger) {
  const {
    chrOplysninger,
    cvrBranchekoder,
    cvrVirksomheder,
    elOplysninger,
    //energiOplysninger,
    smileyBranchekoder,
    smileyVirksomheder,
    landbrugspligtOplysninger,
    elmaalerOplysninger,
  } = props;

  if (
    !chrOplysninger?.oplysninger.length &&
    !cvrBranchekoder?.oplysninger.length &&
    !cvrVirksomheder?.oplysninger.length &&
    !elOplysninger?.oplysninger.length &&
    //!energiOplysninger?.oplysninger.length &&
    !smileyBranchekoder?.oplysninger.length &&
    !smileyVirksomheder?.oplysninger.length &&
    !landbrugspligtOplysninger?.oplysninger.length &&
    !elmaalerOplysninger?.oplysninger.length
  )
    return null;
  const landbrugsplikt =
    landbrugspligtOplysninger?.oplysninger.reduce(function (
      landnrigsplikt,
      oplysning
    ) {
      if (oplysning.landbrugspligt) return "Ja";
      return landnrigsplikt;
    },
    "Nej") ?? "Nej";
  // get antal from oplysning with the most recent aarstal
  const antalElmaalere =
    elmaalerOplysninger?.oplysninger.reduce(
      function (prev, curr) {
        if (curr.aarstal > prev.aarstal)
          return { antal: curr.antal, aarstal: curr.aarstal };
        return prev;
      },
      { antal: 0, aarstal: 0 }
    ).antal ?? 0;
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className="text-info">Supplerende oplysninger</h3>
        <i>Disse oplysninger stammer ikke fra BBR</i>
      </div>
      <div className={styles.content}>
        <div className="container-fluid g-0">
          <div className="row gx-5 pt-5 pb-3">
            <div className="col">
              <h5 className="text-info">Elinstallation</h5>
              <p className="m-0">
                {antalElmaalere} elinstallation
                {antalElmaalere === 0 || antalElmaalere > 1 ? "er" : ""}
              </p>
            </div>
            <div className="col">
              <h5 className="text-info">Landbrugspligt</h5>
              <p className="m-0">{landbrugsplikt}</p>
            </div>
          </div>
          <hr className={styles.hr} />
          <div className="row gx-5 py-4">
            <div className="col d-flex flex-column gap-5">
              {cvrVirksomheder && cvrVirksomheder.oplysninger.length > 0 && (
                <CvrVirksomheder virksomheder={cvrVirksomheder} />
              )}
              {smileyVirksomheder &&
                smileyVirksomheder.oplysninger.length > 0 && (
                  <SmileyVirksomheder smileyVirksomheder={smileyVirksomheder} />
                )}
              {cvrBranchekoder && cvrBranchekoder.oplysninger.length > 0 && (
                <CvrBranchekoder branchekoder={cvrBranchekoder} />
              )}
            </div>

            {chrOplysninger && chrOplysninger.oplysninger.length > 0 && (
              <div className="col">
                <ChrOplysninger chrOplysninger={chrOplysninger} />
              </div>
            )}
            {elOplysninger && elOplysninger.oplysninger.length > 0 && (
              <div className="col-12">
                <ElOplysninger eloplysninger={elOplysninger} />
              </div>
            )}
            {/*energiOplysninger && energiOplysninger.oplysninger.length > 0 && (
          <EnergiOplysninger energioplysninger={energiOplysninger} />
        )*/}
            {smileyBranchekoder &&
              smileyBranchekoder.oplysninger.length > 0 && (
                <div className="col-12">
                  <SmileyBranchekoder smileyBranchekoder={smileyBranchekoder} />
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
