import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import { Initialize } from './models/Initialize';
import { InitializeHelper } from './helpers/InitializeHelper';
import tooltip from './helpers/directives/Tooltip';
import store from './store/store';
import { eventbus, eventNames } from './helpers/EventBus';
import { DefaultIconProvider } from './models/IconProviders/DefaultIconProvider';
import { MaterialDesignIconProvider } from './models/IconProviders/MaterialDesignIconProvider';
import { SourceWorker } from './models/SourceWorker';
import { EjendomFetcher } from './components/Sources/Data/EjendomFetcher';
import { ResultType, SearchResult } from './models/SearchResult';

Vue.use(Vuex);

Vue.config.productionTip = false;
tooltip();

function createSearchComponentHist(initialize: Initialize) {

  InitializeHelper.Normalize(initialize);

  Object.freeze(initialize);

  const vm = new Vue({
    store: store(initialize),
    render: (h) => h(App),
  }).$mount(initialize.elementOrSelector);

  return {
    destroy() {
      vm.$destroy();
      (vm.$el).innerHTML = '';
      if (initialize.elementOrSelector as string !== '') {
        vm.$el.setAttribute('id', initialize.elementOrSelector.toString().replace('#', ''));
      }
    },
    setLoading(value: boolean) {
      vm.$store.commit('isParentLoading', value);
    },
    setMenuActive(value: boolean) {
      vm.$store.commit('menuActive', value);
    },
    getResultById(source: string, id: string) {
      return vm.$store.dispatch('getResultById', { source, id });
    },
    setResultById(source: string, id: string) {
      vm.$store.dispatch('setResultById', { source, id });
    },
    getSearchResultsFromBfeNumbers(bfeNumre: number[]): Promise<SearchResult[]> {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await EjendomFetcher.GetDafResultsFromBFE(bfeNumre,
            initialize.datafordeleren.dafUser,
            initialize.datafordeleren.dafPassword, initialize.datafordeleren.dafEjendomsrelationDate);
          return resolve(result.map((r) =>
            EjendomFetcher.MapDafResultsToSearchResult(
              null,
              this.name,
              r,
              'bfe',
              ResultType.result,
              null)));
        } catch (error) {
          reject(error);
        }
      });
    },
    focus() {
      eventbus.$emit(eventNames.setFocus);
    },
    selectText() {
      eventbus.$emit(eventNames.selectText);
    },
    setText(text: string) {
      eventbus.$emit(eventNames.setText, text);
    },
    blur() {
      eventbus.$emit(eventNames.blur);
    },
    clear() {
      vm.$store.dispatch('clear', false);
    },
    setActiveSource(sourcename: string) {
      const worker = (vm.$store.getters.sourceWorkers as SourceWorker[]).find((w) => w.source.name === sourcename);
      if (worker) {
        vm.$store.dispatch('setActiveSource', worker);
      }
    },
    version() {
      return vm.$store.state.version;
    },
    on(event: string, eventFunction: (payload: any) => void) {
      vm.$store.dispatch('on', { name: event, eventFunction });
    },
  };
}

const searchComponentHist = {
  iconProviders: {
    default: new DefaultIconProvider(),
    material: new MaterialDesignIconProvider(),
  },
  createInstance: createSearchComponentHist,
};

(window as any).searchComponentHist = searchComponentHist;

export default searchComponentHist;
