import { ResultAppender } from '@/models/ResultAppender';
import { ResultType, SearchResult } from '@/models/SearchResult';
import { SourceBase } from '@/models/SourceBase';
import { Grund, GrundFetcher } from '../Data/GrundFetcher';
import DawaJordstykkeResult from './DawaJordstykkeResult';

export default class JordstykkeToGrundAppender extends ResultAppender {
  public grundFetcher: GrundFetcher;
  public source: SourceBase;

  constructor(source: SourceBase) {
      super();
      this.source = source;
      this.grundFetcher = new GrundFetcher(
          source.options.dafUser,
          source.options.dafPassword,
          source.options.dafEjendomsrelationDate);
  }

  public CanAppendResult(resultDataType: string): boolean {
    return resultDataType === 'dawa_jordstykke';
  }

  public async getResults(searchresult: SearchResult, source: SourceBase): Promise<SearchResult[]> {
    const data = searchresult.data as DawaJordstykkeResult;

    const result = await this.grundFetcher.GetFromJordstykke(
      data.jordstykke.ejerlav.kode.toString(),
      data.jordstykke.matrikelnr,
    );

    return result.map((r: Grund) =>
      GrundFetcher.MapToSearchResult(
        `${searchresult.id}_${r.id_lokalId}`,
        source.name,
        r,
        source.getResultType('grund'),
      ),
    );

  }
}
