import React from "react";
import styles from "./Button.module.scss";
import { mergeClassNames } from "util/index";

export default function Button(props: IButtonProps) {
  const {
    children = <span></span>,
    onClick,
    style = {},
    type = "default",
    classNames = "",
    disabled = false,
  } = props;
  let className = "";
  switch (type) {
    case "primary":
      className = styles.primary;
      break;
    case "primary-lg":
      className = styles.primary_lg;
      break;
    case "x-large":
      className = styles.x_large;
      break;
    case "x-small":
      className = styles.x_small;
      break;
    case "small":
      className = styles.small;
      break;
    case "small-filled":
      className = styles.small_filled;
      break;
    case "small-light":
      className = styles.small_light;
      break;
    case "icon":
      className = styles.icon;
      break;
    default:
      break;
  }
  className = mergeClassNames(className, classNames);
  return (
    <button
      disabled={disabled}
      className={mergeClassNames(styles.button, className)}
      onClick={() =>
        onClick ? onClick() : console.debug("Klikket på knap uden onClick")
      }
      style={style}
    >
      {children}
    </button>
  );
}

interface IButtonProps {
  children?: any;
  onClick?: Function;
  style?: React.CSSProperties;
  type?:
    | "default"
    | "primary"
    | "primary-lg"
    | "x-small"
    | "x-large"
    | "small"
    | "small-filled"
    | "small-light"
    | "icon";
  classNames?: string;
  disabled?: boolean;
}
