import { point } from "@turf/helpers";
import * as reproject from "reproject";
import proj4 from "proj4";

const geokoderUrlTemplate = process.env.REACT_APP_GEOKODER_URL_TEMPLATE;
const kortBBRUrlTemplate = process.env.REACT_APP_KORTBBR_URL_TEMPLATE;
const googleStreetViewUrlTemplate =
  process.env.REACT_APP_GOOGLE_STREET_VIEW_URL_TEMPLATE;
const danmarksAdresserUrlTemplate =
  process.env.REACT_APP_DANMARKS_ADRESSER_URL_TEMPLATE;
const markblokkeUrlTemplate = process.env.REACT_APP_MARKBLOKKE_URL_TEMPLATE;
const bbrKommuneUrlTemplate = process.env.REACT_APP_BBR_KOMMUNE_URL_TEMPLATE;
const retBbrUrlTemplate = process.env.REACT_APP_RET_BBR_URL_TEMPLATE;
const skraafotoUrlTemplate = process.env.REACT_APP_SKRAAFOTO_URL_TEMPLATE;
const bbrMeddelelseUrlTemplate =
  process.env.REACT_APP_BBR_MEDDELELSE_URL_TEMPLATE;

function getWGS84Point(x, y) {
  const epsg25832 = proj4.Proj(
    "+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs"
  );
  const mercatorPoint = point([x, y]);
  return reproject.toWgs84(mercatorPoint, epsg25832);
}

export function getGeokoderUrl(grund) {
  return geokoderUrlTemplate && grund
    ? geokoderUrlTemplate
        .replace("{GGUID}", grund.grund.id_lokalId)
        .replace("{KNR}", grund.grund.kommunekode)
    : "#";
}

export function getKortBbrUrl(bfeNr) {
  return kortBBRUrlTemplate && bfeNr
    ? kortBBRUrlTemplate.replace("{BFE}", bfeNr)
    : "#";
}

export function getGoogleStreetViewUrl(x, y) {
  if (googleStreetViewUrlTemplate && x && y) {
    const wgs84Point = getWGS84Point(x, y);
    return googleStreetViewUrlTemplate
      .replace("{X}", wgs84Point.geometry.coordinates[0])
      .replace("{Y}", wgs84Point.geometry.coordinates[1]);
  }
  return "#";
}

export function getDanmarksAdresserUrl(x, y) {
  return danmarksAdresserUrlTemplate && x && y
    ? danmarksAdresserUrlTemplate.replace("{X}", x).replace("{Y}", y)
    : "#";
}

export function getMarkblokkeUrl(x, y, betegnelse) {
  if (markblokkeUrlTemplate && x && y && betegnelse) {
    const wgs84Point = getWGS84Point(x, y);
    return markblokkeUrlTemplate
      .replace("{X}", wgs84Point.geometry.coordinates[0])
      .replace("{X}", wgs84Point.geometry.coordinates[0])
      .replace("{Y}", wgs84Point.geometry.coordinates[1])
      .replace("{Y}", wgs84Point.geometry.coordinates[1])
      .replace("{ADRESSETEKST}", betegnelse);
  }
  return "#";
}

export function getBbrKommuneUrl(bfeNr) {
  return bbrKommuneUrlTemplate && bfeNr
    ? bbrKommuneUrlTemplate.replace("{BFE}", bfeNr)
    : "#";
}

// export function getRetBbrUrl(bestemtFastEjendom) {
//   const { ejendomsnummer, kommunekode } = bestemtFastEjendom;
//   return retBbrUrlTemplate && ejendomsnummer && kommunekode
//     ? retBbrUrlTemplate
//         .replace("{KOMKODE}", kommunekode)
//         .replace("{ESRNR}", ejendomsnummer)
//     : "#";
// }

export function getRetBbrUrl(bfeNummer: number) {
  return retBbrUrlTemplate && bfeNummer
    ? retBbrUrlTemplate.replace("{BFENR}", bfeNummer.toString())
    : "#";
}

export function getSkraafotoUrl(x, y) {
  return skraafotoUrlTemplate && x && y
    ? skraafotoUrlTemplate.replace("{X}", x).replace("{Y}", y)
    : "#";
}

// export function getBbrMeddelelseUrl(bestemtFastEjendom) {
//   const { ejendomsnummer, kommunekode } = bestemtFastEjendom;
//   return bbrMeddelelseUrlTemplate && ejendomsnummer && kommunekode
//     ? bbrMeddelelseUrlTemplate
//         .replace("{KOMKODE}", kommunekode)
//         .replace("{ESRNR}", ejendomsnummer)
//     : "#";
// }

export function getBbrMeddelelseUrl(bfeNummer: number) {
  return bbrMeddelelseUrlTemplate && bfeNummer
    ? bbrMeddelelseUrlTemplate.replace("{BFENR}", bfeNummer.toString())
    : "#";
}

export function getByggesagsarkivurl(kommune: Kommune, esr: string) {
  return (
    kommune &&
    esr &&
    kommune.byggesagsarkivUri
      .replace("{kommunenr}", kommune.kommunenr.toString())
      .replace("{esrejdnr}", esr)
  );
}

export function getGadefotoUrl(url: string, x, y) {
  return url.replace("{x}", x).replace("{y}", y);
}
