import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./Drawer.module.scss";
import MinusIcon from "components/common/icons/MinusIcon";
import PlusIcon from "components/common/icons/PlusIcon";

export default function Drawer(props: IDrawer) {
  const { headerText, children, isOpen } = props;
  const [open, setOpen] = useState(isOpen ? true : false);

  const variants = {
    open: { height: "auto" },
    closed: { height: 0 },
  };

  const renderHeaderButton = () => {
    return (
      <button
        className={styles.header}
        aria-expanded={open} // NB Toggle
        onClick={() => {
          setOpen(!open);
        }}
      >
        <h2>{headerText}</h2>
        <AnimatePresence>
          {open && (
            <motion.span
              className={styles.expand}
              key={open.toString()}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <MinusIcon />
            </motion.span>
          )}
          {!open && (
            <motion.span
              className={styles.expand}
              key={open.toString()}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <PlusIcon />
            </motion.span>
          )}
        </AnimatePresence>
      </button>
    );
  };
  return (
    <div className={styles.container}>
      {renderHeaderButton()}
      <AnimatePresence>
        {open && (
          <motion.div
            style={{ overflow: "hidden" }}
            variants={variants}
            initial="closed"
            animate="open"
            exit={variants.closed}
          >
            {children}
          </motion.div>
        )}
        {!open && (
          <motion.div
            style={{ overflow: "hidden" }}
            variants={variants}
            initial="closed"
            animate="closed"
            exit={variants.closed}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

interface IDrawer {
  children: React.ReactNode;
  headerText: string;
  isOpen?: boolean;
}
