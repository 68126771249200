import React from "react";

export default function StickerIcon(props: Icon) {
  const {
    height,
    width,
    // primaryColor,
    style,
  } = props;
  return (
    <svg
      style={style}
      width={width ? width : height ? height : 16}
      height={height ? height : width ? width : 16}
      viewBox="0 0 16 16"
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1h-11zM2 2.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5V8H9.5A1.5 1.5 0 0 0 8 9.5V14H2.5a.5.5 0 0 1-.5-.5v-11zm7 11.293V9.5a.5.5 0 0 1 .5-.5h4.293L9 13.793z" />
    </svg>
  );
}
