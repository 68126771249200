import React, { useState, useRef, MutableRefObject, useContext } from "react";
// import Button from "./Buttons/Button";
import { motion, AnimatePresence } from "framer-motion";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { kommuner } from "util/index";
import IconFilter from "./icons/IconFilter";
import DropDownMenu from "./DropDownMenu";
import Location from "./icons/Location";
import { OpgaveListePageOptions } from "interfaces/paging";
import { AuthContext } from "context/AuthProvider";
import { FilterToggleButton } from "./FilterToggleButton";
import { FilterDropdownItem } from "./FilterDropdownItem";

const filterVariants = {
  open: { height: "auto", opacity: 1 },
  closed: { height: 0, opacity: 0 },
};

export default function FiltersDropDown(props: {
  defaultOpgaveOptions: OpgaveListePageOptions;
  // currentOpgaveOptions: OpgaveListePageOptions;
  // setCurrentOpgaveOptions: React.Dispatch<
  //   React.SetStateAction<OpgaveListePageOptions>
  // >;
  opgaveOptions: OpgaveListePageOptions;
  setOpgaveOptions: React.Dispatch<
    React.SetStateAction<OpgaveListePageOptions>
  >;
  hide: () => void;
  brugerStatuser?: OpgaveBrugerStatusUI[];
  removeFilters?: any;
  state?: { showFilterOptions: boolean; setShowFilterOptions: Function };
}) {
  const {
    // defaultOpgaveOptions,
    // hide,
    // currentOpgaveOptions,
    // setCurrentOpgaveOptions,
    opgaveOptions,
    setOpgaveOptions,
    // removeFilters,
  } = props;
  const ref = useRef<HTMLDivElement>();
  const authState = useContext(AuthContext);
  const { bruger } = authState;
  const [openFilters, setOpenFilters] = useState(false);
  // const [opgaveOptions, setOpgaveOptions] =
  //   useState<OpgaveListePageOptions>(currentOpgaveOptions);
  const { brugerId, kommunenr } = opgaveOptions;
  const kommuneIsActive = (knr: number) => {
    if (!opgaveOptions.kommunenr) return false;
    const activeKnrs = opgaveOptions.kommunenr
      .split(",")
      .map((b) => parseInt(b));
    return activeKnrs.indexOf(knr) > -1;
  };

  const setKommuneIsActive = (value: number, isActive: boolean) => {
    let kommunenumre: number[] = opgaveOptions.kommunenr
      ? opgaveOptions.kommunenr.split(",").map((knr) => parseInt(knr))
      : [];
    if (isActive) {
      if (kommunenumre.indexOf(value) === -1) {
        kommunenumre.push(value);
        setOpgaveOptions({
          ...opgaveOptions,
          page: 1,
          kommunenr: kommunenumre.join(","),
        });
      }
    } else {
      if (kommunenumre.indexOf(value) > -1) {
        kommunenumre = kommunenumre.filter((b) => b !== value);
        setOpgaveOptions({
          ...opgaveOptions,
          page: 1,
          kommunenr: kommunenumre.length ? kommunenumre.join(",") : undefined,
        });
      }
    }
  };

  useOnClickOutside(ref, (e) => {
    e.stopPropagation();
    setOpenFilters(false);
  });

  // const gemKnapDisabled =
  //   currentOpgaveOptions.brugerId === opgaveOptions.brugerId &&
  //   currentOpgaveOptions.brugerStatus === opgaveOptions.brugerStatus &&
  //   currentOpgaveOptions.kommunenr === opgaveOptions.kommunenr;

  return (
    <div
      ref={ref as MutableRefObject<HTMLDivElement>}
      style={{
        position: "relative",
        minWidth: "fit-content",
        left: "-1px",
      }}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.keyCode === 32) {
          e.preventDefault();
          setOpenFilters(!openFilters);
        } else if (e.keyCode === 13) {
          e.preventDefault();
          setOpenFilters(!openFilters);
        }
      }}
      // onClick={(e) => {
      //   // !preventCloseOnSelect && e.stopPropagation();
      //   setShowFilterOptions(!showFilterOptions);
      // }}
    >
      <button
        className={`c-filter-btn btn btn-outline-primary-subtle btn--icon tooltip-center tooltip-top border-1 d-flex align-items-center border-start-1 ${
          openFilters ? "bg-secondary-subtle" : ""
        }`}
        style={{
          borderRadius: "0 6px 6px 0",
          width: "inherit",
          height: "inherit",
        }}
        onClick={(e) => {
          // preventCloseOnSelect &&
          e.stopPropagation();
          e.preventDefault();
          setOpenFilters(!openFilters);
        }}
        aria-label="Filtrér"
      >
        <IconFilter width={22} />
      </button>
      <AnimatePresence>
        {openFilters && (
          <motion.div
            style={{
              position: "absolute",
              left: 0,
              top: "100%",
              zIndex: 600,
              width: "330px",
              minWidth: "330px",
            }}
            variants={filterVariants}
            initial="closed"
            animate="open"
            exit={filterVariants.closed}
            transition={{ damping: 300 }}
            className="c-filter-options bg-white border shadow rounded"
            // key="filter-dropdown"
            key={Math.floor(Math.random())}
          >
            <div className="border-bottom px-3 py-3">
              <h5 className="text-muted fw-normal">Filtre</h5>
            </div>
            <FilterToggleButton
              isActive={brugerId ? true : false}
              setIsActive={(isActive) => {
                setOpgaveOptions({
                  ...opgaveOptions,
                  page: 1,
                  brugerId: isActive && bruger ? bruger.id : undefined,
                });
              }}
            />

            {bruger?.erSkatteforvaltningen && (
              <div className="px-3 py-3">
                <h5 className="fw-normal fs-4 d-flex align-items-center gap-2 mb-3">
                  <span className="d-flex align-items-center text-primary">
                    <Location width={21} />
                  </span>{" "}
                  Kommuner
                </h5>
                <div className="border rounded">
                  <DropDownMenu
                    arrowClass="text-muted"
                    menuClassNames="py-0 d-flex justify-content-between align-items-center w-100"
                    dropdownTriggerStyle="w-100 px-2 py-2"
                    preventCloseOnSelect={true}
                    width="300px"
                    element={
                      <div
                        className="w-100 d-flex align-items-center" //styles.filtrer
                        style={{
                          fontSize: "1rem",
                          minWidth: 200,
                        }}
                        // onClick={(e) => {
                        //    e.stopPropagation();
                        // }}
                      >
                        <span className="text-muted">
                          {kommunenr ? kommunenr.split(",").join(", ") : "Vælg"}
                        </span>
                      </div>
                    }
                    menu={
                      <div
                        style={{ maxHeight: 350, overflowY: "auto" }}
                        className="w-100 p-3"
                        key="kommuner"
                      >
                        {kommuner
                          .sort((a, b) => {
                            const prepForSort = (navn: string) => {
                              return navn
                                .replace("Aa", "Å")
                                .replace("aa", "å")
                                .replace("Æ", "Z");
                            };
                            return prepForSort(a.navn) < prepForSort(b.navn)
                              ? -1
                              : 1;
                          })
                          .map((kommune) => {
                            const { knr, navn } = kommune;
                            return (
                              <FilterDropdownItem
                                key={knr}
                                isActive={kommuneIsActive(knr)}
                                setIsActive={(isActive) => {
                                  setKommuneIsActive(knr, isActive);
                                }}
                                label={navn}
                                id={knr}
                              />
                            );
                          })}
                      </div>
                    }
                  />
                </div>
              </div>
            )}
            {/* <div className="w-100 d-flex justify-content-end gap-4 px-3 pt-2 pb-3">
              <button
                className="btn btn-outline-primary-subtle w-50 "
                onClick={removeFilters}
              >
                Ryd filtre
              </button>
              <button
                className={`btn btn-primary w-50 ${
                  gemKnapDisabled ? "bg-secondary-subtle border-0" : "border-0"
                }`}
                onClick={() => {
                  !gemKnapDisabled && setOpgaveOptions(opgaveOptions);
                  // !gemKnapDisabled && hide();
                  !gemKnapDisabled && setOpenFilters(!openFilters);
                }}
              >
                Filtrér
              </button>
            </div> */}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
