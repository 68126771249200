import React from "react";
// import styles from "./LinkGroup.module.scss";
export default function LinkGroup(props: ILinkGroupProps) {
  const { title, children } = props;
  return (
    // Checks if the length of the children is larger than 1, and styles accordingly
    <div
      // className={
      //   React.Children.toArray(children).length > 1
      //     ? styles.container
      //     : styles.single
      // }
      className={React.Children.toArray(children).length > 1 ? "" : ""}
    >
      {React.Children.toArray(children).length > 1 && (
        <h6 className="d-block fw-semibold fs-5">{title}</h6>
      )}
      <div className="d-flex flex-column row-gap-2">{children}</div>
    </div>
  );
}

interface ILinkGroupProps {
  title: string;
  children: React.ReactNode;
}
