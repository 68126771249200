import React, { useState, useEffect } from "react";
import Modal from "./common/Modal";
import styles from "./CookieModal.module.scss";
import Logo from "./Nav/Logo";

export default function CookieModal() {
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    // Check localStorage to see if the user has already accepted cookies
    const hasAcceptedCookies = true; // localStorage.getItem("hasAcceptedCookies"); DENNE LINJE UDKOMMENTERET DA VURDST ALLIGEVEL IKKE VIL HAVE COOKIEBANNER.
    if (!hasAcceptedCookies) {
      setIsToggled(true); // Show the modal if the user hasn't accepted cookies
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("hasAcceptedCookies", "true");
    setIsToggled(false);
  };

  return (
    <Modal isToggled={isToggled} setIsToggled={setIsToggled} hideCloseButton>
      <div className={styles.content}>
        <header className={`mb-4 ${styles.header}`}>
          <Logo />
        </header>
        <article className="mb-4 text-dark">
          <h2 className="mb-3">BBR Explore bruger funktionelle cookies</h2>
          <p>
            BBR Explore anvender funktionelle cookies og for at huske dine
            fortrukne indstillinger. Ved at klikke på 'Accepter' giver du
            samtykke til, at funktionaliteten på BBR Explore fungerer.
          </p>
          <p>
            Du kan til enhver tid trække dit samtykke tilbage ved at klikke på
            linket nedenunder.
          </p>
        </article>
        <div className="d-flex gap-3 align-items-center justify-content-between flex-wrap">
          <a
            href="https://bbr.dk/cookies"
            target="_blank"
            rel="noreferrer"
            className="link-primary text-decoration-underline"
          >
            Læs mere om cookies her
          </a>
          <button
            className="btn btn-primary btn-lg px-6"
            onClick={handleAccept}
          >
            Accepter
          </button>
        </div>
      </div>
    </Modal>
  );
}
