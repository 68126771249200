import React from "react";
import styles from "./ArtikelList.module.scss";
import ArtikelListItem from "./ArtikelListItem";
import { Spacing } from "components/common/layout/Spacing";

export default function ArtikelList(props: ArtikelListProps) {
  const { artikelListeMedTema } = props;
  const { artikler, tema } = artikelListeMedTema;
  return (
    <div className={styles.container}>
      <div className={styles.headercontainer}>
        <h1>{tema}</h1>
        <Spacing h={1} />
      </div>
      <ul>
        {artikler.map((artikel) => {
          return <ArtikelListItem key={artikel.id} artikel={artikel} />;
        })}
      </ul>
    </div>
  );
}

interface ArtikelListProps {
  artikelListeMedTema: ArtikelListeMedTema;
}
