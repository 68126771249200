import React from "react";
import styles from "./NotFound.module.scss";
import { Link } from "react-router-dom";
import Arrow from "components/common/icons/Arrow";
export default function NotFound() {
  return (
    <div className={styles.container}>
      <div className={styles.intro}>
        <div className={styles.grid}>
          <div className={styles.col1}>
            <h1>404</h1>
            <p>Siden kunne ikke findes. </p>
            <Link className={styles.internal} to="/">
              Gå til forsiden
              <Arrow />
            </Link>
          </div>
          <div className={styles.col2}>
            <img
              src="https://bbr.dk/file/654761/test2.jpeg"
              alt="Billede af hus"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
