import { SourceOptions } from './SourceOptions';
import { ResultType, SearchResult } from './SearchResult';
import { SearchInput } from './SearchInput';
import { ResultBehaviour } from './ResultBehaviour';
import { SourceFilters } from './SourceFilters';
import { ResultAppender } from './ResultAppender';
import { ExtendedResultAppender } from './ExtendedResultAppender';

export abstract class SourceBase {

    get iconClass(): string {
        return this.options.icon;
    }

    get sourceType(): ResultBehaviour {
        return this.options.resultBehaviour;
    }

    get resultLimit(): number {
        return this.options.resultLimit;
    }

    get title(): string {
        return this.options.title;
    }

    get badgeTitle(): string {
        if (this.options.badgeTitle) {
            return this.options.badgeTitle;
        }

        return this.options.title;
    }
    public abstract name: string;
    public abstract options: SourceOptions;
    public abstract filters: SourceFilters;
    public resultAppenders: ResultAppender[];
    public extendedResultAppender: ExtendedResultAppender;

    public hasResultAppender(datatype: string): boolean {
        if (!this.resultAppenders || !this.resultAppenders.length) {
            return false;
        }

        return this.resultAppenders.findIndex((r) => r.CanAppendResult(datatype)) > -1;
    }

    public getResultAppender(datatype: string): ResultAppender {
        if (!this.resultAppenders || !this.resultAppenders.length) {
            return null;
        }

        return this.resultAppenders.find((r) => r.CanAppendResult(datatype));
    }

    public getResultType(datatype: string): ResultType {
        if (!!this.options.fetchExtendedResult &&
            this.extendedResultAppender &&
            this.extendedResultAppender.CanExtentResult(datatype)) {
            return ResultType.extendedResult;
        }

        if (this.hasResultAppender(datatype)) {
            return ResultType.suggestion;
        }

        return ResultType.result;
    }

    public async searchWithAppenders(input: SearchInput): Promise<SearchResult[]> {
        if (input.suggestion) {
            const resultAppender = this.getResultAppender(input.suggestion.datatype);
            if (resultAppender) {
                return await resultAppender.getResults(input.suggestion, this);
            }
        }

        return await this.search(input);
    }

    public getExtendedResultWithAppenders(searchResult: SearchResult): Promise<SearchResult> {
        if (this.extendedResultAppender && this.extendedResultAppender.CanExtentResult(searchResult.datatype)) {
            return this.extendedResultAppender.getExtendedResult(searchResult, this);
        }

        return new Promise((resolve, reject) => {
            return resolve(Object.assign({}, searchResult));
        });
    }

    public get(id: string): Promise<SearchResult> {
        return new Promise((resolve, reject) => {
            this.search({ id }).then((result) => {
                return resolve(result.length > 0 ? result[0] : null);
            }).catch((error) => {
                return reject(error);
            });
        });
    }

    protected abstract search(input: SearchInput): Promise<SearchResult[]>;

    protected getExtendedResult(searchResult: SearchResult): Promise<SearchResult> {
        return new Promise((resolve, reject) => {
            return resolve(Object.assign({}, searchResult));
        });
    }
}
