import { ExtendedResultAppender } from '@/models/ExtendedResultAppender';
import { SearchResult } from '@/models/SearchResult';
import { SourceBase } from '@/models/SourceBase';
import { EjendomFetcher } from '../Data/EjendomFetcher';

export class DawaAdgangsAdresseToEjendomExtendedAppender extends ExtendedResultAppender {
    public source: SourceBase;

    private extentDataTypes: string[] = [
        'dawa_adresse',
        'dawa_adgangsadresse',
        'bfe',
    ];

    constructor(source: SourceBase) {
        super();
        this.source = source;
    }

    public CanExtentResult(resultDataType: string): boolean {
        return this.extentDataTypes.indexOf(resultDataType) > -1;
    }

    public async getExtendedResult(result: SearchResult, source: SourceBase): Promise<SearchResult> {
        if (result.datatype === 'bfe') {
            return result;
        } else if (result.datatype === 'dawa_adgangsadresse') {
             // tslint:disable-next-line:max-line-length
             const ejendomme = await EjendomFetcher.GetFromAdgangsAdresse(
                 result.data.data.id,
                 source.options.dafUser,
                 source.options.dafPassword,
                 source.options.dafEjendomsrelationDate,
             );
             // tslint:disable-next-line:max-line-length
             return Object.assign({}, result, {
                 data: ejendomme.map((e) =>
                 EjendomFetcher.MapDafResultsToSearchResult(null, source.name, e, 'bfe',
                 source.getResultType('bfe'),
                 null),
                 ),
             });
        } else if (result.datatype === 'dawa_adresse') {
            // tslint:disable-next-line:max-line-length
            const ejendomme = await EjendomFetcher.GetFromAdresse(
                result.data.data.id,
                source.options.dafUser,
                source.options.dafPassword,
                source.options.dafEjendomsrelationDate,
            );
            // tslint:disable-next-line:max-line-length
            return Object.assign({}, result, {
                data: ejendomme.map((e) =>
                EjendomFetcher.MapDafResultsToSearchResult(null, source.name, e, 'bfe',
                source.getResultType('bfe'),
                null),
                ),
            });
        } else {
            return null;
        }
    }
}
