import React from "react";

export default function Hourglass(props: Icon) {
  const { height, width, style = {} } = props;
  return (
    <svg
      // xmlns="http://www.w3.org/2000/svg"
      // width={width ? width : height ? height * (12 / 12) : 12}
      // height={height ? height : width ? width * (12 / 12) : 12}
      // style={style}
      // viewBox="0 0 12 12"

      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height : 16}
      height={height ? height : width ? width : 16}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <g transform="translate(-121 -898)">
        <path
          d="M71.789-892.295a.855.855,0,0,1,.583.215,1.094,1.094,0,0,1,.336.552,5.878,5.878,0,0,0,.27.948,4.445,4.445,0,0,0,.451.882,1.146,1.146,0,0,1,.141.639.894.894,0,0,1-.274.579.778.778,0,0,1-.569.22.608.608,0,0,1-.508-.289,7.559,7.559,0,0,1-.718-1.413,7.271,7.271,0,0,1-.4-1.516.651.651,0,0,1,.149-.568A.674.674,0,0,1,71.789-892.295Zm1.507-5.4a.983.983,0,0,1,.289.6,1.055,1.055,0,0,1-.137.66,4.94,4.94,0,0,0-.46.876,5.242,5.242,0,0,0-.28.932,1.3,1.3,0,0,1-.342.548.8.8,0,0,1-.577.23.694.694,0,0,1-.549-.239.672.672,0,0,1-.159-.569,7.278,7.278,0,0,1,.407-1.515,7.393,7.393,0,0,1,.732-1.423.673.673,0,0,1,.508-.3A.709.709,0,0,1,73.3-897.695Zm1.146,10.4a.985.985,0,0,1,.592-.3,1.131,1.131,0,0,1,.684.132,8,8,0,0,0,.872.426,8.173,8.173,0,0,0,.93.323,1.083,1.083,0,0,1,.538.351.888.888,0,0,1,.22.59.627.627,0,0,1-.249.511.608.608,0,0,1-.548.129,11.256,11.256,0,0,1-1.524-.421,7.319,7.319,0,0,1-1.41-.671.612.612,0,0,1-.307-.5A.7.7,0,0,1,74.442-887.295Zm3.873-13.072a.916.916,0,0,1-.209.59.964.964,0,0,1-.538.34,5.816,5.816,0,0,0-.924.3,7.219,7.219,0,0,0-.857.43,1.232,1.232,0,0,1-.7.147.993.993,0,0,1-.638-.3.666.666,0,0,1-.21-.573.686.686,0,0,1,.329-.512,6.89,6.89,0,0,1,1.422-.671,10.852,10.852,0,0,1,1.546-.4.581.581,0,0,1,.544.134A.659.659,0,0,1,78.316-900.367Zm7.205,7.295a6.352,6.352,0,0,0-1.415-4.07,6.465,6.465,0,0,0-3.619-2.315.721.721,0,0,1-.454-.336,1.072,1.072,0,0,1-.163-.574.711.711,0,0,1,.208-.52.547.547,0,0,1,.507-.16,7.574,7.574,0,0,1,4.665,2.766,8.032,8.032,0,0,1,1.821,5.21,8.016,8.016,0,0,1-1.821,5.206,7.619,7.619,0,0,1-4.665,2.77.547.547,0,0,1-.507-.161.711.711,0,0,1-.208-.52,1.072,1.072,0,0,1,.163-.574.721.721,0,0,1,.454-.336A6.466,6.466,0,0,0,84.107-889a6.352,6.352,0,0,0,1.415-4.07Z"
          transform="translate(208.071 12.913) rotate(180)"
        />
        <path
          d="M9,7A2,2,0,1,1,7,5,2,2,0,0,1,9,7Z"
          transform="translate(122 899)"
        />
      </g>
      {/* <g transform="translate(-213 -169)">
        <g transform="translate(-1054 -189)">
          <g
            transform="translate(1267 358)"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
          >
            <circle cx="6" cy="6" r="6" stroke="none" />
            <circle cx="6" cy="6" r="5.25" fill="none" />
          </g>
        </g>
        <g transform="translate(-1050.995 -185.995)">
          <g
            transform="translate(1266.995 357.995)"
            fill="currentColor"
            // stroke="currentColor"
            strokeWidth="1"
          >
            <circle cx="3" cy="3" r="3" stroke="none" />
            <circle cx="3" cy="3" r="2.5" fill="none" />
          </g>
        </g>
      </g> */}
    </svg>
  );
}

// <svg
//   xmlns="http://www.w3.org/2000/svg"
//   width="16"
//   height="15.971"
//   viewBox="0 0 16 15.971"
// >
//   <g transform="translate(-121 -898)">
//     <path
//       d="M71.789-892.295a.855.855,0,0,1,.583.215,1.094,1.094,0,0,1,.336.552,5.878,5.878,0,0,0,.27.948,4.445,4.445,0,0,0,.451.882,1.146,1.146,0,0,1,.141.639.894.894,0,0,1-.274.579.778.778,0,0,1-.569.22.608.608,0,0,1-.508-.289,7.559,7.559,0,0,1-.718-1.413,7.271,7.271,0,0,1-.4-1.516.651.651,0,0,1,.149-.568A.674.674,0,0,1,71.789-892.295Zm1.507-5.4a.983.983,0,0,1,.289.6,1.055,1.055,0,0,1-.137.66,4.94,4.94,0,0,0-.46.876,5.242,5.242,0,0,0-.28.932,1.3,1.3,0,0,1-.342.548.8.8,0,0,1-.577.23.694.694,0,0,1-.549-.239.672.672,0,0,1-.159-.569,7.278,7.278,0,0,1,.407-1.515,7.393,7.393,0,0,1,.732-1.423.673.673,0,0,1,.508-.3A.709.709,0,0,1,73.3-897.695Zm1.146,10.4a.985.985,0,0,1,.592-.3,1.131,1.131,0,0,1,.684.132,8,8,0,0,0,.872.426,8.173,8.173,0,0,0,.93.323,1.083,1.083,0,0,1,.538.351.888.888,0,0,1,.22.59.627.627,0,0,1-.249.511.608.608,0,0,1-.548.129,11.256,11.256,0,0,1-1.524-.421,7.319,7.319,0,0,1-1.41-.671.612.612,0,0,1-.307-.5A.7.7,0,0,1,74.442-887.295Zm3.873-13.072a.916.916,0,0,1-.209.59.964.964,0,0,1-.538.34,5.816,5.816,0,0,0-.924.3,7.219,7.219,0,0,0-.857.43,1.232,1.232,0,0,1-.7.147.993.993,0,0,1-.638-.3.666.666,0,0,1-.21-.573.686.686,0,0,1,.329-.512,6.89,6.89,0,0,1,1.422-.671,10.852,10.852,0,0,1,1.546-.4.581.581,0,0,1,.544.134A.659.659,0,0,1,78.316-900.367Zm7.205,7.295a6.352,6.352,0,0,0-1.415-4.07,6.465,6.465,0,0,0-3.619-2.315.721.721,0,0,1-.454-.336,1.072,1.072,0,0,1-.163-.574.711.711,0,0,1,.208-.52.547.547,0,0,1,.507-.16,7.574,7.574,0,0,1,4.665,2.766,8.032,8.032,0,0,1,1.821,5.21,8.016,8.016,0,0,1-1.821,5.206,7.619,7.619,0,0,1-4.665,2.77.547.547,0,0,1-.507-.161.711.711,0,0,1-.208-.52,1.072,1.072,0,0,1,.163-.574.721.721,0,0,1,.454-.336A6.466,6.466,0,0,0,84.107-889a6.352,6.352,0,0,0,1.415-4.07Z"
//       transform="translate(208.071 12.913) rotate(180)"
//     />
//     <path
//       d="M9,7A2,2,0,1,1,7,5,2,2,0,0,1,9,7Z"
//       transform="translate(122 899)"
//     />
//   </g>
// </svg>;
