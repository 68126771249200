import { ExtendedResultAppender } from '@/models/ExtendedResultAppender';
import { SearchResult } from '@/models/SearchResult';
import { SourceBase } from '@/models/SourceBase';
import axios from 'axios';
import { EjendomFetcher } from '../Data/EjendomFetcher';
import { AdresseFilters } from './AdresseFilters';

export class DawaAdgangsAdresseToAdresseExtendedAppender extends ExtendedResultAppender {
  public source: SourceBase;

    private extentDataTypes: string[] = [
        'dawa_adresse',
        'dawa_adgangsadresse',
    ];

  constructor(source: SourceBase) {
      super();
      this.source = source;
  }

    public CanExtentResult(resultDataType: string): boolean {
        return this.extentDataTypes.indexOf(resultDataType) > -1;
    }

    public async getExtendedResult(result: SearchResult, source: SourceBase): Promise<SearchResult> {
        // tslint:disable-next-line:max-line-length
      let url = `https://dawa.aws.dk/${
        result.data.type === 'adgangsadresse'
          ? 'adgangsadresser'
          : 'adresser'
      }/${result.data.data.id}?srid=25832`;
      if (!!(source.filters as AdresseFilters).extendedResultStruktur) {
        url += `&struktur=${(source.filters as AdresseFilters).extendedResultStruktur}`;
      }

      try {
        const adressResponse = await axios.get(url);
        const data = adressResponse.data;
        return Object.assign({}, result, { data });
      } catch (error) {
        return result;
      }
    }
}
