import { getWithErrorObject, post } from "../index";

const logOut = async () => {
  try {
    return await post({
      query: `auth/logout`,
      apiPrefix: "/",
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
const getMe = async () => {
  try {
    return await getWithErrorObject<Bruger>({
      query: `auth/HentMig`,
      apiPrefix: "/",
    });
  } catch (error) {
    return Promise.reject(error); // Error is an axios error object here, not a string message
  }
};

const api: RolleApi = {
  logOut,
  getMe,
};

export default api;

export interface RolleApi {
  logOut: {
    (): Promise<any>;
  };
  getMe: {
    (): Promise<Bruger>;
  };
}
