import React, { useState } from "react";
import styles from "./Brugere.module.scss";
import brugerApi from "api/bruger";
import rolleApi from "api/rolle";
import { BrugerePageOptions } from "interfaces/paging";
import { useHistory } from "react-router-dom";
import { parseQueryString } from "util/index";
import BrugerList from "./BrugerList";
import Bruger from "./Bruger";
import { useQuery, useQueryClient } from "react-query";
import useScrollRestoration from "hooks/useScrollRestoration";

export default function Brugere(props: {
  scrollRef: React.MutableRefObject<HTMLDivElement | null>;
}) {
  const history = useHistory();
  const { search } = history.location;
  const query = parseQueryString(search);
  const { brugerid } = query;
  const [showFilter, setShowFilter] = useState(false);
  const [inputBrugernavn, setInputBrugernavn] = useState("");
  const [inputKommunenr, setInputKommunenr] = useState("");
  const pageSize = process.env.REACT_APP_OPGAVE_PAGE_SIZE
    ? parseInt(process.env.REACT_APP_OPGAVE_PAGE_SIZE)
    : 50;

  const [pageOptions, setPageOptions] = useState<BrugerePageOptions>({
    page: 1,
    pageSize: pageSize,
    navn: "",
    kommunenr: "",
  });
  const { restoreScroll } = useScrollRestoration(
    "admin?type=brugere&page=" + pageOptions.page,
    props.scrollRef
  );
  const [totalCount, setTotalCount] = useState(0);

  const { page, id, navn, kommunenr } = pageOptions;
  const { data, isLoading } = useQuery({
    queryKey: ["brugere", page, id, navn, kommunenr],
    queryFn: async () => {
      const ol = await brugerApi.getAllWithHeaders(pageOptions);
      const listContentRange = ol.headers["list-content-range"];
      const tc = parseInt(listContentRange.split("/")[1]);
      setTotalCount(tc);
      return ol.data;
    },
    onSuccess: (d) => {
      setTimeout(() => {
        restoreScroll();
      }, 300);
    },
  });

  const { data: roller, isLoading: rollerLoading } = useQuery(
    ["roller"],
    async () => {
      return await rolleApi.getAll();
    }
  );
  const queryClient = useQueryClient();
  const updateBruger = (bruger: Bruger) => {
    queryClient.invalidateQueries();
  };
  return (
    <div className={styles.container}>
      {!isLoading && data && !brugerid && (
        <BrugerList
          brugere={data}
          history={history}
          pageOptions={pageOptions}
          setPageOptions={setPageOptions}
          totalCount={totalCount}
          brugernavnFilter={pageOptions.navn}
          brugernavn={inputBrugernavn}
          setBrugernavn={setInputBrugernavn}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          kommunenrFilter={pageOptions.kommunenr}
          setKommunenr={setInputKommunenr}
          kommunenr={inputKommunenr}
        />
      )}
      {!isLoading && !rollerLoading && roller && data && brugerid && (
        <Bruger
          roller={roller}
          brugerid={brugerid}
          bruger={data.find((b) => b.id === brugerid)}
          history={history}
          updateBruger={updateBruger}
        />
      )}
    </div>
  );
}
