import { IAutocompleteResult } from './IAutocompleteResult';

export function sortAdresses(a: IAutocompleteResult, b: IAutocompleteResult) {
    if (a.type === 'vejnavn' || b.type === 'vejnavn') {
        if (a.data.navn < b.data.navn) {
          return -1;
        }
        if (a.data.navn > b.data.navn) {
          return 1;
        }
      } else {
        if (a.data.vejnavn < b.data.vejnavn) {
          return -1;
        }
        if (a.data.vejnavn > b.data.vejnavn) {
          return 1;
        }

        // tslint:disable-next-line:max-line-length
        const streetNumber = a.data.husnr.localeCompare(
          b.data.husnr,
          undefined,
          { numeric: true, sensitivity: 'base' },
        );

        if (streetNumber !== 0) {
          return streetNumber;
        }

        if (a.type === 'adresse' || b.type === 'adresse') {
          if (!a.data.etage || !b.data.etage) {
            return 0;
          }

          // tslint:disable-next-line:max-line-length
          const order =
            parseInt(
              a.data.etage
                .replace('st', '0')
                .replace('kl', '-1')
                .replace('k', '-'),
              0,
            ) -
            parseInt(
              b.data.etage
                .replace('st', '0')
                .replace('kl', '-1')
                .replace('k', '-'),
              0,
            );

          if (order < 0) {
            return -1;
          }
          if (order > 0) {
            return 1;
          }
        }
      }
    return 0;
}
