import { useContext, useState } from "react";
import styles from "../Ejendom/Panes/Left/Links.module.scss";
import { motion } from "framer-motion";
import {
  getGeokoderUrl,
  getKortBbrUrl,
  getGoogleStreetViewUrl,
  getDanmarksAdresserUrl,
  // getMarkblokkeUrl,
  getBbrKommuneUrl,
  getRetBbrUrl,
  getSkraafotoUrl,
  getBbrMeddelelseUrl,
  getByggesagsarkivurl,
  getGadefotoUrl,
} from "./Panes/Left/linkutil";
import LinkGroup from "components/common/LinkGroup";
import { getESRList } from "../../util";
import { AuthContext } from "context/AuthProvider";
import ArrowUpRight from "components/common/icons/ArrowUpRight";
import ExternalLinkItem from "./ExternalLinkItem";
// import { formatEjerlav } from "../Main";

interface ILinkProps {
  ejendom: Ejendom | undefined;
  ejendomIsLoading: boolean;
}

export default function ExternalLinks(props: ILinkProps) {
  const { ejendom, ejendomIsLoading } = props;
  const [hoverStateGoogle, setHoverStateGoogle] = useState(false);
  //JF. ISSUE 557: MARKBLOKKE UDKOMMENTERET INDTIL LINKS BLIVER OPDATERET
  // const [hoverStateMarkblokke, setHoverStateMarkblokke] = useState(false);
  const user = useContext(AuthContext);
  const renderLinks = (ejendom: Ejendom) => {
    const esrNumre = getESRList(ejendom);
    const uniqueEsrNumre = Array.from(new Set(esrNumre));
    const { grunde, bfeNr, beliggenhedsadresse, kommune } = ejendom;

    // Destructuring potential null object
    //https://stackoverflow.com/a/45210379
    const {
      // betegnelse = "",
      x = 0,
      y = 0,
    } = beliggenhedsadresse || {};

    return (
      <div
        className="{styles.links} d-flex flex-column gap-3"
        style={{
          maxHeight: "calc(100vh - 14rem)",
          overflowY: "scroll",
          overflowX: "clip",
        }}
      >
        {/* {grunde.length && grunde[0].grund?.bestemtFastEjendom ? (
          <ExternalLinkItem
            title="BBR-meddelelse"
            href={getBbrMeddelelseUrl(grunde[0].grund?.bestemtFastEjendom)}
          />
        ) : null} */}
        {bfeNr ? (
          <ExternalLinkItem
            title="BBR-meddelelse"
            href={getBbrMeddelelseUrl(bfeNr)}
          />
        ) : null}

        <ExternalLinkItem title="BBR Kommune" href={getBbrKommuneUrl(bfeNr)} />
        <ExternalLinkItem title="BBR Kort" href={getKortBbrUrl(bfeNr)} />
        {kommune?.byggesagsarkivUri ? (
          <LinkGroup title="Byggesagsarkiv">
            {uniqueEsrNumre.map((esrnr, i) => {
              // Geokoderen TODO: Clean link logic up
              let appendix =
                uniqueEsrNumre.length > 1 ? `ESR ${esrnr}` : "Byggesagsarkiv";
              return (
                <ExternalLinkItem
                  title={appendix}
                  href={kommune ? getByggesagsarkivurl(kommune, esrnr) : "#"}
                  key={i}
                />
              );
            })}
          </LinkGroup>
        ) : null}
        {x !== 0 && y !== 0 ? (
          <ExternalLinkItem
            title="Danmarks Adresser"
            href={getDanmarksAdresserUrl(x, y)}
          />
        ) : null}
        {/* Done: issue #112 */}
        {user?.bruger?.gadefotoUri && x !== 0 && y !== 0 && (
          <ExternalLinkItem
            title="Danmarks Digitale Gadefoto"
            href={getGadefotoUrl(user.bruger.gadefotoUri.toString(), x, y)}
          />
        )}
        <ExternalLinkItem
          title="Det Centrale Husdyrregister"
          href="https://chr.fvst.dk/"
        />
        <ExternalLinkItem
          title="Det Centrale Virksomhedsregister"
          href="https://datacvr.virk.dk/"
        />

        {grunde.length > 1 ? <h6 className="fs-5">Geokoderen</h6> : null}
        <div
          className={`d-flex flex-column gap-2 ${
            grunde.length > 1 ? "ms-2" : ""
          }`}
        >
          {grunde.map((grund, i) => {
            let appendix = "Geokoderen";
            if (grunde.length > 1) {
              appendix =
                grund.jordstykker && grund.jordstykker.length > 0
                  ? `Grund - ${grund.navnFraJordstykker}`
                  : `Grund ${i + 1}`;
            }
            return (
              <ExternalLinkItem
                title={appendix}
                href={getGeokoderUrl(grund)}
                key={i}
              />
            );
          })}
        </div>

        {x !== 0 && y !== 0 ? (
          <div
            className=""
            onMouseEnter={() => setHoverStateGoogle(true)}
            onMouseLeave={() => setHoverStateGoogle(false)}
          >
            <a
              className={`d-flex justify-content-start align-items-end fs-5 ${
                hoverStateGoogle ? "text-primary" : "text-muted"
              }`}
              rel="noopener noreferrer"
              target="_blank"
              href={getGoogleStreetViewUrl(x, y)}
              style={{ lineHeight: "20px" }}
            >
              <p className="mb-0 d-flex justify-content-start align-items-end">
                Google Street View
                {hoverStateGoogle ? (
                  <ArrowUpRight
                    width={18}
                    style={{
                      paddingBottom: "2px",
                      paddingLeft: "2px",
                      visibility: "visible",
                    }}
                  />
                ) : (
                  <ArrowUpRight
                    width={18}
                    style={{
                      paddingBottom: "2px",
                      paddingLeft: "2px",
                      visibility: "hidden",
                    }}
                  />
                )}
              </p>
            </a>
          </div>
        ) : null}
        {/* JF. ISSUE 557: MARKBLOKKE UDKOMMENTERET INDTIL LINKS BLIVER OPDATERET */}
        {/* {betegnelse && x !== 0 && y !== 0 ? (
          <div
            className=""
            onMouseEnter={() => setHoverStateMarkblokke(true)}
            onMouseLeave={() => setHoverStateMarkblokke(false)}
          >
            <a
              className={`d-flex justify-content-start align-items-end fs-5 ${
                hoverStateMarkblokke ? "text-primary" : "text-muted"
              }`}
              rel="noopener noreferrer"
              target="_blank"
              href={getMarkblokkeUrl(x, y, betegnelse)}
              style={{ lineHeight: "22px" }}
            >
              <p className="mb-0 d-flex align-items-end justify-content-start">
                Markblokke
                {hoverStateMarkblokke ? (
                  <ArrowUpRight
                    width={18}
                    style={{
                      paddingBottom: "2px",
                      paddingLeft: "2px",
                      visibility: "visible",
                    }}
                  />
                ) : (
                  <ArrowUpRight
                    width={18}
                    style={{
                      paddingBottom: "2px",
                      paddingLeft: "2px",
                      visibility: "hidden",
                    }}
                  />
                )}
              </p>
            </a>
          </div>
        ) : null} */}
        {x !== 0 && y !== 0 ? (
          <ExternalLinkItem title="Skråfoto" href={getSkraafotoUrl(x, y)} />
        ) : null}
        {/* {grunde.length && grunde[0].grund?.bestemtFastEjendom ? (
          <ExternalLinkItem
            title="Ret BBR"
            href={getRetBbrUrl(grunde[0].grund?.bestemtFastEjendom)}
          />
        ) : null} */}
        {bfeNr ? (
          <ExternalLinkItem title="Ret BBR" href={getRetBbrUrl(bfeNr)} />
        ) : null}
      </div>
    );
  };
  const renderLoading = () => {
    return Array.from(new Array(10)).map((o, i) => {
      return (
        <motion.li key={i} className={`${styles.ejer} ${styles.loading}`}>
          <div className={styles.text}>
            <div
              style={{ width: 60 + Math.random() * 20 + "%" }}
              className={styles.line}
            ></div>
          </div>
        </motion.li>
      );
    });
  };
  return (
    <>
      {ejendom && renderLinks(ejendom)}
      {ejendomIsLoading && renderLoading()}
    </>
  );
}
