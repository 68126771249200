import React from "react";

export default function ToggleOff(props: Icon) {
  const {
    height,
    width,
    // primaryColor,
    style,
  } = props;
  return (
    <svg
      style={style}
      width={width ? width : height ? height : 16}
      height={height ? height : width ? width : 16}
      viewBox="0 0 16 16"
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z" />
    </svg>
  );
}
