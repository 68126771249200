import { get } from "../index";

const getBrugerStatus = async () => {
  try {
    return await get<any[]>({
      query: `statistik/status`,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
const getKommuneBrugerStatus = async (knr?: number) => {
  try {
    return await get<any[]>({
      query: knr
        ? `statistik/status/kommune/${knr}`
        : "statistik/status/kommune",
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
const getListeStatus = async () => {
  try {
    return await get<any[]>({
      query: `statistik/status/liste`,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
const getKommuneListeStatus = async (knr?: number) => {
  try {
    return await get<any[]>({
      query: knr
        ? `statistik/status/liste/kommune/${knr}`
        : "statistik/status/liste/kommune",
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
const getAfsluttedeTotalt = async () => {
  try {
    return await get<any[]>({
      query: `statistik/afsluttede`,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
const getAfsluttedeEgne = async () => {
  try {
    return await get<any[]>({
      query: `statistik/afsluttede/egne`,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
const getOverblik = async () => {
  try {
    return await get<any[]>({
      query: `statistik/overblik`,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

const api = {
  getBrugerStatus,
  getKommuneBrugerStatus,
  getListeStatus,
  getKommuneListeStatus,
  getAfsluttedeTotalt,
  getAfsluttedeEgne,
  getOverblik,
};

export default api;
