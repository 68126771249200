import React from "react";
import styles from "./NextPreviousPage.module.scss";
import { PageOptions } from "interfaces/paging";
import ChevronLeft from "../icons/ChevronLeft";
import ChevronRight from "../icons/ChevronRight";

interface INextPreviousPageProps {
  pageOptions: PageOptions;
  setPageOptions: (options: PageOptions) => void;
  style?: any;
  totalCount: number;
}
export default function NextPreviousPage(props: INextPreviousPageProps) {
  const { pageOptions, setPageOptions, totalCount, style = {} } = props;
  const pageSize = pageOptions.pageSize
    ? pageOptions.pageSize
    : process.env.REACT_APP_OPGAVE_PAGE_SIZE
    ? parseInt(process.env.REACT_APP_OPGAVE_PAGE_SIZE)
    : 50;
  if (!pageOptions || totalCount === 0) return null;
  return (
    <div
      style={style}
      className={`${styles.paging} d-flex justify-content-start align-items-center border rounded-1 `}
    >
      <button
        aria-label="Første"
        className={`px-1 py-2 h-100 border-end d-flex justify-content-center align-items-center`} //${styles.previous} ${styles.firstlast}
        style={{ color: pageOptions.page === 1 ? "#859c8d" : "inherit" }}
        disabled={pageOptions.page === 1}
        onClick={() =>
          setPageOptions({
            ...pageOptions,
            page: 1,
          })
        }
      >
        <ChevronLeft />
        <ChevronLeft />
        {/* <span>Forrige</span> */}
      </button>
      <button
        aria-label="Forrige"
        className={` px-2 py-2 h-100 border-end d-flex justify-content-center align-items-center`} //styles.previous
        style={{
          color: pageOptions.page === 1 ? "#859c8d" : "inherit",
        }}
        disabled={pageOptions.page === 1}
        // style={{ minWidth: "220px" }}

        onClick={() =>
          setPageOptions({
            ...pageOptions,
            page: pageOptions.page - 1,
          })
        }
      >
        <ChevronLeft />
        {/* <span>Forrige</span> */}
      </button>
      <div
        className={` ${styles.text} px-2 py-2 d-flex justify-content-center gap-2 border-end`}
        style={{ minWidth: "200px" }}
      >
        <span className={styles.mobile}>Viser </span>
        <b>
          {pageOptions.page * pageSize - (pageSize - 1)} -{" "}
          {pageOptions.page * pageSize > totalCount
            ? totalCount
            : pageOptions.page * pageSize}{" "}
        </b>
        af {totalCount}{" "}
      </div>
      <button
        aria-label="Næste"
        className="px-2 py-2 h-100 border-end d-flex align-items-center justify-content-center" // {styles.next}
        style={{
          color:
            pageOptions.page * pageSize >= totalCount ? "#859c8d" : "inherit",
        }}
        disabled={pageOptions.page * pageSize >= totalCount}
        onClick={() =>
          setPageOptions({
            ...pageOptions,
            page: pageOptions.page + 1,
          })
        }
      >
        {/* <span>Næste</span> */}
        <ChevronRight />
      </button>
      <button
        aria-label="Sidste"
        className={`d-flex justify-content-start align-items-center px-1 py-2 h-100 `} //${styles.next} ${styles.firstlast}
        style={{
          color:
            pageOptions.page * pageSize >= totalCount ? "#859c8d" : "inherit",
        }}
        disabled={pageOptions.page * pageSize >= totalCount}
        onClick={() =>
          setPageOptions({
            ...pageOptions,
            page: Math.ceil(totalCount / pageSize),
          })
        }
      >
        {/* <span>Næste</span> */}
        <ChevronRight />
        <ChevronRight />
      </button>
    </div>
  );
}
