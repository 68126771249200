import { get } from "../index";

const getBfe = async (bfe: number, getKommune: boolean = true) => {
  try {
    let promise = get<Ejendom>({
      query: `ejendom/bfe/${bfe}`,
    }).then((e) => {
      // Conditional Chaining to add "kommune" after ejendom has been loaded
      let knr = e?.beliggenhedsadresse?.kommunekode;
      if (knr && getKommune) {
        try {
          return get<Kommune>({
            query: `kommune/${knr}`,
          }).then((kom) => {
            e.kommune = kom;
            return e;
          });
        } catch (error) {
          return e;
        }
      } else {
        // If no kommunenr return ejendom without kommune
        return e;
      }
    });
    return promise;
  } catch (error) {
    return Promise.reject(error);
  }
};

const api: EjendomApi = {
  getBfe,
};

export default api;

export interface EjendomApi {
  getBfe: {
    (bfe: number, getKommune: boolean): Promise<Ejendom>;
  };
}
