import React from "react";

export default function Close(props: Icon) {
  const { height, width, primaryColor, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (30 / 30) : 30}
      height={height ? height : width ? width * (30 / 30) : 30}
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="icon-close"
          fill={primaryColor ? primaryColor : "currentColor"}
          fillRule="nonzero"
        >
          <path
            d="M29.2,29 C28.8,29.4 28.2,29.7 27.6,29.7 C27,29.7 26.4,29.5 26,29 L14.8,17.9 C14.6,18.2 14.5,18.2 14.5,18.2 L4.2,28.6 C3.4,29.4 1.8,29.5 1,28.6 C0.1,27.7 0.1,26.3 1,25.4 L11.3,15.2 C11.4,15.1 11.5,14.9 11.7,14.7 L3.2,6.2 C2.8,5.8 2.5,5.2 2.5,4.6 C2.5,4 2.8,3.4 3.2,3 C3.6,2.6 4.2,2.3 4.8,2.3 C5.4,2.3 6,2.6 6.5,3 L14.7,11.2 C18.3,7.1 22.9,2.4 26.1,0.4 C27.2,-0.2 28.6,0.1 29.2,1.1 C29.5,1.6 29.6,2.3 29.5,2.8 C29.4,3.4 29,3.9 28.5,4.2 C26.3,5.5 22.6,9.1 18,14.3 L29.4,25.7 C30.1,26.7 30.1,28.1 29.2,29 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </svg>
  );
}
