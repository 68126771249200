import { ExtendedResultAppender } from '@/models/ExtendedResultAppender';
import { SearchResult } from '@/models/SearchResult';
import { SourceBase } from '@/models/SourceBase';
import { GrundFetcher } from '../Data/GrundFetcher';
import { IAutocompleteResult } from './IAutocompleteResult';

export class DawaAdgangsAdresseToGrundExtendedAppender extends ExtendedResultAppender {
    public grundFetcher: GrundFetcher;
    public source: SourceBase;

    private extentDataTypes: string[] = [
        'dawa_adresse',
        'dawa_adgangsadresse',
        'grund',
    ];

    constructor(source: SourceBase) {
        super();
        this.source = source;
        this.grundFetcher = new GrundFetcher(
            source.options.dafUser,
            source.options.dafPassword,
            source.options.dafEjendomsrelationDate);
    }

    public CanExtentResult(resultDataType: string): boolean {
        return this.extentDataTypes.indexOf(resultDataType) > -1;
    }

    public async getExtendedResult(searchresult: SearchResult, source: SourceBase): Promise<SearchResult> {
        if (searchresult.datatype === 'bfe') {
            return searchresult;
        } else if (searchresult.datatype === 'dawa_adgangsadresse' || searchresult.datatype === 'dawa_adresse') {


            const data = searchresult.data as IAutocompleteResult;
            let id = data.data.id;
            if (data.type && data.type === 'adresse') {
                id = (data.data as any).adgangsadresseid;
            }

            const grunde = await this.grundFetcher.GetFromAdgangsAdresse(
                id,
            );

            // tslint:disable-next-line:max-line-length
            return Object.assign({}, searchresult, {
                data: grunde.map((e) =>
                    GrundFetcher.MapToSearchResult(null, source.name, e,
                        source.getResultType('grund')),
                ),
            });
        } else {
            return null;
        }
    }
}
