import React, { useState } from "react";

export interface SearchResultState {}

export const SearchResultContext = React.createContext<any>(undefined);

const SearchResultProvider = (props: any) => {
  const [result, setResult] = useState(undefined);

  const [redirect, setRedirect] = useState<{
    state: boolean;
    path: string;
  }>({ state: false, path: "/" });

  const [searchKey, setSearchKey] = React.useState(1);
  const resetSearch = () => {
    setSearchKey(searchKey + 1);
  };

  return (
    <SearchResultContext.Provider
      value={{
        result,
        setResult,
        redirect,
        setRedirect,
        searchKey,
        resetSearch,
      }}
    >
      {props.children}
    </SearchResultContext.Provider>
  );
};

export default SearchResultProvider;
