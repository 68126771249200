import React, { useRef, MutableRefObject } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./Modal.module.scss";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import CloseLg from "./icons/CloseLg";

export default function Modal({
  isToggled,
  setIsToggled,
  hideCloseButton = false,
  children,
}) {
  const ref = useRef<HTMLDivElement>();
  useOnClickOutside(ref, () => setIsToggled(false));

  return (
    <AnimatePresence>
      {isToggled && (
        <motion.div
          className={`${styles.backdrop} ${
            hideCloseButton ? "ignore-onClickOutside" : ""
          }`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            ref={ref as MutableRefObject<HTMLDivElement>}
            className={styles.container}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className={styles.modalcont}
              initial={{ y: 50 }}
              animate={{ y: 0 }}
            >
              {!hideCloseButton && (
                <button
                  className={styles.close}
                  onClick={() => setIsToggled(false)}
                >
                  <CloseLg height={20} />{" "}
                  <h3 className="p-2 text-white fs-2 fw-medium">Luk</h3>
                </button>
              )}
              <div className={styles.content}>{children}</div>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
