import ExternalArrow from "components/common/icons/ExternalArrow";
import Badge from "components/common/Badge";

export default function CvrVirksomheder(props: ICvrVirksomhederProps) {
  const { virksomheder } = props;
  const { oplysninger } = virksomheder;
  return (
    <div>
      <h4 className="text-info mb-3 d-flex align-items gap-2">
        CVR-virksomheder{" "}
        <Badge
          className="bg-white bg-opacity-75 text-info"
          text={oplysninger.length.toString()}
          title={`${oplysninger.length.toString()} virksomheder`}
        />
      </h4>
      <ul className="d-flex gap-3 flex-wrap">
        {oplysninger.map((o, i) => {
          const {
            navnNavn,
            beliggenhedsadresseVejnavn,
            beliggenhedsadresseHusnummerfra,
            beliggenhedsadressePostnummer,
            beliggenhedsadressePostdistrikt,
            cvrnummerrelation,
          } = o;
          return (
            <li
              style={{ flex: "1 0 40%", minWidth: 300 }}
              className="p-3 bg-white bg-opacity-50 rounded"
              key={navnNavn + "-" + cvrnummerrelation + i}
            >
              <p className="mb-1">
                <a
                  className="external text-info"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=${cvrnummerrelation}`}
                >
                  <span>CVR {cvrnummerrelation}</span>
                  <ExternalArrow />
                </a>
              </p>
              <p className="m-0">{navnNavn}</p>
              <p className="m-0">
                {beliggenhedsadresseVejnavn} {beliggenhedsadresseHusnummerfra},{" "}
                {beliggenhedsadressePostnummer}{" "}
                {beliggenhedsadressePostdistrikt}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

interface ICvrVirksomhederProps {
  virksomheder: CvrVirksomheder;
}
