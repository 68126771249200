import { IconProvider } from '../IconProvider';

export class MaterialDesignIconProvider implements IconProvider {
    public name: string = 'material';
    public type: string = 'font';
    public getIcon(nameOrElement: string) {
        switch (nameOrElement) {
            case 'icon-magnifier':
                return '<i class="material-icons">search</i>';
            case 'icon-cross':
                return '<i class="material-icons">close</i>';
            case 'icon-loading':
                return '<i class="material-icons">data_usage</i>';
            case 'icon-source-adresse':
                return '<i class="material-icons">place</i>';
            case 'icon-source-ejendom':
                return '<i class="material-icons">domain</i>';
            case 'icon-source-matrikel':
                return '<i class="material-icons">grid_on</i>';
            case 'icon-source-fritekst':
                return '<i class="material-icons">search</i>';
            case 'icon-source-virksomhed':
                return '<i class="material-icons">work</i>';
            case 'icon-source-action':
                return '<i class="material-icons">arrow_forward_ios</i>';
            case 'icon-nav':
                return '<i class="material-icons">menu</i>';
            case 'icon-clear':
                return '<i class="material-icons">cancel</i>';
            case 'icon-source-history':
                return '<i class="material-icons">access_time</i>';
            case 'icon-widget-history':
                return '<i class="material-icons">access_time</i>';
            default:
                return nameOrElement;
        }
    }
}
