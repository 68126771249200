export const size = {
  base: 15,
};

export const color = {
  green: {
    100: "#f4f8f7",
    200: "#e6eeec",
    300: "#e0ece6",
    400: "#c9ded5",
    500: "#bcd6ca",
    600: "#90bca9",
    700: "#859c8d",
    800: "#546d5c",
    900: "#003c16",
  },
  blue: {
    100: "#edf2f3",
    500: "#92aeb5",
    700: "#104e5b",
  },
  neutral: {
    100: "#fbfbfb",
    300: "#d8d8d8",
    500: "#4f4c4c",
    600: "#2f2f2f",
    700: "#000000",
  },
  red: {
    100: "#fef7d5",
    700: "#ffbc00",
  },
  yellow: {
    100: "#fef7d5",
    700: "#ffbc00",
  },
};

export const bgColor = "#ffffff";
export const bgColorSecondary = color.green[100];
export const bgColorHover = color.green[300];

export const borderColorLight = color.green[200];
export const borderColorMedium = color.green[600];
export const borderColorStrong = color.green[900];
