import React, { useEffect } from "react";
import styles from "./TekniskAnlaegInfo.module.scss";
import Info from "../Info";
import { TekniskAnlaegTemplate } from "../../Template";
import { getRelevantSupplerendeOplysninger } from "util/index";
import SupplerendeOplysninger from "routes/Ejendom/SupplerendeOplysninger/SupplerendeOplysninger";
import BygningPaaFremmedGrund from "../Bygning/BygningPaaFremmedGrund";

export default function TekniskAnlaegInfo(props: ITekniskAnlaegInfoProps) {
  const {
    tekniskAnlaeg,
    setTekniskAnlaegMarkeret,
    liste,
    opgave,
    supplerendeOplysninger,
  } = props;

  const relevantSupplerendeOplysninger = supplerendeOplysninger
    ? getRelevantSupplerendeOplysninger(
        supplerendeOplysninger,
        tekniskAnlaeg.tekniskAnlaeg.id_lokalId
      )
    : undefined;
  const getOpgaveReference = (tekniskAnlaeg, opgave: Opgave) => {
    // This needs to be a list of all "id_lokalId"
    let visningsdata: Visningsdata = opgave?.visningsdata;
    let referencer: Array<VisningsdataElementReference> = [];

    if (visningsdata) {
      visningsdata?.elementer?.forEach((o: VisningsdataElement, idx) => {
        o?.referencer?.forEach((r: VisningsdataElementReference, idx) => {
          if (
            tekniskAnlaeg.tekniskAnlaeg.id_lokalId.toLocaleLowerCase() ===
            r.bbr_id.toLocaleLowerCase()
          ) {
            // Sub-units on the building will be added if push_flag is set
            if (r.attribut_kilde === undefined) {
              r.attribut_kilde = "bbr";
            }
            referencer.push(r);
          }
        });
      });
    }
    return referencer;
  };
  const referencer: Array<VisningsdataElementReference> =
    opgave !== undefined ? getOpgaveReference(tekniskAnlaeg, opgave) : [];

  // If the reference list for the opgave has atleast 1 element, mark the building
  useEffect(() => {
    if (referencer.length > 0) {
      setTekniskAnlaegMarkeret(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referencer]);

  return (
    <div className={styles.container}>
      <Info
        element={tekniskAnlaeg.tekniskAnlaeg}
        renderEmpty={false} // Empty fields are not shown unless they have a reference
        referencer={referencer}
        templater={new TekniskAnlaegTemplate()}
        setMarkeret={setTekniskAnlaegMarkeret}
        listeType={liste?.listetype}
      />
      {tekniskAnlaeg.tekniskAnlaeg.bygningPåFremmedGrund && (
        <BygningPaaFremmedGrund
          BygningPaaFremmedGrund={
            tekniskAnlaeg.tekniskAnlaeg.bygningPåFremmedGrund
          }
        ></BygningPaaFremmedGrund>
      )}
      {relevantSupplerendeOplysninger && (
        <SupplerendeOplysninger {...relevantSupplerendeOplysninger} />
      )}
    </div>
  );
}

interface ITekniskAnlaegInfoProps {
  tekniskAnlaeg: any; //TODO Type this
  setTekniskAnlaegMarkeret: Function;
  expand: boolean;
  liste?: OpgaveListe;
  opgave?: Opgave;
  adresse?: string;
  postnr?: number;
  supplerendeOplysninger?: SupplerendeOplysninger;
}
