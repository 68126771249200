import React, { useContext } from "react";
import styles from "./NextPreviousPage.module.scss";
import { TaskQueryContext } from "context/TaskQueryProvider";
import ChevronRight from "../icons/ChevronRight";
import ChevronLeft from "../icons/ChevronLeft";
import PaginationButton from "./PaginationButton";

export default function TaskNextPreviousPage() {
  const { loading, options, setOptions, nextPage, prevPage, total } =
    useContext(TaskQueryContext);
  const { page } = options;
  const pageSize = options.pageSize ?? 50;
  const isPageOne = page === 1;
  const isLastPage = page * pageSize >= total;

  if (total === 0 || loading || !pageSize) return null;
  return (
    <div className="w-100 d-flex justify-content-center">
      <div className="border d-flex justify-content-center rounded-1">
        <PaginationButton
          ariaLabel="Første"
          disabled={isPageOne}
          style={{ color: isPageOne ? "#859c8d" : "inherit" }}
          onClick={() => setOptions({ ...options, page: 1 })}
        >
          <ChevronLeft />
          <ChevronLeft />
        </PaginationButton>
        <PaginationButton
          ariaLabel="Forrige"
          disabled={isPageOne}
          style={{ color: isPageOne ? "#859c8d" : "inherit" }}
          onClick={prevPage}
        >
          <ChevronLeft />
        </PaginationButton>
        <div
          className="h-100 p-2 border-end d-flex justify-content-center gap-2 align-items-center"
          style={{ minWidth: "220px" }}
        >
          <span className={styles.mobile}>Viser </span>
          <b>
            {page * pageSize - (pageSize - 1)}-
            {page * pageSize > total ? total : page * pageSize}{" "}
          </b>
          af {total}{" "}
        </div>
        <PaginationButton
          ariaLabel="Næste"
          disabled={isLastPage}
          style={{ color: isLastPage ? "#859c8d" : "inherit" }}
          onClick={nextPage}
        >
          <ChevronRight />
        </PaginationButton>
        <PaginationButton
          ariaLabel="Sidste"
          disabled={isLastPage}
          style={{ color: isLastPage ? "#859c8d" : "inherit" }}
          onClick={() =>
            setOptions({
              ...options,
              page: Math.ceil(total / pageSize),
            })
          }
        >
          <ChevronRight />
          <ChevronRight />
        </PaginationButton>
      </div>
    </div>
  );
}
