import { RealTimeContext } from "context/RealTimeProvider";
import { useContext, useEffect } from "react";
import { usePrevious } from "./usePrevious";

export default function useRealTimeOpgaver(
  opgaver: Opgave[] | undefined,
  setOpgaver: (opgaver: Opgave[]) => void
) {
  const { opgaveMessages } = useContext(RealTimeContext);
  const prevOpgaveMessages = usePrevious(opgaveMessages);
  useEffect(() => {
    if (
      opgaver?.length &&
      opgaveMessages.length &&
      (!prevOpgaveMessages || prevOpgaveMessages.length < opgaveMessages.length)
    ) {
      const newOpgaveMessage = opgaveMessages[opgaveMessages.length - 1];
      if (opgaver?.find((o) => o.id === newOpgaveMessage.id)) {
        setOpgaver(
          opgaver.map((o) => {
            if (o.id === newOpgaveMessage.id) {
              return { ...o, ...newOpgaveMessage };
            }
            return o;
          })
        );
      }
    }
  }, [opgaveMessages, prevOpgaveMessages, opgaver, setOpgaver]);
}
