import React, { useState } from "react";
// import {
//   OpgaveListeType,
//   OpgaveBrugerStatus,
// } from "interfaces/Opgaveliste/enums";
import ArrowRight from "components/common/icons/ArrowRight";
import FolderIcon from "components/common/icons/Folder";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";

export default function FejllisteItem(props: IFejllisteItemProps) {
  const {
    // opgave,
    opgaveliste,
    currentOpgaveliste,
  } = props;

  let history = useHistory();
  const [hoverState, setHoverState] = useState(false);

  return (
    <motion.li
      key={opgaveliste.id}
      className="list-group-item list-group-item-action w-100 py-4 d-flex justify-content-between align-items-center border-0 border-bottom border-1 text-muted column-gap-3 flex-wrap"
      onMouseEnter={() => {
        setHoverState(true);
      }}
      onMouseLeave={() => {
        setHoverState(false);
      }}
      aria-selected={
        currentOpgaveliste && currentOpgaveliste.id === opgaveliste.id
          ? true
          : false
      }
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      title={opgaveliste.beskrivelse}
      onClick={() => {
        history.push("/fejllister/" + opgaveliste.id);
      }}
      style={{ cursor: "pointer" }}
    >
      <div className="d-flex gap-3" style={{ flex: 4 }}>
        <div className="hover-effect">
          <FolderIcon width={26} />
        </div>
        <div className="d-flex flex-column gap-2">
          <h2 className="h3 fw-normal"> {opgaveliste.navn}</h2>
          <p className="mb-0">{opgaveliste.beskrivelse}</p>
        </div>
      </div>
      <div
        className="d-flex align-items-center justify-content-end"
        style={{ flex: 2 }}
      >
        <p className="fw-normal mb-0 text-end">
          <span className="fw-semibold">{opgaveliste.antalOpgaver}</span> til
          gennemsyn
        </p>
        <div
          className="d-flex align-items-center justify-content-end"
          style={{ width: "40px" }}
        >
          {hoverState && <ArrowRight width={20} />}
        </div>
      </div>
    </motion.li>
  );
}

interface IFejllisteItemProps {
  opgave?: Opgave;
  opgaveliste: any;
  currentOpgaveliste: any;
}
