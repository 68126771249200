import React from "react";

export default function Chevron(props: Icon) {
  const { height, width, primaryColor, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (10 / 13) : 10}
      height={height ? height : width ? width * (13 / 10) : 13}
      viewBox="0 0 10 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          transform="translate(-1563.000000, -194.000000)"
          stroke={primaryColor ? primaryColor : "currentColor"}
          strokeWidth="1.6"
        >
          <g transform="translate(1567.730766, 201.000000) rotate(-270.000000) translate(-1567.730766, -201.000000) translate(1561.730766, 197.000000)">
            <path d="M3.38840067e-13,7.01767541 C3.32354314,2.47374504 1.45228625,4.75184493 5.69317714,0.249381008"></path>
            <path d="M5.69317714,-7.46069873e-14 C9.34225464,5.41935069 7.46544893,3.03206675 11.1871382,7.26705642"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
