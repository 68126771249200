import { SourceBase } from '../../../models/SourceBase';
import { SourceOptions } from '../../../models/SourceOptions';
import { SearchResult } from '../../../models/SearchResult';
import { SearchInput } from '../../../models/SearchInput';
import { CustomSource } from '@/models/Initialize';
import { ResultBehaviour } from '@/models/ResultBehaviour';
import { SourceFilters } from '@/models/SourceFilters';

const defaultCustomSourceOptions: SourceOptions = {
    minimumInputLength: 1,
    inputDelay: 0,
    hideWhenEmpty: false,
    title: 'Custom',
    icon: 'icon-magnifier',
    resultBehaviour: ResultBehaviour.default,
    isAutoComplete: true,
};

export class CustomWrapperSource extends SourceBase {
    public name: string;
    public options: SourceOptions;
    public filters: SourceFilters;
    private source: CustomSource = null;

    constructor(name: string, options: SourceOptions, filters: SourceFilters, source: CustomSource) {
        super();
        this.options = Object.assign({name}, defaultCustomSourceOptions, options);
        this.filters = Object.assign({}, filters);
        this.name = name;

        source.options = this.options;

        this.source = source;

    }

    public search(input: SearchInput): Promise<SearchResult[]> {
        return this.source.search(input);
    }
}
