import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "../Sidebar/Accordion.module.scss";
// import { mergeClassNames } from "util/index";
import FejllisteItem from "../FejllisteItem";

const variants = {
  open: { opacity: 1, height: "auto" },
  closed: { opacity: 0, height: 0 },
};

export default function List(props: ListProps) {
  const { isOpen, fejllisterState, currentOpgaveliste } = props;

  return (
    <div
    // className={mergeClassNames(
    //   styles.container,
    //   isOpen ? styles.expanded : ""
    // )}
    >
      <AnimatePresence>
        {isOpen && (
          <motion.div
            id="tablist-content"
            className={`${styles.opgaver} border-0`}
            variants={variants}
            initial="closed"
            animate="open"
            exit={variants.closed}
            transition={{ damping: 300 }}
            style={{ overflow: "hidden" }}
          >
            <ul role="tablist" className="list-group border-0">
              {fejllisterState.state === "LOADING" &&
                Array.from(new Array(6)).map((o, i) => {
                  return (
                    <motion.li
                      key={i}
                      className={`${styles.opgave} ${styles.loading} border-0`}
                    >
                      <div className={styles.text}>
                        <div className={styles.line}></div>
                        <div className={styles.line}></div>
                      </div>
                    </motion.li>
                  );
                })}
              {fejllisterState.state === "IDLE" &&
                fejllisterState.data.map((opgaveliste) => {
                  return (
                    <FejllisteItem
                      currentOpgaveliste={currentOpgaveliste}
                      opgaveliste={opgaveliste}
                      key={opgaveliste.id}
                    />
                  );
                })}
              {/* {fejllisterState.state === "IDLE" &&
                [...fejllisterState.data]
                  .sort((a, b) => a.navn.localeCompare(b.navn))
                  .map((opgaveliste) => {
                    return (
                      <FejllisteItem
                        currentOpgaveliste={currentOpgaveliste}
                        opgaveliste={opgaveliste}
                        key={opgaveliste.id}
                      />
                    );
                  })} */}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

interface ListProps {
  isOpen: boolean;
  fejllisterState: {
    state: "IDLE" | "LOADING" | "ERROR";
    data: Array<OpgaveListe>;
  };
  currentOpgaveliste?: OpgaveListe;
}
