import Badge from "components/common/Badge";
import ExternalArrow from "components/common/icons/ExternalArrow";

export default function SmileyVirksomheder(props: ISmileyVirksomhederProps) {
  const { smileyVirksomheder } = props;
  const { oplysninger } = smileyVirksomheder;
  return (
    <div>
      <h4 className="text-info mb-3 d-flex align-items-center gap-2">
        Smiley-virksomheder{" "}
        <Badge
          className="bg-white bg-opacity-75 text-info"
          text={oplysninger.length.toString()}
          title={`${oplysninger.length.toString()} virksomheder`}
        />
      </h4>
      <ul className="d-flex flex-wrap gap-3">
        {oplysninger.map((o, idx) => {
          const { cvrnr, navn1, adresse1, postnr, by, url } = o;
          return (
            <li
              style={{ flex: "1 0 40%", minWidth: 300 }}
              className="p-3 bg-white bg-opacity-50 rounded"
              key={cvrnr + "_" + adresse1 + "_" + idx}
            >
              <p className="m-0">
                <a
                  className="external text-info"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={url}
                >
                  <span>CVR {cvrnr}</span>
                  <ExternalArrow />
                </a>
              </p>
              <p className="m-0">{navn1}</p>
              <p className="m-0">
                {adresse1}, {postnr} {by}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

interface ISmileyVirksomhederProps {
  smileyVirksomheder: SmileyVirksomheder;
}
