import DownloadIcon from "components/common/icons/DownloadIcon";
import DropDownMenu from "components/common/DropDownMenu";
import React, { useContext } from "react";
import { OpgaveSortOrder } from "interfaces/Opgaveliste/enums";
import { TaskQueryContext } from "context/TaskQueryProvider";
import AktiveFiltre from "./Header/AktiveFiltre";
import SortDown from "components/common/icons/SortDown";
import SortAlphaDown from "components/common/icons/SortAlphaDown";
import SortAlphaUp from "components/common/icons/SortAlphaUp";
import Open from "components/common/icons/Open";
import FiltersDropDown from "components/common/FiltersDropDown";
import { AuthContext } from "context/AuthProvider";
import Circle from "components/common/icons/Circle";
import { Link, useParams } from "react-router-dom";
import useMediaQuery from "hooks/useMediaQuery";

const Filter = (props: { data: any }) => {
  const { data } = props;
  const { statusCount, options, setOptions, taskList } =
    useContext(TaskQueryContext);
  const { brugerStatus, sortOrder } = options;
  const { all, doing, done, unsolvable, open } = statusCount;
  const authState = useContext(AuthContext);
  const { bruger } = authState;
  const roller = bruger ? bruger.roller : [];
  const { id } = useParams<RouteParams>();
  type RouteParams = { id: string };
  const matches = useMediaQuery("(max-width: 1000px)");

  const getFileQuery = () => {
    const params = new URLSearchParams();
    const sortOrder = options?.sortOrder ?? OpgaveSortOrder.undefined;
    if (sortOrder) params.append("sortOrder", sortOrder.toString());
    if (options) {
      const { brugerStatus, brugerId, kommunenr } = options;
      if (brugerId) params.append("brugerId", brugerId);
      if (brugerStatus) params.append("brugerStatus", brugerStatus);
      if (kommunenr) params.append("kommunenr", kommunenr);
    }
    return params.toString();
  };

  return (
    <>
      <div
        className={`d-flex justify-content-between  row-gap-2 flex-wrap mt-5 w-100 mw-100 ${
          matches
            ? "w-100 justify-content-between column-gap-0"
            : "justify-content-start column-gap-6"
        }`}
      >
        <div
          className={`d-flex align-items-center mw-100 ${
            matches
              ? "w-100 justify-content-between column-gap-0"
              : "justify-content-start"
          }`}
          style={{ flex: "2 1 auto" }}
        >
          <button
            style={
              matches
                ? {
                    minWidth: "100px",
                    marginBottom: "-2px",
                    flex: "1 1 auto",
                  }
                : {
                    minWidth: "100px",
                    marginBottom: "-2px",
                    flex: "0 1 auto",
                  }
            }
            className={`btn fw-semibold d-flex column-gap-2 py-4 h-100 rounded-0 ${
              brugerStatus === "0"
                ? "text-primary border-top-0 border-start-0 border-end-0 border-bottom border-bottom-3 border-primary"
                : "text-muted border-0 border-top-0 border-start-0 border-end-0 border-bottom border-bottom-3 border-muted"
            } ${
              matches
                ? "flex-column justify-content-end align-items-center row-gap-1 px-2 fs-5"
                : "justify-content-start align-items-center px-3"
            }`}
            onClick={() =>
              setOptions({ ...options, brugerStatus: "0", page: 1 })
            }
          >
            <p className="mb-0">Åbne</p>
            <span
              className={`badge ${
                brugerStatus === "0"
                  ? "bg-primary text-white"
                  : "bg-secondary-subtle text-primary"
              }`}
            >
              {open}
            </span>
          </button>
          <button
            style={{
              minWidth: "100px",
              marginBottom: "-2px",
              flex: "0 1 auto",
            }}
            className={`btn fw-semibold d-flex column-gap-2 py-4 h-100 rounded-0 ${
              brugerStatus === "1"
                ? "text-primary border-top-0 border-start-0 border-end-0 border-bottom border-bottom-3 border-primary"
                : "text-muted border-0 border-top-0 border-start-0 border-end-0 border-bottom border-bottom-3 border-muted"
            } ${
              matches
                ? "flex-column justify-content-end align-items-center row-gap-1 px-2 fs-5"
                : "justify-content-start align-items-center px-3"
            }`}
            onClick={() =>
              setOptions({
                ...options,
                page: 1,
                brugerId: undefined,
                kommunenr: undefined,
                brugerStatus: "1",
              })
            }
          >
            <p className="mb-0">I gang</p>
            <span
              className={`badge ${
                brugerStatus === "1"
                  ? "bg-primary text-white"
                  : "bg-secondary-subtle text-primary"
              }`}
            >
              {doing}
            </span>
          </button>

          <button
            style={
              matches
                ? {
                    minWidth: "100px",
                    marginBottom: "-2px",
                    flex: "1 1 auto",
                  }
                : {
                    minWidth: "100px",
                    marginBottom: "-2px",
                    flex: "0 1 auto",
                  }
            }
            className={`btn fw-semibold d-flex column-gap-2 py-4 h-100 rounded-0 ${
              brugerStatus === "2"
                ? "text-primary border-top-0 border-start-0 border-end-0 border-bottom border-bottom-3 border-primary"
                : "text-muted border-0 border-top-0 border-start-0 border-end-0 border-bottom border-bottom-3 border-muted"
            } ${
              matches
                ? "flex-column justify-content-end align-items-center row-gap-1 px-2 fs-5"
                : "justify-content-start align-items-center px-3"
            }`}
            onClick={() =>
              setOptions({ ...options, brugerStatus: "2", page: 1 })
            }
          >
            <p className="mb-0">Færdige</p>
            <span
              className={`badge ${
                brugerStatus === "2"
                  ? "bg-primary text-white"
                  : "bg-secondary-subtle text-primary"
              }`}
            >
              {done}
            </span>
          </button>

          {data.brugerstatus[5].visesUi === true && (
            <button
              style={
                matches
                  ? {
                      minWidth: "100px",
                      marginBottom: "-2px",
                      flex: "1 1 auto",
                    }
                  : {
                      minWidth: "100px",
                      marginBottom: "-2px",
                      flex: "0 1 auto",
                    }
              }
              className={`btn fw-semibold d-flex column-gap-2 py-4 h-100 rounded-0 ${
                brugerStatus === "5"
                  ? "text-primary border-top-0 border-start-0 border-end-0 border-bottom border-bottom-3 border-primary"
                  : "text-muted border-0 border-top-0 border-start-0 border-end-0 border-bottom border-bottom-3 border-muted"
              } ${
                matches
                  ? "flex-column justify-content-end align-items-center row-gap-1 px-2 fs-5"
                  : "justify-content-start align-items-center px-3"
              }`}
              onClick={() =>
                setOptions({ ...options, brugerStatus: "5", page: 1 })
              }
            >
              <p className="mb-0">Kan ikke løses</p>
              <span
                className={`badge ${
                  brugerStatus === "5"
                    ? "bg-primary text-white"
                    : "bg-secondary-subtle text-primary"
                }`}
              >
                {unsolvable}
              </span>
            </button>
          )}
          <button
            style={
              matches
                ? {
                    minWidth: "100px",
                    marginBottom: "-2px",
                    flex: "1 1 auto",
                  }
                : {
                    minWidth: "100px",
                    marginBottom: "-2px",
                    flex: "0 1 auto",
                  }
            }
            className={`btn fw-semibold d-flex column-gap-2 py-4 h-100 rounded-0 ${
              brugerStatus === undefined
                ? "text-primary border-top-0 border-start-0 border-end-0 border-bottom border-bottom-3 border-primary"
                : "text-muted border-0 border-top-0 border-start-0 border-end-0 border-bottom border-bottom-3 border-muted"
            } ${
              matches
                ? "flex-column justify-content-end align-items-center row-gap-1 px-2 fs-5"
                : "justify-content-start align-items-center px-3"
            }`}
            onClick={() =>
              setOptions({ ...options, brugerStatus: undefined, page: 1 })
            }
          >
            <p className="mb-0">Alle</p>
            <span
              className={`badge ${
                brugerStatus === undefined
                  ? "bg-primary text-white"
                  : "bg-secondary-subtle text-primary"
              }`}
            >
              {all}
            </span>
          </button>
        </div>
        <div
          className={`d-flex  align-items-center gap-3 ${
            matches ? "justify-content-end" : "justify-content-end"
          }`}
          style={{ flex: 1 }}
        >
          <div className="d-flex align-items-center btn-group">
            <DropDownMenu
              arrowClass="text-primary"
              noArrow
              width="260px"
              dropdownTriggerStyle="pe-0 py-0"
              activeButtonStyle
              element={
                <div
                  className={`c-filter-btn btn btn-outline-primary-subtle btn--icon tooltip-center border-1 d-flex align-items-center border-end-1
                   `}
                  style={{
                    width: "fit-content",
                    borderRadius: "6px 0 0 6px",
                  }}
                  aria-label="Sortér"
                >
                  <SortDown width={22} />
                </div>
              }
              menuClassNames="pb-1 border border-muted"
              menu={[
                <div
                  className="px-2 py-1"
                  style={{ width: "260px" }}
                  key="header"
                >
                  <p className="mb-0 py-2 px-2 fs-4 text-muted">
                    Sortér liste efter
                  </p>
                </div>,
                <div
                  className="px-2 py-1"
                  style={{ width: "260px" }}
                  key="medarbejder"
                >
                  <div
                    title="Sortér efter medarbejder"
                    key="medarbejder"
                    className="py-2 px-2 list-group-item list-group-item-action d-flex justify-content-between gap-2 border-0 rounded text-muted"
                    style={{
                      position: "relative",
                      transition: "background-color 300ms",
                    }}
                    onClick={() => {
                      setOptions({
                        ...options,
                        page: 1,
                        sortOrder:
                          sortOrder === OpgaveSortOrder.medarbejderInitialerAsc
                            ? OpgaveSortOrder.medarbejderInitialerDesc
                            : OpgaveSortOrder.medarbejderInitialerAsc,
                      });
                    }}
                  >
                    <div className="d-flex align-items-center gap-2">
                      {sortOrder === OpgaveSortOrder.medarbejderInitialerDesc ||
                      sortOrder === OpgaveSortOrder.medarbejderInitialerAsc ? (
                        <>
                          <span className="d-flex align-items-center text-primary">
                            <Circle width={18} />
                          </span>
                          <p className="mb-0 fw-normal d-flex align-items-center gap-2 text-primary">
                            Medarbejder
                          </p>
                        </>
                      ) : (
                        <>
                          <span className="hover-effect d-flex align-items-center opacity-25">
                            <Open width={18} />
                          </span>
                          <p className="mb-0 d-flex align-items-center gap-2 text-muted">
                            Medarbejder
                          </p>
                        </>
                      )}
                    </div>
                    <span
                      className={`rounded-2 px-3 py-2 d-flex align-items-center ${
                        sortOrder ===
                          OpgaveSortOrder.medarbejderInitialerDesc ||
                        sortOrder === OpgaveSortOrder.medarbejderInitialerAsc
                          ? "text-primary border border-primary"
                          : "text-muted"
                      }`}
                      style={
                        sortOrder !==
                          OpgaveSortOrder.medarbejderInitialerDesc ||
                        sortOrder !== OpgaveSortOrder.medarbejderInitialerAsc
                          ? { border: "1px solid transparent" }
                          : {}
                      }
                    >
                      {sortOrder ===
                        OpgaveSortOrder.medarbejderInitialerDesc && (
                        <SortAlphaDown width={20} />
                      )}
                      {sortOrder ===
                        OpgaveSortOrder.medarbejderInitialerAsc && (
                        <SortAlphaUp width={20} />
                      )}
                      {sortOrder !== OpgaveSortOrder.medarbejderInitialerAsc &&
                        sortOrder !==
                          OpgaveSortOrder.medarbejderInitialerDesc && (
                          <SortAlphaDown width={20} />
                        )}
                    </span>
                  </div>
                </div>,
                <div className="px-2 py-1" style={{ width: "260px" }} key="bfe">
                  <div
                    title="Sortér efter BFE-nummer"
                    key="bfe"
                    className="py-2 px-2 list-group-item list-group-item-action d-flex justify-content-between border-0 gap-4 rounded text-muted"
                    style={{
                      position: "relative",
                      transition: "background-color 300ms",
                    }}
                    onClick={() => {
                      setOptions({
                        ...options,
                        page: 1,
                        sortOrder:
                          sortOrder === OpgaveSortOrder.bfeAsc
                            ? OpgaveSortOrder.bfeDesc
                            : OpgaveSortOrder.bfeAsc,
                      });
                    }}
                  >
                    <div className="d-flex align-items-center gap-2 ">
                      {sortOrder === OpgaveSortOrder.bfeDesc ||
                      sortOrder === OpgaveSortOrder.bfeAsc ? (
                        <>
                          <span className="d-flex align-items-center text-primary">
                            <Circle width={18} />
                          </span>
                          <p
                            className="mb-0 fw-normal d-flex align-items-center gap-2 text-primary"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            BFE-nummer
                          </p>
                        </>
                      ) : (
                        <>
                          <span className="hover-effect d-flex align-items-center opacity-25">
                            <Open width={18} />
                          </span>
                          <p
                            className="mb-0 d-flex align-items-center gap-2 text-muted"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            BFE-nummer
                          </p>
                        </>
                      )}
                    </div>
                    <span
                      className={`rounded-2 px-3 py-2 d-flex align-items-center ${
                        sortOrder === OpgaveSortOrder.bfeDesc ||
                        sortOrder === OpgaveSortOrder.bfeAsc
                          ? "text-primary border border-primary"
                          : "text-muted"
                      }`}
                      style={
                        sortOrder !== OpgaveSortOrder.bfeDesc ||
                        sortOrder !== OpgaveSortOrder.bfeAsc
                          ? { border: "1px solid transparent" }
                          : {}
                      }
                    >
                      {sortOrder === OpgaveSortOrder.bfeDesc && (
                        <SortAlphaDown width={20} />
                      )}
                      {sortOrder === OpgaveSortOrder.bfeAsc && (
                        <SortAlphaUp width={20} />
                      )}
                      {sortOrder !== OpgaveSortOrder.bfeAsc &&
                        sortOrder !== OpgaveSortOrder.bfeDesc && (
                          <SortAlphaDown width={20} />
                        )}
                    </span>
                  </div>
                </div>,
                <div
                  className="px-2 py-1"
                  style={{ width: "260px" }}
                  key="adresse"
                >
                  <div
                    title="Sortér efter adresse"
                    key="adresse"
                    className="py-2 px-2 list-group-item list-group-item-action d-flex justify-content-between border-0 gap-2 rounded text-muted"
                    style={{
                      position: "relative",
                      transition: "background-color 300ms",
                    }}
                    onClick={() => {
                      setOptions({
                        ...options,
                        page: 1,
                        sortOrder:
                          sortOrder === OpgaveSortOrder.adresseAsc
                            ? OpgaveSortOrder.adresseDesc
                            : OpgaveSortOrder.adresseAsc,
                      });
                    }}
                  >
                    <div className="d-flex align-items-center gap-2">
                      {sortOrder === OpgaveSortOrder.adresseDesc ||
                      sortOrder === OpgaveSortOrder.adresseAsc ? (
                        <>
                          <span className="d-flex align-items-center text-primary">
                            <Circle width={18} />
                          </span>
                          <p
                            className="mb-0 fw-normal d-flex gap-2 text-primary"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Adresse
                          </p>
                        </>
                      ) : (
                        <>
                          <span className="hover-effect d-flex align-items-center opacity-25">
                            <Open width={18} />
                          </span>
                          <p
                            className="mb-0 d-flex gap-2 text-muted"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Adresse
                          </p>
                        </>
                      )}
                    </div>
                    <span
                      className={`rounded-2 px-3 py-2 d-flex align-items-center ${
                        sortOrder === OpgaveSortOrder.adresseDesc ||
                        sortOrder === OpgaveSortOrder.adresseAsc
                          ? "text-primary border border-primary"
                          : "text-muted"
                      }`}
                      style={
                        sortOrder !== OpgaveSortOrder.adresseDesc ||
                        sortOrder !== OpgaveSortOrder.adresseAsc
                          ? { border: "1px solid transparent" }
                          : {}
                      }
                    >
                      {sortOrder === OpgaveSortOrder.adresseDesc && (
                        <SortAlphaDown width={20} />
                      )}
                      {sortOrder === OpgaveSortOrder.adresseAsc && (
                        <SortAlphaUp width={20} />
                      )}
                      {sortOrder !== OpgaveSortOrder.adresseAsc &&
                        sortOrder !== OpgaveSortOrder.adresseDesc && (
                          <SortAlphaDown width={20} />
                        )}
                    </span>
                  </div>
                </div>,
              ]}
            />
            {/* <div> */}
            <FiltersDropDown
              // currentOpgaveOptions={options}
              // setCurrentOpgaveOptions={setOptions}
              opgaveOptions={options}
              setOpgaveOptions={setOptions}
              defaultOpgaveOptions={options}
              hide={() => {}}
              brugerStatuser={taskList?.brugerstatus}
              removeFilters={() =>
                setOptions({
                  ...options,
                  page: 1,
                  brugerId: undefined,
                  kommunenr: undefined,
                  brugerStatus: undefined,
                })
              }
            />
            {/* <button
                title="Vis filtervalg"
                className="c-filter-btn btn btn-outline-primary d-flex align-items-center"
                style={{ borderRadius: "0 6px 6px 0" }}
                onClick={() => setShowFilterOptions(!showFilterOptions)}
              >
                <IconFilter width={22} />
              </button>
              {showFilterOptions && taskList?.brugerstatus && (
                <FilterOptions
                  currentOpgaveOptions={options}
                  setCurrentOpgaveOptions={setOptions}
                  defaultOpgaveOptions={options}
                  hide={() => {}}
                  brugerStatuser={taskList.brugerstatus}
                />
              )} */}
            {/* </div> */}
          </div>
          <div className="h-100 d-flex align-items-center my-3">
            {roller.findIndex((role) => role.navn === "administrator") > -1 ? (
              <DropDownMenu
                dropdownButtonStyle="0.39rem 1rem 0.39rem 1rem"
                dropdownTriggerStyle="p-0 d-flex align-items-center gap-2 btn btn-outline-primary-subtle border-1 rounded-3"
                menuClassNames=" py-1 border border-muted"
                width="200px"
                element={
                  <span className="d-flex align-items-center gap-2">
                    <DownloadIcon />
                    Download
                  </span>
                }
                menu={[
                  <div className="w-100 px-2 py-1" key="downloadlisteexcel">
                    <Link
                      to={`/api/download/liste/excel?listeId=${id}&${getFileQuery()}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="py-2 px-2 list-group-item list-group-item-action d-flex align-items-center justify-content-between gap-2 border-0 rounded fs-5 fw-medium"
                    >
                      Download Excel <DownloadIcon />
                    </Link>
                  </div>,
                  <div className="w-100 px-2 py-1 " key="downloadlistecsv">
                    <Link
                      to={`/api/download/liste/csv?listeId=${id}&${getFileQuery()}`}
                      className="py-2 px-2 list-group-item list-group-item-action d-flex align-items-center justify-content-between gap-2 border-0 rounded fs-5 fw-medium"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download CSV <DownloadIcon />
                    </Link>
                  </div>,
                ]}
              />
            ) : (
              <Link
                to={`/api/download/liste/excel?listeId=${id}&${getFileQuery()}`}
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex align-items-center gap-2 btn btn-outline-primary-subtle border-1 rounded-3 fs-5 flex-nowrap text-nowrap"
                style={{ padding: "0.39rem 1rem 0.39rem 1rem" }}
              >
                <DownloadIcon /> Download Excel
              </Link>
            )}
          </div>
        </div>
      </div>
      {(options.brugerId || options.kommunenr) && ( //|| options.brugerStatus
        <div className="border-1 border-top pt-3 border-muted">
          <AktiveFiltre
            opgaveOptions={options}
            setOpgaveOptions={setOptions}
            removeFilters={() =>
              setOptions({
                ...options,
                page: 1,
                brugerId: undefined,
                kommunenr: undefined,
                brugerStatus: undefined,
              })
            }
          />
        </div>
      )}
    </>
  );
};

export default Filter;
