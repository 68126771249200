import { ResultAppender } from '@/models/ResultAppender';
import { ResultType, SearchResult } from '@/models/SearchResult';
import { SourceBase } from '@/models/SourceBase';
import { Grund, GrundFetcher } from '../Data/GrundFetcher';
import { IAutocompleteResult } from './IAutocompleteResult';

export class DawaAdgangsAdresseToGrundAppender extends ResultAppender {
    public grundFetcher: GrundFetcher;
    public source: SourceBase;
    private appendToDataType: string;

    constructor(source: SourceBase, appendToDataType: string) {
        super();
        this.source = source;
        this.grundFetcher = new GrundFetcher(
            source.options.dafUser,
            source.options.dafPassword,
            source.options.dafEjendomsrelationDate);
        this.appendToDataType = appendToDataType;
    }

    public CanAppendResult(resultDataType: string): boolean {
        return resultDataType === (this.appendToDataType || 'dawa_adresse');
    }

    public async getResults(searchresult: SearchResult, source: SourceBase): Promise<SearchResult[]> {
        const data = searchresult.data as IAutocompleteResult;
        let id = data.data.id;
        if (data.type && data.type === 'adresse') {
            id = (data.data as any).adgangsadresseid;
        }

        const result = await this.grundFetcher.GetFromAdgangsAdresse(
            id,
        );

        // tslint:disable-next-line:max-line-length
        return result.map((r: Grund) =>
            GrundFetcher.MapToSearchResult(
                `${searchresult.id}_${r.id_lokalId}`,
                source.name,
                r,
                source.getResultType('grund'),
            ),
        );

    }
}
