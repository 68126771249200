import styles from "./Ejer.module.scss";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import ejfApi from "api/ejf";
import { EjerforholdsKode } from "interfaces/ejf/enums";
import DropDownSection from "components/DropDownSection";
import ArrowRight from "components/common/icons/ArrowRight";
import { useQuery } from "react-query";

export default function Ejer(props: IEjer) {
  const { ejendom } = props;

  const { data: ejerfortegnelse, isLoading: ejerFortegnelseIsLoading } =
    useQuery({
      queryKey: ["fetchEjerfortegnelse", ejendom.bfeNr],
      retry: false,
      queryFn: async () => {
        const ejf = await ejfApi.getEjf(Number(ejendom.bfeNr));
        let primaer = ejf.find((x) => x.primaerKontakt === true);
        let ikke_primaer = ejf.filter((x) => x.primaerKontakt !== true);
        return {
          primaer,
          ikke_primaer,
        };
      },
    });

  //fetchSammeejer(ejendom.bfeNr);
  const { data: sammeejer, isLoading: sammeejerisLoading } = useQuery({
    queryKey: ["fetchSammeejer", ejendom.bfeNr],
    retry: false,
    queryFn: async () => {
      const ol = await ejfApi.getEjfSammeejer(ejendom.bfeNr);
      let bfenumre = ol.filter((x) => x !== ejendom?.bfeNr).sort();
      return {
        bfenumre,
      };
    },
  });

  const renderEjerOplysninger = (e) => {
    let navn: string = "",
      adresse: string = "",
      cvr: number = 0;

    /* Navn */
    // Person
    if (e.ejendePerson) {
      navn = e.ejendePerson.Person.Navn
        ? e.ejendePerson.Person.Navn?.["adresseringsnavn"]
        : "Person med navne- og adressebeskyttelse.";
      // Virksomhed
    } else if (e.ejendeVirksomhed) {
      navn = e.ejendeVirksomhed.attributes.navn
        ? e.ejendeVirksomhed.attributes.navn
        : "Kunne ikke finde virksomhedens navn.";
      cvr = e.ejendeVirksomhed.attributes.CVRNummer;
      // Andet (foreninger m.m)
    } else if (e.ejeroplysninger?.features) {
      if (e.ejeroplysninger?.features.length === 1) {
        navn = e.ejeroplysninger?.features[0].properties.navn;
      } else if (e.ejeroplysninger?.features.length === 0) {
        // Empty array
        navn = "Ingen ejeroplysninger.";
      } else {
        navn = e.ejeroplysninger?.features[0].properties.navn + " m.m";
      }
    } else {
      navn = "Kunne ikke bestemme ejeroplysninger.";
    }
    /* Adresse */
    // Person
    if (e.ejendePerson) {
      if (e.ejendePerson.Person.CprAdresse === null) {
        adresse = "Adressen er beskyttet/skjult.";
      } else {
        adresse =
          e.ejendePerson.Person.Standardadresse +
          ", " +
          e.ejendePerson.Person.CprAdresse?.["postnummer"] +
          " " +
          e.ejendePerson.Person.CprAdresse?.["postdistrikt"];
      }

      // Virksomhed
    } else if (e.ejendeVirksomhed) {
      let bl = e.ejendeVirksomhed.attributes?.beliggenhedsadresse;
      if (bl) {
        adresse =
          bl["CVRAdresse.vejnavn"] +
          " " +
          bl["CVRAdresse.husnummerFra"] +
          ", " +
          bl["CVRAdresse.postnummer"] +
          " " +
          bl["CVRAdresse.postdistrikt"];
      }
      // Andet, foreninger?
    } else if (e.ejeroplysninger?.features) {
      if (e.ejeroplysninger?.features.length === 1) {
        adresse =
          e.ejeroplysninger?.features[0].properties?.alternativAdresse
            ?.adresselinje1 +
          " " +
          e.ejeroplysninger?.features[0].properties?.alternativAdresse
            ?.adresselinje2;
      } else if (e.ejeroplysninger?.features.length === 0) {
        adresse = "Kunne ikke bestemme adressen.";
      } else {
        adresse =
          e.ejeroplysninger?.features[0].properties?.alternativAdresse
            ?.adresselinje1 + " ";
        let adrlinje2 =
          e.ejeroplysninger?.features[0].properties?.alternativAdresse
            ?.adresselinje2;
        if (adrlinje2 !== null) {
          adresse += adrlinje2;
        }
        adresse += " m.m";
      }
    } else {
      adresse = "Kunne ikke bestemme adressen.";
    }

    return (
      <p className={styles.ejer} key={navn}>
        <span>
          {navn !== undefined && navn ? navn : "Kunne ikke hente ejer."}
        </span>
        <span>
          {adresse !== undefined && adresse
            ? adresse
            : "Kunne ikke hente adresse."}
        </span>
        <span>{cvr !== 0 && "CVR: " + cvr}</span>
      </p>
    );
  };

  return (
    <div>
      <h5 className="fw-semibold text-body">Ejer</h5>
      {!ejerFortegnelseIsLoading && ejerfortegnelse && (
        <>
          <p className={styles.ejer}>
            {renderEjerOplysninger(ejerfortegnelse.primaer)}
            {ejerfortegnelse.ikke_primaer?.map((e) => {
              return renderEjerOplysninger(e);
            })}
          </p>
          <div className="mt-4">
            <h5 className="fw-semibold text-body mb-1">Ejerforhold</h5>
            <p className={styles.ejerforhold}>
              {ejerfortegnelse.primaer?.ejerforholdskode &&
                // "Ejerforhold: "
                +ejerfortegnelse.primaer.ejerforholdskode +
                  ", " +
                  EjerforholdsKode[ejerfortegnelse.primaer.ejerforholdskode]}
            </p>
          </div>
        </>
      )}
      {ejerFortegnelseIsLoading &&
        Array.from(new Array(3)).map((o, i) => {
          return (
            <motion.li key={i} className={`${styles.ejer} ${styles.loading}`}>
              <div className={styles.text}>
                <div
                  style={{ width: 30 + Math.random() * 70 + "%" }}
                  className={styles.line}
                ></div>
              </div>
            </motion.li>
          );
        })}
      {!ejerFortegnelseIsLoading && !ejerfortegnelse && (
        <div className={styles.fejl}>
          <p>Kunne ikke hente ejeroplysninger.</p>
        </div>
      )}
      {!sammeejerisLoading && sammeejer && (
        <DropDownSection
          nestingLevel={3}
          element={
            <h5 className="{styles.foldheading} fs-6 text-semibold d-flex align-items-center gap-2">
              Ejendomme med samme ejer:{" "}
              <span className="c-avatar c-avatar--xs bg-body-tertiary px-2">
                {sammeejer.bfenumre.length}
              </span>
            </h5>
          }
        >
          <ul className="{styles.sammeejere} p-2 d-flex flex-column gap-2">
            {sammeejer.bfenumre.map((bfenr) => {
              return (
                <li key={bfenr}>
                  <Link
                    className="bg-body-tertiary px-4 py-2 rounded d-flex align-items-center justify-content-between hover-text-primary hover-primary-svg"
                    to={`/ejendom?bfe=${bfenr}`}
                  >
                    BFE: {bfenr} <ArrowRight />
                  </Link>
                </li>
              );
            })}
          </ul>
        </DropDownSection>
      )}
    </div>
  );
}

// Interface
interface IEjer {
  ejendom: Ejendom;
}
