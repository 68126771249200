import axios from 'axios';

export class KommuneFetcher {
  public static formatKommuneKode(kommuneKode: string) {
    if (!kommuneKode || kommuneKode.length !== 3) {
      return kommuneKode;
    }

    return '0' + kommuneKode;
  }

  private static kommuner: Kommune[] = null;

  private kommuneKode: string = null;
  private hasFetched: boolean = false;

  constructor(kommuneKode: string) {
    if (kommuneKode) {
      this.kommuneKode = KommuneFetcher.formatKommuneKode(kommuneKode);
    }
  }

  public async get(kommuneKode: string): Promise<Kommune> {
    await this.fetch();
    return KommuneFetcher.kommuner.find((k) => k.KomKode === kommuneKode);
  }

  private async fetch(): Promise<void> {
    if (this.hasFetched) {
      return;
    }

    const kom = (await axios.get(`https://api.dataforsyningen.dk/kommuner/${this.kommuneKode ? this.kommuneKode : ''}`)).data as Kom1[];
    const kommuner: Kommune[] = kom.map(p => ({ KomNavn: p.navn, KomKode: p.kode }));
    this.hasFetched = true;
    if (!KommuneFetcher.kommuner || KommuneFetcher.kommuner.length === 0) {
      KommuneFetcher.kommuner = kommuner;
      return;
    }

    for (const kommune of kommuner) {
      if (KommuneFetcher.kommuner.findIndex((k) => k.KomKode === kommune.KomKode) === -1) {
        KommuneFetcher.kommuner.push(kommune);
      }
    }
  }
}

export interface Kommune {
  KomNavn: string;
  KomKode: string;
}

export interface Kom1{
  navn: string;
  kode: string;
}