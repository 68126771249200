import { ExtendedResultAppender } from '@/models/ExtendedResultAppender';
import { SearchResult } from '@/models/SearchResult';
import { SourceBase } from '@/models/SourceBase';
import { EjendomFetcher } from '../Data/EjendomFetcher';

export default class DawaJordstykkeToEjendomExtendedAppender extends ExtendedResultAppender {
    public source: SourceBase;

    private extentDataTypes: string[] = [
        'dawa_jordstykke',
        'bfe',
    ];

    constructor(source: SourceBase) {
        super();
        this.source = source;
    }

    public CanExtentResult(resultDataType: string): boolean {
        return this.extentDataTypes.indexOf(resultDataType) > -1;
    }

    public async getExtendedResult(result: SearchResult, source: SourceBase): Promise<SearchResult> {
        if (result.datatype === 'bfe') {
            return result;
        } else if (result.datatype === 'dawa_jordstykke') {
            const ejendomme = await EjendomFetcher.GetFromJordstykke(
                result.data.jordstykke.ejerlav.kode,
                result.data.jordstykke.matrikelnr,
                source.options.dafUser,
                source.options.dafPassword,
                source.options.dafEjendomsrelationDate,);
            return Object.assign({}, result, {
                data: ejendomme.map((e) =>
                    EjendomFetcher.MapDafResultsToSearchResult(
                        null,
                        source.name,
                        e,
                        'bfe',
                        source.getResultType('bfe'), null)),
            });
        } else {
            return null;
        }
    }
}
