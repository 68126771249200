export interface SearchResult {
    id: string;
    title: string;
    description: string;
    data: any;
    resultType: ResultType;
    source: string;
    datatype: string;
    icon?: string;
    inputText?: string;
}

export enum ResultType {
    result = 1,
    suggestion,
    text,
    extendedResult,
    searchById,
    history,
}
