import React from "react";
import "./BBRFonts.scss";

interface IBBRAnvendelseProps {
  anvendelsesKode: string;
  fontSize?: number | string;
  color?: string;
  opacity?: number;
}
export default function BBRAnvendelse(props: IBBRAnvendelseProps) {
  const { anvendelsesKode, fontSize, color, opacity } = props;
  const anvendelseskode2ikon = function (anvendelseskode) {
    var stranv = "" + anvendelseskode;
    switch (stranv.charAt(0)) {
      case "1":
        /**
         * 110 - Stuehus til landbrugsejendom
         * 120 - Fritliggende enfamilieshus (parcelhus)
         * 121 - Sammenbygget enfamiliehus
         * 130 - Udgår
         * 131 - Række- og kædehus
         * 132 - Dobbelthus
         * 140 - Etagebolig-bygning, flerfamilehus eller to-familiehus
         * 150 - Kollegium
         * 160 - Boligbygning til døgninstitution
         * 185 - Anneks i tilknytning til helårsbolig.
         * 190 - Anden bygning til helårsbeboelse
         */
        return "helaarsbeboelse";
      case "2":
        switch (anvendelseskode) {
          case "211": // Stald til svin
          case "212": // Stald til kvæg, får, mv.
          case "213": // Stald til fjerkræ
          case "214": // Minkhal
          case "215": // Væksthus
          case "216": // Lade til foder, afgrøder mv.
          case "217": // Maskinhus, garage mv.
          case "218": // Lade til halm, hø mv.
          case "219": // Anden bygning til landbrug mv
            return "landbrug";
          default:
            /**
             * 220 - Udgår
             * 221 - Bygning til industri med integreret produktionsapparat
             * 222 - Bygning til industri uden integreret produktionsapparat
             * 223 - Værksted
             * 229 - Anden bygning til produktion
             * 231 - Bygning til energiproduktion
             * 232 - Bygning til forsyning- og energidistribution
             * 233 - Bygning til vandforsyning
             * 234 - Bygning til håndtering af affald og spildevand
             * 239 - Anden bygning til energiproduktion og -distribution
             */
            return "industri";
        }
      case "3":
        /**
         * 311 - Bygning til jernbane- og busdrift
         * 312 - Bygning til luftfart
         * 313 - Bygning til parkering- og transportanlæg
         * 314 - Bygning til parkering af flere end to køretøjer i tilknytning til boliger
         * 315 - Havneanlæg
         * 319 - Andet transportanlæg
         * 320 - Udgår
         * 321 - Bygning til kontor
         * 322 - Bygning til detailhandel
         * 323 - Bygning til lager
         * 323 - Butikscenter
         * 325 - Tankstation
         * 329 - Anden bygning til kontor, handel og lager
         * 330 - Udgår
         * 331 - Hotel, kro eller konferencecenter med overnatning
         * 332 - Bed & breakfast mv.
         * 333 - Restaurant, café og konferencecenter uden overnatning
         * 334 - Privat servicevirksomhed som frisør, vaskeri, netcafé mv.
         * 339 - Anden bygning til serviceerhverv
         */
        return "erhverv";
      case "4":
        /**
         * 410 - Udgår
         * 411 - Biograf, teater, koncertsted mv.
         * 412 - Museum
         * 413 - Bibliotek
         * 414 - Kirke eller anden bygning til trosudøvelse for statsanerkendte trossamfund
         * 415 - Forsamlingshus
         * 416 - Forlystelsespark
         * 419 - Anden bygning til kulturelle formål
         * 420 - Udgår
         * 421 - Grundskole
         * 422 - Universitet
         * 429 - Anden bygning til undervisning og forskning
         * 430 - Udgår
         * 431 - Hospital og sygehus
         * 432 - Hospice, behandlingshjem, mv.
         * 433 - Sundhedscenter, lægehus, fødeklinik mv.
         * 439 - Anden bygning til sundhedsformål
         * 440 - Udgår
         * 441 - Daginstitution
         * 442 - Servicefunktion på døgninstitution
         * 443 - Kaserne
         * 444 - Fængsel, arresthus mv
         * 448 - Anden bygning til institutionsformål
         * 490 - Udgår
         */
        return "institution";
      case "5":
        /**
         * 510 - Sommerhus
         * 521 - Feriecenter, center til campingplads mv.
         * 522 - Bygning med ferielejligheder til erhvervsmæssig udlejning
         * 523 - Bygning med ferielejligheder til eget brug
         * 529 - Anden bygning til ferieformål
         * 531 - Klubhus i forbindelse med fritid og idræt
         * 532 - Svømmehal
         * 533 - Idrætshal
         * 534 - Tribune i forbindelse med stadion
         * 535 - Rideskole
         * 539 - Anden bygning til idrætformål
         * 540 - Kolonihavehus
         * 585 - Anneks i tilknytning til fritids- og sommerhus
         * 590 - Anden bygning til fritidsform
         */
        return "fritidsformaal";
      case "9":
        switch (anvendelseskode) {
          case "910": // Garage
          case "920": // Carport
            return "carport-garage";
          case "930": // Udhus
          case "940": // Drivhus
          case "950": // Fritliggende overdækning
          case "960": // Fritliggende udestue
          case "990": //
            return "smaabygning";
          case "970": // Tiloversbleven landbrugsbygning
            return "landbrug";
          default:
            // 999 - Ukendt bygning eller ingen anvendelse (null)
            return "ukendt-bygning";
        }
      default:
        return "ukendt-bygning";
    }
  };
  const classname = "icon-BBR-" + anvendelseskode2ikon(anvendelsesKode);
  return (
    <span style={{ fontSize, color, opacity }} className={classname}></span>
  );
}
