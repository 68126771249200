import React from "react";

export default function ArrowUpRight(props: Icon) {
  const {
    height,
    width,
    // primaryColor,
    style,
  } = props;
  return (
    <svg
      className="flex-shrink-0"
      style={style}
      width={width ? width : height ? height : 16}
      height={height ? height : width ? width : 16}
      viewBox="0 0 16 16"
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z" />
    </svg>
  );
}
