import { ResultAppender } from '@/models/ResultAppender';
import { ResultType, SearchResult } from '@/models/SearchResult';
import { SourceBase } from '@/models/SourceBase';
import { EjendomFetcher } from '../Data/EjendomFetcher';
import { EjendomsnummerFetcher } from '../Ejendomsnummer/EjendomsnummerFetcher';
import { KommuneFetcher } from '../Ejendomsnummer/KommuneFetcher';
import { AdresseFilters } from './AdresseFilters';
import { IAutocompleteResult } from './IAutocompleteResult';

export class DawaAdgangsAdresseToEjendomAppender extends ResultAppender {
  public source: SourceBase;
  private appendToDataType: string;
  private concatRelatedBFE: boolean;
  private kommuneFetcher: KommuneFetcher = null;

  constructor(source: SourceBase, appendToDataType: string) {
      super();
      this.source = source;
      this.appendToDataType = appendToDataType;
      this.concatRelatedBFE = !!(source.filters as AdresseFilters).concatRelatedBFE;
      this.kommuneFetcher = new KommuneFetcher(source.filters.kommuneKode);
  }

    public CanAppendResult(resultDataType: string): boolean {
        return resultDataType === (this.appendToDataType || 'dawa_adresse');
    }

    public async getResults(searchresult: SearchResult, source: SourceBase): Promise<SearchResult[]> {
        const data = searchresult.data as IAutocompleteResult;

        let results: SearchResult[] = null;

        if (data.type && data.type === 'adgangsadresse') {
          // tslint:disable-next-line:max-line-length
          const result = await EjendomFetcher.GetFromAdgangsAdresse(
            data.data.id,
            source.options.dafUser,
            source.options.dafPassword,
            source.options.dafEjendomsrelationDate,
          );

          // tslint:disable-next-line:max-line-length
          results = result.map((r) =>
            EjendomFetcher.MapDafResultsToSearchResult(
              `${searchresult.id}_${r.bfeNummer}`,
              source.name,
              r,
              'bfe',
              source.getResultType('bfe'),
              searchresult.inputText
            ),
          );
        } else {
          // tslint:disable-next-line:max-line-length
          const result = await EjendomFetcher.GetFromAdresse(
            data.data.id,
            source.options.dafUser,
            source.options.dafPassword,
            source.options.dafEjendomsrelationDate,
          );

          // tslint:disable-next-line:max-line-length
          results = result.map((r) =>
            EjendomFetcher.MapDafResultsToSearchResult(
              `${searchresult.id}_${r.bfeNummer}`,
              source.name,
              r,
              'bfe',
              source.getResultType('bfe'),
              searchresult.inputText
            ),
          );
        }

        if(this.concatRelatedBFE && results.length>1){
          const sfe = results.filter(r=> r.data.type === 'SamletFastEjendom');
          if( sfe.length > 1){
            const ejendomresults = [];
            for (const ejendom of sfe) {
              const ejendomsnr = await EjendomsnummerFetcher.GetEjendomsnummerFromBFE(
                ejendom.data.bfeNummer,
                source.options.dafUser,
                source.options.dafPassword,
                source.options.dafEjendomsrelationDate
              );

              if(ejendomsnr.length == 0){
                continue;
              }

              ejendomresults.push(...ejendomsnr);
            }

            const ejendomsSearchResults = await EjendomsnummerFetcher.MapEjendomsrelationToSearchResult(ejendomresults, this.kommuneFetcher, null, this.source.name, source.getResultType('bfe'));
            for (const searchResult of ejendomsSearchResults) {
              // TODO: only get the ones related to ejendom!!!
                searchResult.data = (sfe.map((e) =>
                    EjendomFetcher.MapDafResultsToSearchResult(
                        null,
                        source.name,
                        e.data,
                        'bfe',
                        source.getResultType('bfe'), null),
                ));
            }
            
            results.unshift(...ejendomsSearchResults);

            // results.unshift({
            //   "id": sfe.map(s=> s.data.bfeNummer).join('_'),
            //   "title": "Alle SFE",
            //   "description": sfe.map(s=> s.data.bfeNummer).join(', '),
            //   "data":sfe,
            //   resultType: ResultType.result,
            //   "source": "daf.ejendomsnummer",
            //   "datatype": "ejendomsrelation",
            //   inputText: searchresult.inputText
            // });
          }
        }

        return results;

    }

}
