import { useContext, useState } from "react";
// import Button from "components/common/Buttons/Button";
// import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import {
  OpgaveListeType,
  OpgaveBrugerStatus,
  OpgaveKommentarAdgang,
} from "interfaces/Opgaveliste/enums";
import opgaveApi from "api/opgave";
import opgaveListeApi from "api/opgavelister";
import { AuthContext } from "context/AuthProvider";
// import DropDownMenu from "components/common/DropDownMenu";
import StatusBadge from "routes/Fejllister/StatusBadge";
import Noter from "./Noter";
import FolderIcon from "components/common/icons/Folder";
import DontShow from "components/common/icons/DontShow";
import CheckLg from "components/common/icons/CheckLg";
import StickerIcon from "components/common/icons/StickerIcon";
import useMediaQuery from "../../hooks/useMediaQuery";
// import Circle from "components/common/icons/Circle";
import StatusDialog from "components/common/StatusDialog/StatusDialog";
import NextPreviousOpgave from "./NextPreviousOpgave";
import { ListeState } from "./Ejendom";
import { useMutation } from "react-query";

export default function StatusDropdown(props: StatusDropdownProps) {
  const {
    erSkatteforvaltningen,
    listeState,
    listeLoading,
    brugerStatusUI,
    opgaver,
    setOpgaver,
    currentOpgave,
  } = props;

  const history = useHistory();
  const matches = useMediaQuery("(max-width: 1050px)");
  const matches500 = useMediaQuery("(max-width: 500px)");
  const authState = useContext(AuthContext);

  const { mutate, isLoading } = useMutation({
    mutationFn: async function (options: {
      id: string;
      brugerStatus: OpgaveBrugerStatus;
    }) {
      const { brugerStatus, id } = options;
      const ol = await opgaveApi.putOpgaveBrugerStatus(
        id,
        brugerStatus,
        authState
      );
      if (ol && opgaver && currentOpgave) {
        setOpgaver(
          opgaver.map((o) => {
            if (o.id === ol.id) {
              return ol;
            }
            return o;
          })
        );
      }
    },
  });

  const hasKanIkkeLoesesButton =
    erSkatteforvaltningen &&
    brugerStatusUI !== undefined &&
    brugerStatusUI.findIndex((b) => b.status === 5 && b.visesUi) > -1;

  return (
    <>
      {opgaver && opgaver.length > 0 && (
        <>
          <StatusDialog
            width={matches ? "auto" : "300px"}
            title="Aktuel visning"
            arrowClass="text-muted"
            noArrow={matches ? true : false}
            preventCloseOnSelect={false}
            scroll={opgaver?.length > 10 ? true : false}
            dropdownTriggerStyle={`w-100 has-tooltip tooltip-start tooltip-bottom
              ${currentOpgave ? "bg-warning-subtle" : "bg-body-tertiary"}`}
            menuClassNames="start-0"
            activeButtonStyle={matches ? true : false}
            element={
              <>
                {!matches && (
                  <>
                    {currentOpgave ? (
                      <h4 className="fs-5 fw-semibold flex-grow-1 d-flex align-items-center justify-content-between text-nowrap">
                        Fejl til gennemsyn {opgaver.indexOf(currentOpgave) + 1}
                        <span className="text-muted">
                          {opgaver.indexOf(currentOpgave) + 1} /{" "}
                          {opgaver?.length}
                        </span>
                      </h4>
                    ) : (
                      <h4 className="fs-5 fw-semibold text-muted opacity-75">
                        Vælg fejl til gennemsyn
                      </h4>
                    )}
                  </>
                )}
                {matches && <StickerIcon width={18} />}
              </>
            }
            menu={
              <>
                {opgaver.map((opgave, index) => (
                  <button
                    className="list-group-item list-group-item-action d-flex align-items-start gap-3 py-3"
                    style={matches500 ? { width: "100%" } : { width: "450px" }}
                    key={index}
                    onClick={() => {
                      const params = new URLSearchParams(
                        history.location.search
                      );
                      params.set("opgaveid", opgave.id.toString());
                      history.push(history.location.pathname + "?" + params);
                    }}
                  >
                    <div
                      style={{ width: "22px", height: "22px" }}
                      className="mt-1 text-primary"
                    >
                      {currentOpgave === opgave && <CheckLg width={22} />}
                    </div>
                    <div className="w-100 d-flex flex-column align-items-start gap-1">
                      <div className="w-100 d-flex justify-content-between  align-items-center">
                        <h3 className="h4 fw-medium text-primary mb-0">
                          Fejl til gennemsyn {index + 1}
                        </h3>
                        <div className="d-flex align-items-center gap-2">
                          <div
                            className="c-avatar c-avatar--sm bg-primary text-white"
                            style={{
                              visibility: opgave.tilhoererBruger?.initialer
                                .length
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {opgave.tilhoererBruger?.initialer}
                          </div>
                          {opgave.brugerStatus === 0 && (
                            <StatusBadge type="open">Åben</StatusBadge>
                          )}
                          {opgave.brugerStatus === 2 && (
                            <StatusBadge type="done">Færdig</StatusBadge>
                          )}
                          {opgave.brugerStatus === 1 && (
                            <StatusBadge type="onit">I gang</StatusBadge>
                          )}
                          {opgave.brugerStatus === 5 && (
                            <StatusBadge type="pause">Fejl</StatusBadge>
                          )}
                        </div>
                      </div>
                      <div className="text-muted d-flex align-items-center gap-2">
                        <FolderIcon width={20} height={20} />
                        <ListName id={opgave.listeId} />
                      </div>
                    </div>
                  </button>
                ))}

                <button
                  className="list-group-item list-group-item-action d-flex align-items-start gap-3 py-3"
                  style={{ width: "450px" }}
                  onClick={() => {
                    const params = new URLSearchParams(history.location.search);
                    params.set("opgaveid", "");
                    history.push(history.location.pathname + "?" + params);
                  }}
                >
                  <div className="mb-0 text-muted d-flex align-items-center gap-3">
                    <DontShow
                      width={18}
                      style={{ minWidth: "23px", height: "22px" }}
                    />
                    <p className="mb-0">Vis ikke fejl</p>
                  </div>
                </button>
              </>
            }
          />
          {currentOpgave && (
            <>
              <div
                className="rounded-pill border h-100 p-1 d-flex gap-1 align-items-center"
                style={isLoading ? { opacity: 0.8 } : {}}
              >
                <div
                  className={`c-avatar c-avatar--sm bg-primary text-white ${
                    currentOpgave?.tilhoererBruger?.initialer.length
                      ? "d-flex"
                      : "d-none"
                  }`}
                  aria-label={`Bruger: ${currentOpgave?.tilhoererBruger?.navn}`}
                >
                  {currentOpgave?.tilhoererBruger?.initialer}
                </div>
                <button
                  className="has-tooltip tooltip-center tooltip-bottom"
                  disabled={isLoading}
                  aria-label={
                    currentOpgave?.brugerStatus !== 0
                      ? "Sæt status til 'Åben"
                      : "Status er sat til 'Åben'"
                  }
                  onClick={() => {
                    mutate({
                      id: currentOpgave.id,
                      brugerStatus: OpgaveBrugerStatus.ikkeTildelt,
                    });
                  }}
                >
                  <StatusBadge
                    type="open"
                    muted={currentOpgave?.brugerStatus !== 0}
                  >
                    Åben
                  </StatusBadge>
                </button>
                <button
                  className="has-tooltip tooltip-center tooltip-bottom"
                  aria-label={
                    currentOpgave?.brugerStatus !== 1
                      ? "Sæt status til 'I gang"
                      : "Status er sat til 'I gang'"
                  }
                  disabled={isLoading}
                  onClick={() => {
                    mutate({
                      id: currentOpgave.id,
                      brugerStatus:
                        currentOpgave.brugerStatus === OpgaveBrugerStatus.iGang
                          ? OpgaveBrugerStatus.ikkeTildelt
                          : OpgaveBrugerStatus.iGang,
                    });
                  }}
                >
                  <StatusBadge
                    type="onit"
                    muted={currentOpgave?.brugerStatus !== 1}
                  >
                    I gang
                  </StatusBadge>
                </button>
                <button
                  disabled={isLoading}
                  className="has-tooltip tooltip-center tooltip-bottom"
                  aria-label={
                    currentOpgave?.brugerStatus !== 2
                      ? "Sæt status til 'Færdig"
                      : "Status er sat til 'Færdig'"
                  }
                  onClick={() => {
                    mutate({
                      id: currentOpgave.id,
                      brugerStatus: OpgaveBrugerStatus.opgaveLoest,
                    });
                  }}
                >
                  <StatusBadge
                    type="done"
                    muted={currentOpgave?.brugerStatus !== 2}
                  >
                    Færdig
                  </StatusBadge>
                </button>
                <button
                  className={`has-tooltip tooltip-center tooltip-bottom ${
                    hasKanIkkeLoesesButton ? "d-flex" : "d-none"
                  }`}
                  disabled={isLoading}
                  aria-label={
                    currentOpgave?.brugerStatus !== 5
                      ? "Sæt status til 'Fejl"
                      : "Status er sat til 'Fejl'"
                  }
                  onClick={() => {
                    mutate({
                      id: currentOpgave.id,
                      brugerStatus: OpgaveBrugerStatus.kanIkkeLoeses,
                    });
                  }}
                >
                  <StatusBadge
                    type="pause"
                    muted={currentOpgave?.brugerStatus !== 5}
                  >
                    Fejl
                  </StatusBadge>
                </button>
              </div>
              {/* Old status dropdown - TODO: reuse element for mobile view */}
              {/* <StatusDialog
          width={matches ? "260px" : "210px"}
          arrowClass="text-muted"
          dropdownTriggerStyle={` ${
            matches
              ? "rounded-3 text-muted"
              : "h-100 c-statusdropdown-item gap-3 d-flex align-items-center justify-content-start"
          }`}
          activeButtonStyle={matches ? true : false}
          noArrow={matches ? true : false}
          element={
            <>
              {!matches && (
                <div className="d-flex gap-2 align-items-center h-100 pe-2">
                  <p className="mb-0 p-1 text-muted fw-medium fs-5">
                    Status:
                  </p>
                  <div className="d-flex gap-2">
                    <motion.div
                      className="c-avatar c-avatar--sm bg-primary text-white"
                      variants={variants}
                      animate={
                        currentOpgave?.tilhoererBruger?.initialer
                          .length
                          ? "show"
                          : "hidden"
                      }
                      transition={{ damping: 300 }}
                    >
                      {currentOpgave?.tilhoererBruger?.initialer}
                    </motion.div>
                    {currentOpgave?.brugerStatus === 0 && (
                      <StatusBadge type="open">Åben</StatusBadge>
                    )}
                    {currentOpgave?.brugerStatus === 2 && (
                      <StatusBadge type="done">Færdig</StatusBadge>
                    )}
                    {currentOpgave?.brugerStatus === 1 && (
                      <StatusBadge type="onit">I gang</StatusBadge>
                    )}
                    {currentOpgave?.brugerStatus === 5 && (
                      <StatusBadge type="pause">Fejl</StatusBadge>
                    )}
                  </div>
                </div>
              )}
              {matches && (
                <span
                  className="px-2 py-2 d-flex align-items-center justify-content-center rounded hover-light hover-primary-svg"
                  style={{ width: "46px", height: "44px" }}
                >
                  <span className="hover-svg">
                    <Circle width={23} />
                  </span>
                </span>
              )}
            </>
          }
          menu={[
            <button
              className="list-group-item list-group-item-action d-flex w-100 justify-content-start gap-3 ps-4 pe-5 py-2"
              disabled={buttonState.state !== "IDLE"}
              onClick={() => {
                if (currentOpgave)
                  putOpgaveBrugerStatus(
                    currentOpgave.id,
                    currentOpgave.brugerStatus ===
                      OpgaveBrugerStatus.iGang
                      ? OpgaveBrugerStatus.ikkeTildelt
                      : OpgaveBrugerStatus.iGang
                  );
              }}
            >
              <div style={{ minWidth: "20px" }}>
                {currentOpgave?.brugerStatus ===
                  OpgaveBrugerStatus.iGang && <CheckLg width={20} />}
              </div>
              <div style={{ minWidth: "30px" }}>
                <div
                  className="c-avatar c-avatar--sm bg-primary text-white"
                  style={{
                    visibility:
                      currentOpgave?.tilhoererBruger?.initialer
                        .length &&
                      currentOpgave.brugerStatus ===
                        OpgaveBrugerStatus.iGang
                        ? "visible"
                        : "hidden",
                  }}
                >
                  {currentOpgave?.tilhoererBruger?.initialer}
                </div>
              </div>
              <StatusBadge type="onit">I gang</StatusBadge>
            </button>,
            <button
              disabled={buttonState.state !== "IDLE"}
              className="list-group-item list-group-item-action d-flex w-100 justify-content-start gap-3 ps-4 pe-5 py-2"
              onClick={() => {
                if (currentOpgave)
                  putOpgaveBrugerStatus(
                    currentOpgave.id,
                    (currentOpgave.brugerStatus =
                      OpgaveBrugerStatus.opgaveLoest)
                  );
              }}
            >
              <div style={{ minWidth: "20px" }}>
                {currentOpgave?.brugerStatus ===
                  OpgaveBrugerStatus.opgaveLoest && (
                  <CheckLg width={20} />
                )}
              </div>
              <div style={{ minWidth: "30px" }}>
                <div
                  className="c-avatar c-avatar--sm bg-primary text-white"
                  style={{
                    visibility:
                      currentOpgave?.tilhoererBruger?.initialer
                        .length &&
                      currentOpgave.brugerStatus ===
                        OpgaveBrugerStatus.opgaveLoest
                        ? "visible"
                        : "hidden",
                  }}
                >
                  {currentOpgave?.tilhoererBruger?.initialer}
                </div>
              </div>
              <StatusBadge type="done">Færdig</StatusBadge>
            </button>,
            <button
              // key={2}
              className="list-group-item list-group-item-action d-flex w-100 justify-content-start gap-3 ps-4 pe-5 py-2"
              disabled={buttonState.state !== "IDLE"}
              onClick={() => {
                if (currentOpgave)
                  putOpgaveBrugerStatus(
                    currentOpgave.id,
                    (currentOpgave.brugerStatus =
                      OpgaveBrugerStatus.ikkeTildelt)
                  );
              }}
            >
              <div style={{ minWidth: "20px" }}>
                {currentOpgave?.brugerStatus ===
                  OpgaveBrugerStatus.ikkeTildelt && (
                  <CheckLg width={20} />
                )}
              </div>
              <div style={{ minWidth: "30px" }}>
                <div
                  className="c-avatar c-avatar--sm bg-primary text-white"
                  style={{
                    visibility:
                      currentOpgave?.tilhoererBruger?.initialer
                        .length &&
                      currentOpgave.brugerStatus ===
                        OpgaveBrugerStatus.ikkeTildelt
                        ? "hidden"
                        : "hidden",
                  }}
                >
                  {currentOpgave?.tilhoererBruger?.initialer}
                </div>
              </div>
              <StatusBadge type="open">Åben</StatusBadge>
            </button>,
            <button
              style={
                hasKanIkkeLoesesButton
                  ? { display: "flex" }
                  : { display: "none" }
              }
              // key={3}
              className="list-group-item list-group-item-action w-100 justify-content-start gap-3 ps-4 pe-5 py-2"
              onClick={() => {
                if (currentOpgave)
                  putOpgaveBrugerStatus(
                    currentOpgave.id,
                    (currentOpgave.brugerStatus =
                      OpgaveBrugerStatus.kanIkkeLoeses)
                  );
              }}
            >
              <div style={{ minWidth: "20px" }}>
                {currentOpgave?.brugerStatus ===
                  OpgaveBrugerStatus.kanIkkeLoeses && (
                  <CheckLg width={20} />
                )}
              </div>
              <div style={{ minWidth: "30px" }}>
                <div
                  className="c-avatar c-avatar--sm bg-primary text-white"
                  style={{
                    visibility:
                      currentOpgave?.tilhoererBruger?.initialer
                        .length &&
                      currentOpgave.brugerStatus ===
                        OpgaveBrugerStatus.kanIkkeLoeses
                        ? "visible"
                        : "hidden",
                  }}
                >
                  {currentOpgave?.tilhoererBruger?.initialer}
                </div>
              </div>
              <StatusBadge type="pause">Fejl</StatusBadge>
            </button>,
          ]}
        /> */}
              {currentOpgave?.kommentarAdgang !==
              OpgaveKommentarAdgang.ingenAdgang ? (
                <Noter
                  opgaver={opgaver}
                  setOpgaver={setOpgaver}
                  currentOpgave={currentOpgave}
                  title="Noter"
                  icon
                />
              ) : null}{" "}
              <NextPreviousOpgave
                {...{
                  authState,
                  opgaver,
                  listeState,
                  erSkatteforvaltningen,
                  listeLoading,
                }}
                erSkatteforvaltningen={erSkatteforvaltningen}
              />
            </>
          )}
        </>
      )}
    </>
  );
}

export function ListName(props: { id: string }) {
  const [name, setName] = useState<string>();
  const getListe = async (id: string) => {
    const result = await opgaveListeApi.getListeFromId(id);
    setName(result.navn);
  };
  getListe(props.id);
  return (
    <p
      className="small mb-0 text-start text-truncate"
      style={{
        width: "280px",
      }}
    >
      {name ? name : "Henter..."}
    </p>
  );
}

interface StatusDropdownProps {
  opgaver: Opgave[] | undefined;
  setOpgaver: (opgaver: Opgave[]) => void;
  currentOpgave: Opgave | undefined;
  listeState: ListeState;
  listeLoading: boolean;
  listetype?: OpgaveListeType;
  brugerStatusUI?: OpgaveBrugerStatusUI[];
  erSkatteforvaltningen: boolean;
}

export interface ButtonState {
  state: "IDLE" | "LOADING" | "ERROR";
}
