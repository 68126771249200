import React from "react";

export default function MainContainer({ children }) {
  return (
    <main className="w-100 d-flex flex-column align-items-center pt-8 px-2 px-sm-4">
      <div className="container-xl">{children}</div>
    </main>
  );
}

//w-100 m-w-1000
