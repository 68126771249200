import { Link, useHistory } from "react-router-dom";
import opgaveApi from "api/opgave";
import { AuthState } from "context/AuthProvider";
import { ListeState } from "./Ejendom";
import ChevronLeft from "components/common/icons/ChevronLeft";
import ChevronRight from "components/common/icons/ChevronRight";
import FolderIcon from "components/common/icons/Folder";
import { useMutation } from "react-query";
import styles from "./NextPreviousOpgave.module.scss";

export default function NextPreviousOpgave(props: {
  listeState: ListeState;
  listeLoading: boolean;
  authState: AuthState;
  opgaver: Opgave[] | undefined;
  erSkatteforvaltningen: boolean;
}) {
  const history = useHistory();
  const {
    listeState,
    listeLoading,
    authState,
    // opgaver,
    erSkatteforvaltningen,
  } = props;
  const { mutate: fetchOpgave, isLoading } = useMutation({
    mutationFn: async (opgaveId: string) => {
      return await opgaveApi.getId(opgaveId, authState);
    },
    onSuccess: (opgave) => {
      if (opgave) {
        let params = new URLSearchParams(history.location.search);
        params.set("bfe", opgave.bfe.toString());
        params.set("opgaveid", opgave.id.toString());
        params.set("listeid", opgave.listeId.toString());
        history.push(history.location.pathname + "?" + params);
      }
    },
  });

  return (
    <div className="d-flex h-100 gap-3">
      <Link
        className="d-flex align-items-center gap-2 h-100 bg-body-tertiary rounded-pill text-primary h-100 px-3 has-tooltip tooltip-center tooltip-bottom tooltip--fixed-width"
        aria-label={`Gå til fejllisten: ${listeState.data?.navn}`}
        to={`/fejllister/${listeState.data?.id}`}
      >
        <FolderIcon width={18} />
        <span className="fw-semibold fs-6 d-none d-lg-flex">Fejllisten</span>
      </Link>
      {!listeLoading && (
        <div
          className="d-none d-md-flex align-items-center fs-6 fw-semibold h-100 gap-2 h-100 bg-body-tertiary rounded-pill text-primary p-1 fw-semibold"
          style={isLoading ? { opacity: 0.6 } : {}}
        >
          <button
            aria-label="Gå til forrige fejl på listen"
            onClick={() => {
              if (
                listeState.opgaveOrder &&
                listeState.opgaveOrder.previousOpgaveId
              ) {
                fetchOpgave(listeState.opgaveOrder.previousOpgaveId);
              }
            }}
            className={`${
              erSkatteforvaltningen
                ? styles["btn--icon"]
                : styles["btn--normal"]
            } has-tooltip tooltip-center tooltip-bottom ${
              listeState.opgaveOrder?.previousOpgaveId === null
                ? " pe-none text-secondary"
                : ""
            }`}
            disabled={
              isLoading || listeState.opgaveOrder?.previousOpgaveId === null
            }
          >
            <ChevronLeft /> {!erSkatteforvaltningen && "Forrige"}
          </button>
          <span className="text-muted text-nowrap opacity-75">
            {listeState.opgaveOrder?.opgaveNumberInList} /{" "}
            {listeState.opgaveOrder?.opgaverTotalInList}
          </span>
          <button
            aria-label="Gå til næste fejl på listen"
            onClick={() => {
              if (
                listeState.opgaveOrder &&
                listeState.opgaveOrder.nextOpgaveId
              ) {
                fetchOpgave(listeState.opgaveOrder.nextOpgaveId);
              }
            }}
            className={`${
              erSkatteforvaltningen
                ? styles["btn--icon"]
                : `${styles["btn--normal"]} ${styles["btn--next"]}`
            } has-tooltip tooltip-center tooltip-bottom${
              listeState.opgaveOrder?.nextOpgaveId === null
                ? " pe-none text-secondary"
                : ""
            }`}
            disabled={
              isLoading || listeState.opgaveOrder?.nextOpgaveId === null
            }
          >
            {!erSkatteforvaltningen && "Næste"} <ChevronRight />
          </button>
        </div>
      )}
    </div>
  );
}
