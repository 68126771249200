import { SearchResult } from './SearchResult';
import { SourceBase } from './SourceBase';

export class ResultAppender {

    public CanAppendResult(resultDataType: string): boolean {
        return false;
    }

    public getResults(result: SearchResult, source: SourceBase): Promise<SearchResult[]> {
        return new Promise((resolve, reject) => {
                return resolve([]);
            });
    }

}
