// Souce from: https://juliangaramendy.dev/use-promise-subscription/
import { useEffect, useState } from "react";

export interface IUsePromise<DataType> {
  state: "LOADING" | "IDLE" | "ERROR";
  data: DataType;
  error: any;
  idx?: number;
  additionalprops?: any;
}

export function usePromise<DataType>(
  promiseOrFunction,
  defaultValue,
  additionalprops = {}
) {
  const [promiseState, setPromiseState] = useState<IUsePromise<DataType>>({
    data: defaultValue,
    error: null,
    state: "LOADING",
    ...additionalprops,
  });

  useEffect(() => {
    const promise =
      typeof promiseOrFunction === "function"
        ? promiseOrFunction()
        : promiseOrFunction;

    let isMounted = true;
    setPromiseState({ ...promiseState, idx: 0, state: "LOADING" });
    if (promise) {
      promise
        .then((value) =>
          isMounted
            ? setPromiseState({
                ...promiseState,
                data: value,
                idx: 0,
                error: null,
                state: "IDLE",
              })
            : null
        )
        .catch((error) =>
          isMounted
            ? setPromiseState({
                ...promiseState,
                data: defaultValue,
                error: error,
                state: "ERROR",
              })
            : null
        );
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promiseOrFunction, defaultValue]);

  return { state: promiseState, setState: setPromiseState };
}
