import React from "react";
import { Route } from "react-router-dom";
import { AuthState } from "context/AuthProvider";

import Forbidden from "./components/Error/Forbidden";

// A wrapper for <Route> that redirects to the home
// screen if you're not yet authenticated.

export default function PrivateRoute(props: PrivateRouteProps) {
  const { path, component, authState, requiredRolle } = props;

  if (authState.state === "LOADING") {
    return <div></div>;
  }

  const roller = authState.bruger ? authState.bruger.roller : [];
  const hasRequiredRolle =
    !requiredRolle ||
    roller.findIndex((role) => role.navn === requiredRolle) > -1;

  // If user is not logged in
  if (authState.state === "UNAUTHORIZED") {
    return (
      <Route
        path={path}
        render={({ location }) => {
          const { pathname, search } = location;
          window.location.href = `/auth/login?returnurl=${encodeURIComponent(
            pathname
          )}${encodeURIComponent(search)}`;
          return (
            <div style={{ marginTop: 100, marginLeft: 100 }}>
              Sender dig til DMP for autenticering ...
            </div>
          );
        }}
      />
    );
  }
  // If the user is logged in, but does not have the required rolle
  // TODO: Instead of changing the HOME route to show forbidden, create a 500 page
  else if (!hasRequiredRolle || authState.state === "FORBIDDEN") {
    return <Route path={"/"} component={Forbidden} />;
  } else {
    // Else everything is good
    return <Route path={path} component={component} />;
  }
}

interface PrivateRouteProps {
  path: string;
  component: any;
  authState: AuthState;
  requiredRolle?: string;
}
