import React, { useEffect, useState } from "react";
import styles from "./Enhed.module.scss";
import { mergeClassNames } from "util/index";
import { OpgaveListeType } from "interfaces/Opgaveliste/enums";
import EnhedModalExternal from "./EnhedModalExternal";
import OpenModal from "components/common/icons/OpenModal";
import EnergiOplysninger from "../../../SupplerendeOplysninger/EnergiOplysninger";
export default function Enhed(props: IEnhed) {
  const {
    enhed,
    modalExpand,
    referencer,
    templater,
    setMarkering,
    listeType,
    enhedModalState,
    setEnhedModalState,
    energiOplysninger,
  } = props;

  const { id_lokalId } = enhed.enhed;
  const map = templater.template;
  const [open, setOpen] = useState(false);
  let markering;
  let adressebetegnelse = "Ingen adresse";

  let energiOplysningerEnhed = energiOplysninger?.oplysninger.filter(
    (oplysning) => oplysning.bbrId === id_lokalId
  );

  // Effect controlling if the enhed has a "fejl" or "opgave" attached
  useEffect(() => {
    if (markering) {
      setMarkering(markering);
      //setEnhedModalState(id_lokalId); // Issue #281
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referencer, markering]);

  // Effect controlling if some other enhed has it's modal shown.
  useEffect(() => {
    // If the modal is open while this state changes
    // and we are not the enhed requesting this change
    if (open && enhedModalState !== id_lokalId) {
      setOpen(false);
    } else if (!open && enhedModalState === id_lokalId) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enhedModalState]);

  const renderEnhed = () => {
    const jsx_elements: JSX.Element[] = [];
    const modal_elements: JSX.Element[] = [];
    /* Extract the correct classname */
    let className = styles.enhed;

    Object.keys(map).forEach((key) => {
      let enhedSpanClassName = styles.enhedItem;
      let matched_reference = referencer?.find(
        (reference) =>
          reference.attribut_id.toLowerCase() === key.toLowerCase() &&
          reference.bbr_id === enhed["enhed"].id_lokalId
      );
      if (matched_reference) {
        markering = true;
        enhedSpanClassName = mergeClassNames(
          enhedSpanClassName,
          matched_reference
            ? listeType === OpgaveListeType.skal
              ? mergeClassNames(styles.markeret, styles.skal)
              : mergeClassNames(styles.markeret, styles.kan)
            : ""
        );
        className = mergeClassNames(
          className,
          mergeClassNames(
            styles.markeret,
            listeType === OpgaveListeType.skal ? styles.skal : styles.kan
          )
        );
      }

      // If there is a value
      if (enhed["enhed"][key] !== null && enhed["enhed"][key] !== undefined) {
        let element: JSX.Element = (
          <div className={enhedSpanClassName} key={key}>
            <h5 className={styles.label} id={key}>
              {map[key].displayname}
              <i>:</i>
            </h5>
            <span>
              {templater.format(enhed["enhed"], key, map[key].useclv)}
            </span>
          </div>
        );
        // If the value is not shown in modal OR its a matched_reference
        //if (!open) {
        //  if (map[key]?.showInModal !== true || matched_reference) {
        //    jsx_elements.push(element);
        //  } else {
        //    modal_elements.push(element);
        //  }

        if (map[key]?.showInModal !== true) {
          jsx_elements.push(element);
        } else {
          if (map[key]?.showInModal !== true || matched_reference) {
            jsx_elements.push(element);
          }

          modal_elements.push(element);
        }
        // TODO: energioplysninger - comment in
        // If the value is not shown in modal OR its a matched_reference
        /*
        if (!open) {
          if (map[key]?.showInModal !== true || matched_reference) {
            jsx_elements.push(element);
          } else {
            modal_elements.push(element);
          }
        } else {
          if (map[key]?.showInModal !== true || matched_reference) {
            jsx_elements.push(element);
          }

          modal_elements.push(element);
        }*/
      } else if (matched_reference) {
        //matched_reference, but no value
        // Always show matched values at the upper level, and not in the modal.
        jsx_elements.push(
          <div className={enhedSpanClassName} key={key}>
            <h5 className={styles.label} id={key}>
              {map[key].displayname}
            </h5>
            <span>
              <p>Ikke oplyst.</p>
            </span>
          </div>
        );
      }
    });

    /* If the enhed has ejerlejligheder, we show them */
    if (enhed.enhed["ejerlejlighedList"]) {
      let el = enhed.enhed["ejerlejlighedList"];
      el.length > 0 &&
        jsx_elements.push(
          <div
            className={mergeClassNames(styles.enhedItem, styles.stretched)}
            key={`el_${enhed.enhed["id_lokalId"]}`}
          >
            <h5 className={styles.label}>
              {"Enheden har følgende ejerlejligheder"}
              <i>:</i>
            </h5>
            {el.map((e) => {
              return (
                <div className={styles.ejerlejlighed} key={e.id}>
                  <h6 className="d-inline-block text-body">BFE:</h6>
                  <span>
                    <p>{e.ejerlejlighed.bfeNummer}</p>
                  </span>
                  <h6 className="d-inline-block text-body">
                    Ejerlejlighedsnummer:
                  </h6>
                  <span>
                    <p>{e.ejerlejlighed.ejerlejlighedsnummer}</p>
                  </span>
                </div>
              );
            })}
          </div>
        );
      // Issue #281 ejerlejligheder på enheder skal i modal
      el.length > 0 &&
        modal_elements.push(
          <div
            className={mergeClassNames(styles.enhedItem, styles.stretched)}
            key={`el_${enhed.enhed["id_lokalId"]}`}
          >
            <h5 className={styles.label}>
              {"Enheden har følgende ejerlejligheder"}
              <i>:</i>
            </h5>
            {el.map((e) => {
              return (
                <div className={styles.ejerlejlighed} key={e.id}>
                  <h6 className="d-inline-block text-body">BFE:</h6>
                  <span>
                    <p>{e.ejerlejlighed.bfeNummer}</p>
                  </span>
                  <h6 className="d-inline-block text-body">
                    Ejerlejlighedsnummer:
                  </h6>
                  <span>
                    <p>{e.ejerlejlighed.ejerlejlighedsnummer}</p>
                  </span>
                </div>
              );
            })}
          </div>
        );
    }

    if (enhed["adresse"]) {
      let dør = enhed["adresse"]["dør"];
      /* placeholder, here will we tage etageType to style kl and t*/
      let etage = enhed["adresse"]["etage"];

      switch (dør) {
        case "tv":
          className = mergeClassNames(className, styles.enhed_tv);
          break;
        case "th":
          className = mergeClassNames(className, styles.enhed_th);
          break;
        case "mf":
          className = mergeClassNames(className, styles.enhed_mf);
          break;

        default:
          className = mergeClassNames(className, styles.enhed_unknown);
          break;
      }
      /* Fill out with correct classnames one kl and t is retrieveable */
      switch (etage) {
        case "kl":
          className = mergeClassNames(className, styles.enhed_kl);
          break;
        case "t":
          className = mergeClassNames(className, styles.enhed_t);
          break;
      }
      adressebetegnelse = enhed["adresse"]["betegnelse"];
    }
    className = mergeClassNames(className, "");

    // Add adresse to enhed
    jsx_elements.unshift(
      <h4 className="fw-semibold" key={"enhed_" + id_lokalId}>
        {adressebetegnelse}
      </h4>
    );
    modal_elements.unshift(
      <h4 key={"modal_" + id_lokalId}>
        <span className="me-2">Enhed: </span>
        {adressebetegnelse}
      </h4>
    );

    // Add energioplysninger ( null and length check )
    if (energiOplysningerEnhed && energiOplysningerEnhed.length > 0) {
      modal_elements.push(
        <EnergiOplysninger
          energioplysninger={energiOplysningerEnhed}
          key={id_lokalId}
        />
      );
    }

    return (
      <li
        className={className}
        role="button"
        tabIndex={0}
        key={enhed.enhed["id_lokalId"]}
        onClick={() => {
          // Close all modals
          if (enhedModalState === id_lokalId) {
            setEnhedModalState(undefined);
          } else {
            setEnhedModalState(id_lokalId);
          }
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 32) {
            e.preventDefault();
            setEnhedModalState(id_lokalId);
          } else if (e.keyCode === 13) {
            e.preventDefault();
            setEnhedModalState(id_lokalId);
          }
        }}
      >
        <div className={styles.enhedInformation}>{jsx_elements}</div>
        <EnhedModalExternal expand={modalExpand} shown={open}>
          {modal_elements}
        </EnhedModalExternal>
        <span className={`${styles.more} btn btn-primary btn-sm`}>
          Flere detaljer <OpenModal />
        </span>
      </li>
    );
  };

  return renderEnhed();
}
interface IEnhed {
  enhed: any;
  templater: any;
  modalExpand: boolean;
  enhedModalState: string;
  setMarkering: Function;
  setEnhedModalState: Function;
  listeType?: OpgaveListeType;
  referencer?: Array<VisningsdataElementReference>;
  energiOplysninger?: EnergiOplysninger;
}
