import { get } from "../index";

const getEjf = async (bfenr: number) => {
  try {
    return await get<[EJF.Ejerfortegnelse]>({
      query: `ejf/bfe/${bfenr}`,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

const getEjfSammeejer = async (bfenr: number) => {
  try {
    return await get<[number]>({
      query: `ejf/sammeejer/${bfenr}`,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
const api: EjfApi = {
  getEjf,
  getEjfSammeejer,
};

export default api;

export interface EjfApi {
  getEjf: {
    (bfenr: number): Promise<[EJF.Ejerfortegnelse]>;
  };
  getEjfSammeejer: {
    (bfenr: number): Promise<[number]>;
  };
}
