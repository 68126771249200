import React, { useState, useEffect, Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import styles from "./OpretArtikel.module.scss";
import artikelApi from "api/artikler";
import { Editor } from "@tinymce/tinymce-react";
import { motion, AnimatePresence } from "framer-motion";
import { Spacing } from "components/common/layout/Spacing";
import { ToastContext } from "context/ToastProvider";
import ChevronRight from "components/common/icons/ChevronRight";
import PlusIcon from "components/common/icons/PlusIcon";
import MinusIcon from "components/common/icons/MinusIcon";

export interface IPostArtikelState {
  state: "IDLE" | "LOADING" | "ERROR" | "POSTING" | "OK";
  code?: number;
  data?: any; // HTTP response
}
// Used for creating / posting a new artikelTema
export interface IPostArtikelTemaState {
  state: "IDLE" | "LOADING" | "ERROR" | "POSTING" | "OK";
  open: boolean;
  navn?: ArtikelTema["navn"];
}
// Used for fetching / GET all available artikelTemaer
export interface IArtikelTemaState {
  state: "IDLE" | "LOADING" | "ERROR" | "OK";
  data?: ArtikelTema[];
}
export default function OpretArtikel(props: IOpretArtikel) {
  const history = useHistory();
  const toast = useContext(ToastContext);
  const { artikelId } = props;
  const [postState, setPostState] = useState<
    "IDLE" | "LOADING" | "ERROR" | "POSTING" | "OK"
  >(artikelId !== undefined ? "LOADING" : "IDLE");
  // Used for fetching / GET all available artikelTemaer
  const [artikelTemaState, setArtikelTemaState] = useState<IArtikelTemaState>({
    state: "IDLE",
  });
  // Used for creating / posting a new artikelTema
  const [artikelTemaStatePost, setArtikelTemaStatePost] =
    useState<IPostArtikelTemaState>({
      state: "IDLE",
      open: false,
    });

  // Use "Omit" to ignore id's, which are generated by the backend
  const [artikelState, setArtikelState] = useState<Omit<Artikel, "id">>({
    titel: "",
    indhold: "",
    offentlig: false,
    dato: new Date(),
    forfatter: "",
    temaId: "",
    temaNavn: "",
    forside: false,
  });

  const [disabled, setDisabled] = useState(true); // Disable the form button if form is not validated

  const variants = {
    open: { opacity: 1, height: "auto" },
    closed: { opacity: 0, height: 0 },
  };

  const populateArtikelTema = () => {
    // TODO
    setArtikelTemaState({ state: "LOADING" });
    const fetchData = async () => {
      artikelApi
        .getAllArtikelTema({ page: 1, pageSize: 1000 })
        .then((resp) => {
          setArtikelTemaState({ data: resp, state: "OK" });
        })
        .catch((error) => {
          if (error === "CANCEL") {
            console.debug(error);
          } else {
            setArtikelTemaState({ state: "ERROR" });
            toast({
              status: "ERROR",
              text: `Kunne ikke hente hjælp til fejllister ...`,
              show: true,
            });
            setPostState("ERROR");
          }
        });
    };
    fetchData();
  };

  if (
    artikelTemaState.data === undefined &&
    artikelTemaState.state === "IDLE"
  ) {
    populateArtikelTema();
  }
  // When the form is submitted, we can do things to it before posting it.
  const handleSubmit = async (e) => {
    if (!disabled) {
      // Form validation checks out
      // New artikel
      setPostState("POSTING"); // Use to start a spinner or similar
      toast({
        status: "OK",
        text: artikelId
          ? `Gemmer ændringer for artiklen ${artikelState.titel} ...`
          : `Gemmer artiklen '${artikelState.titel}' ...`,
        show: true,
        duration: 1000,
      });
      if (artikelId === undefined) {
        artikelApi
          .createArtikel(artikelState)
          .then((resp) => {
            toast({
              status: "OK",
              text: `Artiklen '${artikelState.titel}' blev oprettet`,
              show: true,
            });
            setPostState("OK");
            history.replace(
              `/admin?type=opgavebeskrivelser&handling=rediger&artikelid=${resp.id}`
            );
          })
          .catch((error) => {
            if (error === "CANCEL") {
              console.debug(error);
            } else {
              setPostState("ERROR");
              toast({
                status: "ERROR",
                text: `Artiklen '${artikelState.titel}' kunne ikke oprettes: ${error.statusText}`,
                show: true,
              });
            }
          });
      } else {
        artikelApi
          .updateArtikel(artikelState, artikelId)
          .then((resp) => {
            toast({
              status: "OK",
              text: `Artiklen '${artikelState.titel}' blev redigeret`,
              show: true,
            });
            setPostState("OK");
          })
          .catch((error) => {
            if (error === "CANCEL") {
              console.debug(error);
            } else {
              toast({
                status: "ERROR",
                text: `Artiklen '${artikelState.titel}'' kunne ikke redigeres: ${error.statusText}, status: ${error.status}`,
                show: true,
              });
              setPostState("ERROR");
            }
          });
      }
    }
    e?.preventDefault();
  };

  useEffect(() => {
    // Form validation
    setDisabled(formValidation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artikelState]);

  useEffect(() => {
    let stillMounted = true;

    const fetchData = async () => {
      setPostState("LOADING"); // Might be redundant state update if state starts at loading
      if (artikelId !== undefined) {
        try {
          const ol = await artikelApi.getArtikelFromId(artikelId);
          if (stillMounted) {
            setPostState("IDLE");
            // Dette tæller som en 204 NO content.
            if (typeof ol === "string") {
              toast({
                status: "ERROR",
                text: `Kunne ikke finde artiklen med id: ${artikelId}`,
                show: true,
              });
              setPostState("ERROR");
            } else {
              // Destructure the object by removing the id:
              let { id, ...picked_ol } = ol;
              setArtikelState({ ...picked_ol });
            }
          }
        } catch (error) {
          if (error === "CANCEL") {
            // The request was canceled, all is well
            console.debug(error);
          } else {
            if (stillMounted) {
              // Request failed, set error state
              toast({
                status: "ERROR",
                text: `Kunne ikke indlæse artiklen med id: ${artikelId}`,
                show: true,
              });
              setPostState("ERROR");
            }
          }
        }
      }
    };
    if (artikelId !== undefined) {
      fetchData();
    }

    return () => {
      // The return function is called when
      // the component unmounts. We use the
      // stillmounted flag to avoid attempting
      // to set state on an unmounted component
      stillMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artikelId]);

  const formValidation = () => {
    // Temp flag
    if (artikelState) {
      for (let key in artikelState) {
        if (!artikelState[key]) {
          return true;
        }
      }
    }
    return false;
  };

  const postArtikelTema = () => {
    const postData = async () => {
      if (artikelTemaStatePost.navn !== undefined) {
        setArtikelTemaStatePost({ ...artikelTemaStatePost, state: "POSTING" });
        artikelApi
          .createArtikelTema({ navn: artikelTemaStatePost.navn })
          .then((resp) => {
            setArtikelTemaStatePost({ open: false, state: "OK" });
            setArtikelTemaState({ data: undefined, state: "IDLE" });
            setArtikelState({
              ...artikelState,
              temaNavn: resp.navn,
              temaId: resp.id,
            });
          })
          .catch((error) => {
            if (error === "CANCEL") {
              console.debug(error);
            } else {
              setPostState("ERROR");
            }
          });
      }
    };
    if (artikelTemaStatePost.navn !== undefined) {
      postData();
    }
  };

  return (
    <div className={styles.container}>
      {postState !== "ERROR" && postState !== "LOADING" && (
        <Fragment>
          {artikelId === undefined && <h1>Opret ny beskrivelse</h1>}
          {artikelId !== undefined && (
            <h1>Redigerer beskrivelsen: {artikelState.titel}</h1>
          )}
          <form onSubmit={handleSubmit}>
            <label className="h5" htmlFor="titel">
              Titel
            </label>
            <input
              type="text"
              id="titel"
              name="titel"
              placeholder="F.ex. Arealafvigelser"
              className="rounded"
              required
              value={artikelState.titel}
              onChange={(e) =>
                setArtikelState({ ...artikelState, titel: e.target.value })
              }
            />
            <label className="h5" htmlFor="forfatter">
              Forfatter
            </label>
            <input
              type="text"
              id="forfatter"
              name="forfatter"
              placeholder="Afsenders navn"
              className="rounded"
              required
              value={artikelState.forfatter}
              onChange={(e) =>
                setArtikelState({
                  ...artikelState,
                  forfatter: e.target.value,
                })
              }
            />

            <label className="h5" htmlFor="tema">
              Tema
            </label>
            <div className={styles.selectboxwithbutton}>
              <div className={styles.selectbox}>
                <select
                  id="tema"
                  name="tema"
                  className="rounded"
                  value={artikelState.temaId}
                  onChange={(e) => {
                    setArtikelState({
                      ...artikelState,
                      temaId: e.target.value,
                      temaNavn: e.target.textContent
                        ? e.target.textContent
                        : "",
                    });
                  }}
                >
                  {artikelTemaState.state === "LOADING" && (
                    <option value="" disabled>
                      Henter temaer ...
                    </option>
                  )}
                  {artikelTemaState.state === "OK" &&
                    artikelTemaState.data !== undefined && (
                      <option key={`artikeltema_${-1}`} disabled value="">
                        Vælg tema ...
                      </option>
                    )}
                  {artikelTemaState.state === "OK" &&
                    artikelTemaState.data !== undefined &&
                    artikelTemaState.data.map((artikelTema, idx) => {
                      return (
                        <option
                          key={`artikeltema_${idx}`}
                          value={artikelTema.id}
                        >
                          {artikelTema.navn}
                        </option>
                      );
                    })}
                </select>

                <span className={styles.arrowdown}>
                  <ChevronRight />
                </span>
              </div>
              <AnimatePresence>
                <div
                  onClick={() =>
                    setArtikelTemaStatePost({
                      ...artikelTemaStatePost,
                      open: !artikelTemaStatePost.open,
                      navn: undefined,
                    })
                  }
                  className={styles.addartikeltema}
                >
                  {artikelTemaStatePost.open === false && (
                    <motion.button
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <PlusIcon />
                      <span>Opret nyt tema</span>
                    </motion.button>
                  )}
                  {artikelTemaStatePost.open === true && (
                    <motion.button
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <MinusIcon />
                      <span>Annullér</span>
                    </motion.button>
                  )}
                </div>
              </AnimatePresence>
            </div>

            <AnimatePresence>
              {artikelTemaStatePost.open && (
                <motion.div
                  id="artikel-tema-post"
                  className={`${styles.artikeltemaform}`}
                  variants={variants}
                  initial="closed"
                  animate="open"
                  exit={variants.closed}
                  transition={{ damping: 300 }}
                >
                  <div className={styles.artikeltemacontainer}>
                    <input
                      placeholder="Temaets navn"
                      type="text"
                      id="temaNavnPost"
                      name="temaNavnPost"
                      className="rounded"
                      onChange={(e) => {
                        if (e.target.value) {
                          setArtikelTemaStatePost({
                            ...artikelTemaStatePost,
                            navn: e.target.value,
                          });
                        }
                      }}
                    />
                    <button
                      className={`${styles.artikeltemasubmit} rounded`}
                      onClick={() => {
                        postArtikelTema();
                      }}
                    >
                      Opret tema
                    </button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
            {false && (
              // Udkommenteret da DTO ikke tager en dato
              <Fragment>
                <label className="h5" htmlFor="date">
                  Dato
                </label>
                <fieldset>
                  <input
                    type="date"
                    id="date"
                    name="dato"
                    value={artikelState.dato.toDateInputValue()}
                    required
                    onChange={(e) => {
                      if (e.target.valueAsDate) {
                        setArtikelState({
                          ...artikelState,
                          dato: e.target.valueAsDate,
                        });
                      }
                    }}
                  />
                </fieldset>
              </Fragment>
            )}

            <div className={styles.indhold}>
              <label>Indhold</label>
              <Editor
                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                value={artikelState.indhold}
                init={{
                  height: 500,
                  menubar: false,
                  entity_encoding: "raw",
                  plugins: [
                    "advlist autolink lists link image",
                    "charmap print preview anchor help",
                    "searchreplace visualblocks code",
                    "insertdatetime media table paste wordcount",
                  ],
                  paste_data_images: true,
                  toolbar:
                    // eslint-disable-next-line no-multi-str
                    "undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help",
                }}
                onEditorChange={(content, editor) => {
                  setArtikelState({
                    ...artikelState,
                    indhold: content,
                  });
                }}
              />
            </div>
            <Spacing />
            <div className="">
              <label htmlFor="" className="h5 mb-2">
                Status
              </label>
              <div className="d-flex justify-content-between">
                <div className="d-flex gap-5 px-2">
                  <div className="form-check ">
                    <input
                      className="form-check-input cursor-pointer"
                      type="radio"
                      name="radio-1"
                      id="udkast"
                      checked={!artikelState.offentlig}
                      onChange={(e) => {
                        setArtikelState({
                          ...artikelState,
                          offentlig: false,
                        });
                      }}
                    />
                    <label
                      className="form-check-label cursor-pointer"
                      htmlFor="udkast"
                    >
                      Udkast
                    </label>
                  </div>
                  <div className="form-check w-100 ">
                    <input
                      className="form-check-input cursor-pointer"
                      type="radio"
                      name="radio-1"
                      id="offentlig"
                      checked={artikelState.offentlig}
                      onChange={(e) => {
                        setArtikelState({
                          ...artikelState,
                          offentlig: true,
                        });
                      }}
                    />
                    <label
                      className="form-check-label cursor-pointer"
                      htmlFor="offentlig"
                    >
                      Offentlig
                    </label>
                  </div>
                </div>
                <div className="">
                  <div className="form-check ps-0 d-flex align-items-center">
                    <label
                      className="form-check-label"
                      htmlFor="skatteforvaltningen"
                    >
                      Vis på forsiden
                    </label>
                    <input
                      className="form-check-input d-inline-block ms-3 mb-1"
                      type="checkbox"
                      checked={artikelState.forside}
                      onChange={(e) => {
                        setArtikelState({
                          ...artikelState,
                          forside: e.target.checked,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div></div>

            {/* <div className={styles.flexbetween}>
              <fieldset className={styles.status}>
                <legend>Status</legend>
                <p>
                  <label className={styles.check}>
                    Udkast
                    <input
                      type="radio"
                      name="radio-1"
                      checked={!artikelState.offentlig}
                      onChange={(e) => {
                        setArtikelState({
                          ...artikelState,
                          offentlig: false,
                        });
                      }}
                    />
                    <span className={styles.radiobutton}></span>
                  </label>
                  <label className={styles.check}>
                    Offentlig
                    <input
                      type="radio"
                      name="radio-1"
                      checked={artikelState.offentlig}
                      onChange={(e) => {
                        setArtikelState({
                          ...artikelState,
                          offentlig: true,
                        });
                      }}
                    />
                    <span className={styles.radiobutton}></span>
                  </label>
                </p>
              </fieldset>
              <fieldset>
                 <legend>Status</legend>
                <label className={styles.check}>
                  <span className={styles.checkboxlabel}>Vis på forsiden</span>
                  <input
                    type="checkbox"
                    name="forside"
                    checked={artikelState.forside}
                    onChange={(e) => {
                      setArtikelState({
                        ...artikelState,
                        forside: e.target.checked,
                      });
                    }}
                  />
                  <span className={styles.checkbox}></span>
                </label>
              </fieldset>
            </div> */}
            {artikelId === undefined && (
              <input
                type="submit"
                value="Publicér beskrivelse"
                className="rounded"
                disabled={disabled !== undefined ? disabled : false}
              />
            )}
            {artikelId !== undefined && (
              <input
                type="submit"
                value="Publicér ændringer"
                className="rounded"
                disabled={disabled !== undefined ? disabled : false}
              />
            )}
          </form>
        </Fragment>
      )}
      {postState === "LOADING" && (
        <div className={styles.loading}>
          <h1>Henter beskrivelse til redigering ...</h1>
        </div>
      )}
    </div>
  );
}
interface IOpretArtikel {
  artikelId?: string;
}
// Converts dates into <input type="date"> strings with correct offset
declare global {
  interface Date {
    toDateInputValue(): string;
  }
}

/*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/
Date.prototype.toDateInputValue = function (): string {
  var local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};
