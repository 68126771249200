import React from "react";
import "./BBRFonts.scss";

interface IBBRTekKlassifikationProps {
  klassifikationsKode: string;
  fontSize?: number | string;
  color?: string;
  opacity?: number;
}
export default function BBRTekKlassifikation(
  props: IBBRTekKlassifikationProps
) {
  const { klassifikationsKode, fontSize, color, opacity } = props;
  const klassifikationskode2ikon = function (klassifikationskode, indhold) {
    var strklas = "" + klassifikationskode;
    indhold = parseInt("0" + indhold, 10);
    switch (strklas) {
      case "1110":
        /**
         * Tank (Produkt på væskeform)10 – Mineralske olieprodukter (Olietankbekendtgørelsens §6, stk. 1 nr. 13)
         * 11 - Fuelolie (”tung fuelolie” – kræver opvarmning)
         * 12 - Fyringsgasolie
         * 13 - Autogasolie (Dieselolie)
         * 14 - Benzin
         * 20 - Biobrændstoffer (Organiske olieprodukter som f.eks. rapsolie, bioethanol m.v.)
         * 30 - Affaldsprodukter
         * 31 - Oliebaserede affaldsprodukter (Spildolie)
         */
        if (indhold > 0 && indhold <= 31) {
          return "olietank";
        }
        /**
         * Tank (Produkt på væskeform)
         * 40 - Gylle
         * 50 - Ajle, ensilagesaft, mælkerumsvand eller møddingvand
         * 60 - Øvrige stoffer, produkter og materialer der kan forurene grundvand, jord og undergrund (§ 19)
         * 99 - Andet (f.eks. foderstoffer m.v)
         */
        return "tank"; // alm tank

      case "1120": // Silo (Produkt på fast form)
        return "silo";

      case "1130": // Gasbeholder (Produkt på gasform)
        return "halmfyr-biogas-mv";
      case "1140": // Affaldsbeholder
        return "silo";
      case "1210": // Vindmølle (elproducerende)
        return "vindmoelle";
      case "1220": // Slanger til jordvarme
      case "1230": // Solvarme-/ solcelleanlæg
      case "1240": // Nødstrømsforsyningsanlæg
      case "1250": // Transformerstation
      case "1260": // Elskab
        return "energi";
      case "1265": // Naturgasfyr
        return "halmfyr-biogas-mv";
      case "1270": // Andet energiproducerende eller - distribuerende anlæg
        return "energi";
      case "1275": // Halmfyr
      case "1280": // Biogasanlæg
        return "halmfyr-biogas-mv";
      case "1310": // Vandtårn
      case "1320": // Pumpestation
      case "1330": // Swimmingpool
      case "1340": // Private rensningsanlæg f.eks. pileanlæg, nedsivningsanlæg
      case "1350": // Offentlige rensningsanlæg
      case "1360": // Regnvandsanlæg
        return "vandanlaeg";
      case "1905": // Legeplads
      case "1910": // Teknikhus
      case "1915": // Døgnpostboks
      case "1920": // Køleanlæg (herunder aircondition)
      case "1925": // Kunstværk (springvand, mindesmærker m.v.)
      case "1930": // Kunstværk (springvand, mindesmærker m.v.)
      case "1935": // Skilt
      case "1940": // Antenne / mast fx tv, radio- og telekommunikation
        return "andet-anlaeg";
      case "1945": // Dambrug
        return "vandanlaeg";
      case "1955": // Andet teknisk anlæg
        return "andet-anlaeg";
      case "1950": // Møddingsanlæg
        return "ensilage-planlager";
      case "1960": // Ensilageanlæg
      case "1965": // Planlager
        return "ensilage-planlager";
      case "1970": // Fortidsminde, historisk ruin
        return "andet-anlaeg";
      default:
        return "ukendt-TEK";
    }
  };
  const classname =
    "icon-BBR-" + klassifikationskode2ikon(klassifikationsKode, "");
  return (
    <span style={{ fontSize, color, opacity }} className={classname}></span>
  );
}
