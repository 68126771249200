import { ResultAppender } from '@/models/ResultAppender';
import { SearchResult } from '@/models/SearchResult';
import { SourceBase } from '@/models/SourceBase';
import { DafResult, EjendomFetcher } from '../Data/EjendomFetcher';

export class EsrToEjendomAppender extends ResultAppender {
    public source: SourceBase;

    constructor(source: SourceBase) {
        super();
        this.source = source;
    }

    public CanAppendResult(resultDataType: string): boolean {
        return resultDataType === 'ejendomsrelation';
    }

    public async getResults(searchresult: SearchResult, source: SourceBase): Promise<SearchResult[]> {
        if (!searchresult.data || searchresult.data.length === 0) {
            return [];
        }

        const ejendomme = await EjendomFetcher.GetDafResultsFromBFE(
            searchresult.data.map((d) => d.bfeNummer),
            source.options.dafUser,
            source.options.dafPassword,
            source.options.dafEjendomsrelationDate
        );

        return ejendomme.map((r: DafResult) =>
            EjendomFetcher.MapDafResultsToSearchResult(
                null,
                source.name,
                r,
                'bfe',
                source.getResultType('bfe'), null),
        );

    }
}
