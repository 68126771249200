import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import styles from "./FindEjendom.module.scss";
import Search from "components/Search/Search";
import PageContainer from "components/common/layout/PageContainer";
import { Spacing } from "components/common/layout/Spacing";

export default function FindEjendom() {
  const [result, setResult] = useState(undefined);
  const [redirect, setRedirect] = useState<{
    state: boolean;
    path: string;
  }>({ state: false, path: "/" });

  return (
    <PageContainer classNames={styles.content}>
      <div className={styles.container}>
        <h2>Find ejendom</h2>
        <Search
          onResult={(item) => {
            setResult(item);

            try {
              let bfe =
                item["data"][0] !== undefined
                  ? item["data"][0]["data"]["bfeNummer"]
                  : item["data"]["bfeNummer"];
              if (bfe !== undefined) {
                setRedirect({ state: true, path: "/ejendom?bfe=" + bfe });
              } else {
                redirect.state = false;
              }
            } catch (err) {
              setRedirect({ state: false, path: "" });
            }
          }}
        />
        {redirect.state && <Redirect to={redirect.path} />}
        {result && (
          <>
            <Spacing top={2} />
            <p>Intet BFE-nummer fundet ved søgning.</p>
          </>
        )}
      </div>
    </PageContainer>
  );
}
