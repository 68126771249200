import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import { mergeClassNames } from "util/index";
import styles from "./Avatar.module.scss";
import User from "components/common/icons/User";

interface IAvatarProps {
  size?: "small" | "large";
  bruger?: Bruger;
}

export default function Avatar(props: IAvatarProps) {
  const authState = useContext(AuthContext);
  const authBruger = authState.bruger;
  const { bruger, size = "small" } = props;
  const isMe = authBruger && bruger ? authBruger.id === bruger.id : false;
  let classes = mergeClassNames(
    mergeClassNames(styles.avatar, size === "large" ? styles.avatar__lg : ""),
    isMe ? styles.avatar__iscurrentuser : ""
  );
  return (
    <div className={classes} title={bruger ? bruger.navn : ""}>
      {bruger && bruger.initialer}
      {!bruger && <User height={size === "large" ? 18 : 14} />}
    </div>
  );
}
