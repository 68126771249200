import React from "react";

export default function Open(props: Icon) {
  const { height, width, style } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height : 16}
      height={height ? height : width ? width : 16}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <path
        d="M73.865-878.7a7.8,7.8,0,0,1-3.108-.625,8.054,8.054,0,0,1-2.548-1.715,8.059,8.059,0,0,1-1.715-2.546,7.791,7.791,0,0,1-.625-3.107,7.85,7.85,0,0,1,.625-3.127,7.961,7.961,0,0,1,1.713-2.539,8.182,8.182,0,0,1,2.545-1.711,7.747,7.747,0,0,1,3.108-.63,7.818,7.818,0,0,1,3.13.629,8.077,8.077,0,0,1,2.539,1.708,8.071,8.071,0,0,1,1.709,2.538,7.811,7.811,0,0,1,.63,3.13,7.752,7.752,0,0,1-.63,3.109,8.184,8.184,0,0,1-1.711,2.546,7.962,7.962,0,0,1-2.537,1.714A7.84,7.84,0,0,1,73.865-878.7Zm0-1.529a6.231,6.231,0,0,0,4.581-1.887,6.239,6.239,0,0,0,1.884-4.579,6.236,6.236,0,0,0-1.884-4.581,6.243,6.243,0,0,0-4.587-1.884,6.234,6.234,0,0,0-4.572,1.884,6.236,6.236,0,0,0-1.888,4.587,6.229,6.229,0,0,0,1.887,4.572A6.232,6.232,0,0,0,73.864-880.225ZM73.864-886.691Z"
        transform="translate(-65.869 894.696)"
      />
    </svg>
  );
}
