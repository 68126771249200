import React, { createContext, useEffect, useRef, useState } from "react";
import * as signalR from "@microsoft/signalr";

export const RealTimeContext = createContext<{
  opgaveMessages: OpgaveMessage[];
}>({ opgaveMessages: [] });

const RealTimeProvider = ({ children }) => {
  const [opgaveMessages, setOpgaveMessages] = useState<OpgaveMessage[]>([]);

  const callback = useRef<((message: OpgaveMessage) => void) | null>(null);
  const connection = useRef<signalR.HubConnection | null>(null);
  useEffect(() => {
    if (!connection.current) {
      connection.current = new signalR.HubConnectionBuilder()
        .withUrl("/hubs/opgave")
        .build();
      connection.current
        .start()
        // .then(function () {
        //   console.log("started signal r");
        // })
        .catch(function (err) {
          return console.error(err.toString());
        });
    }

    callback.current = function (message: OpgaveMessage) {
      setOpgaveMessages([...opgaveMessages, message]);
    };
    connection.current.on("ReceiveOpgaveMessage", callback.current);

    return () => {
      if (callback.current && connection.current)
        connection.current.off("ReceiveOpgaveMessage", callback.current);
    };
  }, [opgaveMessages]);

  return (
    <RealTimeContext.Provider value={{ opgaveMessages }}>
      {children}
    </RealTimeContext.Provider>
  );
};
export default RealTimeProvider;

interface OpgaveMessage {
  id: string;
  listeId: string;
  bfe: number;
  brugerStatus: number;
  systemStatus: number;
  oprettetBrugerId: string;
  aendretBrugerId?: string;
  tilhoererBruger: Bruger;
  kommentar: string;
  listeKommentarType: number;
  kommentarAdgang: number; // ingen=0/read=1/write=2
}
