import { getResponse, get, post, del } from "../index";
import { PageOptions } from "interfaces/paging";
import { AxiosResponse } from "axios";

const getAll = async (options?: PageOptions) => {
  return (await getAllWithHeaders(options)).data;
};

const getAllWithHeaders = async (options?: PageOptions) => {
  try {
    if (options) {
      // Default values if omitted by options
      let query = `bruger?`;

      Object.keys(options).forEach((key, i) => {
        if (i !== 0) query += "&";
        query = query.concat(`${key}=${options[key]}`);
      });
      return await getResponse<Bruger[]>({
        query: query,
      });
    } else {
      return await getResponse<Bruger[]>({
        query: `bruger?page=1&pageSize=50`,
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
const getById = async (id: string) => {
  try {
    return await get<Bruger>({
      query: `bruger/${id}`,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

const addUserRole = async (brugerid: string, rolleid: string) => {
  try {
    return await post<Bruger>({
      query: `bruger/${brugerid}/rolle?rolleid=${rolleid}`,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
const deleteUserRole = async (brugerid: string, rolleid: string) => {
  try {
    return await del<Bruger>({
      query: `bruger/${brugerid}/rolle/${rolleid}`,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

const api: BrugerApi = {
  getAll,
  getAllWithHeaders,
  getById,
  addUserRole,
  deleteUserRole,
};

export default api;

export interface BrugerApi {
  getAll: {
    (options?: PageOptions): Promise<Bruger[]>;
  };
  getAllWithHeaders: {
    (options?: PageOptions): Promise<AxiosResponse<Bruger[]>>;
  };
  getById: {
    (id: string): Promise<Bruger>;
  };
  addUserRole: {
    (brugerid: string, rolleid: string): Promise<Bruger>;
  };
  deleteUserRole: {
    (brugerid: string, rolleid: string): Promise<AxiosResponse<Bruger>>;
  };
}
