import React from "react";

export default function Pointer(props: Icon) {
  const { height, width, primaryColor, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (37 / 15) : 37}
      height={height ? height : width ? width * (15 / 37) : 15}
      viewBox="0 0 37 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-1346.000000, -842.000000)"
          fillRule="nonzero"
          stroke={primaryColor ? primaryColor : "currentColor"}
        >
          <g
            id="pointer-external"
            transform="translate(1346.000000, 842.000000)"
          >
            <path
              d="M35.4052,0.635348322 C34.5822579,0.806157928 32.6667892,1.62364288 31.8684043,1.79390677 C29.6151364,2.27686684 27.318211,2.52407691 25.0136456,2.55354566 C24.9203279,2.59611163 24.6845779,2.82203872 24.7909929,2.94700804 C24.9186908,3.09817182 25.4103823,3.1472864 25.4971514,3.14510353 C27.2936537,3.09708038 29.0759674,2.77565272 30.8375438,2.42257344 C32.2836954,2.13116024 33.7156584,1.85011568 35.1296127,1.42336453 C35.0919581,1.76607517 33.9907,2.34562726 33.7123841,2.55027135 C32.1658204,3.68590966 30.5706879,4.74296464 28.9308065,5.74108212 C25.4229338,7.87429219 21.8910496,10.033151 18.0732093,11.5753489 C14.2231717,13.1311898 9.95184008,14.0288952 5.85950386,12.9118113 C4.93778684,12.6602355 4.04280999,12.3137048 3.18930768,11.8842251 C2.80948823,11.6937696 1.3256821,10.491008 0.996614389,10.547217 C0.977514273,10.5504913 0.926216819,10.5111996 0.898385222,10.5068339 C0.535483023,10.4484421 0.696469713,10.7862413 0.727575616,10.9215792 C0.815436148,11.3090387 2.39146856,12.0774091 2.74400212,12.2618616 C3.5593042,12.6886128 4.41280652,13.0438749 5.293049,13.3150966 C7.39078742,13.9623177 9.60912944,14.0141608 11.7849055,13.7576736 C15.8314014,13.2812621 19.6388731,11.561706 23.1854917,9.63641429 C26.5503864,7.81044323 29.8918152,5.83549126 33.0165941,3.61933212 C33.6872811,3.14346638 34.3481451,2.65504913 35.0040976,2.15899184 C34.6968586,3.78250168 34.2335444,5.41583443 33.5121057,6.90618918 C33.3773135,7.18505087 32.7879385,7.87974936 32.783027,8.15369959 C32.6100345,8.38999531 32.972391,8.66230839 33.1492035,8.42273837 C33.4553511,8.32014346 33.8515421,7.26745422 33.98306,6.98968397 C34.7590704,5.34434543 35.6431329,1.64219728 35.6431329,1.56143108 C35.6431329,1.51013362 35.8155797,0.94586163 35.7910224,0.774506308 C35.7828366,0.718297396 35.6382214,0.587325174 35.4052,0.635348322 Z"
              id="Fill-1"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
