import React from "react";
import NavContainer from "./NavContainer";
import Logo from "./Logo";

export default function HomeNav() {
  return (
    <NavContainer>
      <Logo />
    </NavContainer>
  );
}
