import Vue from 'vue';

const updateTooltip = (el: HTMLElement, binding) => {
    if (binding.modifiers.noupdate) {
        return;
    }

    tooltip(el, binding);
};


const tooltip = (el: HTMLElement, binding) => {
    const triggers: string[] = [];

    if (binding.modifiers.focus) {
        triggers.push('focus');
    }
    if (binding.modifiers.hover) {
        triggers.push('hover');
    }
    if (binding.modifiers.click) {
        triggers.push('click');
    }
    if (!triggers.length) {
        triggers.push('hover');
    }
    el.title = '';
    el.setAttribute('tooltip', binding.value);
    el.addEventListener('mouseenter', createTip);
    el.addEventListener('mouseleave', cancelTip);
};

export default function initialize() {
    Vue.directive('asktooltip', {
        bind: tooltip,
        update: updateTooltip,
        unbind(el: HTMLElement) {
            el.removeEventListener('mouseenter', createTip);
            cancelTip();
            el.removeEventListener('mouseleave', cancelTip);
        },
    });
}

function createTip() {
    const element: HTMLElement = this;

    const title = element.getAttribute('tooltip');
    const tooltipWrap = document.createElement('div');
    tooltipWrap.className = 'ask-tooltip';
    tooltipWrap.appendChild(document.createTextNode(title));

    const firstChild = document.body.firstChild;
    firstChild.parentNode.insertBefore(tooltipWrap, firstChild);
    const linkProps = element.getBoundingClientRect();
    const tooltipProps = tooltipWrap.getBoundingClientRect();
    let topPos = linkProps.top - (tooltipProps.height + 4);

    if (topPos < 2) {
        topPos = linkProps.bottom + 4;
        tooltipWrap.className += ' ask-tooltip--under';
    }

    // tslint:disable-next-line:max-line-length
    tooltipWrap.setAttribute('style', 'top:' + topPos + 'px;' + 'left:' + ((linkProps.left + linkProps.width / 2) - tooltipProps.width / 2) + 'px;');

}

function cancelTip() {
    const el = document.querySelector('.ask-tooltip');
    if (el) {
        el.parentNode.removeChild(el);
    }
}
