import { get } from "../index";

const getChr = async (bfeNr: number) => {
  try {
    return await get<ChrOplysninger>({
      query: `suppopl/chr/bfe/${bfeNr}`,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

const api: ChrApi = {
  getChr,
};

export default api;

export interface ChrApi {
  getChr: {
    (bfeNr: number): Promise<ChrOplysninger>;
  };
}
