import React from "react";

interface IArealerProps {
  bygning: BbrBygning;
  arealer: BygningsArealer;
}

export default function Arealer(props: IArealerProps) {
  const { bygning, arealer } = props;
  const {
    byg041BebyggetAreal,
    byg049ArealAfOverdækketAreal,
    byg038SamletBygningsareal,
    byg060SamletAndetAreal,
    byg047ArealAfAffaldsrumITerrænniveau,
    byg042ArealIndbyggetGarage,
    byg043ArealIndbyggetCarport,
    byg044ArealIndbyggetUdhus,
    byg046SamletArealAfLukkedeOverdækningerPåBygningen,
    byg045ArealIndbyggetUdestueEllerLign,
    byg054AntalEtager,
    byg130ArealAfUdvendigEfterisolering,
    byg048AndetAreal,
    byg039BygningensSamledeBoligAreal,
    byg040BygningensSamledeErhvervsAreal,
  } = bygning;

  const {
    arealAfLovligBeboelseIKælder,
    dybKælder,
    erhvervIKælder,
    samletKælderareal,
    samletTagetageareal,
    udnyttetKælder,
    udnyttetTagetage,
  } = arealer;

  return (
    <>
      <section className="table-container table-container--areal">
        <div className="col">
          <table className="c-table c-table--areal">
            <thead>
              <tr>
                <th>Areal i grundplan</th>
                <th>
                  m<sup>2</sup>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Bebygget areal</td>
                <td>{byg041BebyggetAreal ?? 0}</td>
              </tr>
              <tr>
                <td>Overdækket areal</td>
                <td>{byg049ArealAfOverdækketAreal ?? 0}</td>
              </tr>
            </tbody>
          </table>
          <table className="c-table c-table--areal">
            <thead>
              <tr>
                <th>Areal i hele bygningen</th>
                <th>
                  m<sup>2</sup>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Samlet bygningsareal (excl. kælder & tagetage)</td>
                <td>{byg038SamletBygningsareal ?? 0}</td>
              </tr>
              <tr>
                <td>Samlet kælderareal</td>
                <td>{samletKælderareal ?? 0}</td>
              </tr>
              <tr className="sub-row">
                <td>Heraf udnyttet kælder</td>
                <td>{udnyttetKælder ?? 0}</td>
              </tr>
              <tr className="sub-row">
                <td>Heraf dyb kælder</td>
                <td>{dybKælder ?? 0}</td>
              </tr>
              <tr>
                <td>Samlet tagetageareal</td>
                <td>{samletTagetageareal ?? 0}</td>
              </tr>
              <tr className="sub-row">
                <td>Heraf udnyttet tagetage</td>
                <td>{udnyttetTagetage ?? 0}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col">
          <table className="c-table c-table--areal">
            <thead>
              <tr>
                <th>Samlet andet areal</th>
                <th>
                  m<sup>2</sup>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Samlet andet areal</td>
                <td>{byg060SamletAndetAreal ?? 0}</td>
              </tr>
              <tr className="sub-row">
                <td>Heraf affaldsrum i terræn</td>
                <td>{byg047ArealAfAffaldsrumITerrænniveau ?? 0}</td>
              </tr>
              <tr className="sub-row">
                <td>Heraf indbygget garage</td>
                <td>{byg042ArealIndbyggetGarage ?? 0}</td>
              </tr>
              <tr className="sub-row">
                <td>Heraf indbygget carport</td>
                <td>{byg043ArealIndbyggetCarport ?? 0}</td>
              </tr>
              <tr className="sub-row">
                <td>Heraf indbygget udhus</td>
                <td>{byg044ArealIndbyggetUdhus ?? 0}</td>
              </tr>
              <tr className="sub-row">
                <td>Heraf lukkede overdækninger</td>
                <td>
                  {byg046SamletArealAfLukkedeOverdækningerPåBygningen ?? 0}
                </td>
              </tr>
              <tr className="sub-row">
                <td>Heraf indbygget udestue el. lign.</td>
                <td>{byg045ArealIndbyggetUdestueEllerLign ?? 0}</td>
              </tr>
              <tr className="sub-row">
                <td>Heraf udvendig efterisolering</td>
                <td>{byg130ArealAfUdvendigEfterisolering ?? 0}</td>
              </tr>
              <tr className="sub-row">
                <td>Heraf øvrige arealer</td>
                <td>{byg048AndetAreal ?? 0}</td>
              </tr>
              <tr className="sub-row">
                <td>Heraf indbygget garage i kælder</td>
                <td>{byg047ArealAfAffaldsrumITerrænniveau ?? 0}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col">
          <table className="c-table c-table--areal">
            <thead>
              <tr>
                <th>Bygningens arealanvendelse</th>
                <th>
                  m<sup>2</sup>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Samlet boligareal</td>
                <td>{byg039BygningensSamledeBoligAreal ?? 0}</td>
              </tr>
              <tr className="sub-row">
                <td>Heraf areal af lovlig beboelse i kælder</td>
                <td>{arealAfLovligBeboelseIKælder ?? 0}</td>
              </tr>
              <tr>
                <td>Samlet erhvervsareal</td>
                <td>{byg040BygningensSamledeErhvervsAreal ?? 0}</td>
              </tr>
              <tr className="sub-row">
                <td>Heraf erhverv i kælder</td>
                <td>{erhvervIKælder ?? 0}</td>
              </tr>
              <tr>
                <td>Antal etager u. kælder & tagetage</td>
                <td>{byg054AntalEtager ?? 0}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}
