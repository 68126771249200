import { useState } from "react";
import styles from "./EsrSearch.module.scss";
import SearchHist from "components/Search/SearchHist";
import PageContainer from "components/common/layout/PageContainer";
import { SearchResult } from "searchcomponenthist/src/models/SearchResult";
import { Link } from "react-router-dom";
import ReadMoreContainer from "components/common/ReadMoreContainer";

export default function FindEjendom() {
  const [result, setResult] = useState<SearchResult | undefined>(undefined);
  return (
    <PageContainer classNames={styles.content}>
      <div className={styles.container}>
        <ReadMoreContainer maxLength={3}>
          <p>
            Her kan du søge på det historiske ESR-nummer og få vist tilhørende
            BFE-numre eller omvendt søge på et BFE-nummer og få vist det
            historiske ESR-nummer. ESR lukkede pr. 26/1-2024, derfor er
            søgeresultatet et udtryk for den tilknytning, der var mellem BFE og
            ESR på daværende tidspunkt.
          </p>
          <p>I søgeresultatet skal du være opmærksom på, at:</p>
          <ul className={styles.listUnstyled}>
            <li>
              et BFE-nummer kan gå på tværs af kommunegrænser og dermed flere
              ESR-numre
            </li>
            <li>et ESR-nummer kan knyttes til flere BFE-numre</li>
            <li>et BFE-nummer har ikke nødvendigvis et ESR-nummer</li>
            <li>
              det enkelte resultat ved BFE-opslag viser alle de tilknyttede
              ESR-numre pr. 26/1-2024
            </li>
          </ul>
          <p className="mb-1">
            Ved at klikke på ét af de viste resultater, er det muligt at se
            ejendommen på BFE-niveau.
          </p>
        </ReadMoreContainer>
        {/* <p>
          <ul>
            <li>370 26260</li>
            <li>410 10227</li>
            <li>163 11077</li>
          </ul>
        </p> */}
        <SearchHist
          onResult={(item) => {
            setResult(item);
          }}
        />
        {result && result.source === "daf.ejendomsnummer" && (
          <ul className="mt-3 list-group w-100 border-top border-1 container-fluid p-0">
            {result.data.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`list-group-item list-group-item-action border-0 border-bottom border-1 w-100 py-4 rounded-0 ${styles.listGroupItem}`}
                >
                  <LinkItem result={item} />
                </li>
              );
            })}
          </ul>
        )}
        {result && result.source === "daf.ejendom" && (
          <div className="mt-3 list-group w-100 mw-100 border-top border-1 container-fluid p-0">
            <div
              className={`list-group-item list-group-item-action border-0 border-bottom border-1 w-100 py-4 rounded-0 ${styles.listGroupItem}`}
            >
              <LinkItem result={result} />
            </div>
          </div>
        )}
      </div>
    </PageContainer>
  );
}

function LinkItem(props: { result: SearchResult }) {
  const { result } = props;
  const { data } = result;
  const ejdNummers: {
    bfeNummer: number;
    ejendomsnummer: number;
    kommunekode: string;
  }[] = data.ejendomsnummer;
  return (
    <Link className={styles.internal} to={`/ejendom?bfe=${data.bfeNummer}`}>
      <h3>{result.title}</h3>
      <p className="mb-1 lh-lg fw-semibold fs-4">{result.description}</p>
      {ejdNummers.map((ejdNummer, index) => (
        <div className="small" key={index}>
          <div>
            <span className="fw-normal">Kommunekode:</span>{" "}
            {ejdNummer.kommunekode}
          </div>
          <div>
            <span className="fw-normal">Ejendomsnummer:</span>{" "}
            {ejdNummer.ejendomsnummer}
          </div>
        </div>
      ))}
    </Link>
  );
}
