import React from "react";

export default function Vurdst(props: Icon) {
  const { height, width, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (229.5 / 56.7) : 229.5}
      height={height ? height : width ? width * (56.7 / 229.5) : 56.7}
      viewBox="0 0 229.5 56.7"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            className="st0"
            style={{ fill: "#14143C" }}
            d="M62.5,12.9l-6.6,19.2h-5l-6.5-19.2h5.3L53.4,25l3.7-12.1H62.5z"
          />
          <path
            style={{ fill: "#14143C" }}
            d="M64.4,24.4V12.9h5.3v12c0,1.9,1.3,3.1,3,3.1c1.7,0,3-1.2,3-3.1v-12h5.3v11.5c0,5.2-3.3,8-8.3,8
			C67.7,32.5,64.4,29.7,64.4,24.4z"
          />
          <path
            style={{ fill: "#14143C" }}
            d="M84.3,12.9h9.3c4.8,0,7.4,2.7,7.4,6.6c0,2.5-1.5,4.5-3.5,5.6l4,7.1h-5.8L92.4,26h-2.8v6.2h-5.3V12.9z
			 M89.6,17.4v4.2h3.9c1.5,0,2.2-1,2.2-2.1c0-1.1-0.7-2.1-2.2-2.1H89.6z"
          />
          <path
            style={{ fill: "#14143C" }}
            d="M112.2,12.9c4.3,0,10,2,10,9.6c0,7.5-5.7,9.6-10,9.6h-8.1V12.9H112.2z M109.4,17.4v10.3h3.1
			c1.3,0,4.4-1.1,4.4-5.1c0-4.1-3.1-5.2-4.4-5.2H109.4z"
          />
          <path
            style={{ fill: "#14143C" }}
            d="M124.8,12.9h14.4v4.7h-9.2v2.6h7.4v4.4h-7.4v3.1h9.5v4.4h-14.8V12.9z"
          />
          <path
            style={{ fill: "#14143C" }}
            d="M142.5,12.9h9.3c4.8,0,7.4,2.7,7.4,6.6c0,2.5-1.5,4.5-3.5,5.6l4,7.1h-5.8l-3.3-6.2h-2.8v6.2h-5.3V12.9z
			 M147.8,17.4v4.2h3.9c1.5,0,2.2-1,2.2-2.1c0-1.1-0.7-2.1-2.2-2.1H147.8z"
          />
          <path
            style={{ fill: "#14143C" }}
            d="M167.5,12.9v19.2h-5.3V12.9H167.5z"
          />
          <path
            style={{ fill: "#14143C" }}
            d="M171.1,12.9h4.9l6.5,10.4V12.9h5v19.2h-4.9l-6.6-10.3v10.3h-5V12.9z"
          />
          <path
            style={{ fill: "#14143C" }}
            d="M200.7,21.5h10.4c0,0.3,0.1,0.8,0.1,1.1c0,6.3-4.9,9.9-10.5,9.9c-5.6,0-10.5-3.6-10.5-9.9
			c0-6.6,5.2-10,10.5-10c3.5,0,6.8,1.5,8.6,4.4l-4.4,2.5c-0.9-1.5-2.3-2.2-4.2-2.2c-2.9,0-5.2,1.8-5.2,5.3c0,3.3,2.7,5.5,5.2,5.5
			c2.3,0,3.9-0.8,4.8-2.5h-4.7V21.5z"
          />
          <path
            style={{ fill: "#14143C" }}
            d="M212.3,29.4l3.1-3.9c1.2,1.1,3.5,2.5,5.7,2.5c2.2,0,3.1-0.7,3.1-1.6c0-3.2-11.5-0.1-11.5-7.4
			c0-3.7,3.4-6.4,8.3-6.4c3.5,0,5.9,1.4,7.3,2.6l-3,3.5c-0.8-0.8-2.9-1.7-4.6-1.7c-2,0-2.8,0.6-2.8,1.4c0,2.7,11.6,0,11.6,7.4
			c0,3.7-3.3,6.8-8.6,6.8C217.5,32.5,214.4,31.4,212.3,29.4z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#A3928F" }}
            d="M44.7,53.6l3.1-3.9c1.2,1.1,3.5,2.5,5.7,2.5c2.2,0,3.1-0.7,3.1-1.6c0-3.2-11.5-0.1-11.5-7.4
			c0-3.7,3.4-6.4,8.3-6.4c3.5,0,5.9,1.4,7.3,2.6l-3,3.5c-0.8-0.8-2.9-1.7-4.6-1.7c-2,0-2.8,0.6-2.8,1.4c0,2.7,11.6,0,11.6,7.4
			c0,3.7-3.3,6.8-8.6,6.8C49.8,56.7,46.7,55.6,44.7,53.6z"
          />
          <polygon
            style={{ fill: "#A3928F" }}
            points="89.1,37.1 85.2,44.7 81.3,37.1 61.5,37.1 61.5,41.6 67.2,41.6 67.2,56.3 72.5,56.3 72.5,41.6
			78.2,41.6 82.5,49.6 82.5,56.3 87.8,56.3 87.8,49.6 94.5,37.1 		"
          />
          <path
            style={{ fill: "#A3928F" }}
            d="M95.6,37.1h9.3c4.8,0,7.4,2.7,7.4,6.6c0,2.5-1.5,4.5-3.5,5.6l4,7.1h-5.8l-3.3-6.2h-2.8v6.2h-5.3V37.1z
			 M100.8,41.6v4.2h3.9c1.5,0,2.2-1,2.2-2.1c0-1.1-0.7-2.1-2.2-2.1H100.8z"
          />
          <path
            style={{ fill: "#A3928F" }}
            d="M115.2,37.1h14.4v4.7h-9.2v2.6h7.4v4.4h-7.4v3.1h9.5v4.4h-14.8V37.1z"
          />
          <path
            style={{ fill: "#A3928F" }}
            d="M146.9,56.3H133V37.1h5.3v14.8h8.6V56.3z"
          />
          <path
            style={{ fill: "#A3928F" }}
            d="M148.2,53.6l3.1-3.9c1.2,1.1,3.5,2.5,5.7,2.5c2.2,0,3.1-0.7,3.1-1.6c0-3.2-11.5-0.1-11.5-7.4
			c0-3.7,3.4-6.4,8.3-6.4c3.5,0,5.9,1.4,7.3,2.6l-3,3.5c-0.8-0.8-2.9-1.7-4.6-1.7c-2,0-2.8,0.6-2.8,1.4c0,2.7,11.6,0,11.6,7.4
			c0,3.7-3.3,6.8-8.6,6.8C153.3,56.7,150.3,55.6,148.2,53.6z"
          />
          <path
            style={{ fill: "#A3928F" }}
            d="M167.9,37.1h14.4v4.7h-9.2v2.6h7.4v4.4h-7.4v3.1h9.5v4.4h-14.8V37.1z"
          />
          <path
            style={{ fill: "#A3928F" }}
            d="M185.5,37.1h4.9l6.5,10.4V37.1h5v19.2H197L190.4,46v10.3h-5V37.1z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#14143C" }}
            d="M4.8,25.7h1.8h0c0-0.9,0.4-2.5-0.1-3.4c-0.6-1-1.9-2-3.1-3.2c-0.5-0.5-1.6-1.3-1.6-2.6c0-1.8,1.3-2.3,2.3-2.3
			c0.3,0,0.6,0,1,0.1c0.1-0.6,0.4-1.2,0.8-1.7c-0.5-0.2-1.1-0.3-1.7-0.3c-1.7,0-4.2,1.2-4.2,4.2c0,2,1.2,3.1,2.4,4.2
			C3.6,22,4.7,23.2,4.8,25.7"
          />
          <rect
            x="4.8"
            y="27.1"
            style={{ fill: "#14143C" }}
            width="24.9"
            height="1.8"
          />
          <rect
            x="4.8"
            y="30.4"
            style={{ fill: "#14143C" }}
            width="24.9"
            height="1.8"
          />
          <path
            style={{ fill: "#14143C" }}
            d="M10.1,25.7h2c0.2-1.5,0.8-2.8,0.4-3.9c-0.4-1.2-2-2.6-3-3.6c-0.8-0.9-1.3-1.6-1.3-2.7c0-1.2,1-2.3,2.4-2.3
			c1.6,0,2.6,1.5,4.6,1.5v-1.8c-1.2-0.1-2.3-1.5-4.6-1.5c-1.2,0-2.3,0.4-3.1,1.2c-0.7,0.8-1.2,1.8-1.2,2.9c0,1.7,0.8,2.8,1.6,3.8
			C9,20.7,10.1,22.2,10.1,25.7"
          />
          <path
            style={{ fill: "#14143C" }}
            d="M30.4,14.2c0.9,0,2.3,0.5,2.3,2.3c0,1.4-1.1,2.1-1.6,2.6c-1.2,1.1-2.5,2.1-3.1,3.2c-0.5,0.9-0.2,2.5-0.1,3.4
			h1.8c0.2-2.5,1.2-3.7,2.5-5c1.1-1.1,2.4-2.3,2.4-4.2c0-3-2.4-4.2-4.2-4.2c-0.6,0-1.1,0.1-1.7,0.3c0.4,0.5,0.6,1.1,0.8,1.7
			C29.8,14.2,30.1,14.2,30.4,14.2"
          />
          <path
            style={{ fill: "#14143C" }}
            d="M19.5,14.7c2,0,2.9-1.5,4.6-1.5c1.4,0,2.4,1.1,2.4,2.3c0,1.1-0.5,1.8-1.3,2.7c-1,1.1-2.6,2.4-3,3.6
			c-0.4,1.1,0.2,2.4,0.4,3.9h2c-0.1-3.5,1.1-5.1,2.1-6.4c0.8-1,1.6-2.1,1.6-3.8c0-1.1-0.4-2.2-1.2-2.9c-0.8-0.8-1.9-1.2-3.1-1.2
			c-2.3,0-3.4,1.4-4.6,1.5V14.7z"
          />
          <path
            style={{ fill: "#14143C" }}
            d="M16.3,11.5v5.3c0,0,0,0,0,0c0,3.4-0.9,4.2-0.9,5.4c0,1.4,0.6,1.9,0.9,3.5c0,0,0,0,0,0v0h1.9v0c0,0,0,0,0,0
			c0.3-1.6,0.9-2,0.9-3.5c0-1.1-0.9-2-0.9-5.4c0,0,0,0,0,0v-5.3H16.3z"
          />
          <path
            style={{ fill: "#14143C" }}
            d="M17.3,6.9c0.5,0,1,0.4,1,1c0,0.5-0.4,1-1,1c-0.5,0-1-0.4-1-1C16.3,7.4,16.7,6.9,17.3,6.9 M16.4,5.5
			c-1,0.3-1.7,1.3-1.7,2.4c0,1.4,1.2,2.6,2.6,2.6c1.4,0,2.6-1.2,2.6-2.6c0-1.1-0.7-2.1-1.7-2.4V3.6H20V1.9h-1.9V0h-1.7v1.9h-1.9v1.7
			h1.9V5.5z"
          />
        </g>
      </g>
    </svg>
  );
}
