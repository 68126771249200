import React from "react";
import styles from "./Sidebar.module.scss";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { Spacing } from "components/common/layout/Spacing";
import Chevron from "components/common/icons/Chevron";
import { parseQueryString, mergeClassNames } from "util/index";

export default function Sidebar() {
  let history = useHistory();
  const { search } = history.location;
  const query = parseQueryString(search);
  const { type } = query;

  return (
    <div className={styles.container}>
      <ul role="tablist">
        <li>
          <motion.button
            role="tab"
            aria-controls="statistik-main-content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={mergeClassNames(
              styles.menuItem,
              type === "bbr" ? styles.selected : ""
            )}
            title="BBR"
            onClick={() => {
              history.push("/statistik?type=bbr");
            }}
          >
            <Spacing right={0.5} />
            <div className={styles.headeritem}>
              <h2>
                BBR
                <span>
                  <Chevron />
                </span>
              </h2>
            </div>
          </motion.button>
        </li>
      </ul>
      <ul role="tablist">
        <li>
          <motion.button
            role="tab"
            aria-controls="statistik-main-content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={mergeClassNames(
              styles.menuItem,
              type === "fejllister" ? styles.selected : ""
            )}
            title="Lister"
            onClick={() => {
              history.push("/statistik?type=fejllister");
            }}
          >
            <Spacing right={0.5} />
            <div className={styles.headeritem}>
              <h2>
                Fejllister
                <span>
                  <Chevron />
                </span>
              </h2>
            </div>
          </motion.button>
        </li>
      </ul>
    </div>
  );
}
