import React from "react";
import styles from "./WarningLabel.module.scss";
import BBRAdvarsel from "components/common/icons/BBRAdvarsel";
// import { mergeClassNames } from "util/index";

export default function WarningLabel() {
  // const { type } = props;
  return (
    <div className={styles.warning}>
      <i>
        <BBRAdvarsel height={20} />
      </i>
      <span className={styles.warning__text}>Gennemsyn</span>
      <div className={styles.tooltip}>
        <h5>Gennemsyn</h5>
        <p>Der er fundet et område der skal kontrolleres manuelt.</p>
      </div>
    </div>
  );
}
