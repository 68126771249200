import React from "react";
import styles from "./Etage.module.scss";
import { mergeClassNames } from "util/index";

export default function Etage(props: IEtage) {
  const { children, isLast } = props;

  return (
    <div
      className={
        !isLast
          ? styles.container
          : mergeClassNames(styles.container, styles.last)
      }
    >
      {children}
    </div>
  );
}
interface IEtage {
  children: React.ReactNode;
  isLast: boolean;
}
