import { useEffect, useMemo, useRef } from "react";
import searchComponentHist from "searchcomponenthist/dist/jo-informatik.searchcomponentHist-1.0.27.full.umd";
import { SearchResult } from "searchcomponenthist/src/models/SearchResult";
import { Initialize } from "searchcomponenthist/src/models/Initialize";
type SearchHistProps = {
  onResult: (item: SearchResult | undefined) => void;
};
export default function SearchHist(props: SearchHistProps) {
  const { onResult } = props;

  let autocomplete = useRef(undefined);

  const searchComponentHistConfig: Initialize = useMemo(() => {
    return {
      instanceId: "searchComponentHist",
      elementOrSelector: "#searchComponentHist",
      sources: [
        {
          name: "daf.ejendom",
          options: {
            fetchExtendedResult: true,
          },
        },
        {
          name: "daf.ejendomsnummer",
          options: {
            fetchExtendedResult: true,
          },
        },
      ],
      layout: {
        width: "100%",
      },
      localization: {
        placeholder: "Søg ejendom",
      },
      events: {
        itemClicked: (item: SearchResult) => {
          onResult(item);
        },
        cleared: () => {
          onResult(undefined);
        },
      },
      behavior: {},
    };
  }, [onResult]);
  useEffect(() => {
    autocomplete.current = searchComponentHist.createInstance(
      searchComponentHistConfig
    );
  }, [onResult, searchComponentHistConfig]);
  if (autocomplete.current) {
    // issue #243, ønsker ikke autofocus
    // autocomplete.current.focus();
  }
  return <div id="searchComponentHist" />;
}
