import { useState, createContext, useContext, useLayoutEffect } from "react";
import FullScreenMap from "./FullScreenMap";
import Main from "./Panes/Main";
import { useURLSearchParams } from "hooks/useURLSearchParams";
import ejendomApi from "api/ejendom";
import chrApi from "api/chr";
import opgaveApi from "api/opgave";
import opgaveListeApi from "api/opgavelister";
import { motion, AnimatePresence } from "framer-motion";
import { OpgaveSortOrder } from "interfaces/Opgaveliste/enums";
import { AuthContext } from "../../context/AuthProvider";
import { Redirect, useHistory } from "react-router-dom";
import Breadcrumbs from "routes/Fejllister/Breadcrumbs";
import Ejer from "./Panes/Left/Ejer";
import Vurderingsejendom from "./Panes/Left/Vurderingsejendom";
import StickerIcon from "components/common/icons/StickerIcon";
import StatusDropdown from "./StatusDropdown";
import FolderIcon from "components/common/icons/Folder";
import ArrowRight from "components/common/icons/ArrowRight";
import ExternalLinks from "./ExternalLinks";
import ArrowUpRight from "components/common/icons/ArrowUpRight";
import { SearchResultContext } from "context/SearchResultProvider";
import StickyHeader from "components/common/StickyHeader";
import WarningBox from "components/common/WarningBox";
import useMediaQuery from "hooks/useMediaQuery";
import Expand from "components/common/icons/Expand";
import useRealTimeOpgaver from "hooks/useRealTimeOpgaver";
import BbrBaseMap from "components/Map/BbrBaseMap";
import { constructGrunddataKeysFromEjendom } from "components/Map/basemapUtil";
import { useQuery } from "react-query";

// Relates to "enhedModal"
// Allows the modals to see if the right pane is open or not without passing props
// If enhedModal is not used, the context is obsolete, but it's fine.
export const RightPaneOpenContext = createContext<boolean>(false);
export interface ListeState {
  data: OpgaveListe | undefined;
  opgaveOrder: OpgaveOrder | undefined;
}
export default function Ejendom() {
  // const { opgaveMessages } = useContext(RealTimeContext);
  // const prevOpgaveMessages = usePrevious(opgaveMessages);
  const [fullScreenMapToggled, setFullScreenMapToggled] = useState(false);
  const { redirect, resetSearch } = useContext(SearchResultContext);
  useLayoutEffect(() => {
    return () => {
      resetSearch();
    };
  }, [resetSearch]);

  const variants = {
    hidden: {
      opacity: 0,
      width: 0,
      height: 0,
      // transition: { delay: 0.08 },
    },
    show: {
      opacity: 1,
      height: "fit-content",
      transition: { delay: 0.4 },
    },
  };

  const query = useURLSearchParams();
  const queryBfe = query.get("bfe");
  const queryOpgaveid = query.get("opgaveid");
  const querySortOrder = query.get("sortorder");
  const authState = useContext(AuthContext);
  const matches1300 = useMediaQuery("(max-width: 1300px)");

  // const matches500 = useMediaQuery("(max-width: 500px)");
  const { bruger } = authState;
  const roller = bruger && bruger.roller ? bruger.roller : [];
  const history = useHistory();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get kommune oplysninger
  //const fetchKommune = useCallback(() => kommuneApi.getKommune(Number(kommunenr)))
  /* Set the states */
  const {
    data: ejendom,
    isLoading: ejendomLoading,
    isError: ejendomError,
  } = useQuery({
    queryKey: ["ejendom", queryBfe],
    queryFn: async () => {
      return await ejendomApi.getBfe(Number(queryBfe), true);
    },
  });

  const { data: chrOplysninger } = useQuery({
    queryKey: ["chr", queryBfe],
    queryFn: async () => {
      return await chrApi.getChr(Number(queryBfe));
    },
  });

  const [opgaver, setOpgaver] = useState<Opgave[] | undefined>(undefined);
  const { isLoading: opgaverLoading } = useQuery({
    queryFn: async () => {
      return await opgaveApi.getBfe(Number(queryBfe), authState);
    },
    queryKey: ["opgaverforbfe", queryBfe, authState.bruger],
    enabled: !!queryBfe && !!authState.bruger,
    onSuccess: (opg) => {
      setOpgaver(opg);
    },
  });

  useRealTimeOpgaver(opgaver, (updatedOpgaver) => {
    setOpgaver(updatedOpgaver);
  });

  const currentOpgave =
    queryOpgaveid && opgaver
      ? opgaver.find((o) => o.id === queryOpgaveid)
      : undefined;

  const { data: liste, isLoading: listeLoading } = useQuery({
    enabled: !!opgaver,
    queryKey: ["liste", queryOpgaveid, queryBfe, querySortOrder],

    queryFn: async () => {
      if (!opgaver) return undefined;
      let sortOrder = OpgaveSortOrder.opgaveIdAsc;

      if (querySortOrder && querySortOrder in OpgaveSortOrder) {
        sortOrder = parseInt(querySortOrder);
      }
      let idx = 0;
      let listeId: string | undefined;
      let opgaveId: string | undefined;
      if (queryOpgaveid) {
        opgaveId = queryOpgaveid;
        if (opgaver.length > 0) {
          idx = opgaver.findIndex(
            (opgave: Opgave) => opgave.id === queryOpgaveid
          );
          if (idx === -1 && opgaver[0]) {
            listeId = opgaver[0].listeId;
          } else {
            listeId = opgaver[idx].listeId;
          }
        }
      } else {
        // if there is no query opgaveId, take the first returned.
        // ref issue #266, 248
        if (opgaver.length > 0) {
          listeId = opgaver[0].listeId;
          opgaveId = opgaver[0].id;
        }
      }
      let response: ListeState | undefined = undefined;

      if (listeId) {
        if (!response) response = { data: undefined, opgaveOrder: undefined };
        response.data = await opgaveListeApi.getListeFromId(listeId);
      }
      if (opgaveId) {
        if (!response) response = { data: undefined, opgaveOrder: undefined };
        response.opgaveOrder = await opgaveApi.getOpgaveOrder(
          opgaveId,
          sortOrder,
          authState
        );
      }

      return response;
    },
  });

  const retILinks = currentOpgave?.visningsdata?.links
    ? currentOpgave?.visningsdata?.links?.filter((o) => !o.skjul)
    : [];

  return (
    <>
      <div
        id="c-ejendom-container"
        className={`c-ejendom-container container-fluid d-flex justify-content-center pt-8 pb-4 bg-body-tertiary ${
          matches1300 ? "flex-column" : ""
        } `}
        style={{ minHeight: "100vh" }}
      >
        <aside className="col" aria-hidden="true"></aside>
        <div className="container-xl c-ejendom-container-content">
          {redirect.state === true && <Redirect push to={redirect.path} />}
          <Breadcrumbs
            authState={authState}
            classNames="pb-2"
            currentList={
              opgaver && opgaver.length > 0 && liste?.data
                ? {
                    navn: liste.data.navn,
                    id: liste.data.id,
                  }
                : undefined
            }
            bfe={Number(queryBfe)}
          />
          {roller.findIndex((role) => role.navn === "fejllister") > -1 && (
            <StickyHeader>
              <div
                className={`container-fluid bg-white shadow-sm rounded d-flex align-items-center gap-3 justify-content-between ${
                  currentOpgave
                    ? "border border-primary"
                    : "border border-tertiary"
                }`}
                style={{
                  height: 70,
                  paddingTop: "0.8rem",
                  paddingBottom: "0.8rem",
                }}
              >
                {!opgaver && opgaverLoading && <span>Henter...</span>}
                {opgaver && !opgaverLoading && opgaver.length === 0 && (
                  <p className="mb-0 ms-2 text-primary text-nowrap">
                    <span className="fw-bold">0</span> fejl på ejendommen
                  </p>
                )}
                {liste && (
                  <StatusDropdown
                    listeState={liste}
                    opgaver={opgaver}
                    currentOpgave={currentOpgave}
                    setOpgaver={setOpgaver}
                    brugerStatusUI={liste.data?.brugerstatus}
                    erSkatteforvaltningen={
                      bruger !== undefined && bruger.erSkatteforvaltningen
                    }
                    listeLoading={listeLoading}
                  />
                )}
              </div>
            </StickyHeader>
          )}
          <section className="rounded-2 bg-white p-4 text-body mt-5">
            <div className="container-fluid">
              <div className="row pb-4" style={{ rowGap: "2rem" }}>
                <div className="col-12 col-lg-8 px-0">
                  <div className="mb-4 row">
                    <div className="col">
                      {ejendomLoading && (
                        <>
                          <h1 className="mb-2 h2">Henter BFE...</h1>
                          <motion.div
                            className="bg-primary-subtle mb-0"
                            style={{ width: "240px", height: "1.1rem" }}
                            animate={{
                              opacity: [0.1, 0.8, 0.1],
                            }}
                            transition={{ repeat: Infinity, duration: 1.6 }}
                          ></motion.div>
                        </>
                      )}
                      {!ejendomLoading && (
                        <>
                          <h1 className="mb-1 h2">BFE: {ejendom?.bfeNr}</h1>
                          <p className="mb-0 fw-semibold text-primary">
                            {ejendom?.beliggenhedsadresse?.betegnelse}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div
                        className="d-flex flex-column gap-4"
                        style={ejendomLoading ? { minWidth: "50%" } : {}}
                      >
                        {ejendom && <Ejer ejendom={ejendom} />}
                      </div>
                      <div
                        style={ejendomLoading ? { minWidth: "50%" } : {}}
                      ></div>
                    </div>
                    <div className="col d-flex flex-column gap-4">
                      <div className="pe-3">
                        <h5 className="fw-semibold text-body">Kommune</h5>
                        {ejendomLoading && (
                          <motion.div
                            className="bg-primary-subtle shadow-sm"
                            style={{ width: "fit-content" }}
                            animate={{
                              opacity: [0.1, 0.8, 0.1],
                            }}
                            transition={{ repeat: Infinity, duration: 1.6 }}
                          >
                            <div
                              style={{
                                height: "1rem",
                                width: "15rem",
                                maxWidth: "80%",
                              }}
                              className=""
                            ></div>
                          </motion.div>
                        )}
                        {!ejendomLoading && (
                          <p className="mb-0">
                            {ejendom?.kommune?.navn
                              ? `${ejendom?.kommune?.navn}${
                                  ejendom?.kommune.kommunenr === 101
                                    ? "s Kommune"
                                    : ejendom?.kommune.kommunenr === 400
                                    ? "s Regionskommune"
                                    : " Kommune"
                                }`
                              : ""}
                          </p>
                        )}
                      </div>
                      <Vurderingsejendom
                        ejendom={ejendom}
                        isLoading={ejendomLoading}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 px-0">
                  <div className="rounded-3 shadow-sm c-ejendom-mapcontainer">
                    {!ejendomLoading && (
                      <BbrBaseMap
                        divId="ejendom-thumbnail-container"
                        grunddataKeys={constructGrunddataKeysFromEjendom(
                          ejendom
                        )}
                        containerStyle={{ height: 230 }}
                        markers={[]}
                      />
                    )}
                    {ejendomLoading && (
                      <motion.div
                        className="bg-primary-subtle shadow-sm"
                        animate={{
                          opacity: [0.1, 0.8, 0.1],
                        }}
                        transition={{ repeat: Infinity, duration: 1.6 }}
                      >
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ height: 230 }}
                        >
                          <p className="mb-0 fs-5 text-primary">
                            {" "}
                            Henter kort...
                          </p>
                        </div>
                      </motion.div>
                    )}
                    {ejendomError && (
                      <div
                        className="d-flex align-items-center justify-content-center shadow-sm"
                        style={{ height: 230 }}
                      >
                        <p className="mb-0 text-primary">
                          Kunne ikke hente kort.
                        </p>
                      </div>
                    )}
                    {!ejendomLoading && (
                      <button
                        onClick={() => setFullScreenMapToggled(true)}
                        className="btn btn-outline-secondary bg-white rounded-circle m-2 d-flex justify-content-center align-items-center"
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          top: 0,
                          right: 0,
                          zIndex: 2,
                          width: "40px",
                          height: "40px",
                          border: "none",
                        }}
                      >
                        <div
                          className="text-primary"
                          style={{ height: "100%" }}
                        >
                          <Expand width={19} />
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <AnimatePresence>
                {!ejendomLoading && currentOpgave && (
                  <motion.div
                    variants={variants}
                    animate={currentOpgave ? "show" : "hidden"}
                    // transition={{ type: "ease" }}
                    transition={{ damping: 300 }}
                    className="mt-3"
                  >
                    {currentOpgave && opgaver?.length ? (
                      <WarningBox>
                        <div className="col-12 col-lg-8 p-3">
                          <div className="d-flex align-items-center mb-4 gap-2 text-body">
                            <StickerIcon width={22} />
                            <h4 className="mb-0 text-body text-nowrap">
                              Fejl til gennemsyn{" "}
                              <span className="">
                                {opgaver.indexOf(currentOpgave) + 1}
                              </span>
                            </h4>
                          </div>
                          <div className="row">
                            {currentOpgave.visningsdata?.elementer?.map(
                              (element, index) => (
                                <div
                                  className={`mb-3 col-12 col-md-6`}
                                  key={index}
                                >
                                  <h5 className="fw-semibold mb-1 text-nowrap text-body">
                                    {element.info.navn}
                                  </h5>
                                  <p className="mb-0 text-body">
                                    {element.info.vaerdi
                                      ? element.info.vaerdi
                                      : "Ikke oplyst"}
                                  </p>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        <div
                          className={`col-12 col-lg-4 p-3 pt-0 ps-lg-0 pt-lg-3 d-flex flex-column`}
                        >
                          {liste?.data && (
                            <button
                              className="btn btn-link-subtle text-primary fw-semibold fs-5 p-0 mb-4 d-flex align-items-center gap-2"
                              title={`Gå til fejlliste: ${liste.data.navn}`}
                              onClick={() =>
                                history.push(`/fejllister/${liste.data?.id}`)
                              }
                            >
                              <FolderIcon width={20} />
                              <p className="mb-0 text-overflow-ellipsis text-start">
                                {liste.data.navn}
                              </p>
                              <ArrowRight width={18} />
                            </button>
                          )}
                          {retILinks && retILinks.length > 0 && (
                            <div
                              className={`d-flex flex-column border border-warning rounded-3 p-4 pt-3`}
                              style={{ minWidth: "170px" }}
                            >
                              <p className="mb-0 pb-2 fw-semibold fs-5">
                                Ret i
                              </p>
                              <div>
                                <ul className="d-flex flex-column gap-1 links--visited">
                                  {!opgaverLoading &&
                                    renderOpgaveLinks(retILinks)}
                                </ul>
                              </div>
                            </div>
                          )}
                          {/* <div
                            className={`w-75 d-flex flex-column border border-warning rounded-3 shadow-sm p-4 w-75 `}
                            style={{ maxWidth: "300px", minWidth: "170px" }}
                          >
                            <p className="mb-0 pb-2 fw-bold fs-5">Ret i</p>
                            <a
                              className="d-flex justify-content-start gap-2 w-100 fs-5 align-items-center text-primary py-2"
                              rel="noopener noreferrer"
                              target="_blank"
                              href="https://kommune.bbr.dk/"
                            >
                              <p className="mb-0 fs-4">
                                BBR Kommune <ArrowUpRight width={18} />
                              </p>
                            </a>
                            <a
                              className="d-flex justify-content-start gap-2 w-100 fs-5 align-items-center text-primary py-2"
                              rel="noopener noreferrer"
                              target="_blank"
                              href="https://ret.bbr.dk/"
                            >
                              <p className="mb-0 fs-4">
                                Ret BBR <ArrowUpRight width={18} />
                              </p>
                            </a>
                          </div> */}
                        </div>
                      </WarningBox>
                    ) : null}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </section>
          <Main
            ejendom={ejendom}
            ejendomLoading={ejendomLoading}
            chrOplysninger={chrOplysninger}
            listeState={liste}
            showOpgaver={!!currentOpgave}
            currentOpgave={currentOpgave}
          />
        </div>
        {/* c-externallinks-container  */}
        <aside className={`col ${matches1300 ? " container-xl" : ""} `}>
          <div
            className={`d-flex flex-column ${
              matches1300 ? "pt-6 pb-3" : "position-sticky pt-6 ps-6 pe-3"
            } `}
            style={{ top: "2.5rem", minWidth: 246 }}
          >
            <h5 className="mb-3 fw-bold">Eksterne links:</h5>
            <ExternalLinks
              ejendom={ejendom}
              ejendomIsLoading={ejendomLoading}
            />
          </div>
        </aside>
      </div>
      <FullScreenMap
        isToggled={fullScreenMapToggled}
        setIsToggled={setFullScreenMapToggled}
        ejendom={ejendom}
        ejendomIsLoading={ejendomLoading}
      />
    </>
  );
}

const renderOpgaveLinks = (retILinks) => {
  return retILinks.map((o: VisningsdataLink, i: number) => {
    return (
      <li key={i}>
        <a
          title={`Åbn i ${o.system}`}
          className="external w-100"
          style={{ minWidth: 0 }}
          rel="noopener noreferrer"
          target="_blank"
          href={o.url}
        >
          <span className="text-truncate flex-1">{o.system}</span>
          <ArrowUpRight width={18} />
        </a>
      </li>
    );
  });
};

export interface ButtonState {
  state: "IDLE" | "LOADING" | "ERROR";
}
