import React from "react";

export function FilterDropdownItem(props: {
  isActive: boolean;
  setIsActive: (active: boolean) => void;
  label: string;
  id?: number;
}) {
  const { isActive, setIsActive, label, id } = props;
  return (
    <div
      key="a"
      className="styles.filtermenuitem list-group-item list-group-item-action border-0 rounded w-100 px-2 py-3"
      style={{ cursor: "pointer" }}
    >
      <div
        className="form-check d-flex gap-2 align-items-center w-100"
        onClick={(e) => e.stopPropagation()}
      >
        <input
          className="form-check-input mb-1"
          type="checkbox"
          value=""
          id={`${label}${id}`}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === "Enter") {
              setIsActive(!isActive);
            } else if (e.key === " ") {
              e.preventDefault();
              setIsActive(!isActive);
            }
          }}
          checked={isActive}
          onChange={(e) => {
            setIsActive(e.target.checked);
          }}
          onClick={(e) => e.stopPropagation()}
        />
        <label
          className="form-check-label w-100"
          htmlFor={`${label}${id}`}
          style={{ cursor: "pointer" }}
        >
          {label}
        </label>
      </div>
    </div>
  );
}
