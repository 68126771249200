import React from "react";
import TwoPaneView from "components/common/layout/TwoPaneView";
import styles from "./Statistik.module.scss";
import PageContainer from "components/common/layout/PageContainer";
import Sidebar from "./Sidebar/Sidebar";
import { parseQueryString } from "util/index";
import Fejllister from "./MainContent/Fejllister/Fejllister";
import BBR from "./MainContent/BBR/BBR";
import Pointer from "components/common/icons/Pointer";

export default function Statistik({ location }) {
  const { search } = location;
  const query = parseQueryString(search);
  const { type, statistik } = query;
  const renderMainContent = () => {
    return (
      <PageContainer>
        {type === undefined && statistik === undefined && (
          <div className={styles.placeholder}>
            <span className={styles.arrow}>
              <Pointer width={45} />
            </span>
            <p>Vælg statistik i panelet til venstre</p>
          </div>
        )}
        {type === "bbr" && <BBR />}
        {type === "fejllister" && <Fejllister />}
      </PageContainer>
    );
  };
  return <TwoPaneView sidebar={<Sidebar />} main={renderMainContent()} />;
}
