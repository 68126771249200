import { MutableRefObject, useEffect, useState } from "react";
import styles from "./AlleLister.module.scss";
import opgavelisteApi from "api/opgavelister";
import { motion } from "framer-motion";
import NextPreviousPage from "components/common/NextPreviousPage/NextPreviousPage";
import { useHistory } from "react-router-dom";
import OpgaveListeItem from "./OpgaveListeItem";
import { PageOptions } from "interfaces/paging";
import { useURLSearchParams } from "hooks/useURLSearchParams";
import { useQuery } from "react-query";
import {
  ListeSortOrder,
  OpgaveListeStatus,
} from "interfaces/Opgaveliste/enums";
import IconFilter from "components/common/icons/IconFilter";
import useScrollRestoration from "hooks/useScrollRestoration";

export interface IAlleListerState {
  state: "IDLE" | "LOADING" | "ERROR";
  data?: OpgaveListe[];
}

export default function AlleLister(props: {
  page: number;
  scrollref: MutableRefObject<HTMLDivElement | null>;
}) {
  const [showFilter, setShowFilter] = useState(false);
  const { page } = props;
  const { restoreScroll } = useScrollRestoration(
    "admin?type=lister&handling=alle&page=" + page,
    props.scrollref
  );
  let history = useHistory();
  const query = useURLSearchParams();
  const queryPage = query.get("page");
  const pageSize = process.env.REACT_APP_OPGAVE_PAGE_SIZE
    ? parseInt(process.env.REACT_APP_OPGAVE_PAGE_SIZE)
    : 50;
  const [listeStatus, setListeStatus] = useState<OpgaveListeStatus>(
    OpgaveListeStatus.undefined
  );
  const [totalCount, setTotalCount] = useState(0);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["opgavelister", listeStatus],
    queryFn: async () => {
      const ol = await opgavelisteApi.getAllWithHeaders({
        page: page,
        pageSize: pageSize,
        listeStatus,
        listeSortOrder: ListeSortOrder.NavnAsc,
      });
      const listContentRange = ol.headers["list-content-range"];
      const tc = parseInt(listContentRange.split("/")[1]);
      setTotalCount(tc);
      return ol.data;
    },
    onSuccess: (d) => {
      setTimeout(() => {
        restoreScroll();
      }, 300);
    },
  });

  useEffect(() => {
    //refetch data on page change
    refetch();
  }, [page, refetch]);

  const renderLister = () => {
    if (!data) return null;
    const jsx_elements: JSX.Element[] = data?.map((o: OpgaveListe) => {
      return (
        <li key={o.id}>
          <motion.button
            role="tab"
            aria-controls="opgaveliste-main-content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="list-group-item list-group-item-action" //{styles.opgave}
            title="Lister"
            onClick={() => {
              history.push(
                `/admin?type=lister&handling=rediger&listeid=${o.id}&page=${page}`
              );
            }}
          >
            <OpgaveListeItem opgaveliste={o}></OpgaveListeItem>
          </motion.button>
        </li>
      );
    });
    return (
      <div>
        <ul role="tablist" className="">
          <li className={styles.listHeader}>
            <div className={styles.navn}>
              <h2 className="h4">Navn</h2>
            </div>
            <div className={styles.beskrivelse}>
              <h2 className="h4">Beskrivelse</h2>
            </div>
            <div className={styles.antal}>
              <h2 className="h4">Antal</h2>
            </div>
            <div className={styles.listetype}>
              <h2 className="h4">Listetype</h2>
            </div>
            <div className={styles.status}>
              <h2 className="h4">Status</h2>
            </div>
          </li>
          {jsx_elements}
        </ul>
      </div>
    );
  };
  return (
    <div className={styles.container}>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h1 className="mb-0">Alle Lister</h1>
        <div className="d-flex gap-3">
          <button
            className="c-filter-btn btn border btn--icon tooltip-center tooltip-top"
            onClick={() => setShowFilter(!showFilter)}
            aria-label="Filtrér"
          >
            <IconFilter width={22} />
          </button>
          <NextPreviousPage
            pageOptions={{ page, pageSize }}
            setPageOptions={(options: PageOptions) => {
              if (queryPage && options.page !== parseInt(queryPage)) {
                query.set("page", options.page.toString());
                history.push("/admin?" + query.toString());
              }
            }}
            totalCount={totalCount}
          />
        </div>
      </div>
      {showFilter && (
        <div className="{styles.filters} border border-1 px-3 py-3 rounded mb-4">
          <h2>Filtre</h2>
          <h4 className="fw-normal pt-3 pb-2">Status</h4>
          <div className="d-flex align-items-center gap-2">
            <div className="d-flex gap-5 px-2">
              <div className="form-check ">
                <input
                  className="form-check-input cursor-pointer"
                  type="radio"
                  name="admin-lister-radiogroup"
                  id={OpgaveListeStatus.undefined.toString()}
                  value={OpgaveListeStatus.undefined}
                  checked={
                    listeStatus === OpgaveListeStatus.undefined ? true : false
                  }
                  onChange={(e) => {
                    setListeStatus(OpgaveListeStatus.undefined);
                  }}
                />
                <label
                  className="form-check-label cursor-pointer"
                  htmlFor={OpgaveListeStatus.undefined.toString()}
                >
                  Alle
                </label>
              </div>
              <div className="form-check w-100 ">
                <input
                  className="form-check-input cursor-pointer"
                  type="radio"
                  name="admin-lister-radiogroup"
                  id={OpgaveListeStatus.aktiv.toString()}
                  value={OpgaveListeStatus.aktiv}
                  checked={
                    listeStatus === OpgaveListeStatus.aktiv ? true : false
                  }
                  onChange={(e) => {
                    setListeStatus(OpgaveListeStatus.aktiv);
                  }}
                />
                <label
                  className="form-check-label cursor-pointer"
                  htmlFor={OpgaveListeStatus.aktiv.toString()}
                >
                  Aktiv
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input cursor-pointer"
                  type="radio"
                  name="admin-lister-radiogroup"
                  id={OpgaveListeStatus.passiv.toString()}
                  value={OpgaveListeStatus.aktiv}
                  checked={
                    listeStatus === OpgaveListeStatus.passiv ? true : false
                  }
                  onChange={(e) => {
                    setListeStatus(OpgaveListeStatus.passiv);
                  }}
                />
                <label
                  className="form-check-label cursor-pointer"
                  htmlFor={OpgaveListeStatus.passiv.toString()}
                >
                  Passiv
                </label>
              </div>
              <div className="form-check ">
                <input
                  className="form-check-input cursor-pointer"
                  type="radio"
                  name="admin-lister-radiogroup"
                  id={OpgaveListeStatus.udgaaet.toString()}
                  value={OpgaveListeStatus.udgaaet}
                  checked={
                    listeStatus === OpgaveListeStatus.udgaaet ? true : false
                  }
                  onChange={(e) => {
                    setListeStatus(OpgaveListeStatus.udgaaet);
                  }}
                />
                <label
                  className="form-check-label cursor-pointer"
                  htmlFor={OpgaveListeStatus.udgaaet.toString()}
                >
                  Udgået
                </label>
              </div>
            </div>
          </div>
        </div>
      )}

      {isLoading && <p>Henter lister ...</p>}
      {!isLoading && data && (
        <div className={styles.pagingbottom}>{renderLister()}</div>
      )}
    </div>
  );
}
