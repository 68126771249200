import React from "react";
import styles from "../../Statistik.module.scss";
import Opgavestatus from "./Charts/Opgavestatus";
import Afsluttede from "./Charts/Afsluttede";
import Overblik from "./Charts/Overblik";
// import BrugerStatus from "./Charts/BrugerStatus";
// import KommuneBrugerStatus from "./Charts/KommuneBrugerStatus";
// import KommuneListeStatus from "./Charts/KommuneListeStatus";

export default function Fejllister() {
  return (
    <div className={styles.container}>
      <h1>Fejlliste-statistik</h1>
      <Overblik />
      <Afsluttede type="egne" />
      <Afsluttede type="totalt" />
      <Opgavestatus />
    </div>
  );
}
