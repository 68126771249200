var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ask-component",class:[{
    'is-typing': _vm.isTyping,
    'is-active': _vm.isAutoCompleteActive,
    'has-autocomplete': _vm.hasAutocomplete,
    'has-widgets-autocomplete' : _vm.showHistoryWidget && _vm.isAutoCompleteActive,
    'has-content':_vm.hasContent,
    },
    'iconname-is-' + _vm.getIconName,
    'icontype-is-' + _vm.getIconType
    ],style:({'width': _vm.layout.width}),on:{"keydown":function($event){return _vm.keyDown($event)},"keyup":function($event){return _vm.keyUp($event)},"focusin":function($event){$event.stopPropagation();return _vm.showAutocomplete($event)},"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"ask-box has-shadow"},[_c('div',{staticClass:"ask-searchbox"},[_c('ActionsLeftComponent'),_c('InputBadgeComponent'),_c('div',{staticClass:"ask-searchbox-input"},[_c('input',{ref:"autocompleteinput",attrs:{"type":"text","aria-label":"Søgefelt","placeholder":_vm.placeholder,"readonly":_vm.isReadOnly,"id":_vm.instanceId + '_input',"autocomplete":"off"},domProps:{"value":_vm.inputText},on:{"input":function($event){_vm.inputText = $event.target.value},"focusin":_vm.setIsTyping}})]),_c('ActionsRightComponent')],1),_c('AutoCompleteResultComponent')],1),_c('HistoryWidgetComponent'),_c('SourceWidgetComponent')],1)}
var staticRenderFns = []

export { render, staticRenderFns }