import React from "react";

export default function MapMarker(props: Icon) {
  const { height, width, primaryColor, style = {} } = props;
  const fill = primaryColor ? primaryColor : "currentColor";

  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (19 / 25) : 19}
      height={height ? height : width ? width * (25 / 19) : 25}
      viewBox="0 0 19 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Wireframes"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-423.000000, -250.000000)">
          <path
            d="M431.62104,274.015775 C424.810198,264.142075 423.545979,263.128731 423.545979,259.5 C423.545979,254.529421 427.5754,250.5 432.545979,250.5 C437.516554,250.5 441.545979,254.529421 441.545979,259.5 C441.545979,263.128731 440.281756,264.142075 433.470914,274.015775 C433.023961,274.661432 432.067946,274.661385 431.62104,274.015775 Z M432.545977,263.249999 C434.617055,263.249999 436.295978,261.571075 436.295978,259.499998 C436.295978,257.42892 434.617055,255.749999 432.545977,255.749999 C430.474899,255.749999 428.795978,257.42892 428.795978,259.499998 C428.795978,261.571075 430.474899,263.249999 432.545977,263.249999 Z"
            fill={fill}
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </svg>
  );
}
