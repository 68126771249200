import React, { useState, useEffect, useRef } from "react";
import styles from "./ReadMoreContainer.module.scss";
export default function ReadMoreContainer(props: {
  children?: React.ReactNode;
  maxLength: number;
}) {
  const { children, maxLength } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Adjust content height based on whether it's expanded
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isExpanded
        ? "none"
        : `${maxLength * 1.55}rem`;
    }
  }, [isExpanded, maxLength]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className={styles.container}>
        <div
          id="expandableContent"
          ref={contentRef}
          className={`${styles.content} ${isExpanded ? styles.expanded : ""}`}
        >
          {children}
        </div>
        {!isExpanded ? (
          <div className={styles.overlay} onClick={toggleExpand}>
            <button
              className={`btn link-primary ${styles.btn}`}
              onClick={toggleExpand}
              aria-expanded="false"
              aria-controls="expandableContent"
            >
              Læs mere +
            </button>
          </div>
        ) : (
          <button
            className={`btn link-primary ${styles.btn}`}
            onClick={toggleExpand}
            aria-expanded="true"
            aria-controls="expandableContent"
          >
            Læs mindre -
          </button>
        )}
      </div>
    </>
  );
}
