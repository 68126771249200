import React from "react";
import Hourglass from "components/common/icons/Hourglass";
import Pause from "components/common/icons/Pause";
import Open from "components/common/icons/Open";
import Done from "components/common/icons/Done";

export default function StatusBadge(props: IStatusLabelProps) {
  const { children = <p></p>, type = "default", muted } = props;

  let classNames = "";
  switch (type) {
    case "open":
      classNames = "bg-info-subtle text-info";
      break;
    case "onit":
      classNames = "badge-onit";
      break;
    case "done":
      classNames = "bg-success-subtle text-success";
      break;
    case "pause":
      classNames = "bg-danger-subtle badge-pause text-danger";
      break;
    default:
      break;
  }

  const mergedClassNames = muted ? "text-muted bg-body-tertiary" : classNames;

  return (
    <div
      className={`badge d-flex align-items-center ${mergedClassNames}`}
      style={{
        height: "fit-content",
        minWidth: "80px",
        minHeight: "32px",
      }}
    >
      <div
        className="w-100 d-flex align-items-center justify-content-start"
        style={{ gap: "7px" }}
      >
        {type === "open" && <Open width={15} />}
        {type === "done" && <Done width={15} />}
        {type === "onit" && <Hourglass width={15} />}
        {type === "pause" && <Pause width={15} />}
        <span className="d-flex align-items-center">{children}</span>
      </div>
    </div>
  );
}

interface IStatusLabelProps {
  children?: any;
  style?: React.CSSProperties;
  type?: "default" | "open" | "onit" | "done" | "pause";
  classNames?: string;
  muted?: boolean;
}
