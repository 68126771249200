import React from "react";
import { format } from "date-fns";
import { da } from "date-fns/esm/locale";

export default function ElOplysninger(props: IEnergiOplysningerProps) {
  const { energioplysninger } = props;

  // If the table does not contain any information, don't return anything
  if (
    (energioplysninger && energioplysninger.length === 0) ||
    !energioplysninger
  ) {
    return <></>;
  }

  return (
    <section className="table-container table-container--energi">
      <h5 className="table-heading">Energiforbrug</h5>
      <table className="c-table c-table--energi">
        <thead>
          <tr>
            <th>Forsyningsart</th>
            <th>Energiforbrug</th>
            <th>Fra</th>
            <th>Til</th>
          </tr>
        </thead>
        <tbody>
          {energioplysninger.map((o) => {
            const {
              //bbrId,
              efo005Forsyningsart,
              efo007PeriodeStart,
              efo008PeriodeSlut,
              efo004Måleenhed,
              efo025Mængde,
            } = o;
            let periodeStart = format(
              new Date(efo007PeriodeStart),
              "dd-MM-yyyy",
              {
                locale: da,
              }
            );
            let periodeSlut = format(
              new Date(efo008PeriodeSlut),
              "dd-MM-yyyy",
              {
                locale: da,
              }
            );
            return (
              <tr className="energi" key={JSON.stringify(o)}>
                <td>{efo005Forsyningsart}</td>
                <td>
                  {efo025Mængde}&nbsp;{efo004Måleenhed}
                </td>
                <td>{periodeStart}</td>
                <td>{periodeSlut}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
}

interface IEnergiOplysningerProps {
  energioplysninger: EnergiOplysningerOplysning[];
}
