import React, { useState } from "react";
import styles from "./Organisationer.module.scss";
import organisationerApi from "api/organisation";
import { PageOptions } from "interfaces/paging";
import { useHistory } from "react-router-dom";
import { parseQueryString } from "util/index";
import NextPreviousPage from "components/common/NextPreviousPage/NextPreviousPage";
import { motion } from "framer-motion";
import Organisation from "./Organisation";
import useScrollRestoration from "hooks/useScrollRestoration";
import { useQuery } from "react-query";

export default function Organisationer(props: {
  scrollref: React.MutableRefObject<HTMLDivElement | null>;
}) {
  const history = useHistory();
  const { search } = history.location;
  const query = parseQueryString(search);
  const { id } = query;

  // const pageSize = process.env.REACT_APP_OPGAVE_PAGE_SIZE
  //   ? parseInt(process.env.REACT_APP_OPGAVE_PAGE_SIZE)
  //   : 50;
  const pageSize = 10;
  const [pageOptions, setPageOptions] = useState<PageOptions>({
    page: 1,
    pageSize: pageSize,
  });
  const { restoreScroll } = useScrollRestoration(
    "admin?type=organisationer&page=" + pageOptions.page,
    props.scrollref
  );
  const [totalCount, setTotalCount] = useState(0);

  const {
    data: organisationer,
    refetch,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["organisationer", pageOptions.page],
    queryFn: async () => {
      const ol = await organisationerApi.getAllWithHeaders(pageOptions);
      const listContentRange = ol.headers["list-content-range"];
      const tc = parseInt(listContentRange.split("/")[1]);
      setTotalCount(tc);
      return ol.data;
    },
    onSuccess: (d) => {
      setTimeout(() => {
        restoreScroll();
      }, 300);
    },
  });

  const updateOrganisation = () => {
    refetch();
  };
  return (
    <div className={styles.container}>
      {isError && <div>why?</div>}
      {!isLoading && organisationer && !id && (
        <>
          <div className="d-flex justify-content-between align-items-center mb-5">
            <h1 className="mb-0">Organisationer</h1>
            <NextPreviousPage
              pageOptions={pageOptions}
              setPageOptions={setPageOptions}
              totalCount={totalCount}
            />
          </div>

          <ul role="tablist">
            <li className={styles.listHeader}>
              <div className={styles.navn}>
                <h2 className="h4">Navn</h2>
              </div>
              <div className={styles.cvr}>
                <h2 className="h4">CVR</h2>
              </div>
              <div className={styles.email}>
                <h2 className="h4">Email</h2>
              </div>
            </li>
            {organisationer.map((org, i) => {
              return (
                <li key={org.id}>
                  <motion.button
                    role="tab"
                    aria-controls="artikel-main-content"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className={styles.opgave}
                    title={org.navn}
                    onClick={() => {
                      history.push(`/admin?type=organisationer&id=${org.id}`);
                    }}
                  >
                    <div className={styles.listrow}>
                      <div className={styles.navn}>
                        <h2 className="h4">{org.navn}</h2>
                      </div>
                      <div className={styles.cvr}>{org.cvr}</div>
                      <div className={styles.email}>
                        {org.email ? org.email : ""}
                      </div>
                    </div>
                  </motion.button>
                </li>
              );
            })}
          </ul>
        </>
      )}
      {!isLoading && organisationer && id && (
        <Organisation
          id={id}
          organisation={organisationer.find((o) => o.id === id)}
          history={history}
          updateOrganisation={updateOrganisation}
        />
      )}
    </div>
  );
}
