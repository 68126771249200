import React, { useContext } from "react";
import { Redirect, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import MainContainer from "components/common/layout/MainContainer";
import ListHeader from "./MainContent/ListHeader";
import Sublist from "./Sublist";
import { SearchResultContext } from "context/SearchResultProvider";
import TaskQueryProvider, { TaskQueryContext } from "context/TaskQueryProvider";
import Breadcrumbs from "./Breadcrumbs";
import useScrollRestoration from "hooks/useScrollRestoration";

type RouteParams = { id: string };

export default function FejllisteIDContainer() {
  const { id } = useParams<RouteParams>();
  const { restoreScroll } = useScrollRestoration("fejlliste/" + id);
  return (
    <TaskQueryProvider
      id={id}
      callback={() => {
        setTimeout(() => {
          restoreScroll();
        }, 1000);
      }}
    >
      <FejllisteIDContent id={id} />
    </TaskQueryProvider>
  );
}

function FejllisteIDContent(props: { id: string }) {
  const { id } = props;
  const { redirect } = useContext(SearchResultContext);
  const { loading, taskList: data } = useContext(TaskQueryContext);
  if (loading) renderLoading();
  if (!data) return null;
  return (
    <MainContainer>
      <Breadcrumbs
        currentList={{ navn: data.navn, id: id }}
        authState={undefined}
      />
      {redirect.state === true && <Redirect push to={redirect.path} />}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        id="opgaveliste-main-content"
      >
        {data && <ListHeader opgaveliste={data} />}
        <motion.ul
          className="list-group w-100"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {data && <Sublist opgaveliste={data} />}
        </motion.ul>
      </motion.div>
    </MainContainer>
  );
}
const renderLoading = () => {
  return Array.from(new Array(10)).map((o, i) => {
    return (
      <motion.li key={i} className={`${""}`}>
        <div className={""}>
          <div style={{ width: 100 + "%", height: 80 }} className={""}></div>
        </div>
      </motion.li>
    );
  });
};
