import { SourceBase } from '../../../models/SourceBase';
import { SourceOptions } from '../../../models/SourceOptions';
import { SearchResult, ResultType } from '../../../models/SearchResult';
import { SearchInput } from '../../../models/SearchInput';
import { ResultBehaviour } from '@/models/ResultBehaviour';
import { SourceFilters } from '@/models/SourceFilters';
import { EjendomFetcher } from '../Data/EjendomFetcher';
import { Kommune, KommuneFetcher } from './KommuneFetcher';
import { EjendomsnummerFetcher, Ejendomsrelation, Ejendomsrelationer } from './EjendomsnummerFetcher';
import { EsrToGrundAppender } from './EsrToGrundAppender';
import DafEjendomsnummerFilter from './DafEjendomsnummerFilter';
import { EsrToEjendomAppender } from './EsrToEjendomAppender';
import { DafEjendomsnummerToEjendomExtendedAppender } from './DafEjendomsnummerToEjendomExtendedAppender';
import { DafEjendomsnummerToGrundExtendedAppender } from './DafEjendomsnummerToGrundExtendedAppender';
const defaultOptions: SourceOptions = {
    minimumInputLength: 1,
    maximumInputLength: 12,
    inputDelay: 250,
    resultBehaviour: ResultBehaviour.default,
    hideWhenEmpty: false,
    title: 'Kommunalt ejendomsnummer',
    badgeTitle: 'Ejendomsnr',
    icon: 'icon-source-ejendom',
    resultLimit: 1000,
    fetchExtendedResult: false,
    validInput: /^(0?\d{3}[\s-]?)?\d{1,7}$/,
    isAutoComplete: false,
    placeholderText: 'Kommunekode ejendomsnummer',
    helpText: 'Indtast kommunekode efterfulgt af ejendomsnr.',
    notFoundHelpText: 'Dette ejendomsnr findes ikke, format: [kommunekode ejendomsnr]',
};

const defaultFilters: DafEjendomsnummerFilter = {
    dataResultType: 'ejendomsnummer', // ejendom, //grund,
    extendedResultType: 'ejendom', // ejendom, //grund,
};

export class DafEjendomsnummerSource extends SourceBase {
    public static sourceName: string = 'daf.ejendomsnummer';
    public name: string;
    public options: SourceOptions;
    public filters: DafEjendomsnummerFilter;
    private kommuneKode: string = null;
    private kommuneFetcher: KommuneFetcher = null;
    private ejendomsnummerFetcher: EjendomsnummerFetcher = null;

    constructor(options: SourceOptions, filters: SourceFilters) {
        super();
        this.options = Object.assign({}, defaultOptions, options);
        this.filters = Object.assign({}, defaultFilters, filters);

        if ('kommuneKode' in this.filters && this.filters.kommuneKode) {
            this.kommuneKode = this.filters.kommuneKode;
        }

        this.kommuneFetcher = new KommuneFetcher(this.kommuneKode);
        this.ejendomsnummerFetcher = new EjendomsnummerFetcher(this.options.dafUser, this.options.dafPassword, this.options.dafEjendomsrelationDate);

        this.name = DafEjendomsnummerSource.sourceName;
        if ('name' in this.options && this.options.name) {
            this.name = this.options.name;
        }

        if (this.filters.dataResultType === 'ejendom') {
            this.resultAppenders = [new EsrToEjendomAppender(this)];
        } else if (this.filters.dataResultType === 'grund') {
            this.resultAppenders = [new EsrToGrundAppender(this)];
        }

        if (!!this.options.fetchExtendedResult) {
            if (this.filters.extendedResultType === 'ejendom') {
                this.extendedResultAppender = new DafEjendomsnummerToEjendomExtendedAppender(this);
            } else if (this.filters.extendedResultType === 'grund') {
                this.extendedResultAppender = new DafEjendomsnummerToGrundExtendedAppender(this);
            }
        }
    }

    public async search(input: SearchInput): Promise<SearchResult[]> {
        let results: Ejendomsrelation[] = [];
        const parsedInput = this.parseInput(input);
        let kommune: Kommune = null;
        if(parsedInput.kommunekode){
            kommune = await this.kommuneFetcher.get(parsedInput.kommunekode);
        }

            
        if (kommune) {
            // ejendomsnr and kommunekode comes from user input
            results = await this.ejendomsnummerFetcher.GetEjendomsnumre(
                parsedInput.kommunekode,
                parsedInput.ejendomsnummer || parsedInput.ejendomsnummerUnmodified,
            );
        }

        if (!parsedInput.exact && (parsedInput.ejendomsnummerUnmodified[0] !== '0' || !results || results.length === 0)) {
            results = results.concat(
                await this.ejendomsnummerFetcher.GetEjendomsnumre(
                    null,
                    parsedInput.ejendomsnummerUnmodified,
                ),
            );
        }


        return await EjendomsnummerFetcher.MapEjendomsrelationToSearchResult(results, this.kommuneFetcher, input.text, this.name, this.getResultType('ejendomsrelation'));
    }

    private parseInput(input: SearchInput): EjendomsnummerInput {

        
        const splitvalue = (input.id ? input.id : input.text).split(/\s|-/);
        const result: EjendomsnummerInput = {
            kommunekode: null,
            ejendomsnummer: null,
            ejendomsnummerUnmodified: splitvalue.length === 2 ? splitvalue[1] : splitvalue[0],
            exact: !!this.kommuneKode,
        };


        let kommune = this.kommuneKode;
        if (!kommune && splitvalue.length === 2 && (splitvalue[0].length === 3 || splitvalue[0].length === 4)) {
            kommune = KommuneFetcher.formatKommuneKode(splitvalue[0]);
            result.exact = true;
        }

        if (!kommune) {
            if (result.ejendomsnummerUnmodified[0] === '0' && result.ejendomsnummerUnmodified.length > 4) {
                kommune = result.ejendomsnummerUnmodified.substring(0, 4);
                result.ejendomsnummer = result.ejendomsnummerUnmodified.substring(4);
            } else if(result.ejendomsnummerUnmodified.length > 3) {
                kommune = '0' + result.ejendomsnummerUnmodified.substring(0, 3);
                result.ejendomsnummer = result.ejendomsnummerUnmodified.substring(3);
            }
        }

        result.kommunekode = kommune;

        return result;
    }
}



export interface EjendomsnummerInput {
    kommunekode: string;
    ejendomsnummer: string;
    ejendomsnummerUnmodified: string;
    exact: boolean;
}
