import { get, post, put, getResponse } from "../index";
import { ArtikelPageOptions } from "interfaces/paging";
import { AxiosResponse } from "axios";

const getAll = async (options?: ArtikelPageOptions) => {
  return await (
    await getAllWithHeaders(options)
  ).data;
};
const getAllWithHeaders = async (options?: ArtikelPageOptions) => {
  try {
    if (options) {
      // Default values if omitted by options
      let query = `artikel?`;

      Object.keys(options).forEach((key) => {
        if (key !== "totalCount" && key !== "id") {
          query = query.concat(`&${key}=${options[key]}`);
        }
      });
      return await getResponse<Artikel[]>({
        query: query,
      });
    } else {
      return await getResponse<Artikel[]>({
        query: `artikel?page=1&pageSize=50`,
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
const getArtikelFromId = async (id: Artikel["id"]) => {
  try {
    let artikel = await get<Artikel>({
      query: `artikel/${id}`,
    });
    artikel.dato = new Date(artikel.dato);
    return artikel;
  } catch (error) {
    return Promise.reject(error);
  }
};
const createArtikel = async (artikel: Omit<Artikel, "id">) => {
  try {
    const result = await post({
      query: `artikel`,
      body: artikel,
    });
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

// Omit antalOpgaver, as it should not be edited (It probably can't)
const updateArtikel = async (
  artikel: Omit<Artikel, "id">,
  artikelId: string
) => {
  try {
    return await put({
      query: `artikel/${artikelId}`,
      body: artikel,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

const getAllArtikelTemaWithHeaders = async (options?: ArtikelPageOptions) => {
  try {
    if (options) {
      // Default values if omitted by options
      let query = `artikeltema?`;

      Object.keys(options).forEach((key) => {
        if (key !== "totalCount" && key !== "id") {
          query = query.concat(`&${key}=${options[key]}`);
        }
      });
      return await getResponse<ArtikelTema[]>({
        query: query,
      });
    } else {
      return await getResponse<ArtikelTema[]>({
        query: `artikeltema?page=1&pageSize=50`,
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
const getAllArtikelTema = async (options?: ArtikelPageOptions) => {
  return (await getAllArtikelTemaWithHeaders(options)).data;
};

const createArtikelTema = async (artikelTema: Omit<ArtikelTema, "id">) => {
  try {
    const result = await post({
      query: `artikeltema`,
      body: artikelTema,
    });
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

const api: artikelApi = {
  getAll,
  getAllWithHeaders,
  getArtikelFromId,
  createArtikel,
  updateArtikel,
  getAllArtikelTema,
  getAllArtikelTemaWithHeaders,
  createArtikelTema,
};

export default api;

export interface artikelApi {
  getAll: {
    (options?: ArtikelPageOptions): Promise<Array<Artikel>>;
  };
  getAllWithHeaders: {
    (options?: ArtikelPageOptions): Promise<AxiosResponse<Artikel[]>>;
  };
  getArtikelFromId: {
    (id: Artikel["id"]): Promise<Artikel>;
  };
  createArtikel: {
    (artikel: Omit<Artikel, "id">): Promise<any>; // Response body
  };
  updateArtikel: {
    (artikel: Omit<Artikel, "id">, artikelId: string): Promise<any>;
  };
  getAllArtikelTema: {
    (options?: ArtikelPageOptions): Promise<Array<ArtikelTema>>;
  };
  getAllArtikelTemaWithHeaders: {
    (options?: ArtikelPageOptions): Promise<AxiosResponse<ArtikelTema[]>>;
  };
  createArtikelTema: {
    (artikelTema: Omit<ArtikelTema, "id">): Promise<any>; // Response body
  };
}
