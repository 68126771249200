import React from "react";

export default function Arrow(props: Icon) {
  const { height, width, primaryColor, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (26 / 12) : 26}
      height={height ? height : width ? width * (12 / 12) : 26}
      viewBox="0 0 26 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Landing-page-RET-at-top"
          transform="translate(-352.000000, -387.000000)"
          stroke={primaryColor ? primaryColor : "currentColor"}
          strokeWidth="1.6"
        >
          <g id="Group-2" transform="translate(250.000000, 217.000000)">
            <g id="Group-3" transform="translate(0.000000, 162.000000)">
              <g
                id="Dk-green-arrow"
                transform="translate(102.000000, 8.000000)"
              >
                <path
                  d="M0.8,6.4446 C0.8,6.4446 0.8,5.4446 24.8,6.4446"
                  id="Stroke-1"
                ></path>
                <path
                  d="M15.5617,0.8 C15.5617,0.8 23.8017,4.324 25.2307,6.044"
                  id="Stroke-3"
                ></path>
                <path
                  d="M15.5437,11.0285 C15.5437,11.0285 23.3687,8.0725 24.7997,6.4445"
                  id="Stroke-5"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
