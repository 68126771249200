import { Spacing } from "components/common/layout/Spacing";
import React from "react";
import styles from "../../Statistik.module.scss";
// import { Doughnut, HorizontalBar } from "react-chartjs-2";

export default function BBR() {
  // const data = {
  //   labels: [
  //     "110: Stuehus til landbrugsejendom",
  //     "120: Frittliggende enfamilieshus",
  //     "130: Række-, kede-, eller dobbelthus",
  //     "140: Etageboligbebyggelse",
  //     "150: Kollegium",
  //     "160: Døgninstitution (plejehjem, alderdomshjem, børne- eller ungdomshjem)",
  //     "160: Døgninstitution",
  //     "160: Døgninstitution",
  //     "160: Døgninstitution",
  //     "160: Døgninstitution",
  //     "160: Døgninstitution",
  //     "160: Døgninstitution",
  //     "160: Døgninstitution",
  //     "160: Døgninstitution",
  //   ],
  //   datasets: [
  //     {
  //       label: "Antal bygninger",
  //       data: [
  //         971612,
  //         3823832,
  //         1723987,
  //         902982,
  //         152872,
  //         876356,
  //         276356,
  //         1876356,
  //         3576356,
  //         176356,
  //         2176356,
  //         576356,
  //         1676356,
  //         1876356,
  //       ],
  //       backgroundColor: [
  //         "#0c5767",
  //         "#7a9fa8",
  //         "#66a7c5",
  //         "#b3d8c2",
  //         "#93ad9d",
  //         "#c7dde0",
  //         "#0c5767",
  //         "#7a9fa8",
  //         "#66a7c5",
  //         "#b3d8c2",
  //         "#93ad9d",
  //         "#c7dde0",
  //         "#0c5767",
  //         "#7a9fa8",
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };
  // const options = {
  //   legend: {
  //     position: "bottom",
  //   },
  // };
  return (
    <div className={styles.container}>
      <h1>BBR-statistik</h1>
      <Spacing />
      Kommer her
      {/* <div className={styles.statcontainer}>
        <h2>Bygninger fordelt på andvendelse</h2>
        <Spacing h={1} />
        <HorizontalBar
          data={{
            ...data,
            datasets: [{ ...data.datasets[0] }],
          }}
          options={{
            ...options,
            scales: {
              yAxes: [
                {
                  ticks: {
                    fontSize: 15,
                    fontColor: "#003c16",
                    fontFamily: "Open Sans",
                    callback: function (value, index, values) {
                      if (value.length < 24) return value;
                      return value.substr(0, 24) + " ...";
                    },
                  },
                },
              ],
            },
          }}
        />
      </div>
      <Spacing />
      <div className={styles.statcontainer}>
        <h2>Bygninger fordelt på andvendelse</h2>
        <Spacing h={1} />
        <Doughnut data={data} options={{ ...options }} />
      </div>
      <Spacing /> */}
    </div>
  );
}
