import React, { useContext, useLayoutEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import opgaveApi from "api/opgave";
import { AuthContext } from "context/AuthProvider";
import useMediaQuery from "hooks/useMediaQuery";
import Comment from "components/common/icons/Comment";
import StatusDialog from "components/common/StatusDialog/StatusDialog";

export default function Noter(props: KommentarFeltProps) {
  const { opgaver, setOpgaver, currentOpgave, title } = props;
  const authState = useContext(AuthContext);
  const matches = useMediaQuery("(max-width: 1200px)");

  // Kommentartekst
  const [kommentarText, setKommentarText] = useState(currentOpgave.kommentar);
  const initBeforeUnLoad = (showExitPrompt) => {
    window.onbeforeunload = (event) => {
      // Show prompt based on state
      if (showExitPrompt) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "Hey";
        }
        return "";
      }
    };
    return true;
  };

  useLayoutEffect(() => {
    initBeforeUnLoad(kommentarText !== currentOpgave.kommentar);
  }, [kommentarText, currentOpgave.kommentar]);

  useLayoutEffect(() => {
    // opdater kommentar tekst dersom den er ændret via SignalR (anden bruger har ændret den f ex)
    setKommentarText(currentOpgave.kommentar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOpgave.kommentar]);

  const putKommentarText = async (
    id: string,
    kommentar: Opgave["kommentar"]
  ) => {
    try {
      opgaveApi
        .putKommentarText(
          id,
          currentOpgave.kommentarAdgang,
          kommentar,
          authState
        )
        .then((opgave: Opgave) => {
          // update opgave in opgaver
          const data = opgaver.map((o) => {
            if (o.id === opgave.id) {
              return opgave;
            }
            return o;
          });

          setOpgaver([...data]);
          initBeforeUnLoad(false);
          return data;
        });
    } catch (error) {
      if (error === "CANCEL") {
        // The request was canceled, all is well
        console.debug(error);
      } else {
        return undefined;
      }
    }
  };
  const areTextsEqual = (
    a: string | null | undefined,
    b: string | null | undefined
  ): boolean => {
    if (a === null || a === undefined) {
      a = "";
    }
    if (b === null || b === undefined) {
      b = "";
    }

    if (a === b) {
      return true;
    }

    return false;
  };
  return (
    <StatusDialog
      preventCloseOnSelect={true}
      activeButtonStyle={matches ? true : false}
      dropdownTriggerStyle="text-primary bg-body-tertiary h-100"
      menuClassNames="end-0"
      noArrow={true}
      element={
        <span
          className={`h-100 has-tooltip tooltip-center tooltip-bottom d-flex align-items-center gap-2${
            kommentarText ? " tooltip--fixed-width" : ""
          }`}
          aria-label={kommentarText ? kommentarText : "Ingen noter"}
        >
          <span className="position-relative d-flex align-items-center">
            {kommentarText && (
              <svg
                className="position-absolute"
                style={{ top: -4, right: -4 }}
                width="9"
                height="9"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="4.5"
                  cy="4.5"
                  r="4.5"
                  fill="currentColor"
                  stroke="rgb(244, 247, 246)"
                  strokeWidth="2"
                />
              </svg>
            )}
            <Comment width={18} />
          </span>
          <span className="d-none d-lg-flex fw-semibold fs-6">{title}</span>
        </span>
      }
      menu={[
        <div
          key={currentOpgave.id}
          style={
            matches
              ? { width: "100%", height: "auto" }
              : { width: "300px", height: "auto" }
          }
          className="p-2 rounded"
        >
          <div>
            <textarea
              onBlur={(e) => {
                currentOpgave.id &&
                  putKommentarText(currentOpgave.id, e.target.value);
              }}
              className="w-100 rounded border-light text-muted fs-5 p-2"
              style={{ height: "12.5rem" }}
              placeholder="Indtast noter"
              value={kommentarText ? kommentarText : ""}
              onChange={(e) => {
                setKommentarText(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="fs-5 py-2 px-1" aria-live="polite">
            <span>
              <i>
                Gemmer automatisk{" "}
                {!areTextsEqual(kommentarText, currentOpgave?.kommentar)
                  ? "Du har ugemte ændringer"
                  : ""}
              </i>
              <i>
                <Prompt
                  when={!areTextsEqual(kommentarText, currentOpgave?.kommentar)}
                  message="Du har ugemte ændringer, de gemmes hvis du fortsætter."
                ></Prompt>
              </i>
            </span>
          </div>
        </div>,
      ]}
    />
  );
}

interface KommentarFeltProps {
  opgaver: Opgave[];
  setOpgaver: (opgave: Opgave[]) => void;
  currentOpgave: Opgave;
  icon?: boolean;
  title: string;
}
