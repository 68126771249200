import React from "react";
export default function StickyHeader(props: IStickyHeaderProps) {
  const { children } = props;

  return (
    <header className="c-sticky-header bg-body-tertiary">{children}</header>
  );
}

interface IStickyHeaderProps {
  children: React.ReactNode;
}
