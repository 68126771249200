import { SourceBase } from '../../../models/SourceBase';
import { SourceOptions } from '../../../models/SourceOptions';
import axios, { AxiosResponse } from 'axios';
import { SearchResult, ResultType } from '../../../models/SearchResult';
import { SearchInput } from '../../../models/SearchInput';
import { ResultBehaviour } from '@/models/ResultBehaviour';
import { EjendomFetcher } from '../Data/EjendomFetcher';
import { IAutocompleteResult } from './IAutocompleteResult';
import { AdresseFilters } from './AdresseFilters';
import { sortAdresses } from './AdresseHelpers';
import { DawaAdgangsAdresseToGrundAppender } from './DawaAdgangsAdresseToGrundAppender';
import { DawaAdgangsAdresseToEjendomAppender } from './DawaAdgangsAdresseToEjendomAppender';
import { DawaAdgangsAdresseToEjendomExtendedAppender } from './DawaAdgangsAdresseToEjendomExtendedAppender';
import { DawaAdgangsAdresseToAdresseExtendedAppender } from './DawaAdgangsAdresseToAdresseExtendedAppender';
import { DawaAdgangsAdresseToGrundExtendedAppender } from './DawaAdgangsAdresseToGrundExtendedAppender';
// http://dawa.aws.dk/dok/api/autocomplete#autocomplete
// http://dawa.aws.dk/dok/adresser
const defaultOptions: SourceOptions = {
  minimumInputLength: 2,
  inputDelay: 250,
  resultBehaviour: ResultBehaviour.default,
  hideWhenEmpty: false,
  title: 'Adresse',
  icon: 'icon-source-adresse',
  resultLimit: 20,
  fetchExtendedResult: false,
  validInput: /^(\d{0,4}(\D.*|))$/,
  isAutoComplete: true,
};

const defaultFilters: AdresseFilters = {
  level: 'adresse', // adresse, adgangsadresse, vejnavn
  extendedResultStruktur: 'nestet',
  extendedResultType: 'ejendom', // grund
  sortOrder: 'smart',
  dataResultType: 'adresse', // 'ejendom', grund,
};

export class AdresseSource extends SourceBase {
  get useEjendomResult(): boolean {
    return this.filters.dataResultType === 'ejendom';
  }
  public static sourceName: string = 'dawa.adresse';
  public name: string;
  public options: SourceOptions;
  public filters: AdresseFilters;

  private idIndex: number = 0;
  private url: string =
    'https://dawa.aws.dk/autocomplete?supplerendebynavn=true&stormodtagerpostnumre=true&multilinje=true&fuzzy=&srid=25832';

  constructor(options: SourceOptions, filters: AdresseFilters) {
    super();
    this.options = Object.assign({}, defaultOptions, options);
    this.filters = Object.assign({}, defaultFilters, filters);

    if ('kommuneKode' in this.filters && this.filters.kommuneKode) {
      this.url += '&kommunekode=' + this.filters.kommuneKode;
    }

    this.url += '&per_side=' + (this.options.resultLimit + 1).toString();

    this.url += '&type=' + this.filters.level;

    this.name = AdresseSource.sourceName;
    if ('name' in this.options && this.options.name) {
      this.name = this.options.name;
    }

    if (this.filters.dataResultType === 'grund') {
      this.resultAppenders = [new DawaAdgangsAdresseToGrundAppender(this, 'dawa_' + this.filters.level)];
    } else if (this.filters.dataResultType === 'ejendom') {
      this.resultAppenders = [new DawaAdgangsAdresseToEjendomAppender(this, 'dawa_' + this.filters.level)];
    }

    if (!!this.options.fetchExtendedResult) {
      if (this.filters.extendedResultType === 'ejendom') {
        this.extendedResultAppender = new DawaAdgangsAdresseToEjendomExtendedAppender(this);
      } else if (this.filters.extendedResultType === 'grund') {
        this.extendedResultAppender = new DawaAdgangsAdresseToGrundExtendedAppender(this);
      } else {
        this.extendedResultAppender = new DawaAdgangsAdresseToAdresseExtendedAppender(this);
      }
    }
  }

  public async search(input: SearchInput): Promise<SearchResult[]> {
    const source = this;

    const fromId = await this.getSearchText(input);

    if (
      input.suggestion &&
      input.suggestion.data.type === this.filters.level &&
      this.useEjendomResult
    ) {
      if (fromId === 3) {
        return await this.fetchEjendom(input);
      }
    }

    if (!input.text) {
      return [];
    }

    const result = (await axios.get(source.createUrl(input))) as AxiosResponse<
      IAutocompleteResult[]
    >;

    let resultset = result.data;

    if (source.filters.sortOrder === 'asc') {
      resultset = resultset.sort(sortAdresses);
    }
    return resultset.map((r) => this.searchResultMapper(r));
  }

  private async getSearchText(input: SearchInput): Promise<number> {
    if (!input.id) {
      return 0;
    }

    const ids = input.id.split('_');
    let adgangsadresse = ids[0] === 'aa';
    let id = null;
    if (!adgangsadresse && ids.length === 1) {
      id = ids[0];
      adgangsadresse = this.filters.level === 'adgangsadresse';
    } else {
      id = ids[1];
    }

    if (!id) {
      return 0;
    }

    if (adgangsadresse) {
      const aaResponse = (await axios.get(
        'https://dawa.aws.dk/adgangsadresser/' + id + '?srid=25832',
      )) as AxiosResponse<any>;
      const data = aaResponse.data;

      input.suggestion = {
        id: input.id,
        title: '',
        description: '',
        resultType: ResultType.suggestion,
        source: this.name,
        data: { type: 'adgangsadresse', data: { id } },
        datatype: 'dawa_adgangsadresse',
      };

      input.text =
        data.vejstykke.navn +
        ' ' +
        data.husnr +
        ', ' +
        (data.supplerendebynavn ? data.supplerendebynavn + ', ' : '') +
        data.postnummer.nr +
        ' ' +
        data.postnummer.navn;
      return ids.length;
    } else {
      const aResponse = (await axios.get(
        'https://dawa.aws.dk/adresser/' + id + '?srid=25832',
      )) as AxiosResponse<any>;
      const adata = aResponse.data;
      let adresse = adata.adressebetegnelse as string;
      adresse = adresse.substr(0, adresse.lastIndexOf(','));

      input.suggestion = {
        id: input.id,
        title: adresse,
        description: '',
        resultType: ResultType.suggestion,
        source: this.name,
        data: { type: 'adresse', data: { id } },
        datatype: 'dawa_adresse',
      };
      input.text = adata.adressebetegnelse;
      return ids.length;
    }
  }

  private createUrl(input: SearchInput) {
    let extra: string = '';

    if (input.suggestion && input.suggestion.source === this.name) {
      const suggestion = input.suggestion.data as IAutocompleteResult;
      if (suggestion.type === 'adgangsadresse') {
        extra = '&adgangsadresseid=' + suggestion.data.id;
      } else if (suggestion.type === 'vejnavn') {
        extra = '&startfra=adgangsadresse';
      }
    }

    return (
      this.url +
      encodeURI(
        `&q=${input.text}&caretpos=${input.text.length.toString()}${extra}`,
      )
    );
  }

  private searchResultMapper(result: IAutocompleteResult): SearchResult {
    const newlineSplit = result.forslagstekst.lastIndexOf('\n');
    const text = result.forslagstekst.replace('\n', ' ');
    let title = newlineSplit > -1 ? text.substring(0, newlineSplit) : text;

    let resultType = ResultType.suggestion;
    if (result.type === this.filters.level) {
      if( !this.useEjendomResult){
        resultType = this.getResultType('dawa_' + result.type);
      }
    }else{
      title += ' ';
    }

    return {
      id:
        result.type === 'vejnavn'
          ? (this.idIndex++).toString()
          : (result.type === 'adgangsadresse' ? 'aa_' : 'a_') + result.data.id,
      title: title,
      description:
        newlineSplit > -1 ? text.substring(newlineSplit + 1) : result.type,
      data: result,
      resultType,
      datatype: 'dawa_' + result.type,
      source: this.name,
      inputText: title,
    };
  }

  private async fetchEjendom(input: SearchInput): Promise<SearchResult[]> {
    const ids = input.id.split('_');
    const bfe = parseInt(ids[2], 10);
    const result = await EjendomFetcher.GetFromBFE(
      bfe,
      this.options.dafUser,
      this.options.dafPassword,
      this.options.dafEjendomsrelationDate,
    );

    return result.map((r) =>
      EjendomFetcher.MapDafResultsToSearchResult(
        `${input.suggestion.id}_${r.bfeNummer}`,
        this.name,
        r,
        'bfe',
        this.getResultType('bfe'),
        bfe.toString(),
      ),
    );

  }

}




