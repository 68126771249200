import { ResultType, SearchResult } from '@/models/SearchResult';
import axios from 'axios';
import { formatJson, dafUrl } from '../Data/DafHelpers';
import { KommuneFetcher } from './KommuneFetcher';

export class EjendomsnummerFetcher {
  private user: string = null;
  private password: string = null;
  private parameters: string = null;
  private dafEjendomsrelationDate : string = null;

  constructor(user: string,
              password: string, dafEjdDate) {
    this.user = user;
    this.password = password;
    this.dafEjendomsrelationDate = dafEjdDate;

    this.parameters = [
      formatJson,
      `username=${this.user}`,
      `password=${this.password}`,
    ].join('&');
  }

  public async GetEjendomsnumre(
    kommunekode: string,
    ejendomsnummer: string,
  ): Promise<Ejendomsrelation[]> {

    const parameters = [`ejendomsnummer=${ejendomsnummer}`, this.parameters];
    if (kommunekode) {
      parameters.push(`kommunekode=${KommuneFetcher.formatKommuneKode(kommunekode)}`);
    }

    const response = await axios.get(
      // tslint:disable-next-line:max-line-length
      `${dafUrl}/BBR/BBRPublic/1/rest/ejendomsrelation?${parameters.join('&')}&Status=7&VirkningFra=${this.dafEjendomsrelationDate}&VirkningTil=${this.dafEjendomsrelationDate}`,
    );

    return response.data
    .filter((r) => r.status !== '10' && r.status !== '11')
    .map(
      (r) => ({ bfeNummer: r.bfeNummer,
        ejendomsnummer: r.ejendomsnummer,
        kommune: {kode: r.kommunekode},
      }),
    );

  }

  public static async GetEjendomsnummerFromBFE(
    bfeNumber: number,
    user: string,
    password: string,
    dafEjendomsrelationDate: string,
  ): Promise<Ejendomsrelation[]> {
  
    const response = await axios.get(
      // tslint:disable-next-line:max-line-length
      `${dafUrl}/BBR/BBRPublic/1/rest/ejendomsrelation?bfeNummer=${bfeNumber}&${formatJson}&username=${user}&password=${password}&status=7&virkningFra=${dafEjendomsrelationDate}&virkningTil=${dafEjendomsrelationDate}`,
    );
  
      //// Mapning af kommunekode virker ikke af en eller anden grund
      return response.data
      .filter((r) => r.status !== '10' && r.status !== '11')
      .map(
        (r) => ({ bfeNummer: r.bfeNummer,
          ejendomsnummer: r.ejendomsnummer,
          kommune: {kode: r.kommunekode},
        }),
      );
  }

  public static async MapEjendomsrelationToSearchResult(results: Ejendomsrelation[], kommuneFetcher: KommuneFetcher, inputText: string, sourceName: string, resultType: ResultType): Promise<SearchResult[]> {
    if (!results || results.length === 0) {
      return [];
  }

  for (const ejendom of results) {
      // tslint:disable-next-line:max-line-length
      ejendom.kommune = await kommuneFetcher.get(ejendom.kommune.kode);
  }

  const groupedResult: Map<string, Ejendomsrelationer[]> = (results as Ejendomsrelationer[]).reduce(
      (entryMap, e) =>
          entryMap.set(e.ejendomsnummer + '_' + e.kommune.KomKode,
              [...entryMap.get(e.ejendomsnummer + '_' + e.kommune.KomKode) || [], e]),
      new Map<string, Ejendomsrelationer[]>(),
  );

  return Array.from(groupedResult.values())
      .map((r) => this.searchResultMapper(r, inputText, sourceName, resultType))
      .sort((a, b) => a.description.localeCompare(b.description));
  }

  private static searchResultMapper(result: Ejendomsrelationer[], inputText: string, sourceName: string, resultType: ResultType): SearchResult {
    const firstResult = result[0];
    const title = firstResult.ejendomsnummer.toString();
    const description = `${firstResult.kommune.KomNavn} (${firstResult.kommune.KomKode})` ;

    const searchResult: SearchResult = {
        id: `${firstResult.kommune.KomKode}_${firstResult.ejendomsnummer.toString()}`,
        title,
        description,
        data: result,
        resultType: resultType,
        source: sourceName,
        datatype: 'ejendomsrelation',
        inputText: inputText,
    };

    return searchResult;
}
}



export interface Kommune {
  KomNavn: string;
  KomKode: string;
}


export interface Ejendomsrelation {
  bfeNummer: number;
  ejendomsnummer: number;
  kommune: any;
}

export interface Ejendomsrelationer {
  id?: string;
  bfeNummer: number;
  ejendomsnummer: number;
  kommune: any;
}