export enum EjerforholdsKode {
  "Privatpersoner eller interessentskab" = 10,
  "Alment boligselskab" = 20,
  "Aktie-, anpart- eller andet selskab (undtagen interessent­skab)" = 30,
  "Forening, legat eller selvejende institution" = 40,
  "Privat andelsboligforening" = 41,
  "Den kommune, hvori ejendommen er beliggende" = 50,
  "Anden primærkommune" = 60,
  "Region" = 70,
  "Staten" = 80,
  "Andet, herunder moderejendomme for bebyggelser, der er opdelt i ejerlejligheder samt ejendomme, der ejes af flere kategorier af ejere" = 90,
  "Ikke beregnet" = 99,
}
