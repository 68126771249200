import { CustomSource } from '@/models/Initialize';
import { SourceBase } from '@/models/SourceBase';
import { SourceFilters } from '@/models/SourceFilters';
import { SourceOptions } from '@/models/SourceOptions';
import { CustomWrapperSource } from './Common/CustomWrapperSource';
import { FritekstSource } from './Common/FritekstSource';
import { HistorySource } from './Common/HistorySource';
import { DafEjendomSource } from './DAF/DafEjendomSource';
// import { DawaEjendomSource } from './DAWA/DawaEjendomSource';
import { DawaJordstykkeSource } from './DawaJordstykke/DawaJordstykkeSource';
import { AdresseSource } from './DawaAdresse/DawaAdresseSource';
import { DafEjendomsnummerSource } from './Ejendomsnummer/DafEjendomsnummerSource';

export class SourceFactory {
    public static create(name: string,
                         options: SourceOptions,
                         filters: SourceFilters): SourceBase {
        switch (name) {
            case DafEjendomSource.sourceName:
                return new DafEjendomSource(options, filters);
            case DafEjendomsnummerSource.sourceName:
                return new DafEjendomsnummerSource(options, filters);

            case DawaJordstykkeSource.sourceName:
                return new DawaJordstykkeSource(options, filters);

            case AdresseSource.sourceName:
                return new AdresseSource(options, filters);

            /* COMMON */
            case FritekstSource.sourceName:
                return new FritekstSource(options);

            case HistorySource.sourceName:
                return new HistorySource(options);

            /* END OF COMMON */

            default:
                throw new Error('Unknown source: ' + name);
        }
    }

    public static createCustom(name: string,
                               options: SourceOptions,
                               filters: SourceFilters,
                               source: CustomSource): SourceBase {
        return new CustomWrapperSource(name, options, filters, source);
    }
}
