import { BBRBaseMapGrundDataKeys, BBRBaseMapMarker } from "./BbrBaseMap";
import { StrukturType, Strukturtilstande } from "./enums";

export function anvendelseskode2ikon(anvendelseskode) {
  var stranv = "" + anvendelseskode;
  switch (stranv.charAt(0)) {
    case "1":
      /**
       * 110 - Stuehus til landbrugsejendom
       * 120 - Fritliggende enfamilieshus (parcelhus)
       * 121 - Sammenbygget enfamiliehus
       * 130 - Udgår
       * 131 - Række- og kædehus
       * 132 - Dobbelthus
       * 140 - Etagebolig-bygning, flerfamilehus eller to-familiehus
       * 150 - Kollegium
       * 160 - Boligbygning til døgninstitution
       * 185 - Anneks i tilknytning til helårsbolig.
       * 190 - Anden bygning til helårsbeboelse
       */
      return "helaarsbeboelse";
    case "2":
      switch (anvendelseskode) {
        case 211: // Stald til svin
        case 212: // Stald til kvæg, får, mv.
        case 213: // Stald til fjerkræ
        case 214: // Minkhal
        case 215: // Væksthus
        case 216: // Lade til foder, afgrøder mv.
        case 217: // Maskinhus, garage mv.
        case 218: // Lade til halm, hø mv.
        case 219: // Anden bygning til landbrug mv
          return "landbrug";
        default:
          /**
           * 220 - Udgår
           * 221 - Bygning til industri med integreret produktionsapparat
           * 222 - Bygning til industri uden integreret produktionsapparat
           * 223 - Værksted
           * 229 - Anden bygning til produktion
           * 231 - Bygning til energiproduktion
           * 232 - Bygning til forsyning- og energidistribution
           * 233 - Bygning til vandforsyning
           * 234 - Bygning til håndtering af affald og spildevand
           * 239 - Anden bygning til energiproduktion og -distribution
           */
          return "industri";
      }
    case "3":
      /**
       * 311 - Bygning til jernbane- og busdrift
       * 312 - Bygning til luftfart
       * 313 - Bygning til parkering- og transportanlæg
       * 314 - Bygning til parkering af flere end to køretøjer i tilknytning til boliger
       * 315 - Havneanlæg
       * 319 - Andet transportanlæg
       * 320 - Udgår
       * 321 - Bygning til kontor
       * 322 - Bygning til detailhandel
       * 323 - Bygning til lager
       * 323 - Butikscenter
       * 325 - Tankstation
       * 329 - Anden bygning til kontor, handel og lager
       * 330 - Udgår
       * 331 - Hotel, kro eller konferencecenter med overnatning
       * 332 - Bed & breakfast mv.
       * 333 - Restaurant, café og konferencecenter uden overnatning
       * 334 - Privat servicevirksomhed som frisør, vaskeri, netcafé mv.
       * 339 - Anden bygning til serviceerhverv
       */
      return "erhverv";
    case "4":
      /**
       * 410 - Udgår
       * 411 - Biograf, teater, koncertsted mv.
       * 412 - Museum
       * 413 - Bibliotek
       * 414 - Kirke eller anden bygning til trosudøvelse for statsanerkendte trossamfund
       * 415 - Forsamlingshus
       * 416 - Forlystelsespark
       * 419 - Anden bygning til kulturelle formål
       * 420 - Udgår
       * 421 - Grundskole
       * 422 - Universitet
       * 429 - Anden bygning til undervisning og forskning
       * 430 - Udgår
       * 431 - Hospital og sygehus
       * 432 - Hospice, behandlingshjem, mv.
       * 433 - Sundhedscenter, lægehus, fødeklinik mv.
       * 439 - Anden bygning til sundhedsformål
       * 440 - Udgår
       * 441 - Daginstitution
       * 442 - Servicefunktion på døgninstitution
       * 443 - Kaserne
       * 444 - Fængsel, arresthus mv
       * 448 - Anden bygning til institutionsformål
       * 490 - Udgår
       */
      return "institution";
    case "5":
      switch (anvendelseskode) {
        case 531: // Klubhus i forbindelse med fritid og idræt
        case 532: // Svømmehal
        case 533: // Idrætshal
        case 534: // Tribune i forbindelse med stadion
        case 535: // Rideskole
        case 539: // Anden bygning til idrætformål
          return "idraetsformaal";
        default:
          /**
           * 510 - Sommerhus
           * 521 - Feriecenter, center til campingplads mv.
           * 522 - Bygning med ferielejligheder til erhvervsmæssig udlejning
           * 523 - Bygning med ferielejligheder til eget brug
           * 529 - Anden bygning til ferieformål
           * 540 - Kolonihavehus
           * 585 - Anneks i tilknytning til fritids- og sommerhus
           * 590 - Anden bygning til fritidsform
           */
          return "fritidsformaal";
      }
    case "9":
      switch (anvendelseskode) {
        case 910: // Garage
        case 920: // Carport
          return "carport-garage";
        case 930: // Udhus
        case 940: // Drivhus
        case 950: // Fritliggende overdækning
        case 960: // Fritliggende udestue
        case 990: //
          return "smaabygning";
        case 970: // Tiloversbleven landbrugsbygning
          return "landbrug";
        default: // 999 - Ukendt bygning eller ingen anvendelse (null)
          return "smaabygning";
        // return "ukendt-bygning";
      }
    default:
      return "smaabygning";
    // return "ukendt-bygning";
  }
}

// Tager en klassifikation (tek anl.) og omsætter til et ikon-navn - se #1771
export function klassifikationskode2ikon(klassifikationskode, indhold) {
  var strklas = "" + klassifikationskode;
  indhold = parseInt("0" + indhold, 10);
  switch (strklas) {
    case "1110":
      /**
       * Tank (Produkt på væskeform)10 – Mineralske olieprodukter (Olietankbekendtgørelsens §6, stk. 1 nr. 13)
       * 11 - Fuelolie (”tung fuelolie” – kræver opvarmning)
       * 12 - Fyringsgasolie
       * 13 - Autogasolie (Dieselolie)
       * 14 - Benzin
       * 20 - Biobrændstoffer (Organiske olieprodukter som f.eks. rapsolie, bioethanol m.v.)
       * 30 - Affaldsprodukter
       * 31 - Oliebaserede affaldsprodukter (Spildolie)
       */
      if (indhold > 0 && indhold <= 31) {
        return "olietank";
      }
      /**
       * Tank (Produkt på væskeform)
       * 40 - Gylle
       * 50 - Ajle, ensilagesaft, mælkerumsvand eller møddingvand
       * 60 - Øvrige stoffer, produkter og materialer der kan forurene grundvand, jord og undergrund (§ 19)
       * 99 - Andet (f.eks. foderstoffer m.v)
       */
      return "tank"; // alm tank

    case "1120": // Silo (Produkt på fast form)
      return "silo";

    case "1130": // Gasbeholder (Produkt på gasform)
      return "halmfyr-biogas-mv";
    case "1140": // Affaldsbeholder
      return "silo";
    case "1210": // Vindmølle (elproducerende)
      return "vindmoelle";
    case "1220": // Slanger til jordvarme
    case "1230": // Solcelleanlæg
    case "1235": // Solvarmeeanlæg
    case "1240": // Nødstrømsforsyningsanlæg
    case "1250": // Transformerstation
    case "1260": // Elskab
      return "energi";
    case "1265": // Naturgasfyr
      return "halmfyr-biogas-mv";
    case "1270": // Andet energiproducerende eller - distribuerende anlæg
      return "energi";
    case "1275": // Halmfyr
    case "1280": // Biogasanlæg
      return "halmfyr-biogas-mv";
    case "1310": // Vandtårn
    case "1320": // Pumpestation
    case "1330": // Swimmingpool
    case "1340": // Private rensningsanlæg f.eks. pileanlæg, nedsivningsanlæg
    case "1350": // Offentlige rensningsanlæg
    case "1360": // Regnvandsanlæg
      return "vandanlaeg";
    case "1905": // Legeplads
    case "1910": // Teknikhus
    case "1915": // Døgnpostboks
    case "1920": // Køleanlæg (herunder aircondition)
    case "1925": // Kunstværk (springvand, mindesmærker m.v.)
    case "1930": // Kunstværk (springvand, mindesmærker m.v.)
    case "1935": // Skilt
    case "1940": // Antenne / mast fx tv, radio- og telekommunikation
      return "andet-anlaeg";
    case "1945": // Dambrug
      return "vandanlaeg";
    case "1955": // Andet teknisk anlæg
      return "andet-anlaeg";
    case "1950": // Møddingsanlæg
      return "ensilage-planlager";
    case "1960": // Ensilageanlæg
    case "1965": // Planlager
      return "ensilage-planlager";
    case "1970": // Fortidsminde, historisk ruin
      return "andet-anlaeg";
    default:
      return "ukendt-TEK";
  }
}

const wkt_pattern = /\(.*?\)/i;

const getColor = (sikkerhedsklassifikation: string) => {
  switch (sikkerhedsklassifikation) {
    case "1":
      return "sikker";
    case "3":
      return "usikker";
    default:
      return "standard";
    // har også mulighed for "signatur" og hex farve
  }
};

export const constructGrunddataKeysFromEjendom = (
  ejendom: Ejendom | undefined
): BBRBaseMapGrundDataKeys[] => {
  if (!ejendom || !ejendom.grunde) return [];
  return ejendom.grunde.reduce((acc, grund) => {
    const keys = grund.jordstykker.map((jordstykke) => {
      return {
        landsejerlavskode: jordstykke.ejerlavskode,
        matrikelNr: jordstykke.matrikelNummer,
      };
    });
    acc.push(...keys);
    return acc;
  }, [] as BBRBaseMapGrundDataKeys[]);
};

export function constructMarkersFromEjendom(data: Ejendom): Array<any> {
  const markers: Array<BBRBaseMapMarker> = [];
  data.grunde.forEach((grund: Grund, idx) => {
    // Bygninger
    grund.bygninger.forEach((bygningobj, idx) => {
      const bygning = bygningobj.bygning;
      const geom = bygning.byg404Koordinat.match(wkt_pattern);
      const x = geom
        ? parseFloat(geom[0].replace("(", "").replace(")", "").split(" ")[0])
        : 0;
      const y = geom
        ? parseFloat(geom[0].replace("(", "").replace(")", "").split(" ")[1])
        : 0;
      markers.push({
        id: bygning.id_lokalId,
        x,
        y,
        shortname: bygning.byg007Bygningsnummer?.toString() ?? "",
        icon: anvendelseskode2ikon(bygning.byg021BygningensAnvendelse),
        color: getColor(bygning.byg134KvalitetAfKoordinatsæt),
        warning: false,
        title: bygning.byg021BygningensAnvendelseCLV?.title ?? "Bygning",
      });
    });
    // Tekniske anlæg
    grund.tekniskeAnlaeg.forEach((tekobj, idx) => {
      const tekniskAnlaeg: BBRTekniskAnlaeg = tekobj.tekniskAnlaeg;
      const geom = tekniskAnlaeg.tek109Koordinat.match(wkt_pattern);
      const x = geom
        ? parseFloat(geom[0].replace("(", "").replace(")", "").split(" ")[0])
        : 0;
      const y = geom
        ? parseFloat(geom[0].replace("(", "").replace(")", "").split(" ")[1])
        : 0;

      markers.push({
        id: tekniskAnlaeg.id_lokalId,
        x,
        y,
        shortname: tekniskAnlaeg.tek007Anlægsnummer
          ? "T" + tekniskAnlaeg.tek007Anlægsnummer?.toString()
          : "",
        icon: klassifikationskode2ikon(
          tekniskAnlaeg.tek020Klassifikation,
          tekniskAnlaeg.tek034IndholdOlietank
        ),
        color: getColor(tekniskAnlaeg.tek077KvalitetAfKoordinatsæt),
        warning: false,
        title: tekniskAnlaeg.tek020KlassifikationCLV?.title ?? "Teknisk anlæg",
      });
    });
  });
  return markers;
}

/**
 * Blev brugt i RetBBRKort-komponenten
 */
export function constructStrukturerFromEjendom(data): Array<JO.Struktur> {
  let strukturer: Array<JO.Struktur> = [];
  data.grunde.forEach((grund, idx) => {
    // Bygninger
    grund.bygninger.forEach((bygningobj, idx) => {
      let struktur = {} as any;
      try {
        let bygning = bygningobj.bygning;
        let x: number;
        let y: number;

        let geom = bygning.byg404Koordinat.match(wkt_pattern);

        x = geom[0].replace("(", "").replace(")", "").split(" ")[0];
        y = geom[0].replace("(", "").replace(")", "").split(" ")[1];

        struktur.id = bygning.id_lokalId;
        struktur.anvendelseskode = bygning.byg021BygningensAnvendelse;
        struktur.anvendelse = bygning.byg021BygningensAnvendelseCLV.title;
        struktur.matrikelId = `${grund.jordstykker[0].ejerlavskode}-${grund.jordstykker[0].matrikelNummer}`;
        struktur.sikkerhedsklassifikation = "Sikker";
        struktur.tilstand = Strukturtilstande.StamData;
        struktur.strukturType = StrukturType.Bygning;
        struktur.nummer = bygning.byg007Bygningsnummer.toString();
        struktur.maaFlyttes = false;

        struktur.oprindeligGeometri = {
          x: x,
          y: y,
        };
        strukturer.push(struktur);
      } catch (error) {
        return [];
      }
    });
    // Tekniske anlæg
    grund.tekniskeAnlaeg.forEach((teobj, idx) => {
      let struktur = {} as any;
      try {
        let tekniskAnlaeg = teobj.tekniskAnlaeg;
        let x: number;
        let y: number;

        let wkt_pattern = /\(.*?\)/i;

        let geom = tekniskAnlaeg.tek109Koordinat.match(wkt_pattern);

        x = geom[0].replace("(", "").replace(")", "").split(" ")[0];
        y = geom[0].replace("(", "").replace(")", "").split(" ")[1];
        struktur.id = tekniskAnlaeg.id_lokalId;
        struktur.anvendelseskode = tekniskAnlaeg.tek020Klassifikation;
        struktur.anvendelse = tekniskAnlaeg.tek020KlassifikationCLV.title;
        struktur.matrikelId = `${grund.jordstykker[0].ejerlavskode}-${grund.jordstykker[0].matrikelNummer}`;
        struktur.sikkerhedsklassifikation = "Sikker";
        struktur.tilstand = Strukturtilstande.StamData;
        struktur.strukturType = StrukturType.TekniskAnlæg;
        struktur.nummer = tekniskAnlaeg.tek007Anlægsnummer.toString();
        struktur.maaFlyttes = false;

        struktur.oprindeligGeometri = {
          x: x,
          y: y,
        };
        strukturer.push(struktur);
      } catch (error) {
        return [];
      }
    });
  });
  //return grunde;
  return strukturer;
}
