import { getResponse, get, put } from "../index";
import { PageOptions } from "interfaces/paging";
import { AxiosResponse } from "axios";

const getAll = async (options?: PageOptions) => {
  return await (await getAllWithHeaders(options)).data;
};

const getAllWithHeaders = async (options?: PageOptions) => {
  try {
    if (options) {
      // Default values if omitted by options
      let query = `organisation?`;

      Object.keys(options).forEach((key, i) => {
        if (i !== 0) query += "&";
        query = query.concat(`${key}=${options[key]}`);
      });
      return await getResponse<Organisation[]>({
        query: query,
      });
    } else {
      return await getResponse<Organisation[]>({
        query: `organisation?page=1&pageSize=50`,
      });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
const getById = async (id: string) => {
  try {
    return await get<Organisation>({
      query: `organisation/${id}`,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateOrganisation = async (id: string, organisation: Organisation) => {
  try {
    return await put<Organisation>({
      query: `organisation/${id}`,
      body: organisation,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

const api: OrganisationApi = {
  getAll,
  getAllWithHeaders,
  getById,
  updateOrganisation,
};

export default api;

export interface OrganisationApi {
  getAll: {
    (options?: PageOptions): Promise<Organisation[]>;
  };
  getAllWithHeaders: {
    (options?: PageOptions): Promise<AxiosResponse<Organisation[]>>;
  };
  getById: {
    (id: string): Promise<Organisation>;
  };
  updateOrganisation: {
    (id: string, organisation: Organisation): Promise<Organisation>;
  };
}
