import React from "react";

export default function BBRAdvarsel(props: Icon) {
  const { height, width } = props;
  return (
    <svg
      width={width ? width : height ? height : 48}
      height={height ? height : width ? width : 48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.2186 44L24 3L0.781372 44H47.2186Z"
        fill="#FFD400"
      />
      <path
        d="M22.2139 39.6722V36.0278H25.786V39.6722H22.2139ZM22.9953 33.2944L22.1023 19.6278V13.9333H25.8976V19.6278L25.0046 33.2944H22.9953Z"
        fill="black"
      />
    </svg>
  );
}
