import { ExtendedResultAppender } from '@/models/ExtendedResultAppender';
import { SearchResult } from '@/models/SearchResult';
import { SourceBase } from '@/models/SourceBase';
import { GrundFetcher } from '../Data/GrundFetcher';

export class BfeToGrundExtendedAppender extends ExtendedResultAppender {
    public grundFetcher: GrundFetcher;
    public source: SourceBase;

    private extentDataTypes: string[] = [
        'bfe',
        'grund',
    ];

    constructor(source: SourceBase) {
        super();
        this.source = source;
        this.grundFetcher = new GrundFetcher(
            source.options.dafUser,
            source.options.dafPassword,
            source.options.dafEjendomsrelationDate);
    }

    public CanExtentResult(resultDataType: string): boolean {
        return this.extentDataTypes.indexOf(resultDataType) > -1;
    }

    public async getExtendedResult(searchresult: SearchResult, source: SourceBase): Promise<SearchResult> {
        if (searchresult.datatype === 'grund') {
            return searchresult;
        } else if (searchresult.datatype === 'bfe') {

            const grunde = await this.grundFetcher.GetFromBfe(
                parseInt(searchresult.id, 10),
            );

            return Object.assign({}, searchresult, {
                data: grunde.map((e) =>
                    GrundFetcher.MapToSearchResult(null, source.name, e,
                        source.getResultType('grund')),
                ),
            });
        } else {
            return null;
        }
    }
}
