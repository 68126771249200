import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "../components/common/FoldOutSection/FoldOutSection.module.scss";
// import Minus from "../icons/Minus";
// import Plus from "../icons/Plus";
// import MinusSimple from "../icons/MinusSimple";
// import PlusSimple from "../icons/PlusSimple";
import MinusIcon from "../components/common/icons/MinusIcon";
import PlusIcon from "../components/common/icons/PlusIcon";

export default function DropDownSection(props: IFoldOutsection) {
  const { element, opgaveIcon, children, isOpen, nestingLevel = 1 } = props;
  const [open, setOpen] = useState(isOpen ? true : false);

  const variants = {
    open: { height: "auto" },
    closed: { height: 0 },
  };

  const renderHeaderButton = () => {
    if (nestingLevel === 3) {
      return (
        <button
          className="btn d-flex justify-content-start align-items-center gap-2 px-2"
          style={{ minHeight: "2.4rem" }}
          aria-expanded={open} // NB Toggle
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div className="d-flex align-items-center">
            {open ? <MinusIcon width={16} /> : <PlusIcon width={16} />}
          </div>
          {element}
        </button>
      );
    }
    return (
      <button
        className="btn d-flex justify-content-start align-items-center gap-2 px-2"
        aria-expanded={open}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="d-flex align-items-center">
          {open ? <MinusIcon width={16} /> : <PlusIcon width={16} />}
        </div>
        {element}
        <div className={styles.opgaveIcon}>{opgaveIcon}</div>
      </button>
    );
  };
  return (
    <div
      className={
        nestingLevel === 3
          ? `rounded-2 shadow-sm d-inline-block ${
              open ? "border border-primary" : "border"
            }`
          : `border rounded-2 shadow-sm d-inline-block`
      }
    >
      {renderHeaderButton()}
      <AnimatePresence>
        {open && (
          <motion.div
            aria-hidden="false"
            style={{ overflowY: "hidden" }}
            variants={variants}
            initial="closed"
            animate="open"
            exit={variants.closed}
          >
            {children}
          </motion.div>
        )}
        {!open && (
          <motion.div
            aria-hidden="true"
            style={{ overflow: "hidden" }}
            variants={variants}
            initial="closed"
            animate="closed"
            exit={variants.closed}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

interface IFoldOutsection {
  nestingLevel?: 1 | 2 | 3;
  element: JSX.Element;
  elementClassNames?: string;
  children: React.ReactNode;
  opgaveIcon?: React.ReactNode;
  sectionClassNames?: string;
  isOpen?: boolean;
}
