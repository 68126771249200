import React from "react";

export default function Done(props: Icon) {
  const { height, width, style } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height : 16}
      height={height ? height : width ? width : 16}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <path
        d="M73.865-878.7a8.124,8.124,0,0,1-3.159-.611,7.79,7.79,0,0,1-2.539-1.686,7.789,7.789,0,0,1-1.686-2.539,8.123,8.123,0,0,1-.611-3.159,8.061,8.061,0,0,1,.611-3.145,7.866,7.866,0,0,1,1.686-2.539,7.873,7.873,0,0,1,2.539-1.7,8.013,8.013,0,0,1,3.159-.62,7.43,7.43,0,0,1,2.122.305,7.6,7.6,0,0,1,1.951.9.621.621,0,0,1,.348.443.643.643,0,0,1-.117.556.768.768,0,0,1-.5.316.973.973,0,0,1-.6-.078,5.8,5.8,0,0,0-1.53-.672,6.228,6.228,0,0,0-1.671-.227,6.269,6.269,0,0,0-4.61,1.849,6.254,6.254,0,0,0-1.855,4.616,6.254,6.254,0,0,0,1.855,4.616,6.269,6.269,0,0,0,4.61,1.849,6.283,6.283,0,0,0,4.6-1.844,6.2,6.2,0,0,0,1.86-4.589q0-.3-.024-.583a3.637,3.637,0,0,0-.09-.556.92.92,0,0,1,.126-.605.661.661,0,0,1,.484-.316.733.733,0,0,1,.6.125.718.718,0,0,1,.311.521q.055.332.094.677a6.337,6.337,0,0,1,.039.7,8.013,8.013,0,0,1-.62,3.159,7.873,7.873,0,0,1-1.7,2.539,7.868,7.868,0,0,1-2.539,1.686,8.062,8.062,0,0,1-3.145.611ZM72.7-885.218l7.524-7.535a.762.762,0,0,1,.545-.217.792.792,0,0,1,.553.217.794.794,0,0,1,.237.564.758.758,0,0,1-.237.553l-8.074,8.074a.777.777,0,0,1-.553.228.74.74,0,0,1-.542-.228l-2.235-2.254a.7.7,0,0,1-.208-.538.8.8,0,0,1,.227-.549.774.774,0,0,1,.554-.217.737.737,0,0,1,.543.217Z"
        transform="translate(-65.87 894.696)"
      />
    </svg>
  );
}
