import { ExtendedResultAppender } from '@/models/ExtendedResultAppender';
import { SearchResult } from '@/models/SearchResult';
import { SourceBase } from '@/models/SourceBase';
import { EjendomFetcher } from '../Data/EjendomFetcher';
import { GrundFetcher } from '../Data/GrundFetcher';

export default class DawaJordstykkeToGrundExtendedAppender extends ExtendedResultAppender {
    public grundFetcher: GrundFetcher;
    public source: SourceBase;

    private extentDataTypes: string[] = [
        'dawa_jordstykke',
        'grund',
    ];

    constructor(source: SourceBase) {
        super();
        this.source = source;
        this.grundFetcher = new GrundFetcher(
            source.options.dafUser,
            source.options.dafPassword,
            source.options.dafEjendomsrelationDate);
    }

    public CanExtentResult(resultDataType: string): boolean {
        return this.extentDataTypes.indexOf(resultDataType) > -1;
    }

    public async getExtendedResult(result: SearchResult, source: SourceBase): Promise<SearchResult> {
        if (result.datatype === 'grund') {
            return result;
        } else if (result.datatype === 'dawa_jordstykke') {
            const grunde = await this.grundFetcher.GetFromJordstykke(
                result.data.jordstykke.ejerlav.kode,
                result.data.jordstykke.matrikelnr);

            return Object.assign({}, result, {
                data: grunde.map((e) =>
                GrundFetcher.MapToSearchResult(
                        null,
                        source.name,
                        e,
                        source.getResultType('grund'))),
            });
        } else {
            return null;
        }
    }
}
