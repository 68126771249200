import React, { useState, useEffect } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";
import styles from "./Home.module.scss";
import Bbr from "components/common/icons/Bbr";
import Vurdst from "components/common/icons/Vurdst";
import ExternalArrow from "components/common/icons/ExternalArrow";
import Search from "components/Search/Search";
import artikelApi from "api/artikler";
import { motion } from "framer-motion";
import { ArtikelPageOptions } from "interfaces/paging";
import { mergeClassNames } from "util/index";
import { sanitize } from "dompurify";
import { format } from "date-fns";
import { da } from "date-fns/esm/locale";
import { AuthState } from "context/AuthProvider";
import ArrowRight from "components/common/icons/ArrowRight";
// import Arrow from "components/common/icons/Arrow";
// import Button from "components/common/Buttons/Button";
// import { size } from "styles/theme";

interface HomeProps {
  authState: AuthState;
}

export default function Home(props: HomeProps) {
  const { authState } = props;
  const { bruger } = authState;
  const roller = bruger ? bruger.roller : [];
  let history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [result, setResult] = useState(undefined);
  const [redirect, setRedirect] = useState<{
    state: boolean;
    path: string;
  }>({ state: false, path: "/" });

  const [forsideArtikler, setForsideArtikler] = useState<{
    state: "LOADING" | "IDLE" | "OK" | "ERROR";
    data?: Artikel[];
  }>({ state: "LOADING" });

  useEffect(() => {
    let stillMounted = true;
    const fetchData = async () => {
      try {
        const pageoptions: ArtikelPageOptions = {
          page: 1,
          pageSize: 99,
          trimindhold: true, // Forkort indholdet.
          forside: true,
          offentlig: true,
        };
        const ol = await artikelApi.getAll(pageoptions);
        if (stillMounted) {
          setForsideArtikler({ state: "OK", data: ol });
        }
      } catch (error) {
        if (error === "CANCEL") {
          // The request was canceled, all is well
          console.debug(error);
        } else {
          if (stillMounted) {
            // Request failed, set error state
            setForsideArtikler((s) => {
              return { ...s, state: "ERROR" };
            });
          }
        }
      }
    };
    fetchData();
    return () => {
      // The return function is called when
      // the component unmounts. We use the
      // stillmounted flag to avoid attempting
      // to set state on an unmounted component
      stillMounted = false;
    };
  }, []);

  const renderAuthenticatedPage = () => {
    return (
      <div className={styles.container}>
        <div className="container-fluid pt-6">
          {/* m-w-1000 */}
          <div className="container-lg pt-6 pb-8 ">
            <h2 className="fw-normal pb-4">Find ejendom</h2>
            <div>
              <Search
                onResult={(item) => {
                  setResult(item);
                  try {
                    let bfe =
                      item["data"][0] !== undefined
                        ? item["data"][0]["data"]["bfeNummer"]
                        : item["data"]["bfeNummer"];
                    if (bfe !== undefined) {
                      setRedirect({ state: true, path: "/ejendom?bfe=" + bfe });
                    } else {
                      redirect.state = false;
                    }
                  } catch (err) {
                    setRedirect({ state: false, path: "" });
                  }
                }}
              />
              {redirect.state && <Redirect push to={redirect.path} />}
              {result && (
                <>
                  <p>Intet BFE-nummer fundet ved søgning.</p>
                </>
              )}
            </div>
          </div>
        </div>
        {roller.findIndex((role) => role.navn === "fejllister") > -1 && (
          <div className="container-fluid bg-secondary-subtle">
            {/* m-w-1000 */}
            <div className="container-lg pt-6 pb-8">
              <h2 className="fw-normal pb-4">
                <span>Fejllister</span>
              </h2>
              <div className="row align-items-center pb-4">
                <div className="col-md-8">
                  <p>
                    Fejllister er en fortegnelse over grunde, bygninger, enheder
                    eller tekniske anlæg hvor der potentielt kan være fejl.
                  </p>
                </div>
                <div className="col-md-4 d-flex justify-content-md-end">
                  <button
                    className="btn btn-outline-primary btn-lg py-3 px-4 mt-2 mt-md-0"
                    style={{ minWidth: "250px" }}
                    onClick={() => {
                      history.push("/fejllister");
                    }}
                  >
                    Se fejllister
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {roller.findIndex((role) => role.navn === "fejllister") > -1 &&
          bruger?.erSkatteforvaltningen && (
            <div className="bg-body-tertiary container-fluid">
              {/*  m-w-1000 */}
              <div className="container-lg pt-6 pb-8">
                <div className="row align-items-center pb-4 w-100 me-0 ms-0">
                  <div className="col-md-8 g-3">
                    <h2 className="fw-normal">Hjælp til fejllister</h2>
                  </div>
                  <div className="col-md-4 g-3 d-flex justify-content-md-end px-0">
                    <button
                      className="btn btn-outline-primary btn-lg py-3 px-4 mt-2 mt-md-0"
                      style={{ minWidth: "250px" }}
                      onClick={() => {
                        history.push("/hjaelp-til-fejllister");
                      }}
                    >
                      Få hjælp til fejllister
                    </button>
                  </div>
                </div>
                {forsideArtikler.state === "OK" && renderArtikler()}
                {forsideArtikler.state === "LOADING" && renderLoadingArtikler()}
                {forsideArtikler.state === "ERROR" && renderErrorArtikler()}
              </div>
            </div>
          )}
        <footer className="container-fluid bg-white">
          {/* m-w-1000 */}
          <div className="container-lg py-6">
            <div className="row d-flex align-items-center">
              <div className="col-md-6">
                <Bbr width={84} primaryColor="#2f2f2f" />
              </div>
              <div className="col-6 d-flex justify-content-end">
                <Vurdst width={135} />
              </div>
            </div>

            <div className="row w-50">
              <ul className="col-md-6">
                <li className="py-2">
                  <a
                    className="external"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://bbr.dk/persondata"
                  >
                    <span>Persondata</span>
                  </a>
                </li>
                <li className="py-2">
                  <a
                    className="external"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://bbr.dk/cookies"
                  >
                    <span>Cookies</span>
                  </a>
                </li>
              </ul>
              <ul className="col-md-6">
                <li className="py-2">
                  <a
                    className="external"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://ki.bbr.dk"
                  >
                    BBR Kommuneinfo <ExternalArrow />
                  </a>
                </li>
                <li className="py-2">
                  <a
                    className="external"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://teknik.bbr.dk"
                  >
                    BBR Teknik <ExternalArrow />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
    );
  };
  const renderLoadingArtikler = () => {
    return Array.from(new Array(3)).map((o, i) => {
      return (
        <motion.li
          key={i}
          className={mergeClassNames(styles.listitem, styles.loading)}
        >
          <div className={styles.text}>
            <div
              style={{
                width: 20 + Math.random() * 30 + "%",
                height: 30,
                margin: "5px",
              }}
              className={styles.line}
            ></div>
            <div
              style={{ width: 100 + "%", height: 50, margin: "5px" }}
              className={styles.line}
            ></div>
            <div
              style={{
                width: 50 + +(Math.random() * 30) + "%",
                height: 10,
                margin: "10px",
              }}
              className={styles.line}
            ></div>
          </div>
        </motion.li>
      );
    });
  };
  const renderErrorArtikler = () => {
    return (
      <motion.div layout transition={{ damping: 300 }}>
        <h3>En uventet fejl opstod</h3>
      </motion.div>
    );
  };

  const renderArtikler = () => {
    let jsx_elements: JSX.Element[] = [];

    if (forsideArtikler.data && forsideArtikler.data.length > 0) {
      forsideArtikler.data.forEach((artikel, idx) => {
        let parsedDate = format(new Date(artikel.dato), "PPP", {
          locale: da,
        });
        jsx_elements.push(
          <li
            key={artikel.id}
            className="bg-transparent list-group-item list-group-item-action py-4 border-start-0 border-end-0"
          >
            <Link
              className="row d-flex justify-content-between align-items-center"
              to={`/hjaelp-til-fejllister?artikelid=${artikel.id}`}
            >
              <div className="col-md-8 d-flex flex-column gap-2">
                <h2 className="fw-normal">{artikel.titel}</h2>
                <p
                  className="mb-0 overflow-ellipsis-custom"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(artikel.indhold.split(/\r?\n/)[0]),
                  }}
                ></p>

                <p className="mb-0">
                  <small className="fw-normal">Dato: {parsedDate}</small>{" "}
                  <small className="fw-normal">
                    Afsender: {artikel.forfatter}
                  </small>
                </p>
              </div>
              <div className="col-md-4 d-flex justify-content-end pe-6">
                <ArrowRight width={22} />
              </div>
            </Link>
          </li>
        );
      });
    }
    return (
      <ul className="list-group w-100 border-top border-2 rounded-0 border-secondary-subtle">
        {jsx_elements}
      </ul>
    );
  };

  if (authState.state === "LOADING") {
    return <div></div>;
  }
  return <>{renderAuthenticatedPage()}</>;
}
