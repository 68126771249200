import React from "react";

export default function ElOplysninger(props: IElOplysningerProps) {
  const { eloplysninger } = props;
  const { oplysninger } = eloplysninger;
  return (
    <div>
      <h4>Eloplysninger</h4>
      <ul>
        {oplysninger.map((o) => {
          const { antalLeverancestedId, adgangsadresseId } = o;
          return (
            <li key={adgangsadresseId}>
              <p>{antalLeverancestedId} elinstallationer</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

interface IElOplysningerProps {
  eloplysninger: Eloplysninger;
}
