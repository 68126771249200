import { SourceBase } from '../../../models/SourceBase';
import { SourceOptions } from '../../../models/SourceOptions';
import { SearchResult, ResultType } from '../../../models/SearchResult';
import { SearchInput } from '../../../models/SearchInput';
import { ResultBehaviour } from '@/models/ResultBehaviour';
import { SourceFilters } from '@/models/SourceFilters';

const defaultOptions: SourceOptions = {
    minimumInputLength: 1,
    inputDelay: 0,
    hideWhenEmpty: true,
    title: 'Fritekstsøgning',
    icon: 'icon-source-fritekst',
    resultBehaviour: ResultBehaviour.noGroupSimple,
    fetchExtendedResult: true,
    isAutoComplete: true,
};

export class FritekstSource extends SourceBase {
    public static sourceName: string = 'fritekst';
    public name: string;
    public options: SourceOptions;
    public filters: SourceFilters;
    protected readonly kommuneKode: string = '';

    constructor(options: SourceOptions) {
        super();
        this.options = Object.assign({}, defaultOptions, options);

        this.name = FritekstSource.sourceName;
    }

    public search(input: SearchInput): Promise<SearchResult[]> {
        const source = this;
        return new Promise((resolve, reject) => {
            if (input.id) {
                return resolve([]);
            }

            return resolve([{
                id: input.text,
                title: input.text,
                data: input,
                resultType: ResultType.text,
                source: this.name,
            } as SearchResult]);
        });
    }
}
