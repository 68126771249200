import { Initialize, Layout } from '@/models/Initialize';
import { SourceBase } from '@/models/SourceBase';
import { DefaultIconProvider } from '@/models/IconProviders/DefaultIconProvider';
import { SourceFactory } from '@/components/Sources/SourceFactory';

export class InitializeHelper {

    public static Normalize(initialize: Initialize) {
        if (!initialize.instanceId) {
            initialize.instanceId = 'searchcomponentHist';
        }

        if (!initialize.widgets) {
            initialize.widgets = {};
        }

        if (!initialize.events) {
            initialize.events = {};
        }

        if (!initialize.datafordeleren) {
            initialize.datafordeleren = { dafUser: 'RIKFIQTJIV', dafPassword: '!hju7_cWlP8', dafEjendomsrelationDate: '2023-12-31T23:59:59.9999' };
        }
        else {
            const regex = new RegExp('[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(\.[0-9]+)?([Zz]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?');
            if(!regex.test(initialize.datafordeleren.dafEjendomsrelationDate)){
                initialize.datafordeleren.dafEjendomsrelationDate = '2023-12-31T23:59:59.9999';
            }
            if (!(initialize.datafordeleren.dafUser && initialize.datafordeleren.dafUser.trim() 
            && initialize.datafordeleren.dafPassword && initialize.datafordeleren.dafPassword.trim())){
                initialize.datafordeleren.dafUser = 'RIKFIQTJIV';
                initialize.datafordeleren.dafPassword = '!hju7_cWlP8';
            }
        }

        InitializeHelper.NormalizeLocalization(initialize);
        InitializeHelper.NormalizeLayout(initialize);
        InitializeHelper.NormalizeBehaviour(initialize);
    }

    public static ParseSources(initialize: Initialize): SourceBase[] {
        if (!initialize) {
            throw new Error('No parameters');
        }

        const sources: SourceBase[] = [];
        for (const source of initialize.sources) {
            if (source.options && 'visible' in source.options && !source.options.visible) {
                continue;
            }

            const options = Object.assign({}, initialize.datafordeleren, source.options);
            if (source.name && !source.source) {
                sources.push(SourceFactory.create(source.name, options, source.filters));
            } else if (source.source) {
                sources.push(SourceFactory.createCustom(source.name, options, source.filters, source.source));
            }
        }

        return sources;
    }

    private static NormalizeBehaviour(initialize: Initialize): void {
        if (!initialize.behavior) {
            initialize.behavior = {};
        }

        if (initialize.behavior.enterSelectsFirst == null) {
            initialize.behavior.enterSelectsFirst = false;
        }

        if (initialize.behavior.showResultsIfSingleGroup == null) {
            initialize.behavior.showResultsIfSingleGroup = false;
        }

        if (initialize.behavior.highlightSearchResults == null) {
            initialize.behavior.highlightSearchResults = true;
        }
    }

    private static NormalizeLayout(initialize: Initialize): void {
        if (!initialize.layout) {
            initialize.layout = {} as Layout;
        }

        initialize.layout.width = initialize.layout.width ? initialize.layout.width : '600px';

        const possiblePlacements = ['left', 'right', 'none'];
        if (possiblePlacements.indexOf(initialize.layout.searchButtonPlacement) === -1) {
            initialize.layout.searchButtonPlacement = 'right';
        }

        if (possiblePlacements.indexOf(initialize.layout.menuButtonPlacement) === -1) {
            initialize.layout.menuButtonPlacement = 'none';
        }

        if (!initialize.layout.iconProvider) {
            initialize.layout.iconProvider = new DefaultIconProvider();
        }
    }

    private static NormalizeLocalization(initialize: Initialize): void {
        if (!initialize.localization) {
            initialize.localization = {};
        }

        // tslint:disable-next-line:max-line-length
        initialize.localization.placeholder = initialize.localization.placeholder ? initialize.localization.placeholder : 'Søgetekst';
        initialize.localization.clear = initialize.localization.clear ? initialize.localization.clear : 'Ryd';
        // tslint:disable-next-line:max-line-length
        initialize.localization.groupWidgetTitle = initialize.localization.groupWidgetTitle ? initialize.localization.groupWidgetTitle : 'Søgenøgler';
        // tslint:disable-next-line:max-line-length
        initialize.localization.inputMinimumText = initialize.localization.inputMinimumText ? initialize.localization.inputMinimumText : 'Indtast mindst [chars] tegn';
        // tslint:disable-next-line:max-line-length
        initialize.localization.inputMaximumText = initialize.localization.inputMaximumText ? initialize.localization.inputMaximumText : 'Indtast højst [chars] tegn';
        // tslint:disable-next-line:max-line-length
        initialize.localization.searchToGetResults = initialize.localization.searchToGetResults ? initialize.localization.searchToGetResults : 'Søg for at vise resultater';
        initialize.localization.search = initialize.localization.search ? initialize.localization.search : 'Søg';
        // tslint:disable-next-line:max-line-length
        initialize.localization.noResults = initialize.localization.noResults ? initialize.localization.noResults : 'Vi fandt desværre <strong>ingen resultater</strong>';
        // tslint:disable-next-line:max-line-length
        initialize.localization.inputNotValid = initialize.localization.inputNotValid ? initialize.localization.inputNotValid : 'Input format ikke understøttet';
        // tslint:disable-next-line:max-line-length
        initialize.localization.loadingResults = initialize.localization.loadingResults ? initialize.localization.loadingResults : 'Henter resultater';
        // tslint:disable-next-line:max-line-length
        initialize.localization.clearSearch = initialize.localization.clearSearch ? initialize.localization.clearSearch : 'Ryd søgning';
    }
}
