import React from "react";
import styles from "./SidebarItem.module.scss";
import ChevronRight from "components/common/icons/ChevronRight";

export default function SidebarItem(props: ISidebarItemProps) {
  const { title } = props;
  return (
    <div className={styles.titleContainer}>
      <h3 className={styles.titel}>
        {title}{" "}
        <span>
          <ChevronRight />
        </span>
      </h3>
    </div>
  );
}

interface ISidebarItemProps {
  title: string;
}
