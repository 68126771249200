import React, { useState } from "react";
import {
  OpgaveListeType,
  OpgaveBrugerStatus,
} from "interfaces/Opgaveliste/enums";
import StickerIcon from "components/common/icons/StickerIcon";
import StatusBadge from "./StatusBadge";
import ArrowRight from "components/common/icons/ArrowRight";
import useMediaQuery from "hooks/useMediaQuery";

export default function SublistItem({
  opgave,
  goToEjendom,
}: // brugerStatusUI,
// listetype,
// setOpgaveBrugerStatus,
IListItemProps) {
  const [hoverState, setHoverState] = useState(false);
  const matches = useMediaQuery("(max-width: 1000px)");

  return (
    <li
      className="list-group-item list-group-item-action border-0 border-bottom border-1 w-100 py-4 d-flex align-items-center text-muted hover-primary-svg"
      onClick={goToEjendom ? goToEjendom : () => {}}
      style={{ cursor: "pointer" }}
      onMouseEnter={() => {
        setHoverState(true);
      }}
      onMouseLeave={() => {
        setHoverState(false);
      }}
    >
      <div
        className={`d-flex w-100 justify-content-between gap-3 mw-100 ${
          matches ? "flex-wrap gap-4" : ""
        }`}
      >
        <section
          className={`d-flex align-items-start column-gap-3 overflow-hidden${
            matches ? " order-1 w-50" : ""
          }`}
          style={{ minWidth: 250 }}
        >
          <div className="text-muted pt-1 hover-svg">
            <StickerIcon width={24} />
          </div>
          <div className="d-flex flex-column gap-1 mw-100 overflow-hidden">
            <h3 className="text-primary mb-0">BFE: {opgave.bfe}</h3>
            {opgave.adresse && (
              <p
                title={opgave.adresse.betegnelse}
                className="mb-0 fs-5 fw-medium text-primary overflow-hidden text-truncate"
              >
                {opgave.adresse.betegnelse}
              </p>
            )}
            {!opgave.adresse && (
              <p className="mb-0 fs-5 fw-medium text-primary">
                Adresse mangler
              </p>
            )}
          </div>
        </section>
        <section
          className={`d-grid w-100 gap-2 ps-2${matches ? " order-3" : ""}`}
          style={{ gridTemplateColumns: "repeat(3, minmax(0, 1fr))" }}
        >
          {opgave.visningsdata.elementer &&
            opgave.visningsdata.elementer.map((o, idx) => {
              if (o.info.vaerdi === "" && idx <= 2) {
                return (
                  <div className="d-flex flex-column gap-1" key={idx}>
                    <h4 className="fs-6 text-primary">{o.info.navn}</h4>
                    <p className="mb-0 fs-5 text-primary">-</p>
                  </div>
                );
              } else if (idx <= 2) {
                return (
                  <div className="d-flex flex-column gap-1" key={idx}>
                    <h4 className="fs-6 text-primary">{o.info.navn}</h4>
                    <p className="mb-0 fs-5 text-primary">{o.info.vaerdi}</p>
                  </div>
                );
              }
              return null;
            })}
        </section>
        <section
          className={`d-flex align-items-center gap-2 justify-content-end${
            matches ? " order-2" : ""
          }`}
        >
          {opgave?.tilhoererBruger ? (
            <div
              className="c-avatar c-avatar--sm bg-primary"
              aria-label={opgave?.tilhoererBruger?.navn}
            >
              <p className="mb-0 text-white">
                {opgave?.tilhoererBruger?.initialer}
              </p>
            </div>
          ) : (
            <div className="c-avatar c-avatar--sm">
              <p className="mb-0 text-white"></p>
            </div>
          )}
          <div className="d-flex align-items-center gap-3">
            {opgave.brugerStatus === 0 && (
              //   <StatusLabel type="open">Åben</StatusLabel>
              <StatusBadge type="open">Åben</StatusBadge>
            )}
            {opgave.brugerStatus === 2 && (
              <StatusBadge type="done">Færdig</StatusBadge>
            )}
            {opgave.brugerStatus === 1 && (
              <StatusBadge type="onit">I gang</StatusBadge>
            )}
            {opgave.brugerStatus === 5 && (
              <StatusBadge type="pause">Fejl</StatusBadge>
            )}
            <div className="hover-effect" style={{ width: 18 }}>
              {hoverState && <ArrowRight width={18} />}
            </div>
          </div>
        </section>
      </div>
    </li>
  );
}

interface IListItemProps {
  opgave: Opgave;
  setOpgaveBrugerStatus: (
    id: string,
    brugerStatus: OpgaveBrugerStatus
  ) => Promise<Opgave>;
  listetype: OpgaveListeType;
  goToEjendom?: React.MouseEventHandler<HTMLLIElement>;
  brugerStatusUI: OpgaveBrugerStatusUI[];
}
export interface IListItemState {
  headerHovered: boolean;
  toggled: boolean;
}

export type IListItemAction =
  | {
      type: "SET_TOGGLED";
      toggled: boolean;
    }
  | { type: "SET_HOVERED"; hovered: boolean };
