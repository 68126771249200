import React from "react";

export default function BbrLight(props: Icon) {
  const { height, width, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height : 36}
      height={height ? height : width ? width : 30}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 87 51"
    >
      <g fillRule="nonzero" fill="none">
        <path
          fill="currentColor"
          d="M42.639.154L0 16.21v34.034h86.326V16.21z"
        />
      </g>
    </svg>
  );
}
