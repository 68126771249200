import { ResultAppender } from '@/models/ResultAppender';
import { ResultType, SearchResult } from '@/models/SearchResult';
import { SourceBase } from '@/models/SourceBase';
import { Grund, GrundFetcher } from '../Data/GrundFetcher';

export class BfeToGrundAppender extends ResultAppender {
  public grundFetcher: GrundFetcher;
  public source: SourceBase;

  constructor(source: SourceBase) {
      super();
      this.source = source;
      this.grundFetcher = new GrundFetcher(
          source.options.dafUser,
          source.options.dafPassword,
          source.options.dafEjendomsrelationDate);
  }

    public CanAppendResult(resultDataType: string): boolean {
        return resultDataType === 'bfe';
    }

    public async getResults(searchresult: SearchResult, source: SourceBase): Promise<SearchResult[]> {
        const result = await this.grundFetcher.GetFromBfe(
            parseInt(searchresult.id, 10),
          );

        return result.map((r: Grund) =>
          GrundFetcher.MapToSearchResult(
              `${searchresult.id}_${r.id_lokalId}`,
              source.name,
              r,
              source.getResultType('grund'),
            ),
          );

    }
}
