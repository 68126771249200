import React from "react";
import DefaultNav from "./DefaultNav";
import { AuthState } from "../../context/AuthProvider";
import HomeNav from "./HomeNav";

interface NavProps {
  authState: AuthState;
}

export default function Nav(props: NavProps) {
  const { authState } = props;
  return authState.bruger ? <DefaultNav authState={authState} /> : <HomeNav />;
}
