import React, { useState, MutableRefObject } from "react";
import styles from "./AlleArtikler.module.scss";
import artikelApi from "api/artikler";
import { motion } from "framer-motion";
import NextPreviousPage from "components/common/NextPreviousPage/NextPreviousPage";
import { ArtikelPageOptions } from "interfaces/paging";
import { useHistory } from "react-router-dom";
import ArtikelItem from "./ArtikelItem";
import useScrollRestoration from "hooks/useScrollRestoration";
import { useQuery } from "react-query";

export interface IAlleArtiklerState {
  state: "IDLE" | "LOADING" | "ERROR";
  data?: Artikel[];
}

export default function AlleArtikler(props: {
  scrollref: MutableRefObject<HTMLDivElement | null>;
}) {
  let history = useHistory();

  const { restoreScroll } = useScrollRestoration(
    "admin?type=hjaelp-til-fejllister&handling=alle&page=1",
    props.scrollref
  );
  const pageSize = process.env.REACT_APP_OPGAVE_PAGE_SIZE
    ? parseInt(process.env.REACT_APP_OPGAVE_PAGE_SIZE)
    : 50;
  const [pageOptions, setPageOptions] = useState<ArtikelPageOptions>({
    page: 1,
    pageSize: pageSize,
    trimindhold: true,
  });

  const { data: querydata, isLoading } = useQuery({
    queryKey: ["artikler", pageOptions.page],
    queryFn: async () => {
      const ol = await artikelApi.getAllWithHeaders(pageOptions);
      const listContentRange = ol.headers["list-content-range"];
      const tc = parseInt(listContentRange.split("/")[1]);
      return { data: ol.data, totalCount: tc };
    },
    onSuccess: (d) => {
      setTimeout(() => {
        restoreScroll();
      }, 300);
    },
  });

  const renderArtikler = () => {
    if (!querydata) return null;
    let data = querydata.data;

    const jsx_elements: JSX.Element[] = data.map((o: Artikel) => {
      return (
        <li key={o.id}>
          <motion.button
            role="tab"
            aria-controls="artikel-main-content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="list-group-item list-group-item-action" //{styles.opgave}
            title="Hjælp til fejllister"
            onClick={() => {
              history.push(
                `/admin?type=hjaelp-til-fejllister&handling=rediger&artikelid=${o.id}`
              );
            }}
          >
            <ArtikelItem artikel={o}></ArtikelItem>
          </motion.button>
        </li>
      );
    });
    return (
      <div>
        <ul role="tablist">
          <li className={styles.listHeader}>
            <div className={styles.titel}>
              <h2 className="h4">Titel</h2>
            </div>
            <div className={styles.forfatter}>
              <h2 className="h4">Forfatter</h2>
            </div>
            <div className={styles.dato}>
              <h2 className="h4">Dato</h2>
            </div>
            <div className={styles.tema}>
              <h2 className="h4">Tema</h2>
            </div>
            <div className={styles.forside}>
              <h2 className="h4">På forsiden</h2>
            </div>
          </li>
          {jsx_elements}
        </ul>
      </div>
    );
  };
  return (
    <div className={styles.container}>
      <div className="mb-5">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="mb-0">Alle beskrivelser</h1>
          <NextPreviousPage
            pageOptions={pageOptions}
            setPageOptions={setPageOptions}
            totalCount={querydata ? querydata.totalCount : 0}
          />
        </div>
        {isLoading && <p className="pt-3">Henter beskrivelser ...</p>}
      </div>
      {!isLoading && querydata && (
        <div className={styles.pagingbottom}>{renderArtikler()}</div>
      )}
    </div>
  );
}
