import React, { useContext } from "react";
// import styles from "./Header.module.scss";
import { Link } from "react-router-dom";
import FolderIcon from "components/common/icons/Folder";
import ArrowRight from "components/common/icons/ArrowRight";
import { AuthContext } from "context/AuthProvider";

interface ListHeaderProps {
  opgaveliste: OpgaveListe;
}

export default function ListHeader(props: ListHeaderProps) {
  const { opgaveliste } = props;

  const authState = useContext(AuthContext);
  return (
    <div className=" text-primary">
      <div className="d-flex gap-3">
        <FolderIcon width={30} />
        <div className="">
          <div className="pb-3 pt-1">
            <h2 className="">{opgaveliste.navn}</h2>
          </div>
          <div className="">
            <p className="">{opgaveliste?.beskrivelse} </p>
            {authState.bruger?.erSkatteforvaltningen && (
              <div>
                <Link
                  className="d-flex align-items-center gap-2" //internal
                  to={`../hjaelp-til-fejllister?artikelid=${opgaveliste.artikelId}`}
                  target="_blank"
                >
                  <p className="mb-0">
                    Læs dette, før du begynder på fejllisten
                  </p>
                  <span className="fw-bold">
                    <ArrowRight width={19} />
                  </span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
