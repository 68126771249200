import React from "react";
import { motion } from "framer-motion";
import styles from "./Ejer.module.scss";
import { getESRList } from "../../../../util";

export default function Vurderingsejendom(props: IVurderingsejendom) {
  const { ejendom, isLoading } = props;
  return (
    <div className="pe-2">
      <h5 className="fw-semibold text-body">Ejendom</h5>
      {!isLoading && ejendom && (
        <div className="d-flex flex-column">
          <p className="text-muted fs-5 mb-0 fw-semibold">Historisk ESR:</p>
          <p className="mb-0">
            <ESRListe ejendom={ejendom} />
          </p>
          {ejendom.esrRelateredeBfeNr.length > 0 && (
            <p className="text-muted fs-5 mb-0 mt-3 fw-semibold">
              Ejendommen indeholder flere BFE-numre tilknyttet til det
              historiske kommunale ejendomsnummer
            </p>
          )}
        </div>
      )}
      {isLoading &&
        Array.from(new Array(2)).map((o, i) => {
          return (
            <motion.li key={i} className={`${styles.ejer} ${styles.loading}`}>
              <div className={styles.text}>
                <div
                  style={{ width: 30 + Math.random() * 70 + "%" }}
                  className={styles.line}
                ></div>
              </div>
            </motion.li>
          );
        })}
      {!isLoading && !ejendom && <p>Kunne ikke hente historiske ESR-numre.</p>}
    </div>
  );
}

const ESRListe = (props: { ejendom: Ejendom }) => {
  const { ejendom } = props;
  let count_esr = getESRList(ejendom, true).reduce((esr, num) => {
    esr[num] = esr[num] === undefined ? 1 : (esr[num] += 1);
    return esr;
  }, {});

  return (
    <>
      {Object.keys(count_esr)
        .map(function (key, idx) {
          return `${
            count_esr[key]
          } grund${parseInt(count_esr[key]) > 1 ? "e" : ""} med ESR: ${key} \n`;
        })
        .join(", ")}
    </>
  );
};

// Interface
interface IVurderingsejendom {
  ejendom: Ejendom | undefined;
  isLoading: boolean;
}
