import React, { useState, useEffect } from "react";
import styles from "./Bruger.module.scss";
import brugerApi from "api/bruger";
import { Spacing } from "components/common/layout/Spacing";
import Button from "components/common/Buttons/Button";
import Close from "components/common/icons/Close";
import ChevronRight from "components/common/icons/ChevronRight";

interface BrugerProps {
  brugerid: string;
  bruger?: Bruger;
  history: any;
  roller: Rolle[];
  updateBruger: Function;
}
interface BrugerState {
  state: "IDLE" | "LOADING" | "ERROR";
  bruger?: Bruger;
}

export default function Bruger(props: BrugerProps) {
  const { brugerid, bruger, history, roller, updateBruger } = props;
  const [state, setState] = useState<BrugerState>({
    state: bruger ? "IDLE" : "LOADING",
    bruger,
  });

  const [redigerRoller, setRedigerRoller] = useState<boolean>(false);

  useEffect(() => {
    let stillMounted = true;
    const fetchData = async () => {
      try {
        const resp = await brugerApi.getById(brugerid);
        if (stillMounted) {
          setState({ state: "IDLE", bruger: resp });
        }
      } catch (error) {
        if (error === "CANCEL") {
          console.debug(error);
        } else {
          if (stillMounted) {
            setState({ state: "ERROR" });
          }
        }
      }
    };
    if (!bruger) {
      fetchData();
    }
  }, [brugerid, bruger]);

  const [brugerRoller, setBrugerRoller] = useState<Rolle[]>([]);
  useEffect(() => {
    if (state.bruger) {
      if (redigerRoller) {
        setBrugerRoller(state.bruger.roller);
      } else {
        setBrugerRoller([]);
      }
    }
  }, [redigerRoller, state.bruger]);

  const updateUserRoles = async () => {
    setState({ state: "LOADING" });
    try {
      let updatedUser: Bruger | undefined = undefined;
      if (state.bruger) {
        for (let i = 0; i < brugerRoller.length; i++) {
          const br = brugerRoller[i];
          if (
            state.bruger &&
            state.bruger.roller.findIndex((rolle) => rolle.id === br.id) < 0
          ) {
            updatedUser = await brugerApi.addUserRole(state.bruger.id, br.id);
          }
        }

        for (let i = 0; i < state.bruger.roller.length; i++) {
          const r = state.bruger.roller[i];
          if (
            state.bruger &&
            brugerRoller.findIndex((br) => br.id === r.id) === -1
          ) {
            const updatedUserResp = await brugerApi.deleteUserRole(
              state.bruger.id,
              r.id
            );
            updatedUser = updatedUserResp.data;
          }
        }

        setState({
          state: "IDLE",
          bruger: updatedUser ? updatedUser : state.bruger,
        });
        updateBruger(updatedUser);
      }
    } catch (error) {
      setState({ state: "ERROR" });
    }
    // setState({ ...state, bruger: updatedUser });
    setRedigerRoller(false);
  };

  return (
    <div className={styles.container}>
      {state.state === "IDLE" && state.bruger && (
        <div>
          <h3>Navn</h3>
          <p>{state.bruger.navn}</p>
          <h3>Organisation</h3>
          <p>{state.bruger.organisationNavn}</p>
          <h3>Roller</h3>
          <ul>
            {!redigerRoller &&
              state.bruger.roller.length > 0 &&
              state.bruger.roller.map((r) => (
                <li className={styles.rolle} key={r.id}>
                  <p>{r.navn}</p>
                </li>
              ))}
            {redigerRoller &&
              roller.map((r) => (
                <li className={styles.rolle} key={r.id}>
                  <label className={styles.check} style={{ paddingLeft: 0 }}>
                    <span className={styles.checkboxlabel}>{r.navn}</span>
                    <input
                      type="checkbox"
                      name="forside"
                      checked={
                        brugerRoller.findIndex(
                          (brugerrolle) => brugerrolle.id === r.id
                        ) > -1
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setBrugerRoller([...brugerRoller, r]);
                        } else {
                          const index = brugerRoller.findIndex(
                            (br) => br.id === r.id
                          );
                          setBrugerRoller([
                            ...brugerRoller.slice(0, index),
                            ...brugerRoller.slice(index + 1),
                          ]);
                        }
                      }}
                    />
                    <span className={styles.checkbox}></span>
                  </label>
                </li>
              ))}
            {state.bruger.roller.length === 0 && (
              <li>
                <p>Bruger har ingen tilknyttede roller.</p>
              </li>
            )}
          </ul>
          <div className={styles.twobuttons}>
            {redigerRoller && (
              <>
                <Button
                  type="small"
                  classNames={styles.cta}
                  onClick={() => updateUserRoles()}
                >
                  Gem roller
                </Button>
                <Spacing />
                <Button
                  type="small"
                  onClick={() => setRedigerRoller(!redigerRoller)}
                >
                  <span>Afbryd</span>
                  <Spacing right={0.5} />
                  <Close height={15} />
                </Button>
              </>
            )}
            {!redigerRoller && (
              <>
                <Button
                  classNames={styles.cta}
                  type="small"
                  onClick={() => setRedigerRoller(!redigerRoller)}
                >
                  <span>Rediger roller</span>
                </Button>
                <Spacing />
                <div className={styles.dummy}></div>
              </>
            )}
          </div>
        </div>
      )}
      {state.state === "ERROR" && (
        <div>Fandt ikke bruger med id {brugerid}</div>
      )}
      {state.state === "LOADING" && <div>Laster ...</div>}
      <Spacing h={1} />
      <button
        className="{styles.tilbage} d-flex align-items-center gap-2"
        onClick={() => history.push(`/admin?type=brugere`)}
      >
        <ChevronRight /> Tilbage til listen
      </button>
    </div>
  );
}
