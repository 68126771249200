import axios from 'axios';
import { SearchResult, ResultType } from '@/models/SearchResult';
import { dafUrl, format, formatJson } from './DafHelpers';

const samletFastEjendomText: string = 'Samlet fast ejendom';
const ejerLejlighedText: string = 'Ejerlejlighed';
const bpfgText: string = 'Bygning på fremmed grund';

export class EjendomFetcher {
    public static MapDafResultsToSearchResult(
        id: string,
        source: string,
        result: DafResult,
        datatype: string,
        resultType: ResultType,
        inputText: string,
    ): SearchResult {
        return {
            id: `${id ? id : result.bfeNummer}`,
            title: `${result.adresseBetegnelse}`,
            description: [result.typeTekst, result.isModerEjendom ? 'Moderejendom' : '', `BFE-nr.: ${result.bfeNummer}`].filter(a => !!a).join(' | '),
            // tslint:disable-next-line:max-line-length
            // description: `${result.typeTekst}${
            //   result.isModerEjendom ? ' | Moderejendom' : ''
            // }, BFE-nr.: ${result.bfeNummer}`,
            data: result,
            resultType,
            source,
            datatype,
            icon: 'icon-source-ejendom',
            inputText: inputText,
        };
    }

    public static async GetDafResultsFromBFE(
        bfeNumre: number[],
        user: string,
        password: string,
        dafEjendomsrelationDate: string
    ): Promise<DafResult[]> {
        const beliggenhedsResult: Array<Feature<EjendomBeliggenhed>> = [];
        const bfeNumreDist: number[] = [...new Set(bfeNumre.filter(n => !!n))];

        for (let i = 0; i < Math.ceil(bfeNumreDist.length / 200); i++) {
            const iCount = i * 200;
            const bfeString: string = bfeNumreDist
                .slice(iCount, iCount + Math.min(200, bfeNumreDist.length - iCount))
                .join('|');

            const retval = await axios.get(
                // tslint:disable-next-line:max-line-length
                `${dafUrl}/EBR/Ejendomsbeliggenhed/1/REST/Ejendomsbeliggenhed?bfenr=${bfeString}&${format}&username=${user}&password=${password}`,
            );

            beliggenhedsResult.push(...retval.data.features);
        }

        const dafresult: DafResult[] = [];
        for (const beliggenhed of beliggenhedsResult) {
            if (beliggenhed.properties.status.startsWith('g')) {
                const result = {
                    bfeNummer: beliggenhed.properties.bestemtFastEjendomBFENr,
                    adresseBetegnelse: null,
                    type: null,
                    typeTekst: null,
                    isModerEjendom: false,
                } as DafResult;
                
                const response = await axios.get(
                    // tslint:disable-next-line:max-line-length
                    `${dafUrl}/BBR/BBRPublic/1/rest/ejendomsrelation?bfenummer=${result.bfeNummer}&${formatJson}&username=${user}&password=${password}&VirkningFra=${dafEjendomsrelationDate}&virkningTil=${dafEjendomsrelationDate}&status=7`,
                );
        
                const filtered: any = (response.data as Array<{ bfeNummer: number, status: string, kommunekode: string, ejendomsnummer: number }>)
                .filter((r) => r.status !== '10' && r.status !== '11' && !!r.bfeNummer);
                const bfeNumre: Array<{ bfeNummer: number, kommunekode: string, ejendomsnummer: number }> = 
                    filtered.map((r) => ({ bfeNummer: r.bfeNummer, kommunekode: r.kommunekode, ejendomsnummer: r.ejendomsnummer }));
                result.ejendomsnummer = bfeNumre;

                try {
                    if (beliggenhed.properties.husnummer.length > 0) {
                        result.adresseBetegnelse =
                            beliggenhed.properties.husnummer[0].adgangsadressebetegnelse;
                        result.vejnavn =
                            beliggenhed.properties.husnummer[0].navngivenVej.vejnavn;
                        result.husnummertekst =
                            beliggenhed.properties.husnummer[0].husnummertekst;
                        if (beliggenhed.properties.husnummer[0].postnummer) {
                            result.postnr =
                                beliggenhed.properties.husnummer[0].postnummer.postnr;
                            result.postdistrikt =
                                beliggenhed.properties.husnummer[0].postnummer.navn;
                        }
                        if (beliggenhed.properties.husnummer[0].kommuneinddeling) {
                            result.kommunenavn =
                                beliggenhed.properties.husnummer[0].kommuneinddeling.navn;
                            result.kommunekode =
                                beliggenhed.properties.husnummer[0].kommuneinddeling.kommunekode;
                        }
                    } else if (beliggenhed.properties.adresse.length > 0) {
                        result.adresseBetegnelse =
                            beliggenhed.properties.adresse[0].adressebetegnelse;
                        result.vejnavn =
                            beliggenhed.properties.adresse[0].husnummer.navngivenVej.vejnavn;
                        result.husnummertekst =
                            beliggenhed.properties.adresse[0].husnummer.husnummertekst;
                        result.etagebetegnelse =
                            beliggenhed.properties.adresse[0].etagebetegnelse;
                        result.dørbetegnelse =
                            beliggenhed.properties.adresse[0].dørbetegnelse;
                        if (beliggenhed.properties.adresse[0].husnummer.postnummer) {
                            result.postnr =
                                beliggenhed.properties.adresse[0].husnummer.postnummer.postnr;
                            result.postdistrikt =
                                beliggenhed.properties.adresse[0].husnummer.postnummer.navn;
                        }
                        if (beliggenhed.properties.adresse[0].husnummer.kommuneinddeling) {
                            result.kommunenavn =
                                beliggenhed.properties.adresse[0].husnummer.kommuneinddeling.navn;
                            result.kommunekode =
                                beliggenhed.properties.adresse[0].husnummer.kommuneinddeling.kommunekode;
                        }
                    } else {
                        result.adresseBetegnelse = beliggenhed.properties.betegnelse;
                        result.vejnavn = beliggenhed.properties.betegnelse;
                    }
                    // tslint:disable-next-line:no-empty
                } catch (error) { }

                await this.MapEjendomstype(
                    result,
                    beliggenhed.properties,
                    user,
                    password,
                );

                dafresult.push(result);
            }
        }
        dafresult.sort((a, b) => {
            if (a.vejnavn < b.vejnavn) {
                return -1;
            }
            if (a.vejnavn > b.vejnavn) {
                return 1;
            }

            const husnummertekstA = a.husnummertekst ? a.husnummertekst : '-99';
            const husnummertekstB = b.husnummertekst ? b.husnummertekst : '-99';

            // tslint:disable-next-line:max-line-length
            const streetNumber = husnummertekstA.localeCompare(
                husnummertekstB,
                undefined,
                { numeric: true, sensitivity: 'base' },
            );

            if (streetNumber !== 0) {
                return streetNumber;
            }

            // tslint:disable-next-line:max-line-length
            if (
                (!a.etagebetegnelse && !b.etagebetegnelse) ||
                (!a.etagebetegnelse && a.etagebetegnelse === b.etagebetegnelse)
            ) {
                return 0;
            }

            // tslint:disable-next-line:max-line-length
            const etageA = a.etagebetegnelse
                ? a.etagebetegnelse
                    .replace('st', '0')
                    .replace('kl', '-1')
                    .replace('k', '-')
                : '-99';
            // tslint:disable-next-line:max-line-length
            const etageB = b.etagebetegnelse
                ? b.etagebetegnelse
                    .replace('st', '0')
                    .replace('kl', '-1')
                    .replace('k', '-')
                : '-99';

            // tslint:disable-next-line:max-line-length
            const order = parseInt(etageA, 10) - parseInt(etageB, 10);

            if (order < 0) {
                return -1;
            }
            if (order > 0) {
                return 1;
            }

            if (a.dørbetegnelse === b.dørbetegnelse) {
                return 0;
            }

            // tslint:disable-next-line:max-line-length
            const dorA = a.dørbetegnelse
                ? a.dørbetegnelse
                    .replace('tv', '-2')
                    .replace('mf', '-1')
                    .replace('th', '0')
                : '-99';
            // tslint:disable-next-line:max-line-length
            const dorB = b.dørbetegnelse
                ? b.dørbetegnelse
                    .replace('tv', '-2')
                    .replace('mf', '-1')
                    .replace('th', '0')
                : '-99';

            return parseInt(dorA, 10) - parseInt(dorB, 10);
        });

        Object.freeze(dafresult);

        return dafresult;
    }

    public static async GetFromBFE(
        bfeNumber: number,
        user: string,
        password: string,
        dafEjendomsrelationDate: string
    ): Promise<DafResult[]> {
        return await this.GetDafResultsFromBFE([bfeNumber], user, password, dafEjendomsrelationDate);
    }

    public static async GetFromEjendomsnummer(
        kommunekode: string,
        ejendomsnummer: string,
        user: string,
        password: string,
        dafEjendomsrelationDate: string,
    ): Promise<DafResult[]> {

        const response = await axios.get(
            // tslint:disable-next-line:max-line-length
            `${dafUrl}/BBR/BBRPublic/1/rest/ejendomsrelation?ejendomsnummer=${ejendomsnummer}&kommunekode=${kommunekode}&${formatJson}&username=${user}&password=${password}&VirkningFra=${dafEjendomsrelationDate}&virkningTil=${dafEjendomsrelationDate}&status=7`,
        );

        const bfeNumre: number[] = (response.data as Array<{ bfeNummer: number, status: string }>)
            .filter((r) => r.status !== '10' && r.status !== '11' && !!r.bfeNummer)
            .map(
                (r) => r.bfeNummer,
            );

        return await this.GetDafResultsFromBFE(bfeNumre, user, password, dafEjendomsrelationDate);
    }

    public static async GetFromJordstykke(
        ejerlavskode: number,
        matrikelnr: string,
        user: string,
        password: string,
        dafEjendomsrelationDate: string
    ): Promise<DafResult[]> {
        // tslint:disable-next-line:max-line-length
        const sfeResult = await axios.get(
            // tslint:disable-next-line:max-line-length
            `${dafUrl}/Matriklen2/Matrikel/1.0.0/REST/SamletFastEjendom?Ejerlavskode=${ejerlavskode}&Matrikelnr=${matrikelnr}&${formatJson}&username=${user}&password=${password}`,
        );
        const samletFastEjendomResult = sfeResult.data as FeatureCollection<
            Feature<SamletFastEjendom>
        >;

        const bfeNumre: number[] = [
            ...samletFastEjendomResult.features.map((f) => f.properties.BFEnummer),
            ...samletFastEjendomResult.features.flatMap((f) =>
                f.properties.ejerlejlighed.map((e) => e.BFEnummer),
            ),
            // tslint:disable-next-line:max-line-length
            ...samletFastEjendomResult.features.flatMap((f) =>
                f.properties.bygningPaaFremmedGrund.map((b) => b.BFEnummer),
            ),
            // tslint:disable-next-line:max-line-length
            ...samletFastEjendomResult.features.flatMap((f) =>
                f.properties.bygningPaaFremmedGrund.flatMap((b) =>
                    b.ejerlejlighed.map((e) => e.BFEnummer),
                ),
            ),
        ];

        return await this.GetDafResultsFromBFE(bfeNumre, user, password, dafEjendomsrelationDate);
    }

    public static async GetFromAdresse(
        adresseId: string,
        user: string,
        password: string,
        dafEjendomsrelationDate: string
    ): Promise<DafResult[]> {
        return await this.GetFromAdresseOrAdgangsAdresse(
            adresseId,
            null,
            user,
            password,
            dafEjendomsrelationDate
        );
    }

    // tslint:disable-next-line:max-line-length
    public static async GetFromAdgangsAdresse(
        adgangsadresseId: string,
        user: string,
        password: string,
        dafEjendomsrelationDate: string
    ): Promise<DafResult[]> {
        return await this.GetFromAdresseOrAdgangsAdresse(
            null,
            adgangsadresseId,
            user,
            password,
            dafEjendomsrelationDate
        );
    }

    // tslint:disable-next-line:max-line-length
    public static async GetFromAdresseOrAdgangsAdresse(
        adresseId: string,
        adgangsadresseId: string,
        user: string,
        password: string,
        dafEjendomsrelationDate: string
    ): Promise<DafResult[]> {
        let url = '';
        if (adresseId) {
            url = `${dafUrl}/DAR/DAR/2.0.0/REST/Adresse?id=${adresseId}&${format}&username=${user}&password=${password}`;
        } else {
            // tslint:disable-next-line:max-line-length
            url = `${dafUrl}/DAR/DAR/2.0.0/REST/Adresse?husnummer=${adgangsadresseId}&${format}&username=${user}&password=${password}`;
        }

        const adresseResult = await axios.get(url);
        const adresser: Adresse[] = adresseResult.data as Adresse[];

        // tslint:disable-next-line:max-line-length
        const enhederBfeResult = await Promise.all(
            adresser.map(
                async (a) =>
                    await axios.get(
                        `${dafUrl}/DAR/DAR_BFE_Public/1/rest/adresseTilEnhedBfe?adresseid=${a.id_lokalId}&${format}&username=${user}&password=${password}`,
                    ),
            ),
        );
        const enhederBfe = enhederBfeResult.flatMap((r) => r.data) as number[]; // DafItem[];

        // tslint:disable-next-line:max-line-length
        const bfeResult = await axios.get(
            `${dafUrl}/DAR/DAR_BFE_Public/1/rest/husnummertilbygningbfe?husnummerid=${adgangsadresseId ||
            adresser[0].husnummer
                .id_lokalId}&${format}&username=${user}&password=${password}`,
        );
        const bfe = bfeResult.data as HusnummerTilBygningBFE;

        const bfeNumre: number[] = [
            //...enhederBfe.map((f) => f.item),
            ...enhederBfe,
            ...bfe.jordstykkeList.map((f) => f.samletFastEjendom),
            ...bfe.bygningPaaFremmedGrundList,
        ];

        if (adgangsadresseId != null) {

            const bygStatus = ['2','3','6', '7','12','13'];

            const bygJordstykList: string[] = [];
            const grundJordstykList: string[] = [];
            const grundIdList: string[] = [];
            // Get Bygnigner with husnummer.
            const bygningFromHusnummer = await axios.get(
                `${dafUrl}/BBR/BBRPublic/1/REST/bygning?husnummer=${adgangsadresseId}&${format}&username=${user}&password=${password}&status=2|3|6|12|13`,
            );
            const bygningList: Bygning[] = bygningFromHusnummer.data as Bygning[];

            const bygIdList: string[] = [];
            for (const bygning of bygningList) {

                bygIdList.push(bygning.id_lokalId);
            }

            const husnummerFromHusnummer = await axios.get(
                `${dafUrl}/DAR/DAR/2.0.0/REST/husnummer?id=${adgangsadresseId}&${format}&username=${user}&password=${password}`,
            );
            const hList: Husnummer[] = husnummerFromHusnummer.data as Husnummer[];

            for (const h of hList) {
                if (h.adgangTilBygning != null && bygningList.findIndex((b) => b.id_lokalId === h.adgangTilBygning) === -1) {
                    bygIdList.push(h.adgangTilBygning);
                }
            }

            // Get bygninger from opgang with husnummer.
            if (bygIdList.length > 0) {
                const bygIdList1 = [...new Set(bygIdList)];
                const bygIdStr: string = bygIdList1.join('|');
                const bygningFromOpgang = await axios.get(
                    `${dafUrl}/BBR/BBRPublic/1/REST/bygning?id=${bygIdStr}&${format}&username=${user}&password=${password}&status=2|3|6|12|13`,
                );
                const bygFromOpgList: Bygning[] = bygningFromOpgang.data as Bygning[];
                const relatedSfeList: DawaJordstykke[] = [];

                let adresseToEnhedList: Adresse[] = [];
                if (adresser.length > 0) {
                    const adr0 = adresser.findIndex((e) => (e.dørbetegnelse == null || e.dørbetegnelse === '') && (e.etagebetegnelse == null || e.etagebetegnelse === ''));
                    if (adr0 !== -1) adresseToEnhedList.push(adresser[adr0]);
                    const adr1 = adresser.findIndex((e) => (e.dørbetegnelse != null && e.dørbetegnelse !== '') || (e.etagebetegnelse != null && e.etagebetegnelse !== ''));
                    if (adr1 !== -1) adresseToEnhedList.push(adresser[adr1]);
                }

                let enhedList: Enhed[] = [];
                if (adresseToEnhedList.length > 0) {
                    for (const adr of adresseToEnhedList) {
                        const enhed = await axios.get(
                            `${dafUrl}/BBR/BBRPublic/1/REST/enhed?adresseIdentificerer=${adr.id_lokalId}&${format}&username=${user}&password=${password}&status=2|3|6`,
                        );
                        enhedList = enhedList.concat(enhed.data as Enhed[]);
                    }

                    if (enhedList.length > 0) {
                        const opgangFromEnhedList: Enhed[] = enhedList.filter((n, i, arr) => arr.findIndex(c => c.opgang === n.opgang) === i);
                        if (opgangFromEnhedList.length > 0) {
                            const opg = [...new Set(opgangFromEnhedList.map(item => item.opgang))];
                            for (var i = 0; i < Math.ceil(opgangFromEnhedList.length / 50); i++) {                                
                                const start = i * 50;
                                const end = (i + 1) * 50;
                                const opgIdStr: string = opg.slice(start, end).join('|');
                                const bygningFromOpg = await axios.get(
                                    `${dafUrl}/BBR/BBRPublic/1/REST/bygning?opgang=${opgIdStr}&${format}&username=${user}&password=${password}&status=2|3|6|12|13`,
                                );
                                const bygningList: Bygning[] = bygningFromOpg.data as Bygning[];
                                for (const b of bygningList) {
                                    if (bygFromOpgList.findIndex((bo) => b.id_lokalId === bo.id_lokalId) === -1) {
                                        bygFromOpgList.push(b);
                                    }
                                }
                            }
                        }
                    }
                }

                for (const bygning of bygFromOpgList) {
                    if (bygning.bygningPåFremmedGrundList != null) {
                        for (const bpfg of bygning.bygningPåFremmedGrundList.filter(b => bygStatus.indexOf(b.bygningPåFremmedGrund.status) >= 0)) {
                            bfeNumre.push(bpfg.bygningPåFremmedGrund.bfeNummer);
                        }
                    }
                    if (bygning.ejerlejlighed != null && bygStatus.indexOf(bygning.ejerlejlighed.status) >= 0) {
                        bfeNumre.push(bygning.ejerlejlighed.bfeNummer);
                    }
                    bygJordstykList.push(bygning.jordstykke);
                }
            }

            const grundResult = await axios.get(
                // tslint:disable-next-line:max-line-length
                `${dafUrl}/BBR/BBRPublic/1/REST/Grund?husnummer=${adgangsadresseId}&${format}&username=${user}&password=${password}&status=7`,
            );

            // Parse grunde.
            const grunde: Grund[] = grundResult.data as Grund[];
            let bygGrundList: string[] = [];
            for (const b of bygningList) {
                if (grunde.findIndex((g) => g.id_lokalId === b.grund) === -1) {
                    bygGrundList.push(b.grund)
                }
            }
            if (bygGrundList.length > 0) {
                const gIdList = [...new Set(bygGrundList)];
                const gListIdStr: string = gIdList.join('|');

                const grundResult1 = await axios.get(
                    // tslint:disable-next-line:max-line-length
                    `${dafUrl}/BBR/BBRPublic/1/REST/Grund?id=${gListIdStr}&${format}&username=${user}&password=${password}&status=7`,
                );

                // Parse grunde.
                const grunde1: Grund[] = grundResult1.data as Grund[];
                grunde.concat(grunde1);

            }
            const grundBfeList: number[] = [];
            for (const val of grunde) {
                if (Number(val.status) < 9 && val.jordstykkeList != null) {
                    if (val.bestemtFastEjendom != null) {
                        bfeNumre.push(val.bestemtFastEjendom.bfeNummer);
                        grundBfeList.push(val.bestemtFastEjendom.bfeNummer);
                    }
                    for (const jords of val.jordstykkeList) {
                        if (!grundJordstykList.includes(jords)) {
                            grundJordstykList.push(jords);
                        }
                    }
                    grundIdList.push(val.id_lokalId);
                }
            }

            const otherGrundIdList: string[] = [];

            // Sort jordstykker, save unknown jordstykker, and save unknown grunde.
            for (const val of bygningList) {
                if (val.bygningPåFremmedGrundList != null) {
                    for (const val1 of val.bygningPåFremmedGrundList) {
                        bfeNumre.push(val1.bygningPåFremmedGrund.bfeNummer);
                    }
                }
                if (val.ejerlejlighed != null) {
                    bfeNumre.push(val.ejerlejlighed.bfeNummer);
                }
                if (val.jordstykke != null) {
                    if (
                        !grundJordstykList.includes(val.jordstykke) &&
                        !bygJordstykList.includes(val.jordstykke)
                    ) {
                        bygJordstykList.push(val.jordstykke);
                    }
                }
                if (!grundIdList.includes(val.grund)) {
                    otherGrundIdList.push(val.grund);
                }
            }

            for (let index = 0; index < adresser.length; index++) {
                const adresse = adresser[index];
                if (adresse && adresse.husnummer && adresse.husnummer.jordstykke &&
                    !grundJordstykList.includes(adresse.husnummer.jordstykke) &&
                    !bygJordstykList.includes(adresse.husnummer.jordstykke)
                ) {
                    bygJordstykList.push(adresse.husnummer.jordstykke);
                }
            }

            // Get other grund, that were not found in first call to BBR Grund with Husnummer.
            if (otherGrundIdList.length > 0) {
                const grundIdStr: string = otherGrundIdList.join('|');
                const grundResult1 = await axios.get(
                    // tslint:disable-next-line:max-line-length
                    `${dafUrl}/BBR/BBRPublic/1/REST/Grund?id=${grundIdStr}&${format}&username=${user}&password=${password}&status=7`,
                );

                const grunde1: Grund[] = grundResult1.data as Grund[];
                for (const val of grunde1) {
                    if (Number(val.status) < 9 && val.jordstykkeList != null) {
                        if (val.bestemtFastEjendom != null) {
                            bfeNumre.push(val.bestemtFastEjendom.bfeNummer);
                        }
                        for (const jords of val.jordstykkeList) {
                            if (!grundJordstykList.includes(jords)) {
                                grundJordstykList.push(jords);
                            }
                        }
                    }
                }
            }

            // Remove jordstykker of grundList from bygnings jordstykke list.
            bygJordstykList.filter((f) => !grundJordstykList.includes(f));
            if (bygJordstykList.length > 0) {
                for (const js of bygJordstykList) {
                    const tempSfe = await axios.get(
                        `https://dawa.aws.dk/jordstykker?featureid=${js}`,
                    );
                    const tempSfeData: DawaJordstykke[] = tempSfe.data;
                    tempSfeData.forEach((element) => {
                        bfeNumre.push(element.bfenummer);
                    });
                }
            }

            const tekAnlaegResponse = await axios.get(
                // tslint:disable-next-line:max-line-length
                `${dafUrl}/BBR/BBRPublic/1/REST/tekniskanlaeg?husnummer=${adgangsadresseId}&${format}&username=${user}&password=${password}&status=2|3|6|12`,
            );

            const tekAnlaeg: TekniskAnlaeg[] = tekAnlaegResponse.data as TekniskAnlaeg[];
            for(const tek of tekAnlaeg){
                // tslint:disable-next-line:max-line-length
                if (tek.ejerlejlighed != null && tek.ejerlejlighed.bfeNummer != null && tek.ejerlejlighed.status != null && bygStatus.indexOf(tek.ejerlejlighed.status) >= 0) {
                    bfeNumre.push(tek.ejerlejlighed.bfeNummer);
                }
                // tslint:disable-next-line:max-line-length
                if (tek.bygningPåFremmedGrund != null && tek.bygningPåFremmedGrund.bfeNummer != null && tek.bygningPåFremmedGrund.status != null && bygStatus.indexOf(tek.bygningPåFremmedGrund.status) >= 0) {
                    bfeNumre.push(tek.bygningPåFremmedGrund.bfeNummer);
                }
            }

        } else {
            const sfeResult = await axios.get(
                `https://dawa.aws.dk/jordstykker?featureid=${adresser[0].husnummer.jordstykke}`,
            );
            const dawaJordstykkeResult = sfeResult.data as DawaJordstykke[];
            if (dawaJordstykkeResult[0].bfenummer != null) {
                bfeNumre.push(dawaJordstykkeResult[0].bfenummer);
            }
        }

        return await this.GetDafResultsFromBFE(bfeNumre, user, password, dafEjendomsrelationDate);
    }

    // tslint:disable-next-line:max-line-length
    private static async setModerEjendom(
        result: DafResult,
        ejendom: EjendomBeliggenhed,
        user: string,
        password: string,
    ): Promise<void> {
        if (ejendom.Ejendomstype === 'SamletFastEjendom') {
            // tslint:disable-next-line:max-line-length
            const sfeResponse = await axios.get(
                `${dafUrl}/Matriklen2/Matrikel/1.0.0/REST/SamletFastEjendom?sfebfenr=${ejendom.bestemtFastEjendomBFENr}&${formatJson}&username=${user}&password=${password}`,
            );
            const sfeResult = sfeResponse.data as FeatureCollection<
                Feature<SamletFastEjendom>
            >;
            if (sfeResult.features.length) {
                result.isModerEjendom = !!sfeResult.features[0].properties
                    .hovedejendomOpdeltIEjerlejligheder;
            }
        } else if (ejendom.Ejendomstype === 'BygningPaaFremmedGrund') {
            // tslint:disable-next-line:max-line-length
            const bpfgResponse = await axios.get(
                `${dafUrl}/Matriklen2/Matrikel/1.0.0/REST/bygningpaafremmedgrund?BPFGBFEnr=${ejendom.bestemtFastEjendomBFENr}&${formatJson}&username=${user}&password=${password}`,
            );
            const bpfgResult = bpfgResponse.data as BygningPaaFremmedGrundPunktOgFlade;
            if (
                bpfgResult.BygningPaaFremmedGrundPunkt &&
                bpfgResult.BygningPaaFremmedGrundPunkt.features.length
            ) {
                // tslint:disable-next-line:max-line-length
                result.isModerEjendom = !!bpfgResult.BygningPaaFremmedGrundPunkt
                    .features[0].properties.opdeltIEjerlejligheder;
            }
        }
    }

    private static async MapEjendomstype(
        result: DafResult,
        ejendom: EjendomBeliggenhed,
        user: string,
        password: string,
    ): Promise<void> {
        result.type = ejendom.Ejendomstype;
        await this.setModerEjendom(result, ejendom, user, password);
        switch (ejendom.Ejendomstype) {
            case 'Ejerlejlighed':
                result.typeTekst = ejerLejlighedText;
                break;
            case 'SamletFastEjendom':
                result.typeTekst = ''; // samletFastEjendomText;
                break;
            case 'BygningPaaFremmedGrund':
                result.typeTekst = bpfgText;
                break;
            default:
                result.typeTekst = 'Ukendt';
                break;
        }
    }
}

export interface JordstykkeResult {
    samletFastEjendom: SamletFastEjendomMini;
    ejerlejlighed: EjerlejlighedMini[];
    bygningPaaFremmedGrund: BygningPaaFremmedGrundMini[];
}

export interface HusnummerTilBygningBFE {
    bygningPaaFremmedGrundList: number[];
    jordstykkeList: [{ jordstykkeLokalId: string; samletFastEjendom: number }];
}

export interface DafItem {
    item: number;
}

export interface Bygning {
    datafordelerOpdateringstid: string;
    byg007Bygningsnummer: number;
    byg021BygningensAnvendelse: string;
    byg026Opførelsesår: number;
    byg032YdervæggensMateriale: string;
    byg033Tagdækningsmateriale: string;
    byg037KildeTilBygningensMaterialer: string;
    byg038SamletBygningsareal: number;
    byg040BygningensSamledeErhvervsAreal: number;
    byg041BebyggetAreal: number;
    byg053BygningsarealerKilde: string;
    byg054AntalEtager: number;
    byg056Varmeinstallation: string;
    byg094Revisionsdato: string;
    byg133KildeTilKoordinatsæt: string;
    byg134KvalitetAfKoordinatsæt: string;
    byg135SupplerendeOplysningOmKoordinatsæt: string;
    byg136PlaceringPåSøterritorie: string;
    byg404Koordinat: string;
    byg406Koordinatsystem: string;
    forretningshændelse: string;
    forretningsområde: string;
    forretningsproces: string;
    grund: string;
    husnummer: string;
    id_lokalId: string;
    id_namespace: string;
    jordstykke: string;
    kommunekode: string;
    registreringFra: string;
    registreringsaktør: string;
    status: string;
    virkningFra: string;
    virkningsaktør: string;
    etageList: any[];
    opgangList: any[];
    bygningPåFremmedGrundList: BygningBygningPaaFremmedGrundList[];
    ejerlejlighed: BBREjerlejlighed;
}
export interface BygningBygningPaaFremmedGrundList {
    id_lokalid: string;
    bygningPåFremmedGrund: BygningBygningPaaFremmedGrund;
}
export interface BygningBygningPaaFremmedGrund {
    bfeNummer: number;
    bygningPåFremmedGrund: string;
    datafordelerOpdateringstid: string;
    ejendommensEjerforholdskode: string;
    id_lokalId: string;
    kommunekode: string;
    registreringFra: string;
    virkningFra: string;
    status: string;
}
export interface DafResult {
    kommunekode: string;
    kommunenavn: string;
    postdistrikt: string;
    postnr: string;
    bfeNummer: string;
    type: string;
    typeTekst: string;
    isModerEjendom: boolean;
    adresseBetegnelse: string;
    dørbetegnelse: string;
    etagebetegnelse: string;
    vejnavn: string;
    husnummertekst: string;
    ejendomsnummer: any;
}

export interface SamletFastEjendomMini {
    bfeNummer: number;
    type: string;
    adresseBetegnelse: string;
}

export interface BestemtFastEjendomMini {
    bfeNummer: number;
    type: string;
    adresseBetegnelse: string;
}

export interface EjerlejlighedMini {
    bfeNummer: number;
    type: string;
    adresseBetegnelse: string;
}

export interface BygningPaaFremmedGrundMini {
    bfeNummer: number;
    type: string;
    adresseBetegnelse: string;
}

export interface BestemtFastEjendomExtended {
    bygningPaaFremmedGrund?: void;
    ejerlejlighed?: void;
    BFEnummer: number;
    samletFastEjendom?: SamletFastEjendomExtended;
}

export interface BestemtFastEjendomResult {
    SamletFastEjendom: FeatureCollection<Feature<SamletFastEjendom>>;
    Ejerlejlighed: FeatureCollection<Feature<Ejerlejlighed>>;
    BygningPaaFremmedGrund: BygningPaaFremmedGrundPunktOgFlade;
}

export interface BygningPaaFremmedGrundPunktOgFlade {
    BygningPaaFremmedGrundFlade: FeatureCollection<
        Feature<BygningPaaFremmedGrund>
    >;
    BygningPaaFremmedGrundPunkt: FeatureCollection<
        Feature<BygningPaaFremmedGrund>
    >;
}

export interface FeatureCollection<T> {
    type: string;
    features: T[];
}

export interface Feature<T> {
    type: string;
    properties: T;
}

export interface SamletFastEjendomExtended {
    samletFastEjendom: SamletFastEjendom;
    grunde: GrundExtended[];
}

export interface EjendomBeliggenhed {
    id_namespace: string;
    id_lokalId: string;
    bestemtFastEjendomBFENr: string;
    Ejendomstype: string;
    adresseManueltAngivet: boolean;
    ESDHReferenceAdresse: string;
    ESDHReferenceKommune: string;
    kommuneManueltAngivet: boolean;
    kommuneinddelingKommunekode: string;
    betegnelse: string;
    adresse: Adresse[];
    husnummer: Husnummer[];
    status: string;
    forretningshaendelse: string;
    forretningsomraade: string;
    forretningsproces: string;
    virkningFra: string;
    virkningTil: string;
    virkningsaktoer: string;
    registreringFra: string;
    registreringTil: string;
    registreringsaktoer: string;
}

export interface Husnummer {
    datafordelerOpdateringstid: string;
    adgangsadressebetegnelse: string;
    adgangTilBygning: string;
    afstemningsområde: any;
    forretningshændelse: string;
    forretningsområde: string;
    forretningsproces: string;
    geoDanmarkBygning: string;
    husnummerretning: string;
    husnummertekst: string;
    id_lokalId: string;
    id_namespace: string;
    jordstykke: string;
    kommuneinddeling: { id_lokalid: string; kommunekode: string; navn: string };
    menighedsrådsafstemningsområde: {
        id_lokalid: string;
        mrafstemningsområdenummer: string;
        navn: string;
    };
    registreringFra: string;
    registreringsaktør: string;
    sogneinddeling: { id_lokalid: string; sognekode: string; navn: string };
    status: string;
    vejmidte: string;
    virkningFra: string;
    virkningsaktør: string;
    navngivenVej: { vejnavn: string };
    // adgangspunkt: {datafordelerOpdateringstid: "2019-02-28T16:28:49.041798+01:00", oprindelse_kilde: "Adressemyn",…}
    // vejpunkt: {datafordelerOpdateringstid: "2019-02-28T18:19:43.763184+01:00", oprindelse_kilde: "Ekstern",…}
    postnummer: { postnr: string; navn: string };
}

export interface Adresse {
    datafordelerOpdateringstid: string;
    adressebetegnelse: string;
    dørbetegnelse: string;
    etagebetegnelse: string;
    forretningshændelse: string;
    forretningsområde: string;
    forretningsproces: string;
    id_lokalId: string;
    id_namespace: string;
    registreringFra: string;
    registreringsaktør: string;
    status: string;
    virkningFra: string;
    virkningsaktør: string;
    husnummer: Husnummer;
}

export interface Enhed {
    datafordelerOpdateringstid: string;
    adresseIdentificerer: string;
    enh020EnhedensAnvendelse: string;
    enh023Boligtype: string;
    enh024KondemneretBoligenhed: string;
    enh026EnhedensSamledeAreal: number;
    enh027ArealTilBeboelse: number;
    enh030KildeTilEnhedensArealer: string;
    enh031AntalVærelser: number;
    enh032Toiletforhold: string;
    enh033Badeforhold: string;
    enh034Køkkenforhold: string;
    enh045Udlejningsforhold: string;
    enh048GodkendtTomBolig: string;
    enh065AntalVandskylledeToiletter: number;
    enh066AntalBadeværelser: number;
    enh071AdresseFunktion: string;
    etage: string;
    forretningshændelse: string;
    forretningsområde: string;
    forretningsproces: string;
    id_lokalId: string;
    id_namespace: string;
    kommunekode: string;
    opgang: string;
    registreringFra: string;
    registreringsaktør: string;
    status: string;
    virkningFra: string;
    virkningsaktør: string;
    bygning: string;
    ejerlejlighedList: any[]; // [{id_lokalId: "c304f9e5-b276-4463-a854-16adbb4aee5d",…}]
}

export interface TekniskAnlaeg {
    datafordelerOpdateringstid: string;
    forretningshændelse: string;
    forretningsområde: string;
    forretningsproces: string;
    grund: string;
    husnummer: string;
    id_lokalId: string;
    id_namespace: string;
    jordstykke: string;
    kommunekode: string;
    registreringFra: string;
    registreringsaktør: string;
    status: string;
    tek007Anlægsnummer: number;
    tek020Klassifikation: string;
    tek024Etableringsår: number;
    tek026StørrelsesklasseOlietank: string;
    tek027Placering: string;
    tek032Størrelse: number;
    tek034IndholdOlietank: string;
    tek042Revisionsdato: string;
    tek045Koordinatsystem: string;
    tek068Materiale: string;
    tek072Sløjfningsår: number;
    tek076KildeTilKoordinatsæt: string;
    tek077KvalitetAfKoordinatsæt: string;
    tek078SupplerendeOplysningOmKoordinatsæt: string;
    tek107PlaceringPåSøterritorie: string;
    tek109Koordinat: string;
    virkningFra: string;
    virkningsaktør: string;
    ejerlejlighed: BBREjerlejlighed;
    bygningPåFremmedGrund: BygningBygningPaaFremmedGrund;
}
export interface BygningPaaFremmedGrund {
    BFEnummer: number;
    status: string;
    senesteSagLokalId: string;
    ejerlejlighed: Ejerlejlighed[];
    opdeltIEjerlejligheder: boolean;
}
export interface GrundExtended {
    grund: Grund;
    tekniskeAnlaeg: TekniskAnlaeg[];
}

export interface Grund {
    datafordelerOpdateringstid: string;
    forretningshændelse: string;
    forretningsområde: string;
    forretningsproces: string;
    gru009Vandforsyning: string;
    gru010Afløbsforhold: string;
    husnummer: string;
    id_lokalId: string;
    id_namespace: string;
    kommunekode: string;
    registreringFra: string;
    registreringsaktør: string;
    status: string;
    virkningFra: string;
    virkningsaktør: string;
    jordstykkeList: string[];
    bestemtFastEjendom: BestemtFastEjendom;
}
export interface BestemtFastEjendom {
    datafordelerOpdateringstid: string;
    bfeNummer: number;
    ejendommensEjerforholdskode: string;
    ejendomsnummer: number;
    ejendomstype: string;
    forretningshændelse: string;
    forretningsområde: string;
    forretningsproces: string;
    id_lokalId: string;
    id_namespace: string;
    kommunekode: string;
    registreringFra: string;
    registreringsaktør: string;
    samletFastEjendom: string;
    status: string;
    virkningFra: string;
    virkningsaktør: string;
}
export interface Ejerlejlighed {
    BFEnummer: number;
    samletFastEjendomBFEnummer: string;
    bygningPaaFremmedGrundBFEnummer: number;
    ejerlejlighedskort: any;
    ejerlejlighedslod: any[];
    ejerlejlighedsnummer: string;
    fordelingstalNaevner: number;
    fordelingstalTaeller: number;
    forretningshaendelse: string;
    sekundaerForretningshaendelse: any[];
    forretningsomraade: string;
    forretningsproces: string;
    iBygningPaaFremmedGrund: boolean;
    id_lokalId: string;
    id_namespace: string;
    paataenktHandling: any;
    registreringFra: string;
    registreringTil: string;
    registreringsaktoer: string;
    samletAreal: string;
    senesteSagLokalId: string;
    status: string;
    virkningFra: string;
    virkningTil: string;
    virkningsaktoer: string;
}
export interface Jordstykke {
    arealberegningsmetode: string;
    arealbetegnelse: string;
    arealtype: any;
    brugsretsareal: boolean;
    centroide: any; // [{geometri: "POINT(709345.8 6179220.353)", senesteSagLokalId: "100112193", status: "Gældende"}]
    delnummer: number;
    ejerlavskode: number;
    ejerlavsnavn: string;
    faelleslod: boolean;
    forretningshaendelse: string;
    sekundaerForretningshaendelse: any[];
    forretningsomraade: string;
    forretningsproces: string;
    fredskov: any; // {fredskovsareal: null, omfang: null}
    id_lokalId: string;
    id_namespace: string;
    jordrente: any; // {omfang: null}
    klitfredning: any; // {klitfredningsareal: null, omfang: null}
    kommuneLokalId: string;
    majoratskov: any; // {majoratskovsnummer: null, omfang: null}
    matrikelnummer: string;
    paataenktHandling: string;
    regionLokalId: string;
    registreretAreal: number;
    registreringFra: string;
    registreringTil: string;
    registreringsaktoer: string;
    samletFastEjendomBFEnummer: number;
    senesteSagLokalId: string;
    skelforretningssagsLokalId: string;
    sognLokalId: string;
    stammerFraJordstykkeLokalId: string;
    status: string;
    stormfald: any[];
    strandbeskyttelse: any; // {omfang: null, strandbeskyttelsesareal: null}
    supplerendeMaalingSagLokalId: string;
    vandarealinkludering: string;
    vejareal: string;
    vejarealberegningsstatus: string;
    virkningFra: string;
    virkningTil: string;
    virkningsaktoer: string;
}
export interface SamletFastEjendom {
    BFEnummer: number;
    arbejderbolig: boolean;
    bygningPaaFremmedGrund: BygningPaaFremmedGrund[];
    ejerlejlighed: Ejerlejlighed[];
    erFaelleslod: boolean;
    forretningshaendelse: string;
    sekundaerForretningshaendelse: any[];
    forretningsomraade: string;
    forretningsproces: string;
    hovedejendomOpdeltIEjerlejligheder: boolean;
    id_lokalId: string;
    id_namespace: string;
    jordstykke: Array<Feature<Jordstykke>>;
    landbrugsnotering: string;
    paataenktHandling: string;
    registreringFra: string;
    registreringTil: string;
    registreringsaktoer: string;
    senesteSagLokalId: string;
    status: string;
    udskiltVej: boolean;
    virkningFra: string;
    virkningTil: string;
    virkningsaktoer: string;
}
export interface BBROpgang {
    ois_id: string;
    Opgang_id: string;
    AdgAdr_id: string;
    Bygning_id: string;
    OPRET_TS: string;
    AENDR_TS: string;
}
export interface DawaJordstykke {
    matrikelnr: string;
    esrejendomsnr: string;
    udvidet_esrejendomsnr: string;
    sfeejendomsnr: string;
    bfenummer: number;
}

export interface BBREjerlejlighed {
    bfeNummer: number;
    bygningPåFremmedGrund: string;
    ejendommensEjerforholdskode: string;
    ejendomsnummer: number;
    ejendomstype: string;
    ejerlejlighed: string;
    ejerlejlighedsnummer: number;
    forretningshændelse: string;
    forretningsområde: string;
    forretningsproces: string;
    id_lokalId: string;
    id_namespace: string;
    kommunekode: string;
    registreringFra: string;
    registreringTil: string;
    registreringsaktør: string;
    status: string;
    tinglystAreal: string;
    virkningFra: string;
    virkningTil: string;
    virkningsaktør: string;
    vurderingsejendomsnummer: number;
}