import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import UserMenu from "./UserMenu";
import { AuthState } from "context/AuthProvider";
import SearchNew from "components/Search/SearchNew";
import { SearchResultContext } from "context/SearchResultProvider";
import HamburgerMenu from "components/common/icons/HamburgerMenu";
import DropDownMenu from "components/common/DropDownMenu";
import Bbr from "components/common/icons/Bbr";
import Vurdst from "components/common/icons/Vurdst";
import useMediaQuery from "hooks/useMediaQuery";

interface NavProps {
  authState: AuthState;
}

export default function DefaultNav(props: NavProps) {
  const { authState } = props;
  const roller =
    authState.bruger && authState.bruger.roller ? authState.bruger.roller : [];
  const { redirect, setRedirect, setResult, searchKey } =
    useContext(SearchResultContext);
  const matches1300 = useMediaQuery("(max-width: 1422px)");
  const matches1000 = useMediaQuery("(max-width: 1122px)");
  const matches740 = useMediaQuery("(max-width: 740px)");

  const renderNav = () => {
    return (
      <>
        {window.location.pathname !== "/" &&
          window.location.pathname !== "/admin" &&
          window.location.pathname !== "/statistik" && (
            <>
              <div
                key={searchKey}
                style={
                  matches740
                    ? { display: "none" }
                    : { width: "50%", minWidth: "350px" }
                }
                className="c-nav-search c-navlink me-5 ps-0 py-1"
              >
                <SearchNew
                  onResult={(item) => {
                    setResult(item);
                    try {
                      let bfe =
                        item["data"][0] !== undefined
                          ? item["data"][0]["data"]["bfeNummer"]
                          : item["data"]["bfeNummer"];
                      if (bfe !== undefined) {
                        setRedirect({
                          state: true,
                          path: "/ejendom?bfe=" + bfe,
                        });
                      } else {
                        redirect.state = false;
                      }
                    } catch (err) {
                      setRedirect({ state: false, path: "" });
                    }
                    setRedirect({ state: false, path: "" });
                  }}
                />
              </div>
            </>
          )}
        <div className="d-flex gap-3">
          <NavLink
            to="/esrsearch"
            className="c-navlink text-center fw-semibold me-0"
            activeClassName="text-primary"
            style={matches1000 ? { display: "none" } : { display: "flex" }}
          >
            <p
              className="c-navlink-text mb-0 text-nowrap"
              style={{ minWidth: "5em" }}
            >
              Søg ESR/BFE
            </p>
          </NavLink>
          {roller.findIndex((role) => role.navn === "fejllister") > -1 && ( // Claim is still called like so
            <NavLink
              to="/fejllister"
              className="c-navlink text-center fw-semibold me-0"
              activeClassName="text-primary"
              style={matches1000 ? { display: "none" } : { display: "flex" }}
            >
              <p className="c-navlink-text mb-0" style={{ minWidth: "5em" }}>
                Fejllister
              </p>
            </NavLink>
          )}
          {roller.findIndex((role) => role.navn === "statistik") > -1 && (
            <NavLink
              to="/statistik"
              className="c-navlink text-center fw-semibold me-0"
              activeClassName="text-primary"
              style={matches1000 ? { display: "none" } : { display: "flex" }}
            >
              <p className="c-navlink-text mb-0" style={{ minWidth: "5em" }}>
                Statistik
              </p>
            </NavLink>
          )}
          <div style={matches1000 ? { display: "none" } : { display: "block" }}>
            <UserMenu authState={authState} />
          </div>
        </div>
      </>
    );
  };
  return (
    <nav
      aria-label="Hovedmenu"
      role="navigation"
      className={`navigation-fixed container-fluid ${
        window.location.pathname !== "/" ? "navigation-fixed--border" : ""
      }`}
    >
      <NavLink
        to="/"
        className="h-100 d-flex align-items-center"
        style={{ width: 160 }}
        title="Til forsiden"
      >
        {matches1300 ? (
          <>
            <Bbr width={60} height={40} />
            <span
              aria-hidden="true"
              className="fs-5 ps-2 me-2"
              style={{ marginTop: "0.85rem" }}
            >
              Explore
            </span>
          </>
        ) : (
          <>
            <Bbr width={84} height={40} />
            <span
              aria-hidden="true"
              className=""
              style={{ marginTop: "0.85rem" }}
            >
              Explore
            </span>
          </>
        )}
      </NavLink>
      {/*på container:  m-w-1000  */}
      <div className="container-xl d-flex px-0 align-items-center">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          // className="container links"
          // på container: w-100 m-w-1000
          className={`container-xl d-flex align-items-center ${
            window.location.pathname !== "/" &&
            window.location.pathname !== "/admin" &&
            window.location.pathname !== "/statistik"
              ? "justify-content-between"
              : "justify-content-end"
          } `}
        >
          {roller.findIndex(
            (role) => role.navn === "miljoe_bbrexplore_adgang"
          ) > -1 && renderNav()}
          {/* <div style={matches1000 ? { display: "none" } : { display: "block" }}>
            <UserMenu authState={authState} />
          </div> */}
        </motion.div>
        <div
          className="c-mobile-nav justify-content-end"
          style={
            matches1000
              ? { display: "flex", marginTop: "0.2rem" }
              : { display: "none" }
          }
        >
          <DropDownMenu
            menuClassNames=" px-2 py-2"
            element={
              <div className="">
                <HamburgerMenu width={30} />
              </div>
            }
            menu={
              <>
                <NavLink
                  to="/esrsearch"
                  className="list-group-item list-group-item-action border-0 rounded"
                  activeClassName=""
                >
                  Søg ESR/BFE
                </NavLink>
                <NavLink
                  to="/fejllister"
                  className="list-group-item list-group-item-action border-0 rounded"
                  activeClassName=""
                >
                  Fejllister
                </NavLink>
                <NavLink
                  to="/statistik"
                  className="list-group-item list-group-item-action border-0 rounded"
                  activeClassName=""
                >
                  Statistik
                </NavLink>

                {roller.findIndex((role) => role.navn === "administrator") >
                  -1 && (
                  <NavLink
                    to="/admin"
                    className="list-group-item list-group-item-action border-0 rounded"
                    activeClassName=""
                  >
                    Admin
                  </NavLink>
                )}

                <form
                  action={authState.bruger?.logoutUrl ?? "/auth/logout"}
                  id="signoutForm"
                  method="post"
                  className="list-group-item list-group-item-action border-0 rounded"
                >
                  <input
                    type="submit"
                    className="border-0"
                    style={{ all: "unset", width: "100%" }}
                    value="Log ud"
                  />
                </form>
              </>
            }
          />
        </div>
      </div>

      <a
        className={`d-flex justify-content-end ${matches1300 ? "ms-3" : ""}`}
        href="https://www.vurdst.dk"
        title="Vurderingsstyrelsen"
        target="_blank"
        rel="noopener noreferrer"
      >
        {matches1300 ? <Vurdst width={130} /> : <Vurdst width={160} />}
      </a>
    </nav>
  );
}
