import { get } from "../index";

const getAll = async () => {
  try {
    return await get<Rolle[]>({
      query: `rolle?page=1&pageSize=9999`,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

const api: RolleApi = {
  getAll,
};

export default api;

export interface RolleApi {
  getAll: {
    (): Promise<Rolle[]>;
  };
}
