import { breakWords } from "util/index";
import { Bar } from "react-chartjs-2";
import { Chart, ChartOptions, registerables } from "chart.js";

Chart.register(...registerables);
interface IHorizontalStackedBarChartProps {
  vis?: "absolut" | "procentvis";
  data: StackedBarChartData;
}

interface StackedBarChartData {
  labels: Array<string>;
  datasets: Array<StackedBarChartDataPoint>;
}
interface StackedBarChartDataPoint {
  label: string;
  data: Array<number>;
  backgroundColor: string;
  borderColor?: string;
  borderWidth?: number;
}

export default function HorizontalStackedBarChart(
  props: IHorizontalStackedBarChartProps
) {
  const { data, vis = "absolut" } = props;
  const round = function (value) {
    return Math.round(value * 100 * 10) / 10;
  };
  const getProcentVisData = (
    data: StackedBarChartData
  ): StackedBarChartData => {
    const procentvisDatasets = data.datasets.map((dataset) => {
      return {
        ...dataset,
        data: dataset.data.map((d, index) => {
          const total = data.datasets.reduce((acc, curr) => {
            return acc + curr.data[index];
          }, 0);
          return round(d / total);
        }),
      };
    });

    return { ...data, datasets: procentvisDatasets };
  };
  const pData = getProcentVisData(data);
  const totals: number[] = [];
  data.datasets[0].data.forEach((d, i) => {
    totals[i] = data.datasets.reduce((acc, curr) => {
      return acc + curr.data[i];
    }, 0);
  });

  const options: ChartOptions<"bar"> = {
    indexAxis: "y",
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        stacked: true,
        // barThickness: 20,
        ticks: {
          font: {
            size: 13,
            family: "Open Sans",
          },

          color: "#003c16",
          callback: function (value, index, values) {
            const label = data.labels[index];

            const words = breakWords(label);
            const total = totals[index];
            words[words.length - 1] = `${words[words.length - 1]} - ${total}`;
            return words;
          },
        },
      },
      x: {
        max: 100,
        stacked: true,
      },
    },
  };
  const procentvisOptions: ChartOptions<"bar"> = {
    ...options,
    scales: {
      ...options.scales,
      x: {
        max: 100,
        stacked: true,
      },
    },
    plugins: {
      ...options.plugins,
      tooltip: {
        callbacks: {
          label: function (context) {
            const index = context.dataIndex;
            const datasetIndex = context.datasetIndex;
            var datasetLabel = data.datasets[datasetIndex].label || "";
            var originalValue = data.datasets[datasetIndex].data[index];
            var rateValue = pData.datasets[datasetIndex].data[index];
            return (
              "" + datasetLabel + ": " + rateValue + "% (" + originalValue + ")"
            );
          },
        },
      },
    },
  };
  return (
    <div style={{ height: totals.length * 40 + 70 }}>
      <Bar
        data={vis === "absolut" ? data : pData}
        options={vis === "absolut" ? options : procentvisOptions}
      />
    </div>
  );
}
