import React from "react";

export default function PlusSimple(props: Icon) {
  const { height, width, primaryColor, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (17 / 17) : 17}
      height={height ? height : width ? width * (17 / 17) : 17}
      viewBox="0 0 17 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-363.000000, -983.000000)"
          stroke={primaryColor ? primaryColor : "currentColor"}
          strokeWidth="1.6"
        >
          <g id="Group-2" transform="translate(364.000000, 984.316031)">
            <polyline
              id="Stroke-3"
              points="7.4835 -1.86517468e-13 7.4835 4.6523667 7.4835 11.3731756 7.4835 13.9571"
            ></polyline>
            <path
              d="M0,6.1798 L6.2905263,6.1798 C6.2905263,6.1798 12.5810526,5.6238 15,6.1798"
              id="Stroke-5"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
