import { ResultAppender } from '@/models/ResultAppender';
import { SearchResult } from '@/models/SearchResult';
import { SourceBase } from '@/models/SourceBase';
import { DafResult, EjendomFetcher } from '../Data/EjendomFetcher';
import DawaJordstykkeResult from './DawaJordstykkeResult';

export default class JordstykkeToEjendomAppender extends ResultAppender {
  public source: SourceBase;

  constructor(source: SourceBase) {
      super();
      this.source = source;
  }

  public CanAppendResult(resultDataType: string): boolean {
    return resultDataType === 'dawa_jordstykke';
  }

  public async getResults(searchresult: SearchResult, source: SourceBase): Promise<SearchResult[]> {
    const data = searchresult.data as DawaJordstykkeResult;

    const result = await EjendomFetcher.GetFromJordstykke(
      data.jordstykke.ejerlav.kode,
      data.jordstykke.matrikelnr,
      source.options.dafUser,
      source.options.dafPassword,
      source.options.dafEjendomsrelationDate,
    );

    return result.map((r: DafResult) =>
      EjendomFetcher.MapDafResultsToSearchResult(
        null,
        source.name,
        r,
        'bfe',
        source.getResultType('bfe'), null),
    );

  }
}
