import React from "react";

export default function Pause(props: Icon) {
  const { height, width, style = {} } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height : 16}
      height={height ? height : width ? width : 16}
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path
        d="M70.767-885.984H76.96a.737.737,0,0,0,.544-.222.744.744,0,0,0,.221-.546.733.733,0,0,0-.221-.543.743.743,0,0,0-.544-.219H70.767a.737.737,0,0,0-.544.222.744.744,0,0,0-.221.546.733.733,0,0,0,.221.543A.743.743,0,0,0,70.767-885.984Zm3.1,7.288a7.805,7.805,0,0,1-3.118-.625,8.068,8.068,0,0,1-2.543-1.715,8.068,8.068,0,0,1-1.715-2.543,7.808,7.808,0,0,1-.625-3.119,7.819,7.819,0,0,1,.625-3.121,7.969,7.969,0,0,1,1.713-2.536,8.191,8.191,0,0,1,2.542-1.711,7.764,7.764,0,0,1,3.121-.63,7.787,7.787,0,0,1,3.123.629,8.086,8.086,0,0,1,2.536,1.708,8.071,8.071,0,0,1,1.709,2.538,7.8,7.8,0,0,1,.63,3.125,7.755,7.755,0,0,1-.63,3.119,8.156,8.156,0,0,1-1.711,2.538,8.041,8.041,0,0,1-2.537,1.714A7.789,7.789,0,0,1,73.87-878.7Zm0-1.529a6.22,6.22,0,0,0,4.572-1.887,6.25,6.25,0,0,0,1.884-4.588,6.231,6.231,0,0,0-1.88-4.572,6.239,6.239,0,0,0-4.591-1.884,6.24,6.24,0,0,0-4.572,1.88,6.232,6.232,0,0,0-1.888,4.591,6.229,6.229,0,0,0,1.887,4.572A6.243,6.243,0,0,0,73.873-880.225ZM73.864-886.691Z"
        transform="translate(-65.869 894.696)"
      />
    </svg>
  );
}
