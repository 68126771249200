import { SourceBase } from './SourceBase';
import { SearchResult } from './SearchResult';
import { SearchInput } from './SearchInput';
import { SourceResult } from './SourceResult';
import { ResultBehaviour } from './ResultBehaviour';

export class SourceWorker {
    private static workerIndex: number = 0;
    public source: SourceBase;
    public results: SearchResult[];
    public isOpen: boolean = false;
    public showGroup: boolean = true;
    public isBusy: boolean = false;
    public hasError: boolean = false;
    private queue: Promise<void> = Promise.resolve();
    private latestInput: SearchInput = null;
    private inputDelay: number = 0;
    private delayTriggerId: number;
    private key: number;

    constructor(source: SourceBase) {
        this.source = source;
        this.results = [];

        if (source.options.inputDelay) {
            this.inputDelay = source.options.inputDelay;
        }

        this.key = SourceWorker.workerIndex++;
    }

    get sourceHasError(): boolean {
        return this.hasError;
    }

    get isLoading(): boolean {
        return this.isBusy;
    }

    get isAutoComplete(): boolean {
        return !!this.source.options.isAutoComplete;
    }

    get isReadOnly(): boolean {
        return !!this.source.options.isReadOnly;
    }

    get minimumInputLength(): number {
        return this.source.options.minimumInputLength;
    }

    get maximumInputLength(): number {
        return this.source.options.maximumInputLength;
    }

    get placeholderText(): string {
        return this.source.options.placeholderText;
    }

    get helpText(): string {
        return this.source.options.helpText;
    }

    get notFoundHelpText(): string {
        return this.source.options.notFoundHelpText;
    }


    public isMinimumLengthFulfilled(input: SearchInput): boolean {
        if (input.text.length === 0) {
            return false;
        }

        if (input.text.length < this.minimumInputLength) {
            return false;
        }

        return true;
    }

    public isMaximumLengthExceeded(input: SearchInput): boolean {
        if (this.maximumInputLength &&
            this.maximumInputLength > 0 &&
            this.maximumInputLength < input.text.length) {
            return true;
        }

        return false;
    }

    public isInputInValidFormat(input: SearchInput): boolean {
        if (!!this.source.options.validInput && !this.source.options.validInput.test(input.text)) {
            return false;
        }

        return true;
    }

    public canSearch(input: SearchInput): boolean {
        if (!!input.suggestion && this.source.resultAppenders) {
            return true;
        }

        if (!this.isMinimumLengthFulfilled(input)) {
            return false;
        }

        if (this.isMaximumLengthExceeded(input)) {
            return false;
        }

        if (!this.isInputInValidFormat(input)) {
            return false;
        }

        return true;
    }

    public search(input: SearchInput) {
        return new Promise<boolean>((resolve, reject) => {
            this.latestInput = Object.assign({}, input);
            this.hasError = false;
    
            if (!this.canSearch(input)) {
                this.clearResult();
                resolve(false);
                return;
            }
    
            const sourceResult = this;
            sourceResult.isBusy = true;
            clearTimeout(this.delayTriggerId);
            const inputModel = Object.assign({}, input);
            this.delayTriggerId = setTimeout(() => {
                sourceResult.queue = sourceResult.queue.then(() => {
                    if (sourceResult.isNotLatestInput(inputModel) ||
                        !sourceResult.canSearch(inputModel)) {
                            resolve(false);
                        // ignore search, outdated!!!
                        return;
                    }
    
                    return sourceResult.source.searchWithAppenders(inputModel).then((result) => {
                        sourceResult.searchDone(result, inputModel);
                        resolve(true);
                    }).catch((error) => {
                        sourceResult.hasError = true;
                        sourceResult.searchDone([], inputModel);
                        sourceResult.isBusy = false;
                        resolve(false);
                    });
                });
            }, this.inputDelay);
        });
    }

    public clearResult() {
        this.results = [];
        this.latestInput = null;
        this.isBusy = false;
    }

    public isNotLatestInput(inputModel: SearchInput) {
        return !this.latestInput || (this.latestInput &&
            (inputModel.text !== this.latestInput.text ||
                inputModel.suggestion !== this.latestInput.suggestion));
    }

    public hasResult(input: SearchInput): boolean {
        if (this.resultCount > 0) {
            return true;
        }

        switch (this.source.sourceType) {
            case ResultBehaviour.default:
                if (!this.isAutoComplete && this.canSearch(input)) {
                    return true;
                }

                if (!this.source.options.hideWhenEmpty && this.isAutoComplete) {
                    return true;
                }
        }

        return false;
    }

    public getSearchResults(input: SearchInput, noGroup: boolean = false): SourceResult[] {
        const worker = this;

        const behaviour = noGroup ? ResultBehaviour.noGroup : this.source.sourceType;

        switch (behaviour) {
            case ResultBehaviour.default:
                if (this.source.options.hideWhenEmpty && this.resultCount === 0) {
                    return [];
                }

                if (!worker.isAutoComplete && !worker.canSearch(input)) {
                    return [];
                }

                return [
                    {
                        id: worker.key.toString(),
                        searchResult: null,
                        sourceWorker: worker,
                        component: worker.isAutoComplete ? 'GroupItemComponent' : 'GroupItemNoAutocompleteComponent',
                    } as SourceResult];
            case ResultBehaviour.noGroup:
            case ResultBehaviour.noGroupSimple:

                return this.results.slice(0, this.source.resultLimit).map((r) => {
                    return {
                        id: worker.key.toString() + '_' + r.id,
                        searchResult: r,
                        sourceWorker: worker,
                        component: 'ResultItemComponent',
                    } as SourceResult;
                });
        }
    }

    private searchDone(result: SearchResult[], input: SearchInput) {
        if (this.isNotLatestInput(input) ||
            !this.canSearch(input)) {
            // ignore result outdated!!!
            return;
        }

        this.results = result;
        this.isBusy = false;
    }

    get resultCount() {
        return this.results.length;
    }

    get isDisabled() {
        return this.isAutoComplete && this.resultCount === 0;
    }

    get groupResults(): SourceResult[] {
        const worker = this;
        return this.results.slice(0, this.source.resultLimit).map((r) => {
            return {
                id: worker.key.toString() + '_' + r.id,
                searchResult: r,
                sourceWorker: worker,
                component: 'ResultItemComponent',
            } as SourceResult;
        });
    }
}
