import { SearchResult } from './SearchResult';
import { SourceBase } from './SourceBase';

export class ExtendedResultAppender {

    public CanExtentResult(resultDataType: string): boolean {
        return false;
    }

    public getExtendedResult(result: SearchResult, source: SourceBase): Promise<SearchResult> {
        return new Promise((resolve, reject) => {
                return resolve(null);
            });
    }
}
