import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./UserMenu.module.scss";
// import ArrowTriangle from "components/common/icons/ArrowTriangle";
// import FoldOutMenu from "components/common/FoldOutMenu";
import { AuthState } from "context/AuthProvider";
import Avatar from "components/common/Avatar";
import DropDownMenu from "components/common/DropDownMenu";

interface UserMenuProps {
  authState: AuthState;
}

export default function UserMenu(props: UserMenuProps) {
  const { bruger } = props.authState;
  const roller = bruger && bruger.roller ? bruger.roller : [];
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();
  const logoutUrl = bruger ? bruger.logoutUrl : "auth/logout";

  const renderElement = () => {
    const containerClassName = menuOpen
      ? `${styles.usertoggle} ${styles.active}`
      : styles.usertoggle;
    return (
      <div
        aria-label="Brugermenu"
        aria-haspopup="true"
        className={containerClassName}
      >
        <Avatar bruger={bruger} size={"large"} />
        &ensp;
      </div>
    );
  };

  const renderMenu = () => {
    return (
      // <div className="{styles.menu} d-flex flex-column list-group w-100">
      <>
        {bruger && (
          <>
            <div className="w-100 d-flex justify-content-start align-items-center py-2 px-3 border-0 rounded">
              {bruger.navn}
            </div>
            {roller.findIndex((role) => role.navn === "administrator") > -1 && (
              <button
                className="list-group-item list-group-item-action border-0 rounded"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push("/admin");
                }}
              >
                Admin
              </button>
            )}
            <form
              action={`${logoutUrl}`}
              id="signoutForm"
              method="post"
              className="w-100 mw-100 py-0 px-0 list-group-item list-group-item-action border-0 rounded"
            >
              <input
                type="submit"
                className="link-all-unset w-100 mw-100 ps-3 py-2 "
                value="Log ud"
              />
            </form>
          </>
        )}
        {!bruger && <div>Ikke logget ind</div>}
        {/* </div> */}
      </>
    );
  };
  return (
    <DropDownMenu
      state={{ menuOpen, setMenuOpen }}
      element={renderElement()}
      menu={renderMenu()}
      width="200px"
      menuClassNames="px-2 py-2"
    />
  );
}
