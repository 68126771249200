import styles from "./Badge.module.scss";
export default function Badge(props: {
  text: string;
  title: string;
  className?: string;
}) {
  return (
    <span
      className={`${styles.badge} ${props.className || ""}`}
      title={props.title}
    >
      {props.text}
    </span>
  );
}
