/* All enums have "0" as undefined */
export enum OpgaveListeType {
  undefined,
  kan,
  skal,
}

export enum OpgaveListeStatus {
  undefined,
  aktiv,
  passiv,
  udgaaet,
}

export enum OpgaveBrugerStatus {
  ikkeTildelt,
  iGang,
  opgaveLoest,
  ikkeFejl,
  ikkeAktiv,
  kanIkkeLoeses,
}

export function OpgaveBrugerStatusToString(
  opgaveBrugerStatus: OpgaveBrugerStatus
) {
  switch (opgaveBrugerStatus) {
    case OpgaveBrugerStatus.ikkeTildelt:
      return "Åben";
    case OpgaveBrugerStatus.iGang:
      return "I gang";
    case OpgaveBrugerStatus.opgaveLoest:
      return "Færdig";
    case OpgaveBrugerStatus.ikkeFejl:
      return "Ikke fejl";
    case OpgaveBrugerStatus.ikkeAktiv:
      return "Ikke aktiv";
    case OpgaveBrugerStatus.kanIkkeLoeses:
      return "Kan ikke løses";
    default:
      return OpgaveBrugerStatus[opgaveBrugerStatus];
  }
}

export enum OpgaveSystemStatus {
  undefined,
  aktuel,
  opgaveLoest,
  ikkeFejl,
  ikkeAktiv,
}

export enum OpgaveSortOrder {
  undefined,
  //-NOT IN USE --------
  opgaveIdAsc,
  opgaveIdDesc,
  deadlineAsc,
  deadlineDesc,
  prioritetAsc,
  prioritetDesc,
  //--------------------
  medarbejderInitialerAsc,
  medarbejderInitialerDesc,
  bfeAsc,
  bfeDesc,
  adresseAsc,
  adresseDesc,
}

export enum ListeSortOrder {
  Undefined,
  ListeIdAsc,
  ListeIdDesc,
  StatusAsc,
  StatusDesc,
  NavnAsc,
  NavnDesc,
  BeskrivelseAsc,
  BeskrivelseDesc,
}

export enum OpgaveKommentarAdgang {
  ingenAdgang,
  laeseAdgang,
  skriveAdgang,
}

export enum ListeKommentarType {
  ingen,
  skatteforvaltningen,
}
