import Vue from 'vue';

export const eventbus = new Vue();

export const eventNames = {
    setFocus: 'set-focus',
    selectText: 'select-text',
    setText: 'set-text',
    blur: 'blur',
};
