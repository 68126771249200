import React from "react";

export default function Grund(props: Icon) {
  const { height, width, primaryColor, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (30 / 25) : 30}
      height={height ? height : width ? width * (25 / 30) : 25}
      viewBox="0 0 30 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-437.000000, -349.000000)"
          fill={primaryColor ? primaryColor : "currentColor"}
          fillRule="nonzero"
        >
          <g id="Group" transform="translate(437.777256, 349.534447)">
            <path
              d="M9.10600344,11.7894253 C9.98675898,13.0652506 11.9097528,15.8075586 12.5009875,16.6646697 C13.5429941,18.1699725 15.7692436,18.1700097 16.8112935,16.6646931 C17.4025417,15.8075624 19.3255377,13.0652511 20.2062936,11.7894253 L23.8517497,11.7894253 C24.2513963,11.7894253 24.6126665,12.0273733 24.7704806,12.394541 L29.0516848,22.3551157 C29.2697735,22.8625167 29.0352393,23.4506422 28.5278383,23.6687309 C28.4030926,23.7223484 28.2687345,23.75 28.132954,23.75 L1,23.75 C0.44771525,23.75 -9.34363698e-13,23.3022847 -9.34363698e-13,22.75 C-9.34363698e-13,22.6142196 0.0276515775,22.4798614 0.0812691387,22.3551157 L4.36247344,12.394541 C4.5202876,12.0273733 4.8815577,11.7894253 5.2812043,11.7894253 L9.10600344,11.7894253 Z"
              id="Shape-path"
            ></path>
            <path
              d="M14.0426583,15.5974884 C9.52517894,9.04848336 8.6866511,8.37635526 8.6866511,5.96949742 C8.6866511,2.67262484 11.3592759,-6.74127421e-13 14.6561485,-6.74127421e-13 C17.9530211,-6.74127421e-13 20.625646,2.67262484 20.625646,5.96949742 C20.625646,8.37635526 19.7871181,9.04848336 15.2696387,15.5974884 C14.9731848,16.0257376 14.3390812,16.0257066 14.0426583,15.5974884 Z M14.6561485,8.45678802 C16.029848,8.45678802 17.1434391,7.34319692 17.1434391,5.96949742 C17.1434391,4.59579792 16.029848,3.48220684 14.6561485,3.48220684 C13.282449,3.48220684 12.168858,4.59579792 12.168858,5.96949742 C12.168858,7.34319692 13.282449,8.45678802 14.6561485,8.45678802 Z"
              id="Shape"
              opacity="0.6"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
