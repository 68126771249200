import React, { useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { mergeClassNames } from "util/index";
import styles from "./EnhedModalExternal.module.scss";
import { RightPaneOpenContext } from "routes/Ejendom/Ejendom";
import CloseLg from "components/common/icons/CloseLg";

export default function EnhedModal(props: IEnhedModal) {
  const { children, shown } = props;
  const rightPaneOpen = useContext(RightPaneOpenContext);
  const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
  };
  return (
    <div className={styles.container}>
      <AnimatePresence>
        {shown && (
          <motion.div
            className={mergeClassNames(
              styles.containerContent,
              rightPaneOpen ? styles.marginRight : ""
            )}
            variants={variants}
            initial="closed"
            animate="open"
            exit={variants.closed}
            transition={{ duration: 0.15 }}
            role="dialog"
            aria-modal="true"
          >
            <div className={styles.inner}>
              <button
                className={styles.close}
                title="Luk"
                aria-label="Luk dialogboks"
              >
                <CloseLg height={20} />
              </button>
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

interface IEnhedModal {
  expand: boolean;
  shown: boolean;
  children: React.ReactNode;
}
