import React from "react";

export default function CvrBranchekoder(props: ICvrBranchekoderProps) {
  const { branchekoder } = props;
  const { oplysninger } = branchekoder;
  const uniqueOplysninger: CvrBranchekoderOplysning[] = [];
  oplysninger.forEach((o) => {
    if (
      uniqueOplysninger.findIndex(
        (uo) => uo.hovedbrancheBranchekode === o.hovedbrancheBranchekode
      ) < 0
    )
      uniqueOplysninger.push(o);
  });
  return (
    <div>
      <h4>Hovedbranchekoder</h4>
      <ul>
        {uniqueOplysninger.map((o) => {
          const { hovedbrancheBranchekode, hovedbrancheBranchetekst } = o;
          return (
            <li key={hovedbrancheBranchekode}>
              <p>
                {hovedbrancheBranchekode}: {hovedbrancheBranchetekst}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

interface ICvrBranchekoderProps {
  branchekoder: CvrBranchekoder;
}
