import React from "react";
import styles from "./ArtikelListItem.module.scss";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { da } from "date-fns/locale";
import Arrow from "components/common/icons/Arrow";

export default function ArtikelListItem(props: IArtikelListItemProps) {
  const { artikel } = props;
  const {
    dato,
    forfatter,
    id,
    titel,
    // indhold
  } = artikel;
  // const getIngress = () => {
  //   let ingress = indhold.split("<p>");
  //   if (ingress.length < 2) return indhold.substr(0, 100);
  //   ingress = ingress[1].split("</p>");
  //   return ingress[0];
  // };
  return (
    <li
      className={styles.listitem}
      // onClick={() => {
      //   history.push("?artikelid=" + Id);
      //   setArtikel(Id);
      // }}
    >
      <Link
        className={styles.artikellistitem}
        to={`/hjaelp-til-fejllister?artikelid=${id}`}
      >
        <div className={styles.main}>
          <h2>{titel}</h2>
          {/* <p className={styles.ingress}>{getIngress()}</p> */}
          <p className={styles.footer}>
            <small>
              Dato:{" "}
              {format(new Date(dato), "PPP", {
                locale: da,
              })}
            </small>
            <small>Afsender: {forfatter}</small>
          </p>
        </div>
        <span className={styles.arrow}>
          <Arrow />
        </span>
      </Link>
    </li>
  );
}

interface IArtikelListItemProps {
  artikel: Artikel;
}
