export declare const useMatrikelPaging: boolean;

export const format = 'format=json&pagesize=9999&page=1';
export const formatJson = typeof useMatrikelPaging !== 'undefined' && !!useMatrikelPaging ? format : 'format=json';
export const dafUrl = 'https://services.datafordeler.dk';

function constructUrl(area: string, paging: boolean, user: string, password: string) {
    return `${dafUrl}/${area}&${paging ? format : formatJson}&username=${user}&password=${password}`;
}

export function getUrl(area: string, user: string, password: string) {
    return constructUrl(area, false, user, password);
}

export function getUrlPaging(area: string, user: string, password: string) {
    return constructUrl(area, true, user, password);
}
