import { SourceBase } from '../../../models/SourceBase';
import { SourceOptions } from '../../../models/SourceOptions';
import { SearchResult, ResultType } from '../../../models/SearchResult';
import { SearchInput } from '../../../models/SearchInput';
import { ResultBehaviour } from '@/models/ResultBehaviour';
import { SourceFilters } from '@/models/SourceFilters';
import { NameValue } from '@/models/NameValue';

const defaultFritekstOptions: IHistorySourceOptions = {
    minimumInputLength: 1,
    inputDelay: 0,
    hideWhenEmpty: true,
    title: 'Historik',
    icon: 'icon-source-history',
    resultBehaviour: ResultBehaviour.noGroupSimple,
    resultLimit: 5,
    history: [],
    isAutoComplete: true,
};

export class HistorySource extends SourceBase {
    public static sourceName: string = 'history';
    public name: string;
    public options: IHistorySourceOptions;
    public filters: SourceFilters;

    constructor(options: IHistorySourceOptions) {
        super();
        this.options = Object.assign({}, defaultFritekstOptions, options);

        this.name = HistorySource.sourceName;
    }

    public search(input: SearchInput): Promise<SearchResult[]> {
        const source = this;
        return new Promise((resolve, reject) => {
            if (input.id) {
                return resolve([]);
            }

            // tslint:disable-next-line:max-line-length
            return resolve(source.options.history.filter((h) => h.title.toUpperCase().indexOf(input.text.toUpperCase()) === 0 ||  (h.description && h.description.toUpperCase().indexOf(input.text.toUpperCase()) === 0)).map((h) => {
                const sourceIdAndName = source.options.sourceAndName.find( (s) => s.sourceId === h.source);
                let sourceName = 'Ukendt kilde';
                if (sourceIdAndName) {
                    sourceName = sourceIdAndName.sourceName;
                }
                return {
                    id: h.id,
                    title: h.title + ` (${sourceName})`,
                    data: h.data,
                    resultType: h.resultType,
                    source: h.source,
                } as SearchResult;
            }));
        });
    }
}

// tslint:disable-next-line:no-empty-interface
export interface IHistorySourceOptions extends SourceOptions {
    history?: SearchResult[];
    sourceAndName?: NameValue[];
}
