import { format } from "date-fns";
import ExternalArrow from "components/common/icons/ExternalArrow";
import { da } from "date-fns/locale";
import Badge from "components/common/Badge";
import styles from "./SupplerendeOplysninger.module.scss";

export default function ChrOplysninger(props: IChrOplysningerProps) {
  const { chrOplysninger } = props;
  const { oplysninger } = chrOplysninger;
  return (
    <div>
      <h4 className="mb-3 text-info d-flex align-items-center gap-2">
        CHR{" "}
        <Badge
          className="bg-white bg-opacity-75 text-info"
          text={oplysninger.length.toString()}
          title={`${oplysninger.length.toString()} CHR`}
        />
      </h4>
      <ul className="d-flex flex-column gap-2">
        {oplysninger.map((oplysning) => {
          const { chrEjendom } = oplysning;
          const { chrNummer, dyrearter, ophoerteDyrearter } = chrEjendom;
          return (
            <li
              className="p-3 bg-white bg-opacity-50 rounded d-flex flex-column gap-3"
              key={chrNummer}
            >
              {chrNummer !== 0 && (
                <h4 id={"chrnummer"}>
                  <a
                    className="external text-info"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://chr.fvst.dk/"
                  >
                    <span>CHR {chrNummer}</span>
                    <ExternalArrow />
                  </a>
                </h4>
              )}
              {chrNummer === 0 && <p>Adressen har ikke noget CHR nummer.</p>}
              <div>
                <h5 className="text-info fw-semibold mb-n2 d-flex align-items-center gap-2">
                  Registrerede besætninger{" "}
                  <Badge
                    className="bg-info bg-opacity-10 text-info"
                    text={dyrearter.length.toString()}
                    title={`${dyrearter.length.toString()} besætninger`}
                  />
                </h5>
                {dyrearter.length > 0 && (
                  <ul
                    className={`list-group list-group-flush ${styles["list-group--custom"]}`}
                  >
                    {dyrearter.map((o) => {
                      return (
                        <li
                          className="list-group-item"
                          key={"dyreartKode" + o.dyreartKode}
                        >
                          <table>
                            <tbody>
                              <tr>
                                <th scope="row">Art</th>
                                <td>{o.dyreartTekst}</td>
                              </tr>
                              <tr>
                                <th scope="row">Type</th>
                                <td>{o.virksomhedartTekst}</td>
                              </tr>
                              <tr>
                                <th scope="row">Antal i alt</th>
                                <td>{o.antalIAlt}</td>
                              </tr>
                            </tbody>
                          </table>
                        </li>
                      );
                    })}
                  </ul>
                )}
                {dyrearter.length === 0 && (
                  <p className="mt-3 mb-0 opacity-75">
                    Ingen registrerede dyrearter
                  </p>
                )}
              </div>
              <div className="opacity-75 mt-2">
                <h5 className="text-info fw-semibold mb-n2 d-flex align-items-center gap-2">
                  Ophørte dyrearter{" "}
                  <Badge
                    className="bg-info bg-opacity-10 text-info"
                    text={ophoerteDyrearter.length.toString()}
                    title={`${ophoerteDyrearter.length.toString()} besætninger`}
                  />
                </h5>
                {ophoerteDyrearter.length > 0 && (
                  <ul
                    className={`list-group list-group-flush ${styles["list-group--custom"]}`}
                  >
                    {ophoerteDyrearter.map((o) => {
                      return (
                        <li className="list-group-item" key={o.dyreartKode}>
                          <table>
                            <tbody>
                              <tr>
                                <th scope="row">Art</th>
                                <td>{o.dyreartTekst}</td>
                              </tr>
                              <tr>
                                <th scope="row">Type</th>
                                <td>{o.virksomhedartTekst}</td>
                              </tr>
                              <tr>
                                <th scope="row">Ophørt</th>
                                <td>
                                  {format(new Date(o.ophoert), "PPP", {
                                    locale: da,
                                  })}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </li>
                      );
                    })}
                  </ul>
                )}
                {ophoerteDyrearter.length === 0 && (
                  <p className="mt-3 mb-0">Ingen ophørte dyrearter</p>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

interface IChrOplysningerProps {
  chrOplysninger: ChrOplysninger;
}
