import React from "react";

const PaginationButton = ({
  ariaLabel,
  disabled,
  style,
  onClick,
  children,
}) => (
  <button
    aria-label={ariaLabel}
    className="h-100 p-2 border-end d-flex align-items-center"
    style={style}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </button>
);

export default PaginationButton;
