import React from "react";

export default function Bbr(props: Icon) {
  const { height, width, primaryColor, secondaryColor, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height : 36}
      height={height ? height : width ? width : 37}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 87 51"
    >
      <g fillRule="nonzero" fill="none">
        <path
          fill={primaryColor ? primaryColor : "#003C16"}
          d="M42.639.154L0 16.21v34.034h86.326V16.21z"
        />
        <path
          d="M21.608 40.234V19.191H32.39c3.76 0 6.528 1.617 6.528 5.462 0 1.911-1.072 3.812-3.063 4.51v.022c1.906.323 3.636 2.257 3.636 5.177 0 4.254-3.897 5.87-7.742 5.87H21.61l-.002.002zm4.39-12.721h5.286c1.452 0 2.694-.375 2.694-2.11 0-1.798-1.446-2.11-2.92-2.11h-5.06v4.22zm0 8.637h5.604c1.622 0 3.21-.317 3.21-2.336 0-2.025-1.56-2.343-3.352-2.343h-5.462v4.68-.001zm16.192 4.084V19.191h10.787c3.76 0 6.534 1.617 6.534 5.462 0 1.911-1.072 3.812-3.068 4.51v.022c1.911.323 3.647 2.257 3.647 5.177 0 4.254-3.902 5.87-7.748 5.87H42.19v.002zm4.396-12.721h5.286c1.446 0 2.688-.375 2.688-2.11 0-1.798-1.44-2.11-2.915-2.11h-5.06v4.22h.001zm0 8.637h5.609c1.616 0 3.21-.317 3.21-2.336 0-2.025-1.56-2.343-3.352-2.343h-5.462c-.005 0-.005 4.68-.005 4.68v-.001zm20.729-3.465v7.549h-4.537V19.193h10.26c4.508 0 7.197 2.512 7.197 6.822 0 3.437-1.73 5.604-4.588 6.353l5.512 7.871h-5.32l-4.883-7.554h-3.641zm0-3.845h5.059c1.758 0 3.295-.72 3.295-2.78 0-2.047-1.417-2.857-3.295-2.857h-5.059v5.637z"
          fill={secondaryColor ? secondaryColor : "#FFF"}
        />
      </g>
    </svg>
  );
}
