import {
  useHistory,
  useLocation,
  // useParams
} from "react-router-dom";
import BbrLight from "components/common/icons/BbrLight";
import ArrowRight from "components/common/icons/ArrowRight";
import ChevronRight from "components/common/icons/ChevronRight";
import { AuthState } from "context/AuthProvider";

// type RouteParams = { id: string };

export default function Breadcrumbs(props: {
  currentList?: { navn: string; id: string };
  bfe?: number;
  classNames?: string;
  authState: AuthState | undefined;
}) {
  const { currentList, bfe, classNames, authState } = props;
  const bruger = authState?.bruger;
  const history = useHistory();
  const location = useLocation();

  return (
    <div
      className={`d-flex align-items-center justify-content-between pt-2 ${
        classNames ? classNames : "pb-5"
      }`}
    >
      <div className="d-flex align-items-center justify-content-start">
        <button
          onClick={() => history.push("/")}
          className={`d-flex align-items-center gap-2 pe-2 pb-1 text-muted opacity-50 ${
            location.pathname === "/" ? "fw-semibold" : "fw-normal"
          }`}
        >
          <BbrLight height={30} />
        </button>
        <button
          onClick={() => history.push("/fejllister")}
          className={`d-flex align-items-center gap-2 pe-2 `}
        >
          <span className="text-muted d-flex align-items-center">
            <ChevronRight width={12} />
          </span>
          <p
            className={`mb-0 fs-5 $ ${
              location.pathname === "/fejllister"
                ? "fw-semibold text-primary"
                : "fw-semibold text-muted"
            }`}
          >
            Fejllister
          </p>
        </button>
        {currentList ? <FejllisteCrumbs currentList={currentList} /> : null}
        {bfe && <EjendomCrumbs bfe={bfe} />}
      </div>
      {location.pathname === "/fejllister" && bruger?.erSkatteforvaltningen ? (
        <a href="/hjaelp-til-fejllister" className="fs-5 d-flex gap-2">
          Få hjælp til fejllister
          <span>
            <ArrowRight />
          </span>
        </a>
      ) : null}
    </div>
  );
}

function EjendomCrumbs(props: { bfe: number }) {
  return (
    <div className={`d-flex align-items-center gap-2 pe-2`}>
      <span className="text-muted d-flex align-items-center">
        <ChevronRight width={12} />
      </span>

      <p className={`mb-0 fs-5 fw-semibold text-primary`}>BFE: {props.bfe}</p>
    </div>
  );
}

function FejllisteCrumbs(props: { currentList: { navn: string; id: string } }) {
  const history = useHistory();
  const location = useLocation();
  const { currentList } = props;
  return (
    <>
      <button
        onClick={() => history.push(`/fejllister/${currentList.id}`)}
        className={`d-flex align-items-center gap-2 pe-2`}
      >
        <span className="text-muted d-flex align-items-center">
          <ChevronRight width={12} />
        </span>
        <p
          className={`mb-0 fs-5 $ ${
            location.pathname === `/fejllister/${currentList.id}`
              ? "fw-semibold text-primary"
              : "fw-semibold text-muted"
          }`}
        >
          {currentList.navn ? currentList.navn : ""}
        </p>
      </button>
    </>
  );
}
