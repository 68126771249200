import { SourceBase } from '../../../models/SourceBase';
import { SourceOptions } from '../../../models/SourceOptions';
import { SearchResult } from '../../../models/SearchResult';
import { SearchInput } from '../../../models/SearchInput';
import { ResultBehaviour } from '@/models/ResultBehaviour';
import { SourceFilters } from '@/models/SourceFilters';
import { EjendomFetcher } from '../Data/EjendomFetcher';
import { BfeToGrundAppender } from './BfeToGrundAppender';
import DafEjendomFilters from './DafEjendomFilters';
import { BfeToGrundExtendedAppender } from './BfeToGrundExtendedAppender';
const defaultOptions: SourceOptions = {
    minimumInputLength: 1,
    maximumInputLength: 10,
    inputDelay: 250,
    resultBehaviour: ResultBehaviour.default,
    hideWhenEmpty: false,
    title: 'BFE-nummer',
    icon: 'icon-source-ejendom',
    resultLimit: 20,
    fetchExtendedResult: true,
    validInput: /^[1-9][0-9]*$/,
    isAutoComplete: false,
    notFoundHelpText: 'Dette BFE-nummer findes ikke, prøv med flere/færre cifre, eller kontroller om du har det korrekte nummer',
};

const defaultFilters: DafEjendomFilters = {
    dataResultType: 'ejendom',
    extendedResultType: 'ejendom',
};

export class DafEjendomSource extends SourceBase {
    public static sourceName: string = 'daf.ejendom';
    public name: string;
    public options: SourceOptions;
    public filters: DafEjendomFilters;

    constructor(options: SourceOptions, filters: SourceFilters) {
        super();
        this.options = Object.assign({}, defaultOptions, options);
        this.filters = Object.assign({}, defaultFilters, filters);

        this.name = DafEjendomSource.sourceName;
        if ('name' in this.options && this.options.name) {
            this.name = this.options.name;
        }

        if (this.filters.dataResultType === 'grund') {
            this.resultAppenders = [new BfeToGrundAppender(this)];
        }

        if (!!this.options.fetchExtendedResult) {
            if (this.filters.extendedResultType === 'grund') {
                this.extendedResultAppender = new BfeToGrundExtendedAppender(this);
            }
        }
    }

    public async search(input: SearchInput): Promise<SearchResult[]> {
        const bfe = parseInt(input.id ? input.id : input.text, 10);
        const result = await EjendomFetcher.GetFromBFE(bfe, this.options.dafUser, this.options.dafPassword, this.options.dafEjendomsrelationDate);
        return result.map((r) =>
            EjendomFetcher.MapDafResultsToSearchResult(
                null,
                this.name,
                r,
                'bfe',
                this.getResultType('bfe'),
                // Leaving this here for further testing of failed fetch extended result.
                //this.getResultType(!!this.options.fetchExtendedResult ? '' : 'bfe'),
                bfe.toString()));
    }
}
