import { animate } from "framer-motion";
import React, { useEffect, useRef } from "react";

interface IStatSummaryProps {
  title: string;
  value: number;
}
export default function StatSummary(props: IStatSummaryProps) {
  const { title, value } = props;
  const nodeRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(0, value, {
      duration: 1,
      ease: "easeOut",
      onUpdate(value) {
        if (node) {
          node.textContent = value.toFixed(0);
        }
      },
    });

    return () => controls.stop();
  }, [value]);
  return (
    <li>
      <div style={{ color: "#5C7A67" }}>{title}</div>
      <div ref={nodeRef} style={{ fontSize: 30, color: "#003c16" }}></div>
    </li>
  );
}
