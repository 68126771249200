import StatSummary from "components/common/Charts/StatSummary";
import { useCallback, useMemo } from "react";
import styles from "../../../Statistik.module.scss";
import statistikkApi from "api/statistik";
import { usePromise } from "hooks/usePromise";
import { mergeClassNames } from "util/index";

export default function Overblik() {
  const fetchOverblikStatus = useCallback(
    () => statistikkApi.getOverblik(),
    []
  );
  const overblikStateDefault = useMemo(() => {
    return [];
  }, []);
  const overblikState = usePromise(
    fetchOverblikStatus,
    overblikStateDefault
  ).state;
  return (
    <div
      className={mergeClassNames(
        styles.statcontainer,
        overblikState.state === "LOADING" ? styles.loading : ""
      )}
    >
      <ul className={styles.overblik}>
        {overblikState.state === "LOADING" && (
          <div style={{ opacity: 0 }}>
            <StatSummary title={"Loading"} value={0} />
          </div>
        )}
        {overblikState.state === "IDLE" && (
          <>
            {(overblikState.data as any).map((overblik) => {
              return (
                <StatSummary
                  key={overblik.id}
                  title={overblik.navn}
                  value={parseInt(overblik.vaerdi)}
                />
              );
            })}
          </>
        )}
      </ul>
    </div>
  );
}
