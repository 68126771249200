import React from "react";

export default function OpenModal(props: Icon) {
  const { height, width, primaryColor, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (11 / 11) : 11}
      height={height ? height : width ? width * (11 / 11) : 11}
      viewBox="0 0 11 11"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          transform="translate(-1020.000000, -572.000000)"
          stroke={primaryColor ? primaryColor : "currentColor"}
          strokeWidth="1.6"
        >
          <g transform="translate(1021.000000, 573.000000)">
            <path
              d="M0,8.42792847 C0.755544352,7.47738518 7.57922984,1.92496624 7.94196221,0.876288607 C7.94674582,0.862458937 7.91269548,0.87611645 7.89806212,0.876030371"
              id="Path-60"
            ></path>
            <path
              d="M2.56921509,0.527928474 C3.47588108,0.590335466 4.81072156,0.672932677 5.71855172,0.71514945 C6.65479595,0.758687561 7.67534307,0.56020504 7.9424188,0.71514945 C8.20949453,0.87009386 8.29701531,1.60921834 8.25210344,2.03292688 C8.18987013,2.62004971 8.23612705,3.21383906 8.20949453,3.80364996 C8.18197198,4.41317158 8.1423383,5.02228648 8.0896731,5.63015198 C8.08844511,5.64432557 8.06316089,5.64048141 8.04990479,5.64564613"
              id="Path-61"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
