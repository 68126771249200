import React from "react";
export default function BygningPaaFremmedGrund(
  props: IBygningPaaFremmedGrundProps
) {
  const { BygningPaaFremmedGrund } = props;
  const { bfeNummer } = BygningPaaFremmedGrund;
  return (
    <div className="bg-info-subtle px-4 py-3 mb-3 bg-opacity-75">
      <h4 className="h5 text-body fw-semibold">Bygning På Fremmed Grund</h4>
      <p>BFE: {bfeNummer}</p>
    </div>
  );
}

interface IBygningPaaFremmedGrundProps {
  BygningPaaFremmedGrund: any;
}
