import React, { useState } from "react";
import ArrowUpRight from "components/common/icons/ArrowUpRight";

interface ILinkProps {
  href: string;
  title: string;
  classname?: string;
}

export default function ExternalLinkItem(props: ILinkProps) {
  const { href, title, classname } = props;
  const [hoverState, setHoverState] = useState(false);

  return (
    <div
      className="c-external-linkitem"
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
    >
      <a
        className={`d-flex justify-content-start align-items-end fs-5 ${
          hoverState ? "text-primary" : "text-muted"
        } ${classname && classname}`}
        rel="noopener noreferrer"
        target="_blank"
        href={href}
        style={{ lineHeight: "20px" }}
      >
        <p className="mb-0" style={{ maxWidth: "100%" }}>
          {title}
          {hoverState ? (
            <ArrowUpRight
              width={18}
              style={{
                paddingBottom: "2px",
                paddingLeft: "2px",
                visibility: "visible",
              }}
            />
          ) : (
            <ArrowUpRight
              width={18}
              style={{
                paddingBottom: "2px",
                paddingLeft: "2px",
                visibility: "hidden",
              }}
            />
          )}
        </p>
      </a>
    </div>
  );
}
