import React from "react";

export default function MinusSimple(props: Icon) {
  const { height, width, primaryColor, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (22 / 22) : 22}
      height={height ? height : width ? width * (22 / 22) : 22}
      viewBox="0 0 22 2"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(-361.000000, -205.000000)"
          stroke={primaryColor ? primaryColor : "currentColor"}
          strokeWidth="1.6"
        >
          <path
            d="M362.5,206.123556 L370.468,206.123556 C370.468,206.123556 378.436,205.567556 381.5,206.123556"
            id="Stroke-5"
          ></path>
        </g>
      </g>
    </svg>
  );
}
