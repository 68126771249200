import React from "react";

export default function ChevronDown(props: Icon) {
  const {
    height,
    width,
    // primaryColor,
    style,
  } = props;
  return (
    <svg
      style={style}
      width={width ? width : height ? height : 12}
      height={height ? height : width ? width : 12}
      viewBox="0 0 16 16"
      version="1.1"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // fill-rule="evenodd"
        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
      />
    </svg>
  );
}
