import React from "react";
// import styles from "./Toast.module.scss";
// import { mergeClassNames } from "util/index";
import { AnimatePresence, motion } from "framer-motion";
import CheckmarkAnimated from "./icons/CheckmarkAnimated";
import ErrorAnimated from "./icons/ErrorAnimated";

export interface IToast {
  status: "ERROR" | "OK";
  text: string;
  show: boolean;
  duration?: number;
}

interface ToastProps {
  options: IToast;
}

export default function Toast(props: ToastProps) {
  const { text, status, show } = props.options;
  // const statusClassName = status === "OK" ? styles.ok : styles.error;
  const statusClassName = status === "OK" ? "status--ok" : "status--error";

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          // initial={{ opacity: 0, scale: 0.9, x: "-50%" }} // animate from
          // animate={{ opacity: 1, scale: 1, x: "-50%" }} // animate to
          // exit={{ opacity: 0, scale: 0.9, x: "-50%" }} // animate exit

          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          // className={mergeClassNames(styles.notification, statusClassName)}
          className="c-toast"
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            className={`c-toast-notification ${statusClassName}`}
          >
            {text}
            {status === "ERROR" ? (
              <span className="text-danger">
                <ErrorAnimated width={20} height={20} />
              </span>
            ) : null}
            {status === "OK" ? (
              <span className="text-success">
                <CheckmarkAnimated width={20} height={20} />
              </span>
            ) : null}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
