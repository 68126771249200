export default function CheckmarkAnimated(props: Icon) {
  const { height, width } = props;
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130 138"
      height={height ? height : width ? width : 16}
      width={width ? width : height ? height : 16}
      className="c-checkmark--animated"
    >
      <circle
        className="path circle"
        fill="none"
        stroke="currentColor"
        strokeWidth="10"
        strokeMiterlimit="10"
        cx="65.1"
        cy="67"
        r="62.1"
      />
      <polyline
        className="path check"
        fill="none"
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="round"
        strokeMiterlimit="10"
        points="100.2,40.2 51.5,88.8 29.8,67.5 "
      />
    </svg>
  );
}
