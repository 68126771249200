import { useEffect } from "react";
import "./Map.scss";

export type BBRBaseMapMarker = {
  id: string;
  x: number;
  y: number;
  shortname: string;
  warning: boolean;
  icon: string;
  color: string;
  title: string;
};

export type BBRBaseMapGrundDataKeys = {
  landsejerlavskode: number;
  matrikelNr: string;
};
interface IMapProps {
  divId: string;
  containerStyle?: any;
  grunddataKeys: BBRBaseMapGrundDataKeys[];
  markers: BBRBaseMapMarker[];
}
export default function BbrBaseMap(props: IMapProps) {
  const { divId, containerStyle, grunddataKeys, markers } = props;
  useEffect(() => {
    const viewOptions = {
      grunddataKeys,
      markers,
    };

    // let map =
    new UFST.Map(divId, {
      view: viewOptions,
      interactions: {},
      layers: [
        // "default",
        new UFST.WMTSOrtofotoLayer(),
        new UFST.WMTSSkaermkortLayer({
          visible: false,
        }),
        new UFST.WMSMatrikelLayer(),
        new UFST.WMSAdresseLayer({
          maxResolution: 0.65,
        }),
        new UFST.WMSVejnavnLayer(),
        new UFST.VectorMaskLayer(),
        new UFST.VectorBygningLayer(),
      ],
      controls: [
        "default",
        new UFST.BBRLegendControl({
          headingLabel: "Forklaring",
        }),
      ],
    });
  }, [divId, grunddataKeys, markers]);
  return <div style={{ ...containerStyle }} id={divId} />;
}
