import React from "react";
import styles from "./NavContainer.module.scss";
import Vurdst from "components/common/icons/Vurdst";

export default function NavContainer({ children, containerClassName = "" }) {
  return (
    <nav
      aria-label="Hovedmenu"
      role="navigation"
      className={`${styles.nav} ${containerClassName}`}
    >
      {children}
      <a
        className={styles.ufst}
        href="https://www.vurdst.dk"
        title="Vurderingsstyrelsen"
      >
        <Vurdst width={160} />
        <span className={styles.hidden}>Vurderingsstyrelsen logo</span>
      </a>
    </nav>
  );
}
