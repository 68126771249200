import { SourceBase } from '../../../models/SourceBase';
import { SourceOptions } from '../../../models/SourceOptions';
import axios, { AxiosResponse } from 'axios';
import { SearchResult, ResultType } from '../../../models/SearchResult';
import { SearchInput } from '../../../models/SearchInput';
import { ResultBehaviour } from '@/models/ResultBehaviour';
import JordstykkeToGrundAppender from './JordstykkeToGrundAppender';
import DawaJordstykkeToEjendomExtendedAppender from './DawaJordstykkeToEjendomExtendedAppender';
import JordstykkeToEjendomAppender from './JordstykkeToEjendomAppender';
import DawaJordstykkeFilters from './DawaJordstykkeFilters';
import DawaJordstykkeResult from './DawaJordstykkeResult';
import DawaJordstykkeToGrundExtendedAppender from './DawaJordstykkeToGrundExtendedAppender';

// http://dawa.aws.dk/dok/api/jordstykke#s%C3%B8gning
const defaultOptions: SourceOptions = {
    minimumInputLength: 1,
    inputDelay: 250,
    resultBehaviour: ResultBehaviour.default,
    hideWhenEmpty: false,
    title: 'Matrikelbetegnelse',
    badgeTitle: 'Matrikel',
    icon: 'icon-source-matrikel',
    resultLimit: 20,
    isAutoComplete: true,
    fetchExtendedResult: false,
};

const defaultFilters: DawaJordstykkeFilters = {
    dataResultType: 'matrikel',
    extendedResultType: 'ejendom',
};

export class DawaJordstykkeSource extends SourceBase {
    public static sourceName: string = 'dawa.jordstykke';
    public name: string;
    public options: SourceOptions;
    public filters: DawaJordstykkeFilters;
    private url: string = 'https://dawa.aws.dk/jordstykker/autocomplete';

    constructor(options: SourceOptions, filters: DawaJordstykkeFilters) {
        super();
        this.options = Object.assign({}, defaultOptions, options);
        this.filters = Object.assign({}, defaultFilters, filters);

        this.url += '?per_side=' + (this.options.resultLimit + 1).toString();

        if ('kommuneKode' in this.filters && this.filters.kommuneKode) {
            this.url += '&kommunekode=' + this.filters.kommuneKode;
        }

        this.name = DawaJordstykkeSource.sourceName;

        if (this.filters.dataResultType === 'grund') {
            this.resultAppenders = [new JordstykkeToGrundAppender(this)];
        } else if (this.filters.dataResultType === 'ejendom') {
            this.resultAppenders = [new JordstykkeToEjendomAppender(this)];
        }

        if (!!this.options.fetchExtendedResult) {
            if (this.filters.extendedResultType === 'ejendom') {
                this.extendedResultAppender = new DawaJordstykkeToEjendomExtendedAppender(this);
            } else if (this.filters.extendedResultType === 'grund') {
                this.extendedResultAppender = new DawaJordstykkeToGrundExtendedAppender(this);
            }
        }
    }

    public async search(input: SearchInput): Promise<SearchResult[]> {
        if (!input.text && !input.id) {
            return [];
        }

        const result = await axios.get(this.createUrl(input)) as AxiosResponse<DawaJordstykkeResult[]>;

        return result.data.map((r) => this.searchResultMapper(r));
    }

    private createUrl(input: SearchInput) {
        if (input.id) {
            const result = input.id.split('_');
            return this.url + encodeURI(`&ejerlavkode=${result[0]}&matrikelnr=${result[1]}`);
        } else {
            return this.url + encodeURI(`&q=${input.text}`);
        }
    }

    private searchResultMapper(result: DawaJordstykkeResult): SearchResult {
        return {
            id: `${result.jordstykke.ejerlav.kode}_${result.jordstykke.matrikelnr}`,
            title: result.tekst,
            description: `Landsejerlavskode: ${result.jordstykke.ejerlav.kode}`,
            data: result,
            resultType: this.getResultType('dawa_jordstykke'),
            source: this.name,
            datatype: 'dawa_jordstykke',
        };
    }

}
