import { Spacing } from "components/common/layout/Spacing";
import React, { useCallback, useMemo, useState } from "react";
import styles from "../../../Statistik.module.scss";
import { mergeClassNames } from "util/index";
import { usePromise } from "hooks/usePromise";
import statistikkApi from "api/statistik";
import HorizontalStackedBarChart from "components/common/Charts/HorizontalStackedBarChart";
import { OpgaveBrugerStatusToString } from "interfaces/Opgaveliste/enums";

export default function Opgavestatus() {
  const [vis, setVis] = useState<"absolut" | "procentvis">("procentvis");
  const fetchListeStatus = useCallback(
    () => statistikkApi.getListeStatus(),
    []
  );
  const listeStatusDefault = useMemo(() => {
    return [];
  }, []);
  const listeStatusState = usePromise(
    fetchListeStatus,
    listeStatusDefault
  ).state;
  return (
    <div
      className={mergeClassNames(
        styles.statcontainer,
        listeStatusState.state === "LOADING" ? styles.loading : ""
      )}
    >
      <Spacing />
      <div className={styles.headerrow}>
        <h2>Opgavestatus</h2>
        <div>
          <span className={styles.vis}>Vis:</span>
          <button
            className={mergeClassNames(
              styles.togglebutton,
              vis === "procentvis" ? styles.active : ""
            )}
            role="tab"
            aria-selected={vis === "procentvis"}
            onClick={() => setVis("procentvis")}
          >
            Procentvis
          </button>
          <button
            className={mergeClassNames(
              styles.togglebutton,
              vis === "absolut" ? styles.active : ""
            )}
            role="tab"
            aria-selected={vis === "absolut"}
            onClick={() => setVis("absolut")}
          >
            Absolut antal
          </button>
        </div>
        <Spacing />
      </div>
      <Spacing />
      <Spacing />
      {listeStatusState.state === "LOADING" && (
        <div style={{ height: 270 }}></div>
      )}
      {listeStatusState.state === "IDLE" && (
        <HorizontalStackedBarChart
          vis={vis}
          data={toGroupData(
            (listeStatusState.data as any).map((data) =>
              statToBrugerStatusForOpgever(data)
            ),
            "gruppeVaerdi2"
          )}
        />
      )}
    </div>
  );
}

function toGroupData(statistik: IStat[], groupingProperty: gruppeVaerdi) {
  const labelsVals = Array.from(new Set(statistik.map((s) => s.gruppeVaerdi1)));
  const labels = labelsVals.map(
    (l) => statistik.find((s) => s.gruppeVaerdi1 === l)?.gruppeVaerdiNavn1 ?? ""
  );
  const groups = Array.from(new Set(statistik.map((s) => s[groupingProperty])));
  const colors = [
    "#bdcdcf",
    "#86BFCB",
    "#104E5B",
    "#caa484",
    "#e7e7e0",
    "#c7dde0",
  ];
  let datasets = groups.map((group, i) => {
    const dataset = statistik.filter((s) => {
      return s[groupingProperty] === group;
    });
    return {
      label: group,
      backgroundColor: colors[i],
      data: dataset.map((s) => s.antal),
    };
  });
  return {
    labels,
    datasets,
  };
}
function statToBrugerStatusForOpgever(stat: IStat) {
  return {
    ...stat,
    gruppeVaerdi2: OpgaveBrugerStatusToString(parseInt(stat.gruppeVaerdi2)),
  };
}
