import React from "react";

export default function User(props: Icon) {
  const { height, width, primaryColor, style = {} } = props;
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      style={style}
      width={width ? width : height ? height * (24 / 23) : 24}
      height={height ? height : width ? width * (23 / 24) : 23}
      viewBox="0 0 24 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-1163.000000, -39.000000)"
          stroke={primaryColor ? primaryColor : "currentColor"}
          strokeWidth="1.8"
        >
          <g transform="translate(1164.779998, 40.099081)">
            <path
              d="M11.946521,0.56539377 C10.2259519,0.0973393829 8.0350799,-0.436404167 6.44087701,0.580233299 C4.45600825,1.8460019 2.24672325,5.88142349 4.88744144,7.92574948 C7.56345153,9.99739689 11.3232304,10.7420188 13.9155299,8.77475814 C16.0775455,7.13403396 15.5232808,3.86447187 13.9155299,2.03361319"
              id="Path-32"
              strokeLinecap="round"
            ></path>
            <path
              d="M0.0329194024,19.8511443 C-0.0097354155,19.4802469 -0.0251037696,18.3352304 0.0755742202,17.5871139 C0.157112707,16.9812189 0.343292614,16.387987 0.575144638,15.8219851 C0.803171382,15.2653215 1.13541682,14.7561311 1.44632102,14.2408186 C3.58498527,10.6960598 7.96653977,13.2841379 11.2014603,13.0754909 C13.6643884,12.916636 15.6102762,11.2750135 17.9209106,12.9814113 C20.1758838,14.6467034 20.0191496,15.5994916 20.3427564,18.6073003 C20.3929088,19.0734489 20.7758347,19.9339849 20.3120379,20.0134228 C17.4307689,20.5069193 14.4647088,20.0452217 11.5419967,20.114672 C7.81763376,20.2031715 4.20892353,20.4134358 0.490361605,20.2220417 C0.213836682,20.2220417 0.0613559476,20.0984092 0.0329194024,19.8511443 Z"
              id="Path-31"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
