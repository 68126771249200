import React from "react";
import styles from "./BrugerList.module.scss";
import NextPreviousPage from "components/common/NextPreviousPage/NextPreviousPage";
import { BrugerePageOptions, PageOptions } from "interfaces/paging";
import { motion } from "framer-motion";
import Button from "components/common/Buttons/Button";
// import FilterIcon from "components/common/icons/Filter";
// import ArrowTriangle from "components/common/icons/ArrowTriangle";
// import FoldOutMenu from "components/common/FoldOutMenu";
import { Filter } from "routes/Fejllister/MainContent/Header/AktiveFiltre";
import { kommuner } from "util/index";
import IconFilter from "components/common/icons/IconFilter";
import DropDownMenu from "components/common/DropDownMenu";

interface BrugerListProps {
  pageOptions: PageOptions;
  setPageOptions: React.Dispatch<React.SetStateAction<BrugerePageOptions>>;
  totalCount: number;
  brugere: Bruger[];
  history: any;
  showFilter: boolean;
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
  brugernavn: string;
  setBrugernavn: React.Dispatch<React.SetStateAction<string>>;
  kommunenr?: string;
  setKommunenr: (knr: string) => void;
  brugernavnFilter: string | undefined;
  kommunenrFilter: string | undefined;
}
export default function BrugerList(props: BrugerListProps) {
  const {
    pageOptions,
    setPageOptions,
    totalCount,
    brugere,
    history,
    brugernavn,
    setBrugernavn,
    brugernavnFilter,
    kommunenrFilter,
    setShowFilter,
    showFilter,
    kommunenr,
    setKommunenr,
  } = props;
  const skatPlusKommuner = [{ knr: 0, navn: "Skat" }, ...kommuner];
  const kommunenummerFilter: number[] = kommunenrFilter
    ? kommunenrFilter.split(",").map((s) => parseInt(s))
    : [];
  const kommuneIsActive = (knr: number) => {
    if (!kommunenr) return false;
    const activeKnrs = kommunenr.split(",").map((b) => parseInt(b));
    return activeKnrs.indexOf(knr) > -1;
  };

  const setKommuneIsActive = (
    value: number,
    isActive: boolean,
    push: boolean = false
  ) => {
    let kommunenumre: number[] = kommunenr
      ? kommunenr.split(",").map((knr) => parseInt(knr))
      : [];

    if (isActive) {
      if (kommunenumre.indexOf(value) === -1) {
        kommunenumre.push(value);
        setKommunenr(kommunenumre.join(","));
        if (push)
          setPageOptions({ ...pageOptions, kommunenr: kommunenumre.join(",") });
      }
    } else {
      if (kommunenumre.indexOf(value) > -1) {
        kommunenumre = kommunenumre.filter((b) => b !== value);
        setKommunenr(kommunenumre.length ? kommunenumre.join(",") : "");
        if (push)
          setPageOptions({ ...pageOptions, kommunenr: kommunenumre.join(",") });
      }
    }
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h1 className="mb-0">Brugere</h1>
        <div className="d-flex gap-3">
          <button
            className="c-filter-btn btn border btn--icon tooltip-center tooltip-top"
            onClick={() => setShowFilter(!showFilter)}
            aria-label="Filtrér"
          >
            <IconFilter width={22} />
          </button>
          <NextPreviousPage
            pageOptions={pageOptions}
            setPageOptions={setPageOptions}
            totalCount={totalCount}
          />
        </div>
      </div>
      {showFilter && (
        <div className="{styles.filters} border border-1 px-3 py-3 rounded mb-4">
          <h2>Filtre</h2>
          <div className="d-flex align-items-center gap-2">
            <div className="d-flex flex-column h-100 justify-content-between">
              <label htmlFor="" className="fs-5">
                Navn
              </label>
              <input
                style={{ marginBottom: 0, padding: "0.65rem 0.3rem" }}
                className="border rounded"
                value={brugernavn}
                onChange={(e) => setBrugernavn(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter")
                    setPageOptions({ ...pageOptions, navn: brugernavn });
                }}
              />
            </div>
            {/* <label style={{ marginBottom: 0 }}>
              Kommune
              <FoldOutMenu
                menuClassNames={styles.filtermenu}
                element={
                  <button className={styles.filtrer}>
                    <span>
                      {kommunenr
                        ? kommunenr.split(",").join(", ")
                        : "Ingen valgt"}
                    </span>
                    <span className={styles.chevron}>
                      <ArrowTriangle />
                    </span>
                  </button>
                }
                menu={
                  <div style={{ maxHeight: 350, overflowY: "auto" }}>
                    {skatPlusKommuner
                      .sort((a, b) => {
                        const prepForSort = (navn: string) => {
                          return navn
                            .replace("Skat", "1")
                            .replace("Aa", "Å")
                            .replace("aa", "å")
                            .replace("Æ", "Z");
                        };
                        return prepForSort(a.navn) < prepForSort(b.navn)
                          ? -1
                          : 1;
                      })
                      .map((kommune) => {
                        const { knr, navn } = kommune;
                        return (
                          <div key={knr} className={styles.filtermenuitem}>
                            <label
                              className={styles.statuslabel}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <input
                                onKeyDown={(e) => {
                                  e.stopPropagation();
                                  if (e.key === "Enter") {
                                    setKommuneIsActive(
                                      knr,
                                      !kommuneIsActive(knr)
                                    );
                                  } else if (e.key === " ") {
                                    e.preventDefault();
                                    setKommuneIsActive(
                                      knr,
                                      !kommuneIsActive(knr)
                                    );
                                  }
                                }}
                                checked={kommuneIsActive(knr)}
                                type="checkbox"
                                onChange={(e) => {
                                  setKommuneIsActive(knr, e.target.checked);
                                }}
                                onClick={(e) => e.stopPropagation()}
                              ></input>
                              {navn}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                }
              />
            </label> */}
            <div className="mb-1">
              <label htmlFor="" className="fs-5">
                Kommune
              </label>
              <DropDownMenu
                arrowClass="text-muted"
                menuClassNames="py-0 px-2 d-flex justify-content-between align-items-center w-100"
                dropdownTriggerStyle="bg-white w-100 rounded"
                dropdownButtonStyle="0.9rem"
                preventCloseOnSelect={true}
                width="300px"
                element={
                  <div
                    className="w-100 d-flex align-items-center "
                    style={{
                      fontSize: "1rem",
                      minWidth: 200,
                      maxHeight: "3rem",
                    }}
                  >
                    <span>
                      {kommunenr
                        ? kommunenr.split(",").join(", ")
                        : "Ingen valgt"}
                    </span>
                  </div>
                }
                menu={[
                  <div
                    className="w-100 "
                    style={{ maxHeight: 350, overflowY: "auto" }}
                  >
                    {skatPlusKommuner
                      .sort((a, b) => {
                        const prepForSort = (navn: string) => {
                          return navn
                            .replace("Skat", "1")
                            .replace("Aa", "Å")
                            .replace("aa", "å")
                            .replace("Æ", "Z");
                        };
                        return prepForSort(a.navn) < prepForSort(b.navn)
                          ? -1
                          : 1;
                      })
                      .map((kommune) => {
                        const { knr, navn } = kommune;
                        return (
                          <div
                            key={knr}
                            style={{ cursor: "pointer" }}
                            className="styles.filtermenuitem list-group-item list-group-item-action border-0 py-0 rounded w-100 my-1"
                          >
                            <div
                              className="w-100 form-check d-flex align-items-center gap-2 pe-2 py-2"
                              style={{ paddingLeft: "2rem", cursor: "pointer" }}
                            >
                              <input
                                onKeyDown={(e) => {
                                  e.stopPropagation();
                                  if (e.key === "Enter") {
                                    setKommuneIsActive(
                                      knr,
                                      !kommuneIsActive(knr)
                                    );
                                  } else if (e.key === " ") {
                                    e.preventDefault();
                                    setKommuneIsActive(
                                      knr,
                                      !kommuneIsActive(knr)
                                    );
                                  }
                                }}
                                checked={kommuneIsActive(knr)}
                                type="checkbox"
                                className="form-check-input mb-1 d-block"
                                onChange={(e) => {
                                  setKommuneIsActive(knr, e.target.checked);
                                }}
                                id={knr.toString()}
                                onClick={(e) => e.stopPropagation()}
                              ></input>
                              <label
                                className="{styles.statuslabel} form-check-label w-100 h-100 fs-5"
                                onClick={(e) => e.stopPropagation()}
                                htmlFor={knr.toString()}
                                style={{ cursor: "pointer" }}
                              >
                                {navn}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                  </div>,
                ]}
              />
            </div>
            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignSelf: "end",
                marginLeft: "auto",
              }}
            >
              <Button
                type="primary"
                // disabled={brugernavn === brugernavnFilter}
                onClick={() => {
                  setBrugernavn("");
                  setKommunenr("");
                  setPageOptions({
                    ...pageOptions,
                    kommunenr: "",
                    navn: "",
                  });
                }}
              >
                Ryd filtre
              </Button>
              <Button
                type="small-filled"
                disabled={
                  brugernavn === brugernavnFilter &&
                  kommunenr === kommunenrFilter
                }
                onClick={() => {
                  setPageOptions({
                    ...pageOptions,
                    navn: brugernavn,
                    kommunenr,
                  });
                }}
              >
                Filtrer
              </Button>
            </div>
          </div>
        </div>
      )}
      <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
        {brugernavnFilter && (
          <div style={{ display: "flex" }}>
            <Filter
              filtertype="Brugernavn"
              filterValue={brugernavnFilter}
              removeFilter={() => {
                setBrugernavn("");
                setPageOptions({ ...pageOptions, navn: "" });
              }}
            />
          </div>
        )}
        {kommunenummerFilter.map((knr) => {
          return (
            <div key={knr} style={{ display: "flex" }}>
              <Filter
                filtertype="Kommune"
                filterValue={
                  skatPlusKommuner.find((k) => k.knr === knr)?.navn ??
                  knr.toString()
                }
                removeFilter={() => {
                  setKommuneIsActive(knr, false, true);
                }}
              />
            </div>
          );
        })}
      </div>
      <ul role="tablist">
        <li className={styles.listHeader}>
          <div className={styles.navn}>
            <h2 className="h4">Navn</h2>
          </div>
          <div className={styles.org}>
            <h2 className="h4">Organisation</h2>
          </div>
          <div className={styles.cvr}>
            <h2 className="h4">CVR</h2>
          </div>
          <div className={styles.roller}>
            <h2 className="h4">Antal roller</h2>
          </div>
        </li>
        {brugere.map((bruger, i) => {
          return (
            <li key={bruger.id}>
              <motion.button
                role="tab"
                aria-controls="artikel-main-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className={styles.opgave}
                title={bruger.navn}
                onClick={() => {
                  history.push(`/admin?type=brugere&brugerid=${bruger.id}`);
                }}
              >
                <div className={styles.listrow}>
                  <div className={styles.navn}>
                    <h2 className="h4">{bruger.navn}</h2>
                  </div>
                  <div className={styles.org}>{bruger.organisationNavn}</div>
                  <div className={styles.cvr}>{bruger.cvr}</div>
                  <div className={styles.roller}>{bruger.roller.length}</div>
                </div>
              </motion.button>
            </li>
          );
        })}
      </ul>
    </>
  );
}
