import React from "react";

export default function WarningBox(props: WarningBoxProps) {
  const { children } = props;

  return <div className="row bg-warning-subtle rounded-2 p-2">{children}</div>;
}

interface WarningBoxProps {
  children: React.ReactNode;
}
