import React from "react";
import { format } from "date-fns";
import { da } from "date-fns/esm/locale";
/* All information templates extends "Template" */
abstract class TemplateFormatter {
  abstract format(input): JSX.Element;
}

class DefaultFormatter extends TemplateFormatter {
  format(input: string | number): JSX.Element {
    return <p>{input !== null ? input : `Ikke oplyst`}</p>;
  }
}
class SquareMeterFormatter extends TemplateFormatter {
  format(input: string | number): JSX.Element {
    return (
      <p>
        {input !== null ? input : 0} m<sup>2</sup>
      </p>
    );
  }
}
class LiterFormatter extends TemplateFormatter {
  format(input: string | number): JSX.Element {
    return <p>{input !== null ? input : 0} L</p>;
  }
}

class DateFormatter extends TemplateFormatter {
  format(input: string): JSX.Element {
    let parsedDate = Date.parse(input);
    if (!isNaN(parsedDate)) {
      let date = format(new Date(parsedDate), "PPP", {
        locale: da,
      });
      return <p>{date}</p>;
    } else {
      return <p>{input}</p>; // Kunne ikke omsætte dato
    }
  }
}
class EjerlejlighedFormatter extends TemplateFormatter {
  format(input: any): JSX.Element {
    if (input) {
      return (
        <ul>
          <li>
            <h5>BFE:</h5>
            <p>{input.bfeNummer}</p>
          </li>
          <li>
            <h5>Ejerlejlighedsnummer:</h5>
            <p>{input.ejerlejlighedsnummer}</p>
          </li>
        </ul>
      );
    } else {
      return <></>;
    }
  }
}
interface TemplateField {
  displayname: string;
  useclv: boolean;
  formatter: TemplateFormatter;
  showInModal?: boolean; // For "Enheder" additional information can be shown in a modal onClick(). No use for other templates
  category?: string; // For fields that belongs to a certain context or submenu. Used in Arealboks
}
export class Template {
  template: Record<string, TemplateField>;
  constructor(template: Record<string, TemplateField>) {
    this.template = template;
  }
  format(element: Object, key, useclv: boolean): JSX.Element {
    let clvkey: string = key + "CLV";

    if (clvkey in element && element[clvkey] != null && useclv) {
      // TODO: fix this
      return (
        <p>
          {element[clvkey].key} - {element[clvkey].title}
        </p>
      );
    }
    return this.template[key].formatter.format(element[key]);
  }
}
export class GrundTemplate extends Template {
  constructor() {
    super({
      gru009Vandforsyning: {
        displayname: "Vandforsyning",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      gru010Afløbsforhold: {
        displayname: "Afløbsforhold",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      gru021Udledningstilladelse: {
        displayname: "Udledningstilladelse",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      gru022MedlemskabAfSpildevandsforsyning: {
        displayname: "Medlemskab af spildevandsforsyning",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      gru023PåbudVedrSpildevandsafledning: {
        displayname: "Påbud vedrørende spildevandsafledning",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      gru024FristVedrSpildevandsafledning: {
        displayname: "Frist vedrørende spildevandsafledning",
        useclv: false,
        formatter: new DefaultFormatter(),
      },
      gru025TilladelseTilUdtræden: {
        displayname: "Tilladelse til udtræden",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      gru026DatoForTilladelseTilUdtræden: {
        displayname: "Dato for tilladelse til udtræden",
        useclv: false,
        formatter: new DateFormatter(),
      },
      gru027TilladelseTilAlternativBortskaffelseEllerAfledning: {
        displayname: "Tilladelse til alternativ bortskaffelse eller afledning",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      gru028DatoForTilladelseTilAlternativBortskaffelseEllerAfledning: {
        displayname:
          "Dato for tilladelse til alternativ bortskaffelse eller afledning",
        useclv: false,
        formatter: new DateFormatter(),
      },
      gru029DispensationFritagelseIftKollektivVarmeforsyning: {
        displayname: "Dispensation/Fritagelse ift. kollektiv varmeforsyning",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      gru030DatoForDispensationFritagelseIftKollektivVarmeforsyning: {
        displayname:
          "Dato for dispensation/fritagelse ift. kollektiv varmeforsyning",
        useclv: false,
        formatter: new DateFormatter(),
      },
      gru500Notatlinjer: {
        displayname: "Notatlinjer",
        useclv: false,
        formatter: new DefaultFormatter(),
      },
    });
  }
}
export class OverblikTemplate extends Template {
  constructor() {
    super({
      status: {
        displayname: "Status",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      byg026Opførelsesår: {
        displayname: "Opførelsesår",
        useclv: false,
        formatter: new DefaultFormatter(),
      },
      byg054AntalEtager: {
        displayname: "Antal etager",
        useclv: false,
        formatter: new DefaultFormatter(),
      },
      byg021BygningensAnvendelse: {
        displayname: "Anvendelse",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      byg041BebyggetAreal: {
        displayname: "Bebygget areal",
        useclv: false,
        formatter: new SquareMeterFormatter(),
      },
      byg038SamletBygningsareal: {
        displayname: "Samlet bygningsareal",
        useclv: false,
        formatter: new SquareMeterFormatter(),
      },
      //kommunekode: {
      //  displayname: "Kommunekode",
      //  useclv: false,
      //  formatter: new DefaultFormatter(),
      //},
      ////byg021BygningensAnvendelse: {
      ////  displayname: "Anvendelseskode",
      ////  formatter: new DefaultFormatter(),
      ////},
      //byg024AntalLejlighederMedKøkken: {
      //  displayname: "Ant. boliger med køkken",
      //  useclv: false,
      //  formatter: new DefaultFormatter(),
      //},
      //byg025AntalLejlighederUdenKøkken: {
      //  displayname: "Ant. boliger u. køkken",
      //  useclv: false,
      //  formatter: new DefaultFormatter(),
      //},
      //byg040BygningensSamledeErhvervsAreal: {
      //  displayname: "Samlet erhvervsareal",
      //  useclv: false,
      //  formatter: new SquareMeterFormatter(),
      //},
      //ejerlejlighed: {
      //  displayname: "Ejerlejlighed",
      //  useclv: false,
      //  formatter: new EjerlejlighedFormatter(),
      //},
    });
  }
}

export class VandogafloebTemplate extends Template {
  constructor() {
    super({
      byg030Vandforsyning: {
        displayname: "Vandforsyning",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      byg031Afløbsforhold: {
        displayname: "Afløbsforhold",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
    });
  }
}
export class MaterialerTemplate extends Template {
  constructor() {
    super({
      byg032YdervæggensMateriale: {
        displayname: "Ydervæggens materiale",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      byg034SupplerendeYdervæggensMateriale: {
        displayname: "Supplerende ydervægsmateriale",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      byg033Tagdækningsmateriale: {
        displayname: "Tagdækningsmateriale",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      byg035SupplerendeTagdækningsMateriale: {
        displayname: "Supplerende tagdækningsmateriale",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      byg036AsbestholdigtMateriale: {
        displayname: "Asbestholdigt Materiale",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      byg037KildeTilBygningensMaterialer: {
        displayname: "Kilde til bygningens materialer",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
    });
  }
}
export class EnhederTemplate extends Template {
  constructor() {
    super({
      enh020EnhedensAnvendelse: {
        displayname: "Anvendelse",
        useclv: true,
        formatter: new DefaultFormatter(),
        showInModal: false,
      },
      enh023BoligType: {
        displayname: "Boligtype",
        useclv: false,
        formatter: new DefaultFormatter(),
        showInModal: false,
      },
      enh026EnhedensSamledeAreal: {
        displayname: "Enhedens samlede areal",
        useclv: false,
        formatter: new SquareMeterFormatter(),
        showInModal: false,
      },
      enh035Energiforsyning: {
        displayname: "Energiforsyning",
        useclv: true,
        formatter: new DefaultFormatter(),
        showInModal: false,
      },
      enh051Varmeinstallation: {
        displayname: "Varmeforsyning",
        useclv: true,
        formatter: new DefaultFormatter(),
        showInModal: false,
      },
      enh052Opvarmningsmiddel: {
        displayname: "Varmeforsyning",
        useclv: true,
        formatter: new DefaultFormatter(),
        showInModal: false,
      },
      enh053SupplerendeVarme: {
        displayname: "Supplerende varme",
        useclv: true,
        formatter: new DefaultFormatter(),
        showInModal: false,
      },
      enh025OprettelsesdatoForEnhedensIdentifikation: {
        displayname: "Oprettelsesdato",
        useclv: false,
        formatter: new DateFormatter(),
        showInModal: true,
      },
      // ----------*
      // Arealer
      // ----------*

      enh027ArealTilBeboelse: {
        displayname: "Areal til beboelse",
        useclv: false,
        formatter: new SquareMeterFormatter(),
        showInModal: true,
      },
      enh027ArealTilErhverv: {
        displayname: "Areal til erhverv",
        useclv: false,
        formatter: new SquareMeterFormatter(),
        showInModal: true,
      },
      enh127FysiskArealTilBeboelse: {
        displayname: "Fysisk areal til beboelse",
        useclv: false,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      enh128FysiskArealTilErhverv: {
        displayname: "Fysisk areal til erhverv",
        useclv: false,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      // Supplerende erhvervsareal koder:
      enh105SupplerendeAnvendelseskode1: {
        displayname: "Supplerende anvendelse (1)",
        useclv: false,
        formatter: new SquareMeterFormatter(),
        showInModal: true,
      },
      enh102HerafAreal1: {
        displayname: "Heraf areal (1)",
        useclv: false,
        formatter: new SquareMeterFormatter(),
        showInModal: true,
      },
      enh106SupplerendeAnvendelseskode2: {
        displayname: "Supplerende anvendelse (2)",
        useclv: false,
        formatter: new SquareMeterFormatter(),
        showInModal: true,
      },
      enh103HerafAreal2: {
        displayname: "Heraf areal (2)",
        useclv: false,
        formatter: new SquareMeterFormatter(),
        showInModal: true,
      },
      enh107SupplerendeAnvendelseskode3: {
        displayname: "Supplerende anvendelse (3)",
        useclv: false,
        formatter: new SquareMeterFormatter(),
        showInModal: true,
      },
      enh104HerafAreal3: {
        displayname: "Heraf areal (3)",
        useclv: false,
        formatter: new SquareMeterFormatter(),
        showInModal: true,
      },
      enh060EnhedensAndelFællesAdgangsareal: {
        displayname: "Andel i fælles adgangsareal",
        useclv: false,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      enh061ArealAfÅbenOverdækning: {
        displayname: "Areal af åben overdækning",
        useclv: false,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      enh062ArealAfLukketOverdækningUdestue: {
        displayname: "Areal af lukket overdækning",
        useclv: false,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      enh070ÅbenAltanTagterrasseAreal: {
        displayname: "Areal af åben altan/tagterasse",
        useclv: false,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },

      enh039AndetAreal: {
        displayname: "Andet areal",
        useclv: false,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      enh030KildeTilEnhedensArealer: {
        displayname: "Kilde til enhedens arealer",
        useclv: true,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      // ---
      // Værelser
      // ---
      enh031AntalVærelser: {
        displayname: "Antal værelser",
        useclv: false,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      enh063AntalVærelserTilErhverv: {
        displayname: "Antal værelser til erhverv",
        useclv: false,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      //
      enh032Toiletforhold: {
        displayname: "Toiletforhold",
        useclv: true,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      enh065AntalVandskylledeToiletter: {
        displayname: "Antal vandskyllende toiletter",
        useclv: false,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      enh033Badeforhold: {
        displayname: "Badeforhold",
        useclv: true,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      enh066AntalBadeværelser: {
        displayname: "Antal badeværelser",
        useclv: false,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      enh034Køkkenforhold: {
        displayname: "Køkkenforhold",
        useclv: true,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      enh041LovligAnvendelse: {
        displayname: "Lovlig anvendelse",
        useclv: false,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },

      enh024KondemneretBoligenhed: {
        displayname: "Kondemneret boligenhed",
        useclv: true,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      enh045Udlejningsforhold: {
        displayname: "Udlejningsforhold",
        useclv: true,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      enh046OffentligStøtte: {
        displayname: "Offentlig støtte",
        useclv: true,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      enh048GodkendtTomBolig: {
        displayname: "Godkendt som tom bolig",
        useclv: true,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      enh068FlexboligTilladelsesart: {
        displayname: "Flexboligtilladelse",
        useclv: true,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      enh069FlexboligOphørsdato: {
        displayname: "Dato for ophør af flexboligtilladelse",
        useclv: false,
        formatter: new DateFormatter(),
        showInModal: true,
      },

      // -------- *
      // Datoer
      // -------- *

      enh042DatoForTidsbegrænsetDispensation: {
        displayname: "Dato for tidsbegrænset dispensation",
        useclv: false,
        formatter: new DateFormatter(),
        showInModal: true,
      },
      enh044DatoForDelvisIbrugtagningsTilladelse: {
        displayname: "Dato for delvis ibrugtagningstilladelse",
        useclv: false,
        formatter: new DateFormatter(),
        showInModal: true,
      },
      enh047IndflytningDato: {
        displayname: "Dato for indflytning",
        useclv: false,
        formatter: new DateFormatter(),
        showInModal: true,
      },
      enh067Støjisolering: {
        displayname: "Årstal for støjisolering",
        useclv: false,
        formatter: new DefaultFormatter(),
        showInModal: true,
      },
      //enh071AdresseFunktion: {
      //  displayname: "Adressens funktion i forhold til enheden",
      //  useclv: true,
      //  formatter: new DefaultFormatter(),
      //  showInModal: true
      //},
      enh101Gyldighedsdato: {
        displayname: "Dato for gyldighed ifbm. vurdering",
        useclv: false,
        formatter: new DateFormatter(),
        showInModal: true,
      },
    });
  }
}
export class EnergioplysningerTemplate extends Template {
  constructor() {
    super({
      byg056Varmeinstallation: {
        displayname: "Varmeinstallation",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      byg057Opvarmningsmiddel: {
        displayname: "Opvarmningsmiddel",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      byg058SupplerendeVarme: {
        displayname: "Supplerende varme",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
    });
  }
}
export class ArealTemplate extends Template {
  constructor() {
    super({
      byg041BebyggetAreal: {
        displayname: "Bebygget areal",
        useclv: false,
        formatter: new SquareMeterFormatter(),
      },
      byg039BygningensSamledeBoligAreal: {
        displayname: "Samlet bolig areal",
        useclv: false,
        formatter: new SquareMeterFormatter(),
      },
      byg042ArealIndbyggetGarage: {
        displayname: "Areal af indbygget garage",
        useclv: false,
        formatter: new SquareMeterFormatter(),
      },
      byg043ArealIndbyggetCarport: {
        displayname: "Areal af indbygget Carport",
        useclv: false,
        formatter: new SquareMeterFormatter(),
      },
      byg044ArealIndbyggetUdhus: {
        displayname: "Areal af indbygget udhus",
        useclv: false,
        formatter: new SquareMeterFormatter(),
      },
      byg045ArealIndbyggetUdestueEllerLign: {
        displayname: "Areal af indbygget udestue el. lign.",
        useclv: false,
        formatter: new SquareMeterFormatter(),
      },
      byg046SamletArealAfLukkedeOverdækningerPåBygningen: {
        displayname: "Samlet areal af lukkede overdækninger på bygningen",
        useclv: false,
        formatter: new SquareMeterFormatter(),
      },
      byg047ArealAfAffaldsrumITerrænniveau: {
        displayname: "Areal af affaldsrum i terrænniveau",
        useclv: false,
        formatter: new SquareMeterFormatter(),
      },
      byg048AndetAreal: {
        displayname: "Andet areal",
        useclv: false,
        formatter: new SquareMeterFormatter(),
      },
      byg049ArealAfOverdækketAreal: {
        displayname: "Areal af overdækket areal",
        useclv: false,
        formatter: new SquareMeterFormatter(),
      },
      byg050ArealÅbneOverdækningerPåBygningenSamlet: {
        displayname: "Samlet areal af åbne overdækninger på bygningen",
        useclv: false,
        formatter: new SquareMeterFormatter(),
      },
      byg051Adgangsareal: {
        displayname: "Adgangsareal",
        useclv: false,
        formatter: new SquareMeterFormatter(),
      },
      byg052BeregningsprincipCarportAreal: {
        displayname: "Beregningsprincip Carport Areal",
        useclv: false,
        formatter: new SquareMeterFormatter(),
      },
      byg053BygningsarealerKilde: {
        displayname: "Kilde",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
    });
  }
}
export class TekniskAnlaegTemplate extends Template {
  constructor() {
    super({
      tek020Klassifikation: {
        displayname: "Klassifikation",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      tek021FabrikatType: {
        displayname: "Fabrikat",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      tek024Etableringsår: {
        displayname: "Etableringsår",
        useclv: false,
        formatter: new DefaultFormatter(),
      },
      tek026StørrelsesklasseOlietank: {
        displayname: "Størrelsesklasse",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      tek030Fabrikationsnummer: {
        displayname: "Fabrikationsnummer",
        useclv: false,
        formatter: new DefaultFormatter(),
      },
      tek032Størrelse: {
        displayname: "Størrelse", // Angives i Liter, gælder kun for tank
        useclv: false,
        formatter: new LiterFormatter(),
      },
      tek034IndholdOlietank: {
        displayname: "Indhold",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      tek027Placering: {
        displayname: "Placering",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      tek028SløjfningOlietank: {
        displayname: "Sløjfning",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      tek035SløjfningsfristOlietank: {
        displayname: "Sløfjningsfrist",
        useclv: false,
        formatter: new DateFormatter(),
      },
      tek068Materiale: {
        displayname: "Materiale",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      tek022EksternDatabase: {
        displayname: "Ekstern database",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      tek023EksternNøgle: {
        displayname: "Ekstern nøgle",
        useclv: true,
        formatter: new DefaultFormatter(),
      },

      tek025TilOmbygningsår: {
        displayname: "Til-/ombygningsår",
        useclv: false,
        formatter: new DefaultFormatter(),
      },

      tek036Rumfang: {
        displayname: "Rumfang",
        useclv: false,
        formatter: new DefaultFormatter(),
      },
      tek037Areal: {
        displayname: "Areal",
        useclv: false,
        formatter: new SquareMeterFormatter(),
      },
      tek038Højde: {
        displayname: "Højde",
        useclv: false,
        formatter: new DefaultFormatter(),
      },

      tek031Typegodkendelsesnummer: {
        displayname: "Typegodkendelsesnummer",
        useclv: false,
        formatter: new DefaultFormatter(),
      },

      tek033Type: {
        displayname: "Type",
        useclv: true,
        formatter: new DefaultFormatter(),
      },

      tek039Effekt: {
        displayname: "Effekt",
        useclv: false,
        formatter: new DefaultFormatter(),
      },
      tek040Fredning: {
        displayname: "Fredning",
        useclv: false,
        formatter: new DefaultFormatter(),
      },
      tek042Revisionsdato: {
        displayname: "Revisionsdato",
        useclv: false,
        formatter: new DateFormatter(),
      },
      //"tek045Koordinatsystem": {
      //  displayname: "Kilde",
      //  useclv: false,
      //  formatter: new DefaultFormatter(),
      //},
      //tek067Fabrikationsår: {
      //  displayname: "Fabrikationsår",
      //  useclv: false,
      //  formatter: new DefaultFormatter(),
      //},

      tek069SupplerendeIndvendigKorrosionsbeskyttelse: {
        displayname: "Supplerende indvendig korrosionsbeskyttelse",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      tek070DatoForSenestUdførteSupplerendeIndvendigKorrosionsbeskyttelse: {
        displayname:
          "Dato for senest udførte supplerende indvendig korrosionsbeskyttelse",
        useclv: false,
        formatter: new DateFormatter(),
      },
      tek072Sløjfningsår: {
        displayname: "Sløjfningsår",
        useclv: false,
        formatter: new DefaultFormatter(),
      },
      tek073Navhøjde: {
        displayname: "Vindmøllens højde i meter",
        useclv: false,
        formatter: new DefaultFormatter(),
      },
      tek074Vindmøllenummer: {
        displayname: "Vindmøllenummer",
        useclv: false,
        formatter: new DefaultFormatter(),
      },
      tek075Rotordiameter: {
        displayname: "Rotordiameter",
        useclv: false,
        formatter: new DefaultFormatter(),
      },
      //"tek076KildeTilKoordinatsæt": {
      //  displayname: "Kilde",
      //  useclv: false,
      //  formatter: new DefaultFormatter(),
      //},
      //"tek077KvalitetAfKoordinatsæt": {
      //  displayname: "Kilde",
      //  useclv: false,
      //  formatter: new DefaultFormatter(),
      //},
      //"tek078SupplerendeOplysningOmKoordinatsæt": {
      //  displayname: "Kilde",
      //  useclv: false,
      //  formatter: new DefaultFormatter(),
      //},
      tek101Gyldighedsdato: {
        displayname: "Gyldighedsdato",
        useclv: false,
        formatter: new DateFormatter(),
      },
      tek102FabrikatVindmølle: {
        displayname: "Kilde",
        useclv: false,
        formatter: new DefaultFormatter(),
      },
      tek103FabrikatOliefyr: {
        displayname: "Kilde",
        useclv: false,
        formatter: new DefaultFormatter(),
      },
      tek104FabrikatSolcelleanlægSolvarme: {
        displayname: "Fabrikat sol",
        useclv: false,
        formatter: new DefaultFormatter(),
      },
      tek105OverdækningTank: {
        displayname: "Overdækning af tank",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      tek106InspektionsdatoTank: {
        displayname: "Inspektionsdato for tank",
        useclv: false,
        formatter: new DateFormatter(),
      },
      tek107PlaceringPåSøterritorie: {
        displayname: "Placering på søterritorie",
        useclv: true,
        formatter: new DefaultFormatter(),
      },
      //"tek109Koordinat": {
      //  displayname: "Kilde",
      //  useclv: false,
      //  formatter: new DefaultFormatter(),
      //},
      //Tek110Driftstatus: {
      //  displayname: "Driftstatus",
      //  useclv: true,
      //  formatter: new DefaultFormatter(),
      //},
      tek111DatoForSenesteInspektion: {
        displayname: "Dato for seneste inspektion",
        useclv: false,
        formatter: new DateFormatter(),
      },
      tek112InspicerendeVirksomhed: {
        displayname: "Inspicerende virksomhed",
        useclv: false,
        formatter: new DefaultFormatter(),
      },
      tek500Notatlinjer: {
        displayname: "Notatlinjer",
        useclv: false,
        formatter: new DefaultFormatter(),
      },
      ejerlejlighed: {
        displayname: "Ejerlejlighed",
        useclv: false,
        formatter: new EjerlejlighedFormatter(),
      },
    });
  }
}
