import { useState } from "react";
import styles from "./Main.module.scss";
import ExpandablePaper from "components/common/ExpandablePaper";
import PageContainer from "components/common/layout/PageContainer";
import GrundIcon from "components/common/icons/Grund";
import Grund from "../Grund/Grund";
import MapMarker from "components/common/icons/MapMarker";
import SupplerendeOplysninger from "../SupplerendeOplysninger/SupplerendeOplysninger";
import { getRelevantSupplerendeOplysninger } from "util/index";
import PlusIcon from "components/common/icons/PlusIcon";
import MinusIcon from "components/common/icons/MinusIcon";
import { ListeState } from "../Ejendom";

export default function Main(props: MainProps) {
  const {
    ejendom,
    ejendomLoading,
    chrOplysninger,
    listeState,
    currentOpgave,
    showOpgaver,
  } = props;
  const [minimized, setMinimized] = useState(false);

  return (
    <PageContainer classNames="w-100 p-0">
      <div className="d-flex justify-content-between pt-5 pb-4">
        <div className="d-flex flex-column gap-2 pb-2">
          <h3>BBR-oplysninger</h3>
          <p className="text-muted mb-0 fs-5 fw-medium">
            Der vises kun felter, der har indhold
          </p>
        </div>
        {!ejendomLoading && showOpgaver && (
          <div>
            {!minimized && (
              <button
                onClick={() => setMinimized(true)}
                className="btn btn-outline-primary d-flex align-items-center gap-2"
                style={{ width: "10em" }}
              >
                <span className="d-flex align-items-center">
                  <MinusIcon width={12} />
                </span>
                <p className="mb-0 fs-5">Minimér alle</p>
              </button>
            )}
            {minimized && (
              <button
                onClick={() => setMinimized(false)}
                className="btn btn-outline-primary d-flex align-items-center gap-2"
                style={{ width: "10em" }}
              >
                <span className="d-flex align-items-center">
                  <PlusIcon width={12} />
                </span>
                <p className="mb-0 fs-5 text-nowrap"> Udfold relevante</p>
              </button>
            )}
          </div>
        )}
      </div>
      {ejendomLoading && (
        <div className={`${styles.box} rounded-2`}>
          <GrundIcon /> <span>Henter Ejendom ...</span>
        </div>
      )}
      {!ejendomLoading &&
        renderEjendom(
          ejendom,
          chrOplysninger,
          listeState,
          currentOpgave,
          minimized,
          showOpgaver
        )}
      {!ejendom && !ejendomLoading && (
        <div className={styles.box}>
          <GrundIcon /> <span>Ingen grund fundet på opslag</span>
        </div>
      )}
    </PageContainer>
  );
}

const renderEjendom = (
  ejendom: Ejendom | undefined,
  ejdChrOplysninger: ChrOplysninger | undefined,
  listeState: ListeState | undefined,
  currentOpgave: Opgave | undefined,
  minimized: boolean,
  showOpgaver: boolean
) => {
  if (!ejendom) return null;

  const relevantSupplerendeOplysninger = getRelevantSupplerendeOplysninger(
    { ...ejendom.supplerendeOplysninger, chrOplysninger: ejdChrOplysninger },
    null
  );

  return (
    <div>
      <ExpandablePaper
        headerIcon={<MapMarker />}
        title="Ejendom"
        subtitle={`BFE: ${ejendom.bfeNr}`}
      >
        {
          <div>
            <SupplerendeOplysninger {...relevantSupplerendeOplysninger} />
          </div>
        }
      </ExpandablePaper>
      {renderGrunde(ejendom, listeState, currentOpgave, minimized, showOpgaver)}
    </div>
  );
};
export const formatEjerlav = (ejerlav: string) => {
  if (ejerlav) {
    const tokens = ejerlav.split(" ");
    let formattedTokens: string[] = [];
    tokens.forEach((t) => {
      if (t !== "") {
        formattedTokens.push(
          t.charAt(0).toUpperCase() + t.toLowerCase().slice(1)
        );
      }
    });
    return formattedTokens.join(" ");
  } else {
    return "";
  }
};
const renderGrunde = (
  ejendom: Ejendom | undefined,
  listeState: ListeState | undefined,
  currentOpgave: Opgave | undefined,
  minimized: boolean,
  showOpgaver: boolean
) => {
  if (!ejendom) return null;
  const liste = listeState?.data;
  if ("grunde" in ejendom && ejendom.grunde.length > 0) {
    const { grunde } = ejendom;
    return grunde.map(function (grund, i) {
      let title = `Grund`;
      if (grund.jordstykker && grund.jordstykker.length > 0) {
        title = `Grund - ${grund.navnFraJordstykker}`;
      }
      return (
        <Grund
          key={grund.grund?.["id_lokalId"]}
          expand={!minimized}
          grund={grund}
          liste={liste}
          opgave={currentOpgave}
          supplerendeOplysninger={ejendom.supplerendeOplysninger}
          title={title}
          showOpgaver={showOpgaver}
        />
      );
    });
  } else {
    return (
      <div className={styles.box}>
        <GrundIcon /> <span>Kunne ikke finde grund for BFE</span>
      </div>
    );
  }
};
interface MainProps {
  ejendom: Ejendom | undefined;
  ejendomLoading: boolean;
  chrOplysninger: ChrOplysninger | undefined;
  listeState: ListeState | undefined;
  currentOpgave: Opgave | undefined;
  showOpgaver: boolean;
}
