import Modal from "components/common/Modal";
import "./MapOverride.scss";

import BbrBaseMap from "components/Map/BbrBaseMap";
import {
  constructGrunddataKeysFromEjendom,
  constructMarkersFromEjendom,
} from "components/Map/basemapUtil";
export default function FullScreenMap(props: {
  ejendom: Ejendom | undefined;
  ejendomIsLoading: boolean;
  isToggled: boolean;
  setIsToggled: (isToggled: boolean) => void;
}) {
  const { ejendom, ejendomIsLoading, isToggled, setIsToggled } = props;
  return (
    <Modal isToggled={isToggled} setIsToggled={setIsToggled}>
      <div className="fullscreen-map-override">
        {!ejendomIsLoading && ejendom && (
          <BbrBaseMap
            divId="ejendom-full-screen-map-container"
            containerStyle={{
              height: "80vh",
              width: "80vw",
              margin: 15,
            }}
            grunddataKeys={constructGrunddataKeysFromEjendom(ejendom)}
            markers={constructMarkersFromEjendom(ejendom)}
          />
        )}
      </div>
    </Modal>
  );
}
