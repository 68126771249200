import axios from 'axios';
import { SearchResult, ResultType } from '@/models/SearchResult';
import {
    Adresse,
    Bygning,
    BBROpgang,
    EjendomBeliggenhed,
    Feature,
    FeatureCollection,
    Husnummer,
    SamletFastEjendom,
    Enhed,
} from './EjendomFetcher';
import { getUrlPaging as getUrlPagingHelper, getUrl as getUrlHelper } from './DafHelpers';

export class GrundFetcher {

    public static MapToSearchResult(
        id: string,
        source: string,
        result: Grund,
        resultType: ResultType,
    ): SearchResult {
        return {
            id: id || result.id_lokalId,
            title: `${result.adgangsadressebetegnelse || 'Ingen betegnelse'}`,
            // tslint:disable-next-line:max-line-length
            description: `Bygninger: ${result.bygninger.length}`,
            data: result,
            resultType,
            source,
            datatype: 'grund',
            icon: 'icon-source-ejendom',
        };
    }
    public dafUser: string;
    public dafPassword: string;
    public dafEjendomsrelationDate: string
    constructor(user: string, password: string, dafEjdDate) {
        this.dafUser = user;
        this.dafPassword = password;
        this.dafEjendomsrelationDate = dafEjdDate;
    }

    public async GetFromAdresse(
        adresseId: string,
    ): Promise<Grund[]> {
        const url = this.getUrlPaging(`DAR/DAR/2.0.0/REST/Adresse?id=${adresseId}`);
        const adresseResult = await axios.get(url);
        const adresser = adresseResult.data as Adresse[];
        if (!adresser || adresser.length === 0) {
            return [];
        }

        return await this.GetFromAdgangsAdresseOrJordstykke(
            adresser[0].husnummer.id_lokalId, adresser[0].husnummer.jordstykke,
        );
    }

    public async GetFromAdgangsAdresse(
        adgangsadresseId: string,
    ): Promise<Grund[]> {
        return await this.GetFromAdgangsAdresseOrJordstykke(adgangsadresseId, null);
    }

    public async GetFromJordstykke(
        ejerlav: string,
        matrikelnr: string,
    ): Promise<Grund[]> {
        // tslint:disable-next-line:max-line-length
        const url = this.getUrl(`Matriklen2/Matrikel/1.0.0/rest/SamletFastEjendom?Matrikelnr=${matrikelnr}&Ejerlavskode=${ejerlav}`);

        const sfeResult = await axios.get(url);
        const sfe = sfeResult.data as FeatureCollection<
            Feature<SamletFastEjendom>
        >;

        if (!sfe || sfe.features.length === 0) {
            return [];
        }

        return await this.GetFromBfe(sfe.features[0].properties.BFEnummer);
    }


    public async GetFromBfe(
        bfe: number,
    ): Promise<Grund[]> {
        const url = this.getUrlPaging(`BBR/BBRPublic/1/rest/grund?bfenummer=${bfe}&status=7`);

        const grundeResult = await axios.get(url);
        const grunde: Grund[] = grundeResult.data as Grund[];

        await this.Enrich(grunde);
        return grunde;
    }


    public async GetFromEsr(
        kommunekode: string,
        ejendomsnummer: string,
    ): Promise<Grund[]> {
        // tslint:disable-next-line:max-line-length
        const url = this.getUrl(`BBR/BBRPublic/1/rest/ejendomsrelation?ejendomsnummer=${ejendomsnummer}&kommunekode=${kommunekode}&virkningFra=${this.dafEjendomsrelationDate}&virkningTil=${this.dafEjendomsrelationDate}&status=7`);
        const response = await axios.get(url);
        const bfeNumre: number[] = [...new Set((response.data as Array<{ bfeNummer: number, status: string }>)
            .filter((r) => r.status !== '10' && r.status !== '11' && !!r.bfeNummer)
            .map(
                (r) => r.bfeNummer,
            ))];

        const grunde = [];

        for (const bfe of bfeNumre) {
            const result = await this.GetFromBfe(bfe);
            for (const grund of result) {
                if (grunde.findIndex((g) => g.id_lokalId === grund.id_lokalId) === -1) {
                    grunde.push(grund);
                }
            }
        }
        return grunde;
    }

    private async GetFromAdgangsAdresseOrJordstykke(
        adgangsadresseId: string,
        jordstykkeId: string,
    ): Promise<Grund[]> {

        let url = this.getUrlPaging(`BBR/BBRPublic/1/rest/grund?husnummer=${adgangsadresseId}&status=7`);
        let grundeResult = await axios.get(url);
        let grunde: Grund[] = grundeResult.data as Grund[];
        if (!grunde || grunde.length === 0) {
            let jordstykke = jordstykkeId;
            if (!jordstykke) {
                // tslint:disable-next-line:max-line-length
                const husnummerResult = (await axios.get(this.getUrlPaging(`DAR/DAR/2.0.0/REST/husnummer?id=${adgangsadresseId}`))).data as Husnummer[];
                if (husnummerResult && husnummerResult.length > 0) {
                    jordstykke = husnummerResult[0].jordstykke;
                }
            }

            if (jordstykke) {
                url = this.getUrlPaging(`BBR/BBRPublic/1/rest/grund?jordstykke=${jordstykke}&status=7`);
                grundeResult = await axios.get(url);
                grunde = grundeResult.data as Grund[];
            }
        }

        const bygninger = await this.getAllBygningerRelatedToHusnummer(adgangsadresseId);

        const bygningsGrundIds: string[] = bygninger.filter(
            (b) => grunde.findIndex(
                (g) => g.id_lokalId === b.grund) === -1).map((b) => b.grund);

        // Get other grund, that were not found in first call to BBR Grund with Husnummer.
        if (bygningsGrundIds.length > 0) {
            const grundIdStr = bygningsGrundIds.join('|');
            const bygningsGrundeResult = await axios.get(
                // tslint:disable-next-line:max-line-length
                this.getUrlPaging(`BBR/BBRPublic/1/REST/Grund?id=${grundIdStr}&status=7`),
            );
            for (const grund of bygningsGrundeResult.data as Grund[]) {
                if (grunde.findIndex((g) => g.id_lokalId === grund.id_lokalId) === -1) {
                    grunde.push(grund);
                }
            }
        }

        return await this.GetRelatedAndEnrich(grunde);
    }

    private getUrlPaging(area: string) {
        return getUrlPagingHelper(area, this.dafUser, this.dafPassword);
    }

    private getUrl(area: string) {
        return getUrlHelper(area, this.dafUser, this.dafPassword);
    }

    private async GetRelatedAndEnrich(
        grunde: Grund[],
    ): Promise<Grund[]> {

        const grundeResult: Grund[] = await this.GetRelated(grunde);
        await this.Enrich(grundeResult);
        return grundeResult;
    }


    private async GetRelated(
        grunde: Grund[],
    ): Promise<Grund[]> {

        const grundeResult: Grund[] = grunde;

        const bfeNumbers = [...new Set(grunde.filter(
            (g) => g.bestemtFastEjendom).map(
                (g) => g.bestemtFastEjendom.bfeNummer))];
        for (const bfe of bfeNumbers) {
            const url = this.getUrlPaging(`BBR/BBRPublic/1/rest/grund?bfenummer=${bfe}&status=7`);
            const result = await axios.get(url);
            for (const grund of result.data as Grund[]) {
                if (grundeResult.findIndex((g) => g.id_lokalId === grund.id_lokalId) === -1) {
                    grundeResult.push(grund);
                }
            }
        }

        return grundeResult;
    }

    private async Enrich(
        grunde: Grund[],
    ): Promise<void> {

        const bfeNumbers = [...new Set(grunde.filter(
            (g) => g.bestemtFastEjendom).map(
                (g) => g.bestemtFastEjendom.bfeNummer))];
        for (const bfe of bfeNumbers) {
            // tslint:disable-next-line:max-line-length
            const url = this.getUrlPaging(`EBR/Ejendomsbeliggenhed/1/rest/Ejendomsbeliggenhed?bfenr=${bfe}`);
            const result = (await axios.get(url)).data as FeatureCollection<Feature<EjendomBeliggenhed>>;
            if (result && result.features.length === 0) {
                continue;
            }
            for (const feature of result.features) {
                if (feature.properties.status.startsWith('g')) {
                    let husnummer = null;
                    if (feature.properties.husnummer && feature.properties.husnummer.length > 0) {
                        husnummer = feature.properties.husnummer[0];
                    }

                    for (const grund of grunde) {
                        if (grund.adgangsadressebetegnelse) {
                            continue;
                        }

                        if (husnummer && grund.husnummer === husnummer.id_lokalId) {
                            grund.adgangsadressebetegnelse = husnummer.adgangsadressebetegnelse;
                        } else if (!grund.husnummer &&
                            grund.bestemtFastEjendom &&
                            grund.bestemtFastEjendom.bfeNummer === bfe) {
                            grund.adgangsadressebetegnelse = husnummer ?
                                husnummer.adgangsadressebetegnelse :
                                feature.properties.betegnelse;
                        }
                    }
                }
            }
        }

        // tslint:disable-next-line:max-line-length
        const husnumre = [...new Set(grunde.filter((g) => g.husnummer && !g.adgangsadressebetegnelse).map((g) => g.husnummer))];
        for (const husnummer of husnumre) {
            const url = this.getUrlPaging(`DAR/DAR/2.0.0/rest/husnummer?id=${husnummer}&meddybde=false`);
            const result = await (await axios.get(url)).data as Husnummer[];
            if (result.length === 0) {
                continue;
            }
            for (const grund of grunde) {
                if (grund.husnummer === husnummer) {
                    grund.adgangsadressebetegnelse = result[0].adgangsadressebetegnelse;
                }
            }
        }

        for (const grund of grunde) {
            // tslint:disable-next-line:max-line-length
            const url = this.getUrlPaging(`BBR/BBRPublic/1/rest/Bygning?grund=${grund.id_lokalId}&meddybde=false&status=2|3|6|12|13`);
            grund.bygninger = (await axios.get(url)).data as Bygning[];
        }
    }

    private async getAllBygningerRelatedToHusnummer(
        adgangsadresseId: string,
    ): Promise<Bygning[]> {
        // Get Bygnigner with husnummer.
        const bygningFromHusnummerResult = await axios.get(
            this.getUrlPaging(`BBR/BBRPublic/1/REST/bygning?husnummer=${adgangsadresseId}&status=2|3|6|12|13`),
        );

        const bygninger: Bygning[] = bygningFromHusnummerResult.data as Bygning[];

        const husnummerFromHusnummer = await axios.get(
            this.getUrlPaging(`DAR/DAR/2.0.0/REST/husnummer?id=${adgangsadresseId}&`)
        );
        const hList: Husnummer[] = husnummerFromHusnummer.data as Husnummer[];

        const bygIdList: string[] = [];
        for (const h of hList){
            if(bygIdList.findIndex((b) => b === h.adgangTilBygning) === -1){
                bygIdList.push(h.adgangTilBygning);
            }
        }
        
        const adresseFromHusnummer = await axios.get(
            this.getUrlPaging(`DAR/DAR/2.0.0/REST/adresse?husnummer=${adgangsadresseId}`)
        );
        const adresser: Adresse[] = adresseFromHusnummer.data as Adresse[];

        if (bygIdList.length > 0) {
            const bygningFromOpgangResult = await axios.get(
                this.getUrlPaging(`BBR/BBRPublic/1/REST/bygning?id=${[...new Set(bygIdList)].join('|')}&status=2|3|6|12|13`),
            );

            for (const bygning of bygningFromOpgangResult.data as Bygning[]) {
                if (bygninger.findIndex((b) => b.id_lokalId === bygning.id_lokalId) === -1) {
                    bygninger.push(bygning);
                }
            }
        }

        let adresseToEnhedList: Adresse[] = [];
          if(adresser.length > 0){
            const adr0 = adresser.findIndex((e) => (e.dørbetegnelse == null || e.dørbetegnelse === '') && (e.etagebetegnelse == null || e.etagebetegnelse === ''));
              if (adr0 !== -1) adresseToEnhedList.push(adresser[adr0]);
              const adr1 = adresser.findIndex((e) => (e.dørbetegnelse != null && e.dørbetegnelse !== '') || (e.etagebetegnelse != null && e.etagebetegnelse !== ''));
              if (adr1 !== -1) adresseToEnhedList.push(adresser[adr1]);
          }

          let enhedList: Enhed[] = [];
          if(adresseToEnhedList.length > 0){
              for (const adr of adresseToEnhedList){
                  const enhed = await axios.get(
                    this.getUrlPaging(`BBR/BBRPublic/1/REST/enhed?adresseIdentificerer=${adr.id_lokalId}&status=2|3|6`),
                  );
                  enhedList = enhedList.concat( enhed.data as Enhed[]);
              }

              if (enhedList.length > 0){
                  const opgangFromEnhedList: Enhed[] = enhedList.filter((n, i, arr) => arr.findIndex(c => c.opgang === n.opgang) === i);
                  if(opgangFromEnhedList.length > 0){
                      const opg = [...new Set(opgangFromEnhedList.map(item => item.opgang))];
                      for(var i = 0; i < Math.ceil(opgangFromEnhedList.length / 50); i++ ){
                          const opgIdStr: string = opg.slice(i * 50, 50).join('|');
                          const bygningFromOpg = await axios.get(
                            this.getUrlPaging(`BBR/BBRPublic/1/REST/bygning?opgang=${opgIdStr}&status=2|3|6|12|13`),
                          );
                          const bygningList: Bygning[] = bygningFromOpg.data as Bygning[];
                          for (const b of bygningList){
                            if (bygninger.findIndex((bo) => b.id_lokalId === bo.id_lokalId) === -1){
                                bygninger.push(b);
                            }
                        }
                      }
                  }
              }
          }

        return bygninger;
    }

}

export interface Grund {
    id_lokalId: string;
    husnummer: string;
    kommunekode: string;
    bestemtFastEjendom: { bfeNummer: number };
    adgangsadressebetegnelse: string;
    bygninger: Bygning[];
}
