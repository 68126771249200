export function placeElementInViewport(el: HTMLElement) {
    if (!el) {
      return;
    }

    // TODO: skal rettes så id ikke er hardcoded
    const list = document.getElementById('ask-autocomplete');
    if (!list) {
      return;
    }
    const listRect = list.getBoundingClientRect();
    const elementRect = el.getBoundingClientRect();
    if (list.clientHeight < elementRect.top - listRect.top + el.clientHeight) {
      list.scrollTop =
        elementRect.top -
        listRect.top +
        el.clientHeight -
        list.clientHeight +
        list.scrollTop;
    } else if (elementRect.top - listRect.top < 0) {
      list.scrollTop = list.scrollTop + (elementRect.top - listRect.top);
    }
}
