import axios, { AxiosResponse } from "axios";
import { AuthState } from "context/AuthProvider";

const axiosConfig = {
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
};

export function validateAuth(authState: AuthState, requiredRole: string) {
  const { bruger } = authState;
  const roller = bruger ? bruger.roller : [];

  return roller.findIndex((role) => role.navn === requiredRole) > -1;
}

export async function get<T>({ query = "", apiPrefix = "/api/" }): Promise<T> {
  try {
    const response = await axios.get<T>(`${apiPrefix}${query}`, axiosConfig);
    return response.data;
  } catch (error) {
    return Promise.reject((error as any).message);
  }
}
export async function getWithErrorObject<T>({
  query = "",
  apiPrefix = "/api/",
}): Promise<T> {
  try {
    const response = await axios.get<T>(`${apiPrefix}${query}`, axiosConfig);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getResponse<T>({
  query = "",
  apiPrefix = "/api/",
}): Promise<AxiosResponse<T>> {
  try {
    const response = await axios.get<T>(`${apiPrefix}${query}`, axiosConfig);
    return response;
  } catch (error) {
    return Promise.reject((error as any).message);
  }
}

export async function put<T>({
  query = "",
  body = {},
  apiPrefix = "/api/",
}): Promise<T> {
  try {
    const response = await axios.put<T>(
      `${apiPrefix}${query}`,
      body,
      axiosConfig
    );
    return response.data;
  } catch (error) {
    return Promise.reject((error as any).message);
  }
}

export async function post<T>({
  query = "",
  body = {},
  apiPrefix = "/api/",
}): Promise<T> {
  try {
    const response = await axios.post<T>(
      `${apiPrefix}${query}`,
      body,
      axiosConfig
    );
    return response.data;
  } catch (error) {
    return Promise.reject((error as any).response);
  }
}
export async function del<T>({
  query = "",
  apiPrefix = "/api/",
}): Promise<AxiosResponse<T>> {
  try {
    return await axios.delete<T>(`${apiPrefix}${query}`, axiosConfig);
  } catch (error) {
    return Promise.reject((error as any).response);
  }
}

const api = {
  get,
  put,
  post,
  del,
};

export default api;
